import fscreen from 'fscreen';
import React, { useState } from 'react';

import { FullscreenContext } from './full-screen.context';

/** Renders full screen provider component */
const FullScreenProvider: React.FC = ({ children }) => {
	// Create local state
	const [isFullScreen, setIsFullScreen] = useState(false);

	// Toggle full screen mode
	const toggleFullScreen = () => {
		// Check if full screen is active
		if (!isFullScreen && fscreen.fullscreenElement === null) {
			// Request full screen mode
			fscreen.requestFullscreen(document.documentElement);
			// Update state
			setIsFullScreen(true);
		} else {
			// Exit full screen mode
			fscreen.exitFullscreen();
			// Update state
			setIsFullScreen(false);
		}
	};

	// Set value for context
	const contextValue = {
		isFullScreen,
		setIsFullScreen,
		toggleFullScreen,
	};

	return (
		<FullscreenContext.Provider value={contextValue}>
			{children}
		</FullscreenContext.Provider>
	);
};

export default FullScreenProvider;
