import React from 'react';
import styled, { css } from 'styled-components';

import OrderDetailsItemStatus from './order-details-item-status/order-details-item-status.component';
import OrderDetailsServiceCharge from './order-details-service-charge/order-details-service-charge.component';
import OrderDetailsTip from './order-details-tip/order-details-tip.component';

import { intl } from 'modules/core/i18n/i18n.config';
import { IOrderItem, IOrder, IServiceCharge } from 'modules/orders/order.types';

interface IComponentProps {
	nonRefundedItems: IOrderItem[];
	refundedItems: IOrderItem[];
	className?: string;
	order: IOrder;
	refundActive: boolean;
	serviceCharge?: IServiceCharge;
}

interface IStyledWrapper {
	refundActive: boolean;
}

const StyledOrderDetailsWrapper = styled.div<IStyledWrapper>`
	${({ refundActive }) =>
		refundActive &&
		css`
			> div:nth-child(1),
			> div:nth-child(3) {
				.sub-refund {
					opacity: 1;
				}
			}
		`}
`;

/** Renders order details items wrapper component */
const OrderDetailsItemsWrapper: React.FC<IComponentProps> = ({
	nonRefundedItems,
	refundedItems,
	className,
	order,
	refundActive,
	serviceCharge,
}) => {
	return (
		<StyledOrderDetailsWrapper
			className={className}
			refundActive={refundActive}
		>
			<OrderDetailsItemStatus
				orderStatus={order.status}
				isVisible={nonRefundedItems.length > 0}
				orderId={order.id}
				title={intl.formatMessage({
					id: 'order_details.item.status.items',
				})}
				items={nonRefundedItems}
				refundActive={refundActive}
			/>
			<OrderDetailsItemStatus
				orderStatus={order.status}
				isVisible={refundedItems.length > 0}
				isRefunded={true}
				orderId={order.id}
				title={intl.formatMessage({
					id: 'order_details.item.status.refunded',
				})}
				items={refundedItems}
			/>
			{!!serviceCharge && (
				<OrderDetailsServiceCharge
					serviceCharge={serviceCharge}
					refundActive={refundActive}
				/>
			)}
			{!!order.tip.amount && (
				<OrderDetailsTip tip={order.tip} refundActive={refundActive} />
			)}
		</StyledOrderDetailsWrapper>
	);
};

export default OrderDetailsItemsWrapper;
