import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { ReactComponent as terminalHistory } from 'assets/svg/terminalHistory.svg';
import { ReactComponent as terminalLogo } from 'assets/svg/terminalLogo.svg';
import { ReactComponent as terminalMenus } from 'assets/svg/terminalMenus.svg';
import { ReactComponent as terminalModifiers } from 'assets/svg/terminalModifiers.svg';
import { ReactComponent as terminalNotes } from 'assets/svg/terminalNotes.svg';
import { ReactComponent as terminalReceipts } from 'assets/svg/terminalReceipts.svg';
import { ReactComponent as terminalTipping } from 'assets/svg/terminalTipping.svg';
import Button from 'components/button/button.component';

export const Container = styled.div`
	height: 100%;
	display: flex;
	padding-bottom: 100px;
	flex-direction: column;
	overflow: auto;
	align-items: center;
	justify-content: center;

	${mq.mobile`
		padding-bottom: 0;
	`}
`;

export const Hero = styled.div`
	width: 100%;
	height: 216px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background-image: url('../images/terminal-hero.png');
	overflow: visible;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	${mq.mobile`
		height: 117px;
	`}
`;

export const DeviceImage = styled.div`
	width: 460px;
	height: 350px;
	background-image: url('../images/terminal-hero-device.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	${mq.mobile`
		width: 221px;
		height: 152px;
		background-size: cover;
		background-position: initial;
	`}
`;

export const Logo = styled(terminalLogo)`
	width: 349px;
	height: 32px;
	margin: 125px auto 25px;
	display: block;

	${mq.mobile`
		width: 244px;
		margin-top: 45px;
	`}
`;

export const Title = styled.h3`
	margin-bottom: 30px;
	font-size: ${fonts.sizes.larger};
	font-weight: ${fonts.weights.regular};

	${mq.mobile`
		font-size: ${fonts.sizes.large};
		text-align: center;
		margin: 0 45px;
	`}
`;

export const FeaturesList = styled.ul`
	width: 100%;
	list-style: none;
	margin: 0 0 45px 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	${mq.mobile`
		padding: 20px 48px 0px 48px;
		display: grid;
  	grid-template-columns: 1fr 1fr 1fr;
		gap: 8px;
	`}

	li {
		margin: 0 12px 0 0;
		padding: 6px 12px 6px;
		display: flex;
		align-items: center;
		border: 1px solid ${brand.borders};
		border-radius: 4px;
		font-size: ${fonts.sizes.smaller};
		font-weight: ${fonts.weights.semibold};

		${mq.mobile`
			margin: 0;
			min-height: 66px;
			flex-direction: column;

			div {
				text-align: center;
			}
		`}

		&:last-child {
			margin-right: 0;
		}

		.icon {
			width: 22px;
			height: 22px;
			color: ${brand.opBlue};
		}
	}
`;

export const TerminalTippingIcon = styled(terminalTipping)``;
export const TerminalMenusIcon = styled(terminalMenus)``;
export const TerminalReceiptsIcon = styled(terminalReceipts)``;
export const TerminalHistoryIcon = styled(terminalHistory)``;
export const TerminalModifiersIcon = styled(terminalModifiers)``;
export const TerminalNotesIcon = styled(terminalNotes)``;

export const Actions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		width: 200px;
		font-weight: ${fonts.weights.medium};
		border: 1px solid ${brand.opBlue};
	}

	${mq.mobile`
		flex-direction: column-reverse;
		width: 100%;
		padding: 0 18px;

		button {
			width: 100%;
			margin-bottom: 10px;
		}
	`}
`;

export const GetTerminalButton = styled(Button)`
	margin-right: 10px;
	background: transparent;
	color: ${brand.opBlue};

	&:hover {
		background: ${brand.opBlue};
		color: ${brand.white};
	}

	${mq.mobile`
		margin-right: 0px;
	`}
`;

export const AddFirstButton = styled(Button)`
	background: ${brand.opBlue};

	&:hover {
		background: none;
		color: ${brand.opBlue};
	}
`;
