import currency from 'currency.js';

/**
 * Format a price in pence to its string representation. If the price is `undefined`,
 * it defaults to <<currency symbol>> + '0.00' i.e. £0.00.
 *
 * For example:
 *
 * formatPrice(500) => '£5.00'
 *
 * formatPrice(6050, '$') => '$60.50'
 *
 * @param price The price in pence to be formatted
 * @param currencySymbol The currency symbol to use. Defaults to '£'
 */
export function formatPrice(
	price: number | undefined,
	currencySymbol = '£'
): string {
	return currency(price || 0, { fromCents: true, precision: 2 }).format({
		symbol: currencySymbol,
	});
}
