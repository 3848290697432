import { Form } from 'formik';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Input from 'components/form-inputs/input/input.component';
import Select from 'components/form-inputs/select/select.component';

export const StockRowCss = css`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: 2px 15px 2px 6px;
	gap: 6px;
	overflow-wrap: anywhere;

	> * {
		flex: 1 1 auto;
	}

	[data-cell-type='img'] {
		flex: 0 0 38px;
		height: 38px;
		width: 38px;

		&.mod-image {
			border-radius: 3px;
			display: flex;
			align-items: center;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
		}
		&.mod-no-image {
			background-color: ${brand.body};
		}
		img {
			width: 100%;
			height: auto;
		}
	}

	[data-cell-type='text'] {
		text-align: left;
		flex: 0 1 300px;
	}

	[data-cell-type='switch'] {
		flex: 0 0 428px;
	}

	[data-cell-type='icon'] {
		flex: 1 1 44px;
		display: flex;
		justify-content: flex-end;
	}

	${mq.desktopDown`
        [data-cell-type='switch'] {
            flex: 0 0 200px;
        }
    `}

	${mq.mobile`
        flex-wrap: wrap;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 6px 6px 12px;
        gap: 6px;

        [data-cell-type='text'], 
        [data-cell-type='img'] {
            flex: 0 0 auto;
        }

        [data-cell-type='text']:empty{
            display: none;
        }

        .mod-no-image {
            display: none;
        }

        [data-cell-type='switch'] {
            flex: 0 0 auto;
            width: 100%;
        }

        [data-cell-type="icon"] {
            position: absolute;
            top: 4px;
            right: 4px;
        }
    `}
`;

export const StyledHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 1% 18px 0;

	${mq.mobile`
    background: ${brand.white};
    justify-content: space-between;
    align-items: center;
    margin: -10px 0 15px;
    padding: 28px 1% 17px;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid ${rgba(brand.text, 0.25)};

`}
`;

export const StyledMobileFilter = styled.div`
	display: none;

	${mq.mobile`
    display: block;
`}
`;

export const StyledOrderFilters = styled.div`
	margin: 16px 0 0;
	width: 100%;
	max-width: 710px;

	${mq.mobile`
    padding: 0 1% 0;
    background: ${brand.white};
    max-height: 0;
    overflow: hidden;
    margin: 0;
    transition: all 0.3s ease;

    &.mod-mobile-filters {
        padding: 12px 1% 0;
        max-height: 370px;
        overflow: visible;
    }
`}
`;

export const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	justify-content: flex-end;

	${mq.mobile`
    flex-direction: column;
`}
`;

export const StyledInput = styled(Input)`
	min-width: 0;
	margin: 0;
	max-width: 231px;

	${mq.mobile`
    width: 100%;
    margin: 0;
    max-width: none;
`}

	input {
		background-color: ${brand.neutralLight};
		font-size: ${fonts.sizes.large};
		font-weight: ${fonts.weights.light};

		${mq.mobile`
        font-size: ${fonts.sizes.standard};
    `}
	}
`;

export const StyledSelect = styled(Select)`
	min-width: 0;
	margin: 0 10px 0 0;
	max-width: 231px;

	${mq.mobile`
    width: 100%;
    margin: 0 0 5px;
    max-width: none;
`}
`;

export const StyledStockListHeader = styled.div`
    ${StockRowCss}
    font-size: ${fonts.sizes.standard};
    font-weight: ${fonts.weights.medium};
    color: ${rgba(brand.text, 0.4)};
    padding-bottom: 6px;
    ${mq.mobile`
        display: none;
    `}
`;

export const StyledListWrapper = styled.div`
	padding: 0;
	list-style: none;
`;

export const StyledEmptyHeading = styled.h2`
	margin: 50px 15px 0 15px;
	text-align: center;
`;

export const listHeightCss = (
	viewHeight?: number,
	mobileFiltersActive?: boolean
) => css`
	height: calc((${viewHeight}px * 100) - 225px);

	${mq.tabletDown`
    height: calc((${viewHeight}px * 100) - 280px);
`}

	${mobileFiltersActive
		? mq.mobile`
    height: 100%;
    max-height: calc((${viewHeight}px * 100) - 365px);
`
		: mq.mobile`
    height: 100%;
    max-height: calc((${viewHeight}px * 100) - 235px);
`}
`;

interface IStyledComponent {
	viewHeight?: number;
	mobileFiltersActive?: boolean;
}
export const StyledStockList = styled.div<IStyledComponent>`
	overflow: scroll;
	transition: max-height 0.3s ease;
	${({ viewHeight, mobileFiltersActive }) =>
		listHeightCss(viewHeight, mobileFiltersActive)}
	${mq.mobile`
        padding: 0 15px;
    `}
`;
