// Function to check if device is running IOS
export const isIos = () =>
	[
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod',
	].includes(navigator.platform) ||
	// iPad on iOS 13 detection
	(navigator.userAgent.includes('Mac') && 'ontouchend' in document);

// Function to check if device iPhone (or iPod)
export const isIphone = () =>
	['iPhone Simulator', 'iPod Simulator', 'iPhone', 'iPod'].includes(
		navigator.platform
	);
