import { createBrowserHistory } from 'history';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Router, Redirect } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import {
	GuardToRoute,
	Next,
	GuardFunctionRouteProps,
	GuardFunction,
} from 'react-router-guards/dist/types';

import { fireDialog } from '../dialog/dialog.service';
import FeatureToggles from '../feature-toggles/feature-toggles.component';
import { intl } from '../i18n/i18n.config';
import { RootState } from '../state/root.reducer';
import NotFoundPage from './not-found.page';

import ForgotPasswordPage from 'modules/auth/forgot-password/forgot-password.page';
import LoginPage from 'modules/auth/login/login.page';
import ResetPasswordPage from 'modules/auth/reset-password/reset-password.page';
import CompletedOrdersPage from 'modules/completed-orders/completed-orders.page';
import DashboardPage from 'modules/dashboard/dashboard.page';
import FutureOrdersPage from 'modules/future-orders/future-orders.page';
import SettingsPage from 'modules/settings/settings.page';
import StockPage from 'modules/stock/stock.page';
import TerminalPage from 'modules/terminal/terminal.page';
import TicketView from 'modules/ticket-view/ticket-view.page';

// Create an export browser history
export const history = createBrowserHistory({
	// Overide default confirmation
	async getUserConfirmation(message, callback) {
		// Fire dialog
		const { value } = await fireDialog({
			title: intl.formatMessage({ id: 'appRouter.navigation.confirm.title' }),
			text: intl.formatMessage({ id: 'appRouter.navigation.confirm.text' }),
			showCancelButton: true,
		});
		// callback result from dialog
		callback(value);
	},
});

/** Router component containing all application routes */
const AppRouter: React.FC = () => {
	const hasAuth: boolean = useSelector(
		(state: RootState) => state.auth.hasAuth
	);
	/** Route guard for routes requiring login */
	const requireLogin: GuardFunction = async (
		to: GuardToRoute,
		from: GuardFunctionRouteProps | null,
		next: Next
	) => {
		// If route requires auth
		if (to.meta.auth) {
			hasAuth ? next() : next.redirect('/');
		} else {
			next();
		}
	};

	return (
		<Router history={history}>
			<FeatureToggles />
			<GuardProvider guards={[requireLogin]} error={NotFoundPage}>
				<Switch>
					<Route
						exact
						path="/"
						render={({ location }) =>
							hasAuth ? (
								<Redirect
									to={{
										pathname: '/dashboard',
										state: { from: location },
									}}
								/>
							) : (
								<LoginPage />
							)}
					/>
					<Route exact path="/forgot" render={() => <ForgotPasswordPage />} />
					<Route exact path="/reset" render={() => <ResetPasswordPage />} />
					<GuardedRoute
						exact
						path="/dashboard/:date?"
						render={() => <DashboardPage />}
						meta={{ auth: true }}
					/>
					<GuardedRoute
						exact
						path="/future-orders/:date"
						render={() => <FutureOrdersPage />}
						meta={{ auth: true }}
					/>
					<GuardedRoute
						exact
						path="/completed-orders/:date?"
						render={() => <CompletedOrdersPage />}
						meta={{ auth: true }}
					/>
					<GuardedRoute
						exact
						path="/stock"
						render={() => <StockPage />}
						meta={{ auth: true }}
					/>
					<GuardedRoute
						exact
						path="/ticket-view/:primaryTab"
						render={() => <TicketView />}
						meta={{ auth: true }}
					/>
					<GuardedRoute
						exact
						path="/settings/:primaryTab"
						render={() => <SettingsPage />}
						meta={{ auth: true }}
					/>
					<GuardedRoute
						exact
						path={['/terminal', '/terminal/:step']}
						render={() => <TerminalPage />}
						meta={{ auth: true }}
					/>
					<Route component={NotFoundPage} />;
				</Switch>
			</GuardProvider>
		</Router>
	);
};

export default AppRouter;
