import * as React from 'react';
import styled, { css } from 'styled-components';
// Interface for component props
interface IComponentProps {
	className?: string;
	testId?: string;
	isDisabled?: boolean;
}

const disabledStyling = css`
	opacity: 0.3;
	pointer-events: none;
`;

// Create styled form element component
const StyledFormElement = styled.div<IComponentProps>`
	width: 100%;
	margin: 0 0 15px;
	position: relative;

	${({ isDisabled }) => isDisabled && disabledStyling}
`;

/** Renders form element component */
const FormElement: React.FC<IComponentProps> = ({
	children,
	className,
	testId,
	isDisabled,
}) => {
	return (
		<StyledFormElement
			data-testid={testId}
			className={className}
			isDisabled={isDisabled}
		>
			{children}
		</StyledFormElement>
	);
};

export default FormElement;
