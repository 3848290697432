import { IntlShape } from 'react-intl';
import { number, string } from 'yup';

import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';

// Interface for string validation
interface IStringValidation {
	fieldName: string;
	websiteValidation?: boolean;
	hexValidation?: boolean;
}

// Interface for number validation
interface INumberValidation {
	fieldName: string;
	min: number;
	max: number;
}

// Function to create default string validation
export const genericValidationString = ({ fieldName }: IStringValidation) => {
	// Create schema with required, min & max
	const schema = string()
		.required(validationMessages.required(fieldName))
		.min(1, validationMessages.minLength(fieldName))
		.max(255, validationMessages.maxLength(fieldName));

	return schema;
};

// Function to create default number validation
export const genericValidationNumber = ({
	fieldName,
	min,
	max,
}: INumberValidation) => {
	// Create schema with required, min, max and check if it's a number
	return number()
		.typeError(validationMessages.number(fieldName))
		.required(validationMessages.number(fieldName))
		.min(min, validationMessages.minNumber(fieldName, min))
		.max(max, validationMessages.maxNumber(fieldName, max));
};

export const priceValidationTestFunction = (value: string): boolean =>
	!isNaN(Number(value.trim().replace(/^[£|€|$]/gm, '')));

export const priceValidationString = (fieldName: string, intl: IntlShape) => {
	return string()
		.required(validationMessages.required(fieldName))
		.test(
			'price',
			({ value }) =>
				value
					? intl.formatMessage(
						{ id: 'errors.forms.validation.price' },
						{ value }
					  )
					: intl.formatMessage({ id: 'errors.forms.validation.priceNoValue' }),
			priceValidationTestFunction
		);
};
