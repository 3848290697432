import { rgba } from 'polished';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';

export const StyledMain = styled.main`
	height: 100vh;
	margin: 0 1%;
	padding-bottom: 100px;
	overflow: auto;
`;

export const StyledHeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${mq.mobile`
		margin: -10px 0 15px;
		padding: 28px 1% 0;
		border-bottom: 1px solid ${rgba(brand.text, 0.25)};
		align-items: flex-start;
		background: ${brand.white};

		> header {
			flex-basis: auto;
			margin-bottom: 12px;
		}
	`}

	button {
		padding: 15px 45px;

		${mq.mobile`
			padding: 15px 20px;
			margin-left: 10px;
			align-self: flex-end;
		`}
	}
`;

export const StyledHeaderItems = styled.div`
	width: 100%;
	margin: 0 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
