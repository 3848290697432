import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import InstallLoading from '../install-loading/install-loading.component';
import {
	Form,
	Field,
	FieldGroup,
	Actions,
	Container,
	HelpText,
	Tooltip,
} from './stripe-install.styles';

import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import { RootState } from 'modules/core/state/root.reducer';
import { onboardStripe } from 'modules/terminal/terminal.slice';

const StripeInstall: React.FC = () => {
	const dispatch = useDispatch();

	const [isInstalling, setIsInstalling] = useState(false);

	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	interface IFormValues {
		word1: string;
		word2: string;
		word3: string;
	}

	const initialFormValues: IFormValues = {
		word1: '',
		word2: '',
		word3: '',
	};

	const handleFormSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		setSubmitting(true);
		const stripeOnboardToken = `${values.word1}-${values.word2}-${values.word3}`;

		const response = await dispatch(
			onboardStripe(stripeOnboardToken.toLowerCase(), selectedVenue.id)
		);

		if (!response) {
			setSubmitting(false);
			return;
		}

		setSubmitting(false);
		setIsInstalling(true);
	};

	return (
		<div>
			{!isInstalling ? (
				<Container>
					<h2>
						<FormattedMessage id="terminal.install.title" />
					</h2>
					<p>
						<FormattedMessage id="terminal.install.text" />
					</p>
					<Formik
						enableReinitialize
						initialValues={initialFormValues}
						onSubmit={handleFormSubmit}
					>
						{({ isSubmitting, values }) => (
							<Form>
								<FieldGroup>
									<Field component={Input} name="word1" />
									<Field component={Input} name="word2" />
									<Field component={Input} name="word3" />
								</FieldGroup>
								<Actions>
									<Button
										type="submit"
										disabled={
											isSubmitting ||
											values.word1 === '' ||
											values.word2 === '' ||
											values.word3 === ''
										}
										ariaLabel="submit-button"
									>
										<FormattedMessage id="terminal.install.submit" />
									</Button>
									<HelpText>
										<Tooltip>
											<FormattedMessage id="terminal.tooltip.nocode" />
											<a href="mailto:support@orderpay.com">
												support@orderpay.com
											</a>
										</Tooltip>
										<FormattedMessage id="terminal.install.help" />
									</HelpText>
								</Actions>
							</Form>
						)}
					</Formik>
				</Container>
			) : (
				<InstallLoading />
			)}
		</div>
	);
};

export default StripeInstall;
