import { Formik, Field, FormikProps } from 'formik';
import queryString from 'query-string';
import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import Header from '../../components/header/header.component';
import StockListItem from './stock-list-item/stock-list-item.component';
import { getStockList, resetStockState } from './stock.slice';
import {
	StyledHeaderWrapper,
	StyledMobileFilter,
	StyledOrderFilters,
	StyledForm,
	StyledSelect,
	StyledInput,
	StyledStockList,
	StyledListWrapper,
	StyledEmptyHeading,
	StyledStockListHeader,
} from './stock.style';
import { IStockListFilters } from './stock.types';

import { IQueryParams } from 'app.types';
import { IOption } from 'components/form-inputs/select/select.component';
import Icon from 'components/icons/icon.component';
import Pagination from 'components/pagination/pagination.component';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import {
	getVenueMenuCategories,
	getVenueMenus,
} from 'modules/venue/venue.slice';

interface ISearchFormValues {
	search: string;
	menu: string;
	category: string;
}

interface ISelectChangeValue {
	fieldValue: string;
	form: FormikProps<ISearchFormValues>;
}

/** Renders stock page component */
const StockPage: React.FC = () => {
	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const { selectedVenue, venueMenus, venueMenuCategories } = useSelector(
		(state: RootState) => state.venue
	);

	const menuOptions: IOption<string>[] =
		venueMenus?.map((menu) => {
			return {
				label: menu.name,
				value: menu.id,
			};
		}) || [];

	const categoryOptions: IOption<string>[] =
		venueMenuCategories?.map((menu) => {
			return {
				label: menu.name,
				value: menu.id,
			};
		}) || [];

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);
	const pagination = useSelector((state: RootState) => state.stock.pagination);
	const { stock } = useSelector((state: RootState) => state.stock);
	const [searchQuery, setSearchQuery] = useState<string>(query.search || '');
	const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false);

	// Debounce searchTerm changes
	const [debouncedSearch] = useDebounce(searchQuery, 300);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	useEffect(() => {
		const getData = async () => {
			if (selectedVenue) {
				// Creat object of filters from query params
				const filters: IStockListFilters = {
					search: query.search,
					pageNumber: parseFloat(query.pageNumber!) || 1,
					menu: query.menu,
					category: query.category,
				};

				// get stock list
				await dispatch(getStockList(selectedVenue.id, filters));

				// get venue menus
				await dispatch(getVenueMenus(selectedVenue.id));

				// get venue menu categories if menu is set
				query.menu &&
					(await dispatch(
						getVenueMenuCategories(selectedVenue.id, query.menu)
					));
			}
		};

		getData();

		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => {
			window.removeEventListener('resize', getSetViewHeight);
			dispatch(resetStockState());
		};
	}, [
		dispatch,
		selectedVenue,
		query.search,
		query.pageNumber,
		query.category,
		query.menu,
	]);

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(`${location.pathname}?${queryString.stringify(filters)}`);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearch, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearch]);

	// Function to filter stock list and change route
	const handleStockFilter = ({
		category = query.category,
		menu = query.menu,
	}) => {
		// create menu filters
		const filters: IStockListFilters = {};

		// add filters if available
		if (menu) {
			filters.menu = menu;
			!!category && (filters.category = category);
		}

		// remove page number & search term on search
		delete filters.pageNumber;
		delete filters.search;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	return (
		<main>
			<StyledHeaderWrapper>
				<Header title="stock.header.title" />
				<StyledMobileFilter
					onClick={() => setShowMobileFilters(!showMobileFilters)}
				>
					<Icon
						name="filters"
						colour="primary"
						widthMobile={20}
						heightMobile={20}
					/>
				</StyledMobileFilter>
				<StyledOrderFilters
					className={`${showMobileFilters && 'mod-mobile-filters'}`}
				>
					<Formik
						initialValues={{
							search: query.search,
							menu: query.menu,
							category: query.category,
						}}
						onSubmit={() => {}}
					>
						{({ values, setFieldValue }) => {
							return (
								<StyledForm>
									<Field
										component={StyledSelect}
										name="menu"
										placeholder={intl.formatMessage({
											id: 'form.fields.menuSelect.label',
										})}
										handleChangeEvent={async (value: ISelectChangeValue) => {
											handleStockFilter({
												menu: value.fieldValue || '',
											});

											// Reset the category filter
											setFieldValue('category', '');
										}}
										isClearable={true}
										size="sm"
										selectOptions={menuOptions}
										value={menuOptions.find(
											(menu) => menu.value === values.menu
										)}
									/>
									{categoryOptions.length > 0 && (
										<Field
											component={StyledSelect}
											name="category"
											placeholder={intl.formatMessage({
												id: 'form.fields.categorySelect.label',
											})}
											handleChangeEvent={async (value: ISelectChangeValue) =>
												handleStockFilter({ category: value.fieldValue || '' })}
											size="sm"
											isClearable={true}
											selectOptions={categoryOptions}
											isDisabled={!query.menu}
											value={
												values.menu && values.category
													? categoryOptions.find(
														(cat) => cat.value === values.category
													  )
													: ''
											}
										/>
									)}
									<Field
										component={StyledInput}
										name="search"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={15}
										iconHeight={15}
										iconWidthMobile={15}
										iconheightMobile={15}
										iconColour="placeholderLight"
										iconPlacement="left"
										handleChangeEvent={(value: string) => {
											setSearchQuery(value);
										}}
									/>
								</StyledForm>
							);
						}}
					</Formik>
				</StyledOrderFilters>
			</StyledHeaderWrapper>
			{stock && stock.length > 0 ? (
				<>
					<StyledListWrapper>
						<StyledStockListHeader>
							<div data-cell-type="img" />
							<div data-cell-type="text">
								<FormattedMessage id="stock.list.headings.product" />
							</div>
							<div data-cell-type="text">
								<FormattedMessage id="stock.list.headings.sku" />
							</div>
							<div data-cell-type="text">
								<FormattedMessage id="stock.list.headings.type" />
							</div>
							<div data-cell-type="switch">
								<FormattedMessage id="stock.list.headings.status" />
							</div>
							<div data-cell-type="icon" />
						</StyledStockListHeader>
						<StyledStockList
							viewHeight={viewHeight}
							mobileFiltersActive={showMobileFilters}
						>
							{stock.map((item) => (
								<StockListItem key={item.id} item={item} />
							))}
						</StyledStockList>
					</StyledListWrapper>
					{pagination && pagination.pageCount > 1 && (
						<Pagination
							pageNumber={query.pageNumber ? parseFloat(query.pageNumber) : 1}
							pageCount={pagination.pageCount}
							setPageNumber={handlePagination}
						/>
					)}
				</>
			) : (
				<StyledEmptyHeading>
					<FormattedMessage id="stock.list.empty" />
				</StyledEmptyHeading>
			)}
		</main>
	);
};

export default withNav(StockPage);
