import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { ITicketViewStore } from './ticket-view.types';

import { IOrder } from 'modules/orders/order.types';

// Create initial state for ticket view slice
export const initialTicketViewState: ITicketViewStore = {
	eventsInProgress: 0,
	completedOrders: [],
};

const ticketViewSlice = createSlice({
	name: 'ticketView',
	initialState: initialTicketViewState,
	reducers: {
		ADD_COMPLETED_TICKET(state, action) {
			return {
				...state,
				completedOrders: [...state.completedOrders, action.payload.order],
			};
		},
		REMOVE_COMPLETED_TICKET(state, action) {
			return {
				...state,
				completedOrders: state.completedOrders.filter(
					(order: IOrder) => order.id !== action.payload.id
				),
			};
		},
		SET_ORDER_FILTERS(state, action) {
			return {
				...state,
				orderFilters: action.payload.filters,
			};
		},
		RESET_TICKET_VIEW_STATE(state) {
			return initialTicketViewState;
		},
	},
});

// Destructure and export the plain action creators
export const {
	ADD_COMPLETED_TICKET,
	REMOVE_COMPLETED_TICKET,
	SET_ORDER_FILTERS,
	RESET_TICKET_VIEW_STATE,
} = ticketViewSlice.actions;

/** Thunk to process add completed ticket request */
export const addCompletedTicket = (order: IOrder) => async (
	dispatch: Dispatch
) => {
	// Add completed ricket
	await dispatch(
		ADD_COMPLETED_TICKET({
			order,
		})
	);
};

/** Thunk to process remove completed ticket request */
export const removeCompletedTicket = (id: string) => (dispatch: Dispatch) => {
	return dispatch(
		// remove completed ricket
		REMOVE_COMPLETED_TICKET({
			id,
		})
	);
};

/** Thunk to set order filters request */
export const setOrderFilters = (filters: string[]) => (dispatch: Dispatch) => {
	return dispatch(
		// remove completed ricket
		SET_ORDER_FILTERS({
			filters,
		})
	);
};

/** Reset ticket view State */
export const resetTicketViewState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_TICKET_VIEW_STATE());
};

export default ticketViewSlice.reducer;
