import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import {
	formatVenueOpeningTimesToApi,
	formatVenueOpeningTimesToForm,
} from './helpers/opening-times-formatter.helper';
import {
	IVenueStore,
	IPayloadProcessUpdateVenueDates,
	IPauseOrdersFormValues,
	IOfflineNotificationSettings,
	IPusherPausedService,
	IServiceType,
	IPayloadProcessUpdatePausedServiceTypes,
	IVenueConfig,
	IVenueServiceAvailabilitySubmitValues,
	IVenueOpeningFormValues,
	VenueServiceWaitTimesDto,
	IVenue,
	IPauseOrdersByMenuData,
} from './venue.types';

import dayjs from 'helpers/dayjs.helper';

// Create initial state
export const initialVenueState: IVenueStore = {
	eventsInProgress: 0,
	selectedVenue: {
		id: '',
		logoUrl: '',
		name: '',
		hasPausedOrders: false,
		serviceTypes: [],
		orderAlertEnabled: true,
		orderAlertTime: 2,
		orderAlertEscalationTime: 1,
	},
	venues: [],
	venueDates: [],
	timeslots: {},
	printingEnabled: false,
};

const venueSlice = createSlice({
	name: 'venue',
	initialState: initialVenueState,
	reducers: {
		VENUE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		VENUE_SUCCESS(state, action) {
			return {
				...state,
				selectedVenue: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_VENUE(state, action) {
			return {
				...state,
				selectedVenue: action.payload,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		VENUES_SUCCESS(state, action) {
			return {
				...state,
				selectedVenue: action.payload.data[0],
				venues: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_DATES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		VENUE_DATES_SUCCESS(state, action) {
			return {
				...state,
				venueDates: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_DATES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_TIMESLOTS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		VENUE_TIMESLOTS_SUCCESS(state, action) {
			return {
				...state,
				timeslots: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_TIMESLOTS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_OFFLINE_NOTIFICATION_SETTINGS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_OFFLINE_NOTIFICATION_SETTINGS_SUCCESS(state, action) {
			return {
				...state,
				offlineNotificationSettings: {
					notificationEmail: action.payload.data.venues[0]?.notificationEmail,
					notificationEnabled:
						action.payload.data.venues[0]?.notificationEnabled,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_OFFLINE_NOTIFICATION_SETTINGS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_VENUE_OFFLINE_NOTIFICATION_SETTINGS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PUT_VENUE_OFFLINE_NOTIFICATION_SETTINGS_SUCCESS(state, action) {
			return {
				...state,
				offlineNotificationSettings: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_VENUE_OFFLINE_NOTIFICATION_SETTINGS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_PAUSE_ORDERS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		VENUE_PAUSE_ORDERS_SUCCESS(state, action) {
			return {
				...state,
				selectedVenue: {
					...state.selectedVenue,
					hasPausedOrders: true,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_PAUSE_ORDERS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_PAUSE_ORDER_SERVICES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		VENUE_PAUSE_ORDER_SERVICES_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_PAUSE_ORDER_SERVICES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_UNPAUSE_ORDERS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		VENUE_UNPAUSE_ORDERS_SUCCESS(state, action) {
			return {
				...state,
				selectedVenue: {
					...state.selectedVenue,
					hasPausedOrders: false,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_UNPAUSE_ORDERS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_MENUS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_MENUS_SUCCESS(state, action) {
			return {
				...state,
				venueMenus: action.payload.data.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_MENUS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_MENU_CATEGORIES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_MENU_CATEGORIES_SUCCESS(state, action) {
			return {
				...state,
				venueMenuCategories: action.payload.data.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_MENU_CATEGORIES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				venueConfig: action.payload.data,
			};
		},
		GET_VENUE_CONFIG_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_VENUE_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PATCH_VENUE_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_VENUE_CONFIG_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_OPENING_TIMES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_OPENING_TIMES_SUCCESS(state, action) {
			const formattedOpeningTimes = formatVenueOpeningTimesToForm(
				action.payload.data
			);

			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				openingTimes: formattedOpeningTimes,
				openingTimesArray: Object.values(formattedOpeningTimes),
			};
		},
		GET_VENUE_OPENING_TIMES_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_VENUE_OPENING_TIMES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PUT_VENUE_OPENING_TIMES_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_VENUE_OPENING_TIMES_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VENUE_UPDATE_DATES_HAS_ORDER(state, action) {
			return processUpdateVenueDateHasOrder(state, action);
		},
		VENUE_UPDATE_PAUSED_SERVICE_TYPES(state, action) {
			return processUpdatePausedServiceTypes(state, action);
		},
		GET_VENUE_PRINTING_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_PRINTING_CONFIG_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_PRINTING_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				printingEnabled:
					/(android)/i.test(navigator.userAgent) &&
					action.payload.data.includes('quickprinter'),
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_AVAILABILITY(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_SERVICE_AVAILABILITY_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_AVAILABILITY_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_SERVICE_WAIT_TIMES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_SERVICE_WAIT_TIMES_SUCCESS(state, action) {
			return {
				...state,
				serviceWaitTimes: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_SERVICE_WAIT_TIMES_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_WAIT_TIMES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_SERVICE_WAIT_TIMES_SUCCESS(state, action) {
			return {
				...state,
				serviceWaitTimes: action.payload?.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_WAIT_TIMES_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	VENUE_DATES_FAIL,
	VENUE_DATES_SUCCESS,
	VENUE_DATES,
	VENUE_FAIL,
	VENUE_SUCCESS,
	SET_VENUE,
	VENUE_TIMESLOTS_FAIL,
	VENUE_TIMESLOTS_SUCCESS,
	VENUE_TIMESLOTS,
	GET_VENUE_OFFLINE_NOTIFICATION_SETTINGS,
	GET_VENUE_OFFLINE_NOTIFICATION_SETTINGS_SUCCESS,
	GET_VENUE_OFFLINE_NOTIFICATION_SETTINGS_FAIL,
	PUT_VENUE_OFFLINE_NOTIFICATION_SETTINGS,
	PUT_VENUE_OFFLINE_NOTIFICATION_SETTINGS_SUCCESS,
	PUT_VENUE_OFFLINE_NOTIFICATION_SETTINGS_FAIL,
	VENUE_UPDATE_DATES_HAS_ORDER,
	VENUE_UPDATE_PAUSED_SERVICE_TYPES,
	VENUE,
	VENUES,
	VENUES_SUCCESS,
	VENUE_PAUSE_ORDERS,
	VENUE_PAUSE_ORDERS_SUCCESS,
	VENUE_PAUSE_ORDERS_FAIL,
	VENUE_PAUSE_ORDER_SERVICES,
	VENUE_PAUSE_ORDER_SERVICES_SUCCESS,
	VENUE_PAUSE_ORDER_SERVICES_FAIL,
	VENUE_UNPAUSE_ORDERS,
	VENUE_UNPAUSE_ORDERS_SUCCESS,
	VENUE_UNPAUSE_ORDERS_FAIL,
	GET_VENUE_MENUS,
	GET_VENUE_MENUS_FAIL,
	GET_VENUE_MENUS_SUCCESS,
	GET_VENUE_MENU_CATEGORIES,
	GET_VENUE_MENU_CATEGORIES_FAIL,
	GET_VENUE_MENU_CATEGORIES_SUCCESS,
	GET_VENUE_CONFIG,
	GET_VENUE_CONFIG_FAIL,
	GET_VENUE_CONFIG_SUCCESS,
	PATCH_VENUE_CONFIG,
	PATCH_VENUE_CONFIG_FAIL,
	PATCH_VENUE_CONFIG_SUCCESS,
	GET_VENUE_OPENING_TIMES,
	GET_VENUE_OPENING_TIMES_FAIL,
	GET_VENUE_OPENING_TIMES_SUCCESS,
	PUT_VENUE_OPENING_TIMES,
	PUT_VENUE_OPENING_TIMES_FAIL,
	PUT_VENUE_OPENING_TIMES_SUCCESS,
	GET_VENUE_PRINTING_CONFIG,
	GET_VENUE_PRINTING_CONFIG_FAIL,
	GET_VENUE_PRINTING_CONFIG_SUCCESS,
	UPDATE_VENUE_SERVICE_AVAILABILITY,
	UPDATE_VENUE_SERVICE_AVAILABILITY_SUCCESS,
	UPDATE_VENUE_SERVICE_AVAILABILITY_FAIL,
	GET_VENUE_SERVICE_WAIT_TIMES,
	GET_VENUE_SERVICE_WAIT_TIMES_FAIL,
	GET_VENUE_SERVICE_WAIT_TIMES_SUCCESS,
	UPDATE_VENUE_SERVICE_WAIT_TIMES,
	UPDATE_VENUE_SERVICE_WAIT_TIMES_FAIL,
	UPDATE_VENUE_SERVICE_WAIT_TIMES_SUCCESS,
} = venueSlice.actions;

/** Thunk to get the venue request */
export const getVenue = (venueId: string) => async (dispatch: Dispatch) => {
	try {
		// Create request
		const { payload } = await dispatch(
			VENUE({
				request: {
					method: 'get',
					url: `venue/${venueId}`,
				},
			})
		);

		return payload.data;
	} catch (error) {
		return {};
	}
};

/** Thunk to set the selected venue */
export const setVenue = (venue: IVenue) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(SET_VENUE(venue));
	return payload;
};

/** Thunk to get all venues accessible by the user */
export const getVenues = () => async (dispatch: Dispatch) => {
	try {
		const { payload } = await dispatch(
			VENUES({
				request: {
					method: 'get',
					url: 'venue',
				},
			})
		);

		return payload.data;
	} catch (error) {
		return {};
	}
};

/** Thunk to get the venue request */
export const getVenueDates = (venueId: string) => async (
	dispatch: Dispatch
) => {
	try {
		// Create request
		const { payload } = await dispatch(
			VENUE_DATES({
				request: {
					method: 'get',
					url: `venue/${venueId}/dates`,
				},
			})
		);

		return payload.data;
	} catch (error) {
		return {};
	}
};

export const getVenueTimeslots = (venueId: string) => async (
	dispatch: Dispatch
) => {
	try {
		// Create request
		const { payload } = await dispatch(
			VENUE_TIMESLOTS({
				request: {
					method: 'get',
					url: `venue/${venueId}/timeslots`,
				},
			})
		);

		return payload.data;
	} catch (error) {
		return {};
	}
};

/** Thunk to get venue offline notification settings */
export const getVenueOfflineNotificationSettings = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_OFFLINE_NOTIFICATION_SETTINGS({
			request: {
				method: 'get',
				url: 'venue/offline/settings',
				params: { venueIds: venueId },
			},
		})
	);

	return payload.data;
};

/** Thunk to update venue offline notification settings */
export const updateVenueOfflineNotificationSettings = (
	venueId: string,
	data: IOfflineNotificationSettings
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		PUT_VENUE_OFFLINE_NOTIFICATION_SETTINGS({
			request: {
				method: 'put',
				url: `venue/${venueId}/offline`,
				data,
			},
		})
	);

	return payload.data;
};

/** Thunk to pause venue orders request */
export const pauseVenueOrders = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		VENUE_PAUSE_ORDERS({
			request: {
				method: 'patch',
				url: `venue/${venueId}/pauseOrders`,
			},
		})
	);

	return payload?.status === 200;
};

/** Thunk to pause venue orders request */
export const pauseVenueOrdersByType = (
	venueId: string,
	data: IPauseOrdersFormValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		VENUE_PAUSE_ORDER_SERVICES({
			request: {
				method: 'patch',
				url: `venue/${venueId}/updateServiceOrderPauseState`,
				data,
			},
		})
	);

	return payload?.status === 200;
};

/** Thunk to pause venue orders request */
export const pauseVenueOrdersByMenu = (
	venueId: string,
	data: IPauseOrdersByMenuData[]
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		VENUE_PAUSE_ORDER_SERVICES({
			request: {
				method: 'patch',
				url: `venue/${venueId}/pauseMenu`,
				data: {
					pauseMenuDetails: data,
				},
			},
		})
	);

	return payload?.status === 200;
};

/** Thunk to unpause venue orders request */
export const unPauseVenueOrders = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		VENUE_UNPAUSE_ORDERS({
			request: {
				method: 'patch',
				url: `venue/${venueId}/unPauseOrders`,
			},
		})
	);

	return payload?.status === 200;
};

/** Thunk to get venue menus */
export const getVenueMenus = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_MENUS({
			request: {
				method: 'get',
				url: `venue/${venueId}/menus`,
			},
		})
	);

	return payload?.data;
};

/** Thunk to get venue menu categories */
export const getVenueMenuCategories = (
	venueId: string,
	menuId: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_MENU_CATEGORIES({
			request: {
				method: 'get',
				url: `venue/${venueId}/menus/${menuId}/categories`,
			},
		})
	);

	return payload?.data;
};

/** Thunk to get venue config */
export const getVenueConfig = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_CONFIG({
			request: {
				method: 'get',
				url: `venue/${venueId}/config`,
			},
		})
	);

	return payload?.data;
};

/** Thunk to patch venue config */
export const patchVenueConfig = (venueId: string, data: IVenueConfig) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		PATCH_VENUE_CONFIG({
			request: {
				method: 'patch',
				url: `venue/${venueId}/config`,
				data,
			},
		})
	);

	return payload?.data;
};

/** Get venue opening times */
export const getVenueOpeningTimes = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_OPENING_TIMES({
			request: {
				method: 'get',
				url: `venue/${venueId}/opening-times`,
			},
		})
	);

	return payload?.data;
};

/** update venue opening times */
export const putVenueOpeningTimes = (
	venueId: string,
	values: IVenueOpeningFormValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		PUT_VENUE_OPENING_TIMES({
			request: {
				method: 'put',
				url: `venue/${venueId}/opening-times`,
				data: formatVenueOpeningTimesToApi(values),
			},
		})
	);

	return payload?.data;
};

/** Thunk to update if a venue date has orders */
export const pusherUpdateVenueDateHasOrder = (orderDate: string) => async (
	dispatch: Dispatch
) => {
	// dispatch venue update redux request
	await dispatch(
		VENUE_UPDATE_DATES_HAS_ORDER({
			data: orderDate,
		})
	);
};

/** Thunk to update paused service types */
export const pusherUpdatePausedServiceTypes = (
	serviceTypes: IPusherPausedService[]
) => async (dispatch: Dispatch) => {
	// dispatch paused service types redux request
	await dispatch(
		VENUE_UPDATE_PAUSED_SERVICE_TYPES({
			data: serviceTypes,
		})
	);
};

/** Thunk to get venue printing config */
export const getVenuePrintingConfig = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// dispatch paused service types redux request
	await dispatch(
		GET_VENUE_PRINTING_CONFIG({
			request: {
				method: 'get',
				url: `receipts/${venueId}/types`,
			},
		})
	);
};

export const patchServiceAvailability = (
	venueId: string,
	data: IVenueServiceAvailabilitySubmitValues,
	serviceType: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		UPDATE_VENUE_SERVICE_AVAILABILITY({
			request: {
				method: 'patch',
				url: `venue/${venueId}/venue-service/${serviceType}/availability`,
				data,
			},
		})
	);
	return payload?.data;
};

/** PROCESSES */

// Process update venue dates
const processUpdateVenueDateHasOrder = (
	state: IVenueStore,
	action: PayloadAction<IPayloadProcessUpdateVenueDates>
) => {
	// Get date from data
	const { date } = action.payload.data;

	// Get venue to update
	const venueToUpdate = state.venueDates.filter((venue) =>
		dayjs(venue.date).isSame(date, 'day')
	)[0];

	// Alter venue to set has orders to true
	const alteredVenue = {
		...venueToUpdate,
		hasOrders: true,
	};

	// Filter venues to remove old venue
	const filteredVenues = state.venueDates.filter(
		(filterVenue) => !dayjs(filterVenue.date).isSame(date, 'day')
	);

	// Push altered venue
	filteredVenues.push(alteredVenue);

	// Return venues and sort
	return {
		...state,
		venueDates: filteredVenues.sort((a, b) => {
			if (a.date === b.date) {
				return 0;
			}
			return a.date! > b.date! ? 1 : -1;
		}),
	};
};

// Process update paused service types
const processUpdatePausedServiceTypes = (
	state: IVenueStore,
	action: PayloadAction<IPayloadProcessUpdatePausedServiceTypes>
) => {
	// Get new service types payload
	const newServiceTypes = action.payload.data;

	// Create new array of service types
	const serviceTypes: IServiceType[] = state.selectedVenue.serviceTypes.map(
		(serviceType) => {
			// get updated service type
			const updatedServiceType = newServiceTypes.find(
				(service) => service.type === serviceType.type
			);

			// If no new service type, return current version
			if (!updatedServiceType) {
				return serviceType;
			}

			// Return updated service type
			return {
				...serviceType,
				isOrderingPaused: updatedServiceType.isOrderingPaused,
				ordersPausedUntil: updatedServiceType.ordersPausedUntil,
			};
		}
	);

	// Return venue with updated service types
	return {
		...state,
		selectedVenue: {
			...state.selectedVenue,
			serviceTypes,
		},
	};
};

/** Thunk to get venue service wait times */
export const getVenueServiceWaitTimes = (venueId: string) => async (
	dispatch: Dispatch
) => {
	await dispatch(
		GET_VENUE_SERVICE_WAIT_TIMES({
			request: {
				method: 'get',
				url: `venue/${venueId}/waiting-times`,
			},
		})
	);
};

export const updateVenueServiceWaitTimes = (
	venueId: string,
	data: VenueServiceWaitTimesDto
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		UPDATE_VENUE_SERVICE_WAIT_TIMES({
			request: {
				method: 'put',
				url: `venue/${venueId}/waiting-times`,
				data,
			},
		})
	);

	return payload?.data;
};

export default venueSlice.reducer;
