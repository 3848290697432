import React from 'react';
import { useHistory } from 'react-router-dom';

import {
	Container,
	ProgressWrapper,
	ProgressStep,
	ProgressIcon,
	CloseIcon,
	CompletedIcon,
} from './progress-indicator.styles';

interface IComponentProps {
	step: string;
	device?: string;
}
const ProgressIndicator: React.FC<IComponentProps> = ({ step, device }) => {
	const history = useHistory();

	let progressSteps: string[] = ['device', 'install', 'verify'];

	// If device is mobile, remove install step
	if (device === 'mobile') {
		progressSteps = progressSteps.filter(
			(pStep: string) => pStep !== 'install'
		);
	}

	const isStepCompleted = (stepToCheck: string): boolean => {
		if (step === 'paired') {
			return true;
		}

		const stepIndex = progressSteps.indexOf(stepToCheck);

		if (stepIndex < progressSteps.indexOf(step)) {
			return true;
		}
		return false;
	};

	return (
		<Container>
			<ProgressWrapper>
				{progressSteps.map((progressStep: string) => (
					<ProgressStep key={progressStep}>
						<span>{progressStep}</span>
						<ProgressIcon
							variant={progressStep === step ? 'active' : ''}
							completed={isStepCompleted(progressStep)}
						>
							<CompletedIcon
								name="miniTick"
								width={18}
								height={18}
								colour="white"
								completed={isStepCompleted(progressStep)}
							/>
						</ProgressIcon>
					</ProgressStep>
				))}
			</ProgressWrapper>
			<CloseIcon
				onClick={() => history.push('/terminal')}
				variant="icon"
				icon="close"
				iconColour="black"
				iconHeight={18}
				iconWidth={18}
			/>
		</Container>
	);
};

export default ProgressIndicator;
