import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import ReactModal, { ModalProps } from 'styled-react-modal';

import brand from 'assets/styles/variables/brand';

interface IModalBackgroundProps {
	colour?: string;
	viewHeight?: number;
}

export interface IComponentProps extends ModalProps {
	toggleModal: Function;
	isExitable?: boolean;
	className?: string;
	backgroundProps?: IModalBackgroundProps;
	printStatus?: string | null;
}

export const modalBackground = styled.div<IModalBackgroundProps>`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(${({ viewHeight }) => viewHeight}px * 100);
	background-color: ${(props) => {
		return props.colour || rgba(brand.text, 0.5);
	}};
	align-content: center;
	align-items: center;
	justify-content: center;
`;

const StyledModal = ReactModal.styled`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${brand.white};
	padding: 30px 20px;
`;

/** Renders Modal component */
const Modal: React.FC<IComponentProps> = ({
	children,
	toggleModal,
	isOpen,
	allowScroll,
	afterOpen,
	afterClose,
	beforeOpen,
	beforeClose,
	backgroundProps,
	isExitable = true,
	className,
}) => {
	const exitableProps = isExitable
		? {
			onBackgroundClick: () => toggleModal(),
			onEscapeKeydown: () => toggleModal(),
		  }
		: null;

	return (
		<StyledModal
			// @ts-ignore
			className={className}
			isOpen={isOpen}
			allowScroll={false}
			afterOpen={afterOpen}
			afterClose={afterClose}
			beforeOpen={beforeOpen}
			beforeClose={beforeClose}
			backgroundProps={backgroundProps}
			{...exitableProps}
		>
			{children}
		</StyledModal>
	);
};

export default Modal;
