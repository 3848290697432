export interface ITerminalStore {
	eventsInProgress: number;
	devices: IDevice[];
	devicesPagination?: {
		page: number;
		count: number;
		totalPageCount: number;
	};
}

export interface IDeviceDetails {
	manufacturer?: string;
	model?: string;
	os?: string;
	osVersion?: string;
	version?: string;
}

export enum EDeviceStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}

export interface IDevice {
	id: string;
	uniqueDeviceIdentifier: string;
	details: IDeviceDetails;
	status: EDeviceStatus;
	venueId: string;
	createdAt: Date;
}
