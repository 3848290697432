import { Formik, Form, Field, FormikHelpers } from 'formik';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import LoginWrapper from 'components/login-wrapper/login-wrapper.component';
import LogoBlock from 'components/logo-block/logo-block.component';
import { processForgotPassword } from 'modules/auth/auth.slice';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';

// Styling for forgot password form
const StyledForgotPasswordForm = styled(Form)`
	width: 350px;
	padding: 20px 28px 30px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${brand.white};

	p {
		padding: 0 20px;
		margin-bottom: 25px;
		text-align: center;
	}

	Button {
		width: 100%;
		margin-bottom: 20px;
	}

	.btn-link {
		color: ${brand.primary};
		font-size: ${fonts.sizes.small};
		font-weight: ${fonts.weights.semibold};
		text-decoration: none;
	}
`;

const StyledField = styled(Field)`
	width: 100%;
`;

/** Success message component */
const SuccessMessage: FunctionComponent = () => {
	return (
		<>
			<h1 aria-label="success-title">
				<FormattedMessage id="forgotPassword.success.title" />
			</h1>
			<p>
				<FormattedMessage id="forgotPassword.success.text" />
			</p>
		</>
	);
};

/** Renders forgot password page component */
const ForgotPasswordPage: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useDispatch();

	// Interface for form values
	interface IFormValidation {
		email: string;
	}

	// Validation schema
	const formValidationSchema = object<IFormValidation>().shape({
		email: string()
			.required(validationMessages.required('email'))
			.email(validationMessages.email('email')),
	});

	// Handle form submission
	const handleSubmit = async (
		values: IFormValidation,
		{ resetForm, setStatus }: FormikHelpers<IFormValidation>
	) => {
		// Create forgot password request
		const response = await dispatch(processForgotPassword(values.email));

		// If submission failed
		if (!response) {
			resetForm();
			return;
		}

		// Set success status on form
		setStatus({
			success: true,
		});
	};

	return (
		<LoginWrapper>
			<LogoBlock variant="login" />
			<Formik
				initialValues={{
					email: '',
				}}
				validationSchema={formValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ status }) => (
					<StyledForgotPasswordForm>
						{status && status.success ? (
							<SuccessMessage />
						) : (
							<>
								<h1>
									<FormattedMessage id="forgotPassword.title" />
								</h1>
								<p>
									<FormattedMessage id="forgotPassword.copy" />
								</p>
								<StyledField
									component={Input}
									type="email"
									name="email"
									placeholder={intl.formatMessage({
										id: 'form.email.placeholder',
									})}
									size="lg"
								/>
								<Button type="submit" size="lg" ariaLabel="submit-button">
									<FormattedMessage id="forgotPassword.form.submit" />
								</Button>
							</>
						)}
						<Link className="btn-link" to="/" aria-label="back-link">
							<FormattedMessage id="forgotPassword.backLink" />
						</Link>
					</StyledForgotPasswordForm>
				)}
			</Formik>
		</LoginWrapper>
	);
};

export default ForgotPasswordPage;
