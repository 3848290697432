import { Form } from 'formik';
import { rgba } from 'polished';
import styled from 'styled-components';

import { StockRowCss } from '../stock.style';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import Select from 'components/form-inputs/select/select.component';
import Switch from 'components/form-inputs/switch/switch.component';

export const StyledListItem = styled.li`
	${StockRowCss}
	position: relative;
	margin: 0 0 7px;
	background: ${brand.white};
	border-radius: 5px;
	box-shadow: 0px 3px 7px ${rgba(brand.black, 0.1)};

	.mod-mobile-only {
		display: none;
	}

	${mq.tabletDown`
		padding: 15px;
	`}

	${mq.mobile`
	.mod-mobile-only {
		display: inline-block;
		margin-right: 5px;
	}
    .mod-name {
        font-size: ${fonts.sizes.semiLarge};
        font-weight: ${fonts.weights.regular};
    }
    .mod-sku {
        font-size: ${fonts.sizes.med};
        font-weight: ${fonts.weights.light};
    }
    .mod-type {
        font-size: ${fonts.sizes.med};
        font-weight: ${fonts.weights.regular};
    }`}
`;

export const StyledForm = styled(Form)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 4px 15px;
`;

export const StyledSwitch = styled(Switch)`
	margin: 0;
	max-width: 232px;
	${mq.desktopDown`
        max-width: 200px;
    `}

	${mq.mobile`
	    max-width: none;
	`}
`;

export const StyledSelect = styled(Select)`
	max-width: 181px;
	margin: 0;
	${mq.desktopDown`
		max-width: none;
	`}
	${mq.mobile`
	    max-width: none;
	`}
`;

export const StyledButton = styled(Button)`
	display: flex;
	justify-content: flex-end;
	background: ${brand.white};
	:hover {
		background: ${brand.white};
	}
`;
