import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
	AddFirstButton,
	Container,
	GetTerminalButton,
	FeaturesList,
	Title,
	Logo,
	Hero,
	DeviceImage,
	TerminalTippingIcon,
	TerminalMenusIcon,
	TerminalReceiptsIcon,
	TerminalHistoryIcon,
	TerminalModifiersIcon,
	TerminalNotesIcon,
	Actions,
} from './no-devices.styles';

const NoDevices: React.FC = () => {
	const history = useHistory();

	return (
		<Container>
			<Hero>
				<DeviceImage />
			</Hero>
			<Logo />
			<Title>
				<FormattedMessage id="terminal.noDevices.title" />
			</Title>
			<FeaturesList>
				<li>
					<TerminalTippingIcon className="icon" />
					<div>
						<FormattedMessage id="terminal.noDevices.features.tipping" />
					</div>
				</li>
				<li>
					<TerminalMenusIcon className="icon" />
					<div>
						<FormattedMessage id="terminal.noDevices.features.menus" />
					</div>
				</li>
				<li>
					<TerminalReceiptsIcon className="icon" />
					<div>
						<FormattedMessage id="terminal.noDevices.features.receipts" />
					</div>
				</li>
				<li>
					<TerminalHistoryIcon className="icon" />
					<div>
						<FormattedMessage id="terminal.noDevices.features.history" />
					</div>
				</li>
				<li>
					<TerminalModifiersIcon className="icon" />
					<div>
						<FormattedMessage id="terminal.noDevices.features.modifiers" />
					</div>
				</li>
				<li>
					<TerminalNotesIcon className="icon" />
					<div>
						<FormattedMessage id="terminal.noDevices.features.notes" />
					</div>
				</li>
			</FeaturesList>
			<Actions>
				<GetTerminalButton
					onClick={() => {
						window.open(
							'https://orderpay.com/terminal_introduction/',
							'_blank'
						);
					}}
				>
					<FormattedMessage id="terminal.noDevices.button.getTerminal" />
				</GetTerminalButton>
				<AddFirstButton onClick={() => history.push('/terminal/device')}>
					<FormattedMessage id="terminal.noDevices.button.addFirst" />
				</AddFirstButton>
			</Actions>
		</Container>
	);
};

export default NoDevices;
