import { css } from 'styled-components';

import fonts from '../variables/fonts';

/** Global font styles */
const globalFonts = css`
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0 0 20px 0;
		padding: 0;
		line-height: ${fonts.headings.line_height};
		font-family: ${fonts.headings.family};
		font-weight: ${fonts.headings.weight};
		color: ${fonts.headings.color};
	}

	h1 {
		font-size: ${fonts.sizes.h1};
	}

	h2 {
		font-size: ${fonts.sizes.h2};
	}

	h3 {
		font-size: ${fonts.sizes.h3};
	}

	h4 {
		font-size: ${fonts.sizes.h4};
	}

	h5 {
		font-size: ${fonts.sizes.h5};
	}

	h6 {
		font-size: ${fonts.sizes.h6};
	}

	p {
		margin: 0 0 20px 0;
	}

	strong {
		font-weight: ${fonts.weights.semibold};
	}
`;

export default globalFonts;
