import { EOrderType } from 'app.types';
import { IServiceType } from 'modules/venue/venue.types';

// Enum for service charge type
export enum EServiceChargeType {
	amount = 'amount',
	percentage = 'percentage',
}

/** ORDER ITEM */
export interface IModifier {
	id: string;
	name: string;
	imageUrl?: string;
	modifierId?: string;
	quantity: number;
	price: {
		units: number;
		currencyCode: string;
	};
	allergiesAcceptedAt?: string;
	options?: unknown[];
}
export interface IModifierCategories {
	id: string;
	modifiers?: IModifier[];
	modifierId?: string;
	name: string;
	imageUrl?: string;
	price?: {
		units: number;
		currencyCode: string;
	};
	quantity?: number;
}
export interface IOrderItem {
	collected: boolean;
	hasAllergen?: boolean;
	id: string;
	modifierCategories?: IModifierCategories[];
	quantity: number;
	ready: boolean;
	refundedQuantity: number;
	name: string;
	imageUrl?: string;
	productId?: string;
	price: {
		units: number;
		currencyCode: string;
	};
	menuId: string;
	itemNotes?: string;
}

// Used for updated an individual order item status
export interface IOrderItemsStatus {
	id: string;
	collected?: boolean;
	ready?: boolean;
}

/** REFUND */
export interface IRefundItem {
	id: string;
	quantity: number;
}
export interface IOrderRefund {
	id: string;
	items: IRefundItem[];
	reason?: string;
	refundServiceCharge?: boolean;
	refundTip?: boolean;
}

export interface IOrderQuery {
	id: string;
	notes: string;
}

/** ORDER STATUS */
export enum EOrderStatus {
	'venueAccepted', // upcoming
	'orderBeingPrepared', // in progress
	'pendingPickup', // ready/awaitingPickup
	'completed', // completed
	'venueRejected', // POS rejected
	'paymentOutstanding', // Payment Outstanding
}

export interface IOrderItemStatus {
	ready?: boolean;
	collected?: boolean;
}

export interface IOrderStatusResponse {
	id: string;
	status: keyof typeof EOrderStatus;
}

export interface IAllergen {
	id: string;
	name: string;
}

export interface ICustomServiceConfirmationLine {
	title: string;
	shortTitle?: string;
	value: string;
	isHighlighted: boolean;
	display: boolean;
}

export type PrintStatus = 'None' | 'Complete' | 'Pending';

export interface IPrintJob {
	id: string;
	data: string;
}

export interface IOrderPrintStatus {
	[orderId: string]: PrintStatus;
}

export interface IPayloadPrintStatus {
	orderId: string;
	status: PrintStatus;
}

export interface ITip {
	amount: number;
	isRefunded: boolean;
}

// Interface for service charge on an order
export interface IServiceCharge {
	id: string;
	editable: boolean;
	type: EServiceChargeType;
	name: string;
	message: string;
	amount: number;
	percent: number;
	refunded: boolean;
	orderId: string;
}

export interface IAlertConfig {
	id?: string;
	enabled?: boolean;
	initialAlertSeconds?: number;
	escalationAlertSeconds?: number;
	prepTime?: number;
	isOrderInAlert?: boolean;
	isOrderInEscalation?: boolean;
}
// Interface for an order
export interface IOrder {
	arrivedAt?: string | null;
	collectAt?: string | null;
	completedAt?: string | null;
	customer: {
		allergens?: IAllergen[];
		avatarUrl?: string | null;
		name: string | null;
		phone: string;
	};
	id: string;
	items: IOrderItem[];
	orderedAt: string;
	orderNum: string;
	status: keyof typeof EOrderStatus;
	type: keyof typeof EOrderType;
	tableNumber?: string;
	tip: ITip;
	total: {
		units: number;
		currencyCode: string;
	};
	notes?: string;
	refundReason?: string;
	menuIds: string[];
	confirmationLineItems?: ICustomServiceConfirmationLine[];
	customService?: {
		displayName: string;
		imageUrl: string;
	};
	truncated?: boolean;
	serviceCharge?: IServiceCharge;
	alertConfig?: IAlertConfig;
	customerNotes?: ICustomerNote[];
}

export interface ICustomerNote {
	roundId: number;
	note: string;
}

/** ORDERS LIST */
export type IOrdersList = IOrder[];

/** COMPLETED ORDERS */
export interface ICompletedOrders {
	orders: IOrder[];
	pagination: {
		pageSize: number;
		pageNumber: number;
		pageCount: number;
		count: number;
	};
}

export interface IOrderStore {
	eventsInProgress: number;
	ordersList: IOrdersList;
	order?: IOrder;
	pagination?: {
		pageSize: number;
		pageNumber: number;
		pageCount: number;
		total: number;
	};
	printStatusList: IOrderPrintStatus;
}

export interface IPusherNewOrderData {
	type: string;
	medium: string;
	id: string;
	data: {
		status: EOrderStatus;
		message: string;
		order: IOrder;
	};
}

/** CUSTOMER ARRIVED */

export interface ICustomerArrived {
	id: string;
	arrivedAt: string;
}

export interface IPayloadCustomerArrived {
	data: ICustomerArrived;
}

export interface IOrderItemStatusResponse {
	orderId: string;
	itemId: string;
	ready: boolean;
	collected: boolean;
}

export interface IPayloadProcessOrderAlertConfigUpdate {
	data: {
		orderId: string;
		alertConfig: Partial<IAlertConfig>;
	};
}

export interface IPayloadProcessOrderStatus {
	data: IOrderStatusResponse;
}

export interface IPayloadProcessOrderAlertConfig {
	data: {
		orders: IOrder[];
		serviceTypes: IServiceType[];
	};
}

export interface IPayloadProcessOrderRefund {
	data: IOrderRefund;
}
export interface IPayloadProcessOrderNote {
	data: IOrderQuery;
}

export interface IPayloadProcessOrderItemStatus {
	data: {
		newItemStatus: IOrderItemStatusResponse;
	};
}

export interface IPayloadProcessOrder {
	data: {
		order: IOrder;
		serviceTypes: IServiceType[];
	};
}

export interface IOrderReceivedReqest {
	timestamp: string;
}

export interface IOrderListFilters {
	menuIds: string[];
}
export interface ICompletedOrdersFilters {
	venueId?: string;
	dateFrom?: string;
	name?: string;
	pageSize?: number;
	pageNumber?: number;
}
