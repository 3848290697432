import dayjs from 'dayjs';

import { IVenueMenu } from '../modules/venue/venue.types';

export const isMenuPaused = (menu: IVenueMenu): boolean => {
	if (!menu.pausedUntil) return false;
	const pausedUntil = dayjs(menu.pausedUntil).tz('Europe/London');
	const now = dayjs().tz('Europe/London');
	return pausedUntil.isAfter(now);
};
