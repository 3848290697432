import { rgba } from 'polished';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';

export const StyledFormElement = styled(FormElement)`
	margin: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 1;

	&:last-of-type {
		margin-right: 0;
	}

	div,
	input {
		flex-shrink: 1;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	input {
		width: 100%;
		height: 40px;
		line-height: 40px;
		min-width: 59px;
		padding: 0 12px;
		border: 1px solid ${brand.borders};
		border-radius: 5px;
		font-size: ${fonts.sizes.standard};
		color: ${brand.text};

		&::placeholder {
			font-size: ${fonts.sizes.standard};
			font-weight: ${fonts.weights.regular};
			color: ${brand.placeholder};
		}

		&:active,
		&:focus {
			padding: 0 12px;
			border: 1px solid ${brand.link};
			outline: none;
			box-shadow: none;
		}

		&:disabled {
			opacity: 0.3;
		}

		&.has-error {
			background: ${rgba(brand.validation_error, 0.05)};
			border: 1px solid ${brand.validation_error};
		}
	}

	.mod-input-wrapper {
		position: relative;
	}
`;

export const StyledLabel = styled.label`
	margin: 0 10px 0 0;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.medium};
`;

export const StyledChevron = styled(Icon)`
	pointer-events: none;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);

	svg {
		transform: rotate(90deg);
	}
`;
