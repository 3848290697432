import { lighten } from 'polished';
import React, { FunctionComponent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Icon from 'components/icons/icon.component';
import { RootState } from 'modules/core/state/root.reducer';

const StyledText = styled.div`
	font-size: ${fonts.sizes.large};
	color: ${brand.white};
	font-weight: ${fonts.weights.semibold};
`;

const StyledOffline = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: calc(96% - 114px);
	top: 5px;
	left: 96px;
	right: 0;
	margin: 0 auto;
	background: ${lighten(0.1, brand.generic_error)};
	border-radius: 5px;
	padding: 7px;

	${mq.mobile`
		left: 0;
		width: 97%;
	`}
`;

const StyledRefresh = styled.div`
	cursor: pointer;
`;

/** Renders network connectivity component */
const NetworkConnectivity: FunctionComponent = () => {
	// Get connection status from state
	const pusherStatus = useSelector(
		(state: RootState) => state.pusher.connectionStatus
	);

	const isConnected = useMemo(() => {
		// create list of acceptable connections
		const acceptableConnection: string[] = ['connected', 'initialized'];
		return acceptableConnection.includes(pusherStatus) || navigator.onLine;
	}, [pusherStatus]);

	return (
		<>
			{!isConnected && (
				<StyledOffline>
					<StyledText>
						<FormattedMessage id="networkConnectivity.text" />
					</StyledText>
					<StyledRefresh onClick={() => window.location.reload()}>
						<Icon name="refresh" colour="white" width={40} height={40} />
					</StyledRefresh>
				</StyledOffline>
			)}
		</>
	);
};

export default NetworkConnectivity;
