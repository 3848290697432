import MockAdapter from 'axios-mock-adapter/types';

import dayjs from '../../helpers/dayjs.helper';
import {
	IOrder,
	IOrderItem,
	IOrdersList,
	IOrderStatusResponse,
	ICustomerArrived,
	IOrderReceivedReqest,
	IServiceCharge,
	EServiceChargeType,
	IAlertConfig,
} from './order.types';
/** MOCK DATA */

import mockVenue from 'modules/venue/venue.mock';

const serviceCharge: IServiceCharge = {
	id: '2133123-1235345-12312312-12312',
	editable: true,
	type: EServiceChargeType.percentage,
	name: 'Optional Service Charge',
	message: 'Please tip the waitstaff if you think they were good',
	amount: 1500,
	percent: 20,
	refunded: false,
	orderId: '21349234-dfsfasdsdf-12312sd',
};

const alertConfig: IAlertConfig = {
	isOrderInAlert: true,
	isOrderInEscalation: true,
};

const order: IOrder = {
	orderedAt: dayjs().add(6, 'minute').format(),
	collectAt: dayjs().add(1, 'hour').format(),
	customer: {
		name: 'James Bond',
		phone: '01234 567 890',
	},
	type: 'clickAndCollect',
	status: 'venueAccepted',
	id: '21349234-dfsfasdsdf-12312sd',
	orderNum: '2d23d32',
	items: [
		{
			id: 'dfsdf03sdgs0-sefsef3-sdfs',
			productId: 'dfsdf03sdgs0-sefsef3-sdfs',
			name: 'Crisps',
			price: {
				units: 99,
				currencyCode: 'GBP',
			},
			quantity: 1,
			ready: false,
			collected: false,
			refundedQuantity: 0,
			menuId: 'menu-id-1',
		},
	],
	tip: {
		amount: 1000,
		isRefunded: false,
	},
	total: {
		units: 99,
		currencyCode: 'GBP',
	},
	menuIds: ['menu-id-1'],
};

const futureOrder: IOrder = {
	...order,
	collectAt: dayjs().add(1, 'day').format(),
};

const orderCompleted: IOrder = {
	arrivedAt: dayjs().add(55, 'minute').format(),
	collectAt: dayjs().add(1, 'hour').format(),
	orderedAt: dayjs().format(),
	customer: {
		name: 'James Bond',
		phone: '01234 567 890',
	},
	type: 'clickAndCollect',
	status: 'completed',
	id: '21349234-dfsfasdsdf-12312sd',
	orderNum: '2d23d32',
	items: [
		{
			id: '1',
			name: 'Crisps',
			price: {
				units: 99,
				currencyCode: 'GBP',
			},
			quantity: 1,
			ready: false,
			collected: false,
			refundedQuantity: 0,
			menuId: 'menu-id-1',
		},
	],
	tip: {
		amount: 1000,
		isRefunded: false,
	},
	total: {
		units: 99,
		currencyCode: 'GBP',
	},
	menuIds: ['menu-id-1'],
};

const orderWithAllergens: IOrder = {
	arrivedAt: dayjs().add(55, 'minute').format(),
	collectAt: dayjs().add(1, 'hour').format(),
	orderedAt: dayjs().format(),
	customer: {
		allergens: [
			{
				id: '1',
				name: 'eggs',
			},
			{
				id: '2',
				name: 'milk',
			},
		],
		name: 'James Bond',
		phone: '01234 567 890',
	},
	type: 'clickAndCollect',
	status: 'venueAccepted',
	id: '21349234-dfsf3-sdf-12312sd',
	orderNum: '2d23d32',
	notes: 'No more pepsi',
	items: [
		{
			id: '1',
			name: 'Crisps',
			price: {
				units: 99,
				currencyCode: 'GBP',
			},
			quantity: 1,
			ready: false,
			collected: false,
			refundedQuantity: 0,
			menuId: 'menu-id-1',
		},
	],
	tip: {
		amount: 1000,
		isRefunded: false,
	},
	total: {
		units: 99,
		currencyCode: 'GBP',
	},
	menuIds: ['menu-id-1'],
};

const upcomingOrders: IOrder[] = [
	{
		id: '5362e2a2-0504-4eb5-bb42-a0ba4be8dc69',
		type: 'clickAndCollect',
		orderNum: '00003',
		status: 'venueAccepted',
		arrivedAt: null,
		collectAt: '2020-12-15T12:10:00.000Z',
		completedAt: null,
		orderedAt: '2020-12-15T09:53:04.642Z',
		menuIds: [
			'826fe57e-c8ef-46b1-b4c5-15559849051d',
			'826fe57e-c8ef-46b1-b4c5-15559849051d',
			'826fe57e-c8ef-46b1-b4c5-15559849051d',
			'826fe57e-c8ef-46b1-b4c5-15559849051d',
			'826fe57e-c8ef-46b1-b4c5-15559849051d',
			'826fe57e-c8ef-46b1-b4c5-15559849051d',
			'826fe57e-c8ef-46b1-b4c5-15559849051d',
			'826fe57e-c8ef-46b1-b4c5-15559849051d',
			'826fe57e-c8ef-46b1-b4c5-15559849051d',
		],
		tip: {
			amount: 1000,
			isRefunded: false,
		},
		total: {
			units: 31180,
			currencyCode: 'GBP',
		},
		truncated: false,
		notes: '',
		refundReason: '',
		customer: {
			allergens: [
				{
					id: '448850f8-55fe-4573-8342-18afdff2bfc5',
					name: 'Gluten & wheat',
				},
				{
					id: '9a2191e4-5bff-415d-bf4f-782e049a20ea',
					name: 'Eggs',
				},
				{
					id: '5938b193-aaad-44ea-be17-1803438f347a',
					name: 'Fish',
				},
				{
					id: '5a8a259d-100e-4be2-9de6-bc6c5731b2bc',
					name: 'Milk',
				},
				{
					id: '2866c4e6-2cb8-435f-b960-41dad7e3fdac',
					name: 'Molluscs',
				},
				{
					id: '1c011a63-9851-41eb-ba33-ad3925f1eafa',
					name: 'Mustard',
				},
				{
					id: '212c307c-891a-4b21-add8-a9bc5b1fb071',
					name: 'Tree nuts',
				},
				{
					id: '5870374a-bce3-4dc2-bde8-748fcb3b8bb0',
					name: 'Peanuts',
				},
				{
					id: '471e554d-db61-4d13-aa38-cbd634b68367',
					name: 'Soya',
				},
				{
					id: 'b29c51ed-f0ba-43de-9c4a-a62c8e757c90',
					name: 'Sulphites',
				},
			],
			avatarUrl: null,
			name: 'Alpha Testing',
			phone: '+44 7998 877660',
		},
		items: [
			{
				collected: false,
				ready: false,
				id: 'd81f270c-d90f-4868-a7cd-ad6225e80438',
				name: 'Sweet & Sour Chicken',
				imageUrl:
					'https://orderpay.s3.amazonaws.com/menus/images/product/image/2deeb3ee-b5f8-4c44-aa75-8e4edbabfad4.PNG',
				refundedQuantity: 0,
				productId: '27c65fec-950e-418f-8feb-e1ee4c32c893',
				quantity: 5,
				menuId: '826fe57e-c8ef-46b1-b4c5-15559849051d',
				price: {
					units: 920,
					currencyCode: 'GBP',
				},
				modifierCategories: [
					{
						id: '93ca34b7-3517-4e8d-93f2-97d393a633e6',
						modifierId: '0ab0f9ac-7c4f-414e-ab4b-0ad66b7dbc74',
						name: 'Add a Drink & Side for £1.95',
						imageUrl: '',
						price: {
							units: 195,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '6572eaaf-8ad6-4f31-9732-c410f147475d',
								name: 'VAT Water',
								imageUrl: '',
								modifierId: '694b7199-6345-4497-9174-554178bbfabe',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
							{
								id: '9d5b3b44-a145-4f79-8192-0f21196bba9f',
								name: 'VAT 5 Mini Veg Spring Rolls',
								imageUrl: '',
								modifierId: '00589516-8446-4a56-877a-900edfa795c3',
								quantity: 1,
								price: {
									units: 195,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
					{
						id: '2aeb1fc1-2a04-4573-8783-91f39d7c6a95',
						modifierId: 'a8fe4cbf-03e7-4ab4-a4d4-c150b61b543e',
						name: '2. Choose Your Base',
						imageUrl: '',
						price: {
							units: 0,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '1a1d033f-4619-410b-ba19-256ad7207bc9',
								name: 'Egg Fried Rice',
								imageUrl:
									'https://orderpay.s3.amazonaws.com/menus/images/modifier-item/image/9cf34fc9-2445-4c8b-ac2b-f3a7016c5994.PNG',
								modifierId: '27b32c73-0683-452c-babe-56a0a8811961',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
				],
			},
			{
				collected: false,
				ready: false,
				id: '4eee8467-4bd5-419f-a6e7-5ec40ee0cbd7',
				name: 'Sweet Chilli Prawns',
				imageUrl:
					'https://orderpay.s3.amazonaws.com/menus/images/product/image/03a31908-b837-4569-a6a4-7ee4832de6b8.PNG',
				refundedQuantity: 0,
				productId: '77f080cd-9d83-4b1c-a6ee-f0ace1a2ae89',
				quantity: 4,
				menuId: '826fe57e-c8ef-46b1-b4c5-15559849051d',
				price: {
					units: 745,
					currencyCode: 'GBP',
				},
				modifierCategories: [
					{
						id: '609e02b8-f685-4f5f-8506-4d25e3676df0',
						modifierId: 'a8fe4cbf-03e7-4ab4-a4d4-c150b61b543e',
						name: '2. Choose Your Base',
						imageUrl: '',
						price: {
							units: 0,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '4e01d4fb-fcb9-4040-8227-e1b5b7e0b167',
								name: 'Vegetable Noodles',
								imageUrl:
									'https://orderpay.s3.amazonaws.com/menus/images/modifier-item/image/d2f2df3f-4179-4687-85c8-57eb7a48cf34.PNG',
								modifierId: 'ee182758-c69c-4519-9211-aeafb11cf5d6',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
					{
						id: '09990a40-3148-4185-a755-473ca915e871',
						modifierId: '0ab0f9ac-7c4f-414e-ab4b-0ad66b7dbc74',
						name: 'Add a Drink & Side for £1.95',
						imageUrl: '',
						price: {
							units: 195,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: 'd01c1a0f-5841-4f9e-883f-03bef4d9b3cc',
								name: 'VAT Prawn Crackers',
								imageUrl: '',
								modifierId: '0c35dce8-3719-4a42-abf9-13fed284e502',
								quantity: 1,
								price: {
									units: 195,
									currencyCode: 'GBP',
								},
								options: [],
							},
							{
								id: '0026129f-38e4-4ae9-8585-9fbabe13ddad',
								name: 'VAT 7Up',
								imageUrl: '',
								modifierId: '38bd546b-8357-4f34-8a52-66daaa2a823b',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
				],
			},
			{
				collected: false,
				ready: false,
				id: 'a880d0b7-d9ec-44ef-906c-c151b3e356c3',
				name: 'BBQ Chicken',
				imageUrl:
					'https://orderpay.s3.amazonaws.com/menus/images/product/image/a06b7e8e-6a58-453e-ba2f-52fb322f5dc5.PNG',
				refundedQuantity: 0,
				productId: 'eff2aca4-f171-45f8-b5e7-29c194966d06',
				quantity: 3,
				menuId: '826fe57e-c8ef-46b1-b4c5-15559849051d',
				price: {
					units: 1115,
					currencyCode: 'GBP',
				},
				modifierCategories: [
					{
						id: '64393bab-417f-4957-ade2-429fc16cd4cf',
						modifierId: '0ab0f9ac-7c4f-414e-ab4b-0ad66b7dbc74',
						name: 'Add a Drink & Side for £1.95',
						imageUrl: '',
						price: {
							units: 390,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: 'f5396541-b9c8-49d3-a3bc-68e1944c1758',
								name: 'VAT Prawn Crackers',
								imageUrl: '',
								modifierId: '0c35dce8-3719-4a42-abf9-13fed284e502',
								quantity: 1,
								price: {
									units: 195,
									currencyCode: 'GBP',
								},
								options: [],
							},
							{
								id: '6c7255ac-f90e-45a4-8673-e831adb1fbdc',
								name: 'VAT 5 Mini Veg Spring Rolls',
								imageUrl: '',
								modifierId: '00589516-8446-4a56-877a-900edfa795c3',
								quantity: 1,
								price: {
									units: 195,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
					{
						id: 'd1796483-6dea-42b1-8efa-bc1f8fbf1d25',
						modifierId: 'a8fe4cbf-03e7-4ab4-a4d4-c150b61b543e',
						name: '2. Choose Your Base',
						imageUrl: '',
						price: {
							units: 0,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '5d27bd4b-8a04-49cf-8938-6b76f3fdbab4',
								name: 'Egg Fried Rice',
								imageUrl:
									'https://orderpay.s3.amazonaws.com/menus/images/modifier-item/image/9cf34fc9-2445-4c8b-ac2b-f3a7016c5994.PNG',
								modifierId: '27b32c73-0683-452c-babe-56a0a8811961',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
				],
			},
			{
				collected: false,
				ready: false,
				id: '5b60966a-9be7-40ef-a59e-38fb1612d844',
				name: 'Caramel Drizzle Chicken',
				imageUrl: '',
				refundedQuantity: 0,
				productId: 'c5c81386-166c-42c9-b076-794854634220',
				quantity: 2,
				menuId: '826fe57e-c8ef-46b1-b4c5-15559849051d',
				price: {
					units: 820,
					currencyCode: 'GBP',
				},
				modifierCategories: [
					{
						id: '622359b2-7de2-4703-a707-9133e3e5bec4',
						modifierId: '0ab0f9ac-7c4f-414e-ab4b-0ad66b7dbc74',
						name: 'Add a Drink & Side for £1.95',
						imageUrl: '',
						price: {
							units: 195,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '9ecc66b4-0c90-4231-acc1-cb561016ee5c',
								name: 'VAT 7Up',
								imageUrl: '',
								modifierId: '38bd546b-8357-4f34-8a52-66daaa2a823b',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
							{
								id: 'e3829d38-9718-4f9b-a8dc-227861b10e92',
								name: 'VAT Prawn Crackers',
								imageUrl: '',
								modifierId: '0c35dce8-3719-4a42-abf9-13fed284e502',
								quantity: 1,
								price: {
									units: 195,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
					{
						id: '845d6686-bf7c-41ac-ba81-949748cae8ab',
						modifierId: 'a8fe4cbf-03e7-4ab4-a4d4-c150b61b543e',
						name: '2. Choose Your Base',
						imageUrl: '',
						price: {
							units: 0,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '410812e1-ae5d-4f1f-8328-1fc97c16813c',
								name: 'Vegetable Noodles',
								imageUrl:
									'https://orderpay.s3.amazonaws.com/menus/images/modifier-item/image/d2f2df3f-4179-4687-85c8-57eb7a48cf34.PNG',
								modifierId: 'ee182758-c69c-4519-9211-aeafb11cf5d6',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
				],
			},
			{
				collected: false,
				ready: false,
				id: '8e7fd0e6-0300-47c9-9759-cbb61e9cb0a3',
				name: 'Green Thai Tofu Curry',
				imageUrl:
					'https://orderpay.s3.amazonaws.com/menus/images/product/image/a921651d-ac39-4a90-9e1c-c0db2362003c.PNG',
				refundedQuantity: 0,
				productId: '0e4fab22-86a9-45cd-90f2-a8da83d59a92',
				quantity: 9,
				menuId: '826fe57e-c8ef-46b1-b4c5-15559849051d',
				price: {
					units: 1115,
					currencyCode: 'GBP',
				},
				modifierCategories: [
					{
						id: 'a9365779-f2e2-4d54-9b96-4f8961ada1cb',
						modifierId: '0ab0f9ac-7c4f-414e-ab4b-0ad66b7dbc74',
						name: 'Add a Drink & Side for £1.95',
						imageUrl: '',
						price: {
							units: 390,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: 'f1b308ec-c454-4def-9e7a-b399ba2c2581',
								name: 'VAT Prawn Crackers',
								imageUrl: '',
								modifierId: '0c35dce8-3719-4a42-abf9-13fed284e502',
								quantity: 1,
								price: {
									units: 195,
									currencyCode: 'GBP',
								},
								options: [],
							},
							{
								id: '18e835c4-7339-4539-8684-8cb3c04a2afa',
								name: 'VAT 10 Chicken Balls',
								imageUrl: '',
								modifierId: '1f35c6d3-6ceb-40f3-8898-9b1db1359f98',
								quantity: 1,
								price: {
									units: 195,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
					{
						id: 'b83f9c89-ab46-4ead-9e78-2a84bf230210',
						modifierId: 'a8fe4cbf-03e7-4ab4-a4d4-c150b61b543e',
						name: '2. Choose Your Base',
						imageUrl: '',
						price: {
							units: 0,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '09b26548-0fb4-4d0b-bf3e-34b12d288008',
								name: 'Egg Fried Rice',
								imageUrl:
									'https://orderpay.s3.amazonaws.com/menus/images/modifier-item/image/9cf34fc9-2445-4c8b-ac2b-f3a7016c5994.PNG',
								modifierId: '27b32c73-0683-452c-babe-56a0a8811961',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
				],
			},
			{
				collected: false,
				ready: false,
				id: '7402ba08-e2b1-41b7-b181-655d3df08735',
				name: 'Sweet Chilli Prawns',
				imageUrl:
					'https://orderpay.s3.amazonaws.com/menus/images/product/image/03a31908-b837-4569-a6a4-7ee4832de6b8.PNG',
				refundedQuantity: 0,
				productId: '77f080cd-9d83-4b1c-a6ee-f0ace1a2ae89',
				quantity: 2,
				menuId: '826fe57e-c8ef-46b1-b4c5-15559849051d',
				price: {
					units: 745,
					currencyCode: 'GBP',
				},
				modifierCategories: [
					{
						id: 'fffc7911-14ee-4df2-abb6-98e9919c2573',
						modifierId: 'a8fe4cbf-03e7-4ab4-a4d4-c150b61b543e',
						name: '2. Choose Your Base',
						imageUrl: '',
						price: {
							units: 0,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '30e504c7-6b55-473a-ab20-f44f5ab7b68b',
								name: 'Egg Fried Rice',
								imageUrl:
									'https://orderpay.s3.amazonaws.com/menus/images/modifier-item/image/9cf34fc9-2445-4c8b-ac2b-f3a7016c5994.PNG',
								modifierId: '27b32c73-0683-452c-babe-56a0a8811961',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
					{
						id: 'b956276f-941b-4cda-b672-25ad80159403',
						modifierId: '0ab0f9ac-7c4f-414e-ab4b-0ad66b7dbc74',
						name: 'Add a Drink & Side for £1.95',
						imageUrl: '',
						price: {
							units: 195,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '2c4cac40-7e7e-4467-9828-45bc5de21625',
								name: 'VAT 10 Chicken Balls',
								imageUrl: '',
								modifierId: '1f35c6d3-6ceb-40f3-8898-9b1db1359f98',
								quantity: 1,
								price: {
									units: 195,
									currencyCode: 'GBP',
								},
								options: [],
							},
							{
								id: 'd9413b52-bd55-4519-82ca-62cc201f2b3b',
								name: 'VAT Water',
								imageUrl: '',
								modifierId: '694b7199-6345-4497-9174-554178bbfabe',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
				],
			},
			{
				collected: false,
				ready: false,
				id: 'f87bcfa0-43aa-45de-9654-68137a1699be',
				name: 'BBQ Chicken',
				imageUrl:
					'https://orderpay.s3.amazonaws.com/menus/images/product/image/2e3102d4-9e94-4d01-a313-2a64d4b78799.PNG',
				refundedQuantity: 0,
				productId: '00c12eea-222c-4d21-88ad-ddcd3e258924',
				quantity: 1,
				menuId: '826fe57e-c8ef-46b1-b4c5-15559849051d',
				price: {
					units: 625,
					currencyCode: 'GBP',
				},
				modifierCategories: [
					{
						id: '315fec01-225c-4ee8-baa0-1f5b82ed0d6b',
						modifierId: 'a8fe4cbf-03e7-4ab4-a4d4-c150b61b543e',
						name: '2. Choose Your Base',
						imageUrl: '',
						price: {
							units: 0,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '9d18c42e-a3d1-4b89-beb0-a6f1ffe69732',
								name: 'Egg Fried Rice',
								imageUrl:
									'https://orderpay.s3.amazonaws.com/menus/images/modifier-item/image/9cf34fc9-2445-4c8b-ac2b-f3a7016c5994.PNG',
								modifierId: '27b32c73-0683-452c-babe-56a0a8811961',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
					{
						id: 'd7c70fc1-e308-46d4-a613-f3257baf1ee7',
						modifierId: '0ab0f9ac-7c4f-414e-ab4b-0ad66b7dbc74',
						name: 'Add a Drink & Side for £1.95',
						imageUrl: '',
						price: {
							units: 0,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '7e310cfa-cbab-43ef-b342-322225db2313',
								name: 'VAT 7Up',
								imageUrl: '',
								modifierId: '38bd546b-8357-4f34-8a52-66daaa2a823b',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
							{
								id: '8d439da3-770e-4e0b-9443-cfb11ee91ef9',
								name: 'VAT Diet Pepsi',
								imageUrl: '',
								modifierId: '0918c914-e713-4172-b7ec-c457d708ad6a',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
				],
			},
			{
				collected: false,
				ready: false,
				id: 'd4925df2-3fd5-4988-a015-2c2fcb448d39',
				name: 'BBQ Chicken',
				imageUrl:
					'https://orderpay.s3.amazonaws.com/menus/images/product/image/2e3102d4-9e94-4d01-a313-2a64d4b78799.PNG',
				refundedQuantity: 0,
				productId: '00c12eea-222c-4d21-88ad-ddcd3e258924',
				quantity: 7,
				menuId: '826fe57e-c8ef-46b1-b4c5-15559849051d',
				price: {
					units: 820,
					currencyCode: 'GBP',
				},
				modifierCategories: [
					{
						id: 'eca4d1be-fe63-44f4-958a-bf48dc7fb2aa',
						modifierId: 'a8fe4cbf-03e7-4ab4-a4d4-c150b61b543e',
						name: '2. Choose Your Base',
						imageUrl: '',
						price: {
							units: 0,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '752f8e45-4c28-405f-8b7c-eaea24abab99',
								name: 'Vegetable Noodles',
								imageUrl:
									'https://orderpay.s3.amazonaws.com/menus/images/modifier-item/image/d2f2df3f-4179-4687-85c8-57eb7a48cf34.PNG',
								modifierId: 'ee182758-c69c-4519-9211-aeafb11cf5d6',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
					{
						id: 'eb75712b-34df-44c7-8b68-cebb600697ca',
						modifierId: '0ab0f9ac-7c4f-414e-ab4b-0ad66b7dbc74',
						name: 'Add a Drink & Side for £1.95',
						imageUrl: '',
						price: {
							units: 195,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: 'f96af48d-2e19-43ec-bca4-2ae6322226ea',
								name: 'VAT 7Up',
								imageUrl: '',
								modifierId: '38bd546b-8357-4f34-8a52-66daaa2a823b',
								quantity: 1,
								price: {
									units: 0,
									currencyCode: 'GBP',
								},
								options: [],
							},
							{
								id: '1516555a-85e2-4144-8282-08de43c19478',
								name: 'VAT 10 Chicken Balls',
								imageUrl: '',
								modifierId: '1f35c6d3-6ceb-40f3-8898-9b1db1359f98',
								quantity: 1,
								price: {
									units: 195,
									currencyCode: 'GBP',
								},
								options: [],
							},
						],
					},
				],
			},
			{
				id: '9264653b-7332-4562-9634-feed868fd9fa',
				name: 'BBQ Chicken',
				imageUrl:
					'https://orderpay.s3.amazonaws.com/menus/images/product/image/a06b7e8e-6a58-453e-ba2f-52fb322f5dc5.PNG',
				refundedQuantity: 0,
				productId: 'eff2aca4-f171-45f8-b5e7-29c194966d06',
				quantity: 1,
				menuId: '826fe57e-c8ef-46b1-b4c5-15559849051d',
				price: {
					units: 725,
					currencyCode: 'GBP',
				},
				collected: false,
				ready: false,
			},
		],
	},
	{
		...order,
		collectAt: dayjs().add(10, 'minute').format(),
		customer: {
			name: 'george horselace',
			phone: '01234 222 120',
		},
		type: 'clickAndCollect',
		id: '231432dsd-dfsdfsdfsf3-sdf-sdfsdfsf43gvi',
	},
	{
		...order,
		collectAt: dayjs().add(1, 'hour').format(),
		orderedAt: dayjs().add(3, 'minute').format(),
		customer: {
			allergens: [
				{
					id: '1',
					name: 'eggs',
				},
				{
					id: '2',
					name: 'milk',
				},
			],
			name: 'James Bond',
			phone: '01234 567 890',
		},
		type: 'delivery',
		id: 'asdmo-asd-3-fdfsdf-333',
		notes: 'we have no red bull',
		items: [
			{
				id: '1',
				name: 'Hawaiian Pizza',
				price: {
					units: 999,
					currencyCode: 'GBP',
				},
				modifierCategories: [
					{
						id: '12392139-sdf-sdf-23',
						name: 'Size',
						price: {
							units: 200,
							currencyCode: 'GBP',
						},
						quantity: 1,
						modifiers: [
							{
								id: '12313-c-svdvsdv3',
								modifierId: '12313-c-svdvsdv3',
								name: 'Large',
								price: {
									units: 200,
									currencyCode: 'GBP',
								},
								quantity: 1,
							},
						],
					},
					{
						id: '12392139-3xsdf-xs22',
						name: 'Toppings',
						modifiers: [
							{
								id: 'asd2cc-d44gh56-dedd',
								name: 'Sweetcorn',
								price: {
									units: 50,
									currencyCode: 'GBP',
								},
								quantity: 1,
							},
							{
								id: 'ytj666-d44gh56-dedd',
								name: 'Onions',
								price: {
									units: 50,
									currencyCode: 'GBP',
								},
								quantity: 1,
							},
						],
					},
				],
				quantity: 1,
				ready: false,
				collected: false,
				refundedQuantity: 0,
				hasAllergen: true,
				menuId: 'menu-id-3',
			},
			{
				id: '2',
				name: 'Sprite',
				price: {
					units: 99,
					currencyCode: 'GBP',
				},
				quantity: 1,
				ready: true,
				collected: false,
				refundedQuantity: 0,
				menuId: 'menu-id-2',
			},
			{
				id: '3',
				name: 'Coke Zero',
				price: {
					units: 99,
					currencyCode: 'GBP',
				},
				quantity: 1,
				ready: true,
				collected: true,
				refundedQuantity: 0,
				menuId: 'menu-id-2',
			},
			{
				id: '4',
				name: 'Peanut packet',
				price: {
					units: 99,
					currencyCode: 'GBP',
				},
				quantity: 3,
				ready: false,
				collected: false,
				refundedQuantity: 1,
				menuId: 'menu-id-1',
			},
			{
				id: '5',
				name: 'Red Bull',
				price: {
					units: 99,
					currencyCode: 'GBP',
				},
				quantity: 1,
				ready: false,
				collected: false,
				refundedQuantity: 1,
				menuId: 'menu-id-2',
			},
		],
		menuIds: ['menu-id-1', 'menu-id-2', 'menu-id-3'],
	},
	{
		...order,
		type: 'delivery',
		status: 'orderBeingPrepared',
		id: '21349234-dfsf3-sdf-vn55h5',
		customer: {
			allergens: [
				{
					id: '1',
					name: 'eggs',
				},
				{
					id: '2',
					name: 'milk',
				},
			],
			name: 'Rich Mucha',
			phone: '01234 567 890',
		},
		collectAt: dayjs().add(62, 'minute').format(),
		orderedAt: dayjs().add(3, 'minute').format(),
	},
	{
		...order,
		id: '21349234-asdasdwwd-sdf-vn55h5',
		customer: {
			name: 'Kirsten Thomas',
			phone: '01234 567 890',
		},
		collectAt: dayjs().add(64, 'minute').format(),
		orderedAt: dayjs().add(1, 'minute').format(),
		type: 'tableService',
		tableNumber: '2',
	},
	{
		...order,
		status: 'pendingPickup',
		collectAt: dayjs().add(65, 'minute').format(),
		orderedAt: dayjs().format(),
		customer: {
			allergens: [
				{
					id: '1',
					name: 'eggs',
				},
				{
					id: '2',
					name: 'milk',
				},
			],
			name: 'craig matthews',
			phone: '01234 567 890',
		},
		type: 'delivery',
		id: '21349234-dfsf3-sdf-2awcac',
	},
	{
		...order,
		collectAt: dayjs().add(70, 'minute').format(),
		customer: {
			name: 'jake jones',
			phone: '01234 567 890',
		},
		type: 'tableService',
		tableNumber: '3',
		id: '21349234-dfsf3-sdf-sssdac',
	},
	{
		...order,
		collectAt: dayjs().add(75, 'minute').format(),
		customer: {
			name: 'Greg Morse',
			phone: '01234 567 890',
		},
		type: 'customService',
		id: '21349234-dfsf3-sdf-sdas',
		confirmationLineItems: [
			{
				title: 'Registration number',
				shortTitle: 'Reg',
				value: 'Y77 LRC',
				display: true,
				isHighlighted: false,
			},
			{
				title: 'Car colour',
				shortTitle: 'Colour',
				value: 'Blue',
				display: false,
				isHighlighted: false,
			},
			{
				title: 'Row number',
				shortTitle: 'Row',
				value: 'A1',
				display: false,
				isHighlighted: false,
			},
			{
				title: 'Additional information',
				value: 'My car has a yellow bonnet',
				display: false,
				isHighlighted: false,
			},
		],
		customService: {
			displayName: 'Ollies Pastie Shack',
			imageUrl:
				'https://production-orderpay.s3-eu-west-1.amazonaws.com/venues/images/curbside.png',
		},
	},
	{
		...order,
		collectAt: dayjs().add(80, 'minute').format(),
		customer: {
			name: 'Matt Smith',
			phone: '01234 567 890',
		},
		type: 'delivery',
		id: '21349234-dfsf3-sdf-20m4598',
	},
	{
		...order,
		collectAt: dayjs().add(85, 'minute').format(),
		customer: {
			name: 'James Bond',
			phone: '01234 567 890',
		},
		type: 'tableService',
		tableNumber: '4',
		id: '21349234-werwer-e-ff12312sd',
	},
	{
		...order,
		collectAt: dayjs().add(90, 'minute').format(),
		customer: {
			name: 'David Arnold',
			phone: '01234 567 890',
		},
		type: 'tableService',
		tableNumber: '5',
		id: '21349234-werwer-0dfs2-dvsv',
	},
	{
		...order,
		collectAt: dayjs().add(95, 'minute').format(),
		customer: {
			name: 'Austin Powers',
			phone: '01234 567 890',
		},
		type: 'delivery',
		id: '21349234-565653-sdf-12312sd',
	},
	{
		...order,
		collectAt: dayjs().add(100, 'minute').format(),
		customer: {
			name: 'Herman Grace',
			phone: '01234 567 890',
		},
		type: 'clickAndCollect',
		id: '232345-dfsf3-sdf-12312sd',
	},
	{
		...order,
		collectAt: dayjs().add(105, 'minute').format(),
		customer: {
			name: 'Janet Jones',
			phone: '01234 567 890',
		},
		type: 'tableService',
		tableNumber: '6',
		id: '232-dfsf3-sdf-12312sd',
	},
	{
		...order,
		collectAt: dayjs().add(110, 'minute').format(),
		customer: {
			name: 'Craig David',
			phone: '01234 567 890',
		},
		type: 'delivery',
		id: '21349234-dfsf3-sdf-12hngng312sd',
	},
	{
		...order,
		collectAt: dayjs().add(115, 'minute').format(),
		customer: {
			name: 'Fox Hound',
			phone: '01234 567 890',
		},
		type: 'clickAndCollect',
		id: '21349234-3434-sdf-2342',
	},
	{
		...order,
		collectAt: dayjs().add(120, 'minute').format(),
		customer: {
			name: 'Michael Swimmer',
			phone: '01234 567 890',
		},
		type: 'tableService',
		tableNumber: '7',
		id: '21349234-dfsf3-gb-12312sd',
	},
	{
		...order,
		collectAt: dayjs().add(125, 'minute').format(),
		customer: {
			name: 'Mallory Knox',
			phone: '01234 567 890',
		},
		type: 'tableService',
		tableNumber: '8',
		id: '21349234-dfsf3-sdf-edd12312sd',
	},
	{
		...order,
		collectAt: dayjs().add(130, 'minute').format(),
		customer: {
			name: 'James Bond',
			phone: '01234 567 890',
		},
		type: 'tableService',
		tableNumber: '9',
		id: 'nsdf-3efsdf-32-sdfe',
	},
	{
		...order,
		collectAt: dayjs().add(135, 'minute').format(),
		customer: {
			name: 'Harry Felt',
			phone: '01234 567 890',
		},
		type: 'delivery',
		id: '21349234-df3453453sf3-sdf-12312sd',
	},
	{
		...order,
		collectAt: dayjs().add(140, 'minute').format(),
		customer: {
			name: 'Annie Davies',
			phone: '01234 567 890',
		},
		type: 'clickAndCollect',
		id: '21349234-dfsf3-sdvsdv-3-dv',
	},
	{
		...order,
		collectAt: dayjs().add(145, 'minute').format(),
		customer: {
			name: 'Darcy Jones',
			phone: '01234 567 890',
		},
		type: 'tableService',
		tableNumber: '10',
		id: '234-dfsf3-sdf-12312sd',
	},
];

const futureOrders: IOrder[] = upcomingOrders.map((currOrder) => ({
	...currOrder,
	collectAt: dayjs().add(1, 'year').format(),
	orderedAt: dayjs().add(100, 'minute').format(),
}));

const completedOrders: IOrder[] = upcomingOrders.map((currOrder) => ({
	...currOrder,
	status: 'completed',
	orderedAt: dayjs().add(100, 'minute').format(),
}));

const items: IOrderItem[] = [
	{
		id: '1',
		name: 'Crisps',
		price: {
			units: 99,
			currencyCode: 'GBP',
		},
		quantity: 1,
		ready: false,
		collected: false,
		refundedQuantity: 0,
		hasAllergen: true,
		menuId: 'menu-id-1',
	},
	{
		id: '2',
		name: 'Pepsi',
		price: {
			units: 125,
			currencyCode: 'GBP',
		},
		quantity: 1,
		ready: false,
		collected: false,
		refundedQuantity: 0,
		hasAllergen: true,
		menuId: 'menu-id-2',
	},
	{
		id: '3',
		name: 'Hawaiian Pizza',
		price: {
			units: 999,
			currencyCode: 'GBP',
		},
		modifierCategories: [
			{
				id: '12392139-sdf-sdf-23',
				name: 'Size',
				modifiers: [
					{
						id: '12313-c-svdvsdv3',
						name: 'Large',
						price: {
							units: 200,
							currencyCode: 'GBP',
						},
						quantity: 1,
					},
				],
			},
			{
				id: '12392139-3xsdf-xs22',
				name: 'Toppings',
				modifiers: [
					{
						id: 'asd2cc-d44gh56-dedd',
						name: 'Sweetcorn',
						price: {
							units: 50,
							currencyCode: 'GBP',
						},
						quantity: 1,
					},
					{
						id: 'ytj666-d44gh56-dedd',
						name: 'Onions',
						price: {
							units: 50,
							currencyCode: 'GBP',
						},
						quantity: 1,
					},
				],
			},
		],
		menuId: 'menu-id-3',
		quantity: 1,
		ready: false,
		collected: false,
		refundedQuantity: 0,
		hasAllergen: true,
	},
];

const itemsStatusUpdated: IOrderItem[] = [
	{
		id: '2',
		name: 'Pepsi',
		price: {
			units: 125,
			currencyCode: 'GBP',
		},
		quantity: 1,
		ready: false,
		collected: false,
		refundedQuantity: 0,
		hasAllergen: true,
		menuId: 'menu-id-2',
	},
	{
		id: '3',
		name: 'Hawaiian Pizza',
		price: {
			units: 999,
			currencyCode: 'GBP',
		},
		modifierCategories: [
			{
				id: '12392139-sdf-sdf-23',
				name: 'Size',
				modifiers: [
					{
						id: '12313-c-svdvsdv3',
						name: 'Large',
						price: {
							units: 200,
							currencyCode: 'GBP',
						},
						quantity: 1,
					},
				],
			},
			{
				id: '12392139-3xsdf-xs22',
				name: 'Toppings',
				modifiers: [
					{
						id: 'asd2cc-d44gh56-dedd',
						name: 'Sweetcorn',
						price: {
							units: 50,
							currencyCode: 'GBP',
						},
						quantity: 1,
					},
					{
						id: 'ytj666-d44gh56-dedd',
						name: 'Onions',
						price: {
							units: 50,
							currencyCode: 'GBP',
						},
						quantity: 1,
					},
				],
			},
		],
		menuId: 'menu-id-3',
		quantity: 1,
		ready: false,
		collected: false,
		refundedQuantity: 0,
		hasAllergen: true,
	},
	{
		id: '1',
		name: 'Crisps',
		price: {
			units: 99,
			currencyCode: 'GBP',
		},
		quantity: 1,
		ready: true,
		collected: true,
		refundedQuantity: 0,
		hasAllergen: true,
		menuId: 'menu-id-1',
	},
];

const itemsRefundUpdated: IOrderItem[] = [
	{
		id: '1',
		name: 'Crisps',
		price: {
			units: 99,
			currencyCode: 'GBP',
		},
		quantity: 1,
		ready: false,
		collected: false,
		refundedQuantity: 1,
		hasAllergen: true,
		menuId: 'menu-id-1',
	},
	{
		id: '2',
		name: 'Pepsi',
		price: {
			units: 125,
			currencyCode: 'GBP',
		},
		quantity: 1,
		ready: false,
		collected: false,
		refundedQuantity: 0,
		hasAllergen: true,
		menuId: 'menu-id-2',
	},
	{
		id: '3',
		name: 'Hawaiian Pizza',
		price: {
			units: 999,
			currencyCode: 'GBP',
		},
		modifierCategories: [
			{
				id: '12392139-sdf-sdf-23',
				name: 'Size',
				modifiers: [
					{
						id: '12313-c-svdvsdv3',
						name: 'Large',
						price: {
							units: 200,
							currencyCode: 'GBP',
						},
						quantity: 1,
					},
				],
			},
			{
				id: '12392139-3xsdf-xs22',
				name: 'Toppings',
				modifiers: [
					{
						id: 'asd2cc-d44gh56-dedd',
						name: 'Sweetcorn',
						price: {
							units: 50,
							currencyCode: 'GBP',
						},
						quantity: 1,
					},
					{
						id: 'ytj666-d44gh56-dedd',
						name: 'Onions',
						price: {
							units: 50,
							currencyCode: 'GBP',
						},
						quantity: 1,
					},
				],
			},
		],
		quantity: 1,
		ready: false,
		collected: false,
		refundedQuantity: 0,
		hasAllergen: true,
		menuId: 'menu-id-3',
	},
];

const orderWithMultipleItems: IOrder = {
	orderedAt: dayjs().format(),
	collectAt: dayjs().add(1, 'hour').format(),
	customer: {
		name: 'James Bond',
		phone: '01234 567 890',
	},
	type: 'tableService',
	tableNumber: '11',
	status: 'venueAccepted',
	id: '21349234-dfsfasdsdf-12312sd',
	orderNum: '2d23d32',
	items,
	tip: {
		amount: 1000,
		isRefunded: false,
	},
	total: {
		units: 1000,
		currencyCode: 'GBP',
	},
	menuIds: ['menu-id-1', 'menu-id-2', 'menu-id-3'],
};

const ordersList: IOrdersList = [...upcomingOrders];

/** MOCKED ENDPOINT CONFIG */

const orderStatusResponse: IOrderStatusResponse = {
	id: order.id,
	status: 'venueAccepted',
};

const orderItemStatusReadyResponse = {
	orderId: order.id,
	itemId: order.items[0].id,
	ready: true,
	collected: true,
};

const orderRefundResponse = {
	id: order.id,
	items: [order.items[0].id],
	reason: 'Everything tastes like chicken',
};

const orderQueryResponse = {
	id: '6432245b-33c2-42fa-abaf-d694034b8aa7',
	query: 'Diet Pepsi replaced with 7UP',
};

const orderReceivedRequest: IOrderReceivedReqest = {
	timestamp: new Date().toISOString(),
};

const pagination = {
	pageSize: 10,
	pageNumber: 1,
	pageCount: 3,
	count: 29,
};

const customerArrived: ICustomerArrived = {
	id: order.id,
	arrivedAt: '13:26 30/07/2020',
};

const getOrder = {
	request: order.id,
	response: {
		order,
	},
};

const getOrdersList = {
	request: { venueId: mockVenue.data.venue.id },
	response: {
		orders: ordersList,
	},
};

const getCompletedOrders = {
	response: {
		orders: completedOrders,
	},
};

const postOrderReceived = {
	request: {
		...orderReceivedRequest,
	},
	response: {
		...orderReceivedRequest,
	},
};

export const mockedOrdersEndpoints = (mockHttpClient: MockAdapter) => {
	// Regex for order item update status url
	const putItemStatusRegex = new RegExp('/order/.*/item/.*/status');

	const putOrderStatusRegex = new RegExp('order/.*/status');

	const putOrderRefundRegex = new RegExp('order/.*/refund');

	const putOrderQueryRegex = new RegExp('order/.*/query');

	const postOrderReceivedRegex = new RegExp('order/.*/received');

	/** GetOrders */
	mockHttpClient
		.onGet('order', { query: mockOrder.endpoints.getOrdersList.request })
		.reply(() => {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					// Return 200 success and mock response
					resolve([200, mockOrder.endpoints.getOrdersList.response]);
				}, 1000);
			});
		});

	/** getCompletedOrders */
	mockHttpClient.onGet('order/completed').reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockOrder.endpoints.getCompletedOrders.response]);
			}, 1000);
		});
	});
	/** getOrder */
	mockHttpClient
		.onGet(`order/${mockOrder.endpoints.getOrder.request}`)
		.reply(() => {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					// Return 200 success and mock response
					resolve([200, mockOrder.endpoints.getOrder.response]);
				}, 1000);
			});
		});

	/** order refund */
	mockHttpClient.onPost(putOrderRefundRegex).reply((config) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Get data from request
				const data = JSON.parse(config.data);
				// split out url
				const url = config?.url?.split('/');
				// Create mock response
				const response = {
					...data,
					orderId: url![1],
					itemId: url![3],
				};

				// Return 200 success and mock response
				resolve([200, { response }]);
			}, 1000);
		});
	});

	/** put item status */
	mockHttpClient.onPut(putItemStatusRegex).reply((config) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Get data from request
				const data = JSON.parse(config.data);
				// split out url
				const url = config?.url?.split('/');
				// Create mock response
				const response = {
					...data,
					orderId: url![1],
					itemId: url![3],
				};

				// Return 200 success and mock response
				resolve([200, { newItemStatus: response }]);
			}, 1000);
		});
	});

	/** Post order status */
	mockHttpClient.onPost(putOrderStatusRegex).reply((config) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Get data from request
				const data = JSON.parse(config.data);
				// split out url
				const url = config?.url?.split('/');
				// Create mock response
				const response = {
					...data,
					id: url![1],
				};

				// Return 200 success and mock response
				resolve([200, response]);
			}, 1000);
		});
	});

	/** put order query */
	mockHttpClient.onPut(putOrderQueryRegex).reply((config) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Get data from request
				const data = JSON.parse(config.data);
				// split out url
				const url = config?.url?.split('/');
				// Create mock response
				const response = {
					...data,
					id: url![1],
				};

				// Return 200 success and mock response
				resolve([200, { orderQuery: response }]);
			}, 1000);
		});
	});

	/** Post order received */
	mockHttpClient.onPost(postOrderReceivedRegex).reply((config) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success
				resolve([200]);
			}, 1000);
		});
	});
};

const mockOrder = {
	data: {
		futureOrders,
		completedOrders,
		customerArrived,
		items,
		itemsStatusUpdated,
		itemsRefundUpdated,
		order,
		orderWithMultipleItems,
		futureOrder,
		orderCompleted,
		ordersList,
		orderWithAllergens,
		upcomingOrders,
		pagination,
		orderItemStatusReadyResponse,
		orderQueryResponse,
		orderRefundResponse,
		orderStatusResponse,
		orderReceivedRequest,
		serviceCharge,
		alertConfig,
	},
	endpoints: { getOrdersList, getCompletedOrders, getOrder, postOrderReceived },
};

export default mockOrder;
