import { Formik, Field, Form } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import FieldError from 'components/field-error/field-error.component';
import Input from 'components/form-inputs/input/input.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenueOfflineNotificationSettings,
	updateVenueOfflineNotificationSettings,
} from 'modules/venue/venue.slice';
import { IOfflineNotificationSettings } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	background: ${brand.white};
	padding: 40px 40px 27px;
	border-radius: 8px;
	margin: 0 0 30px;

	${mq.mobile`
		padding: 20px 16px 20px;
	`}
`;

const StyledIntro = styled.div`
	margin: 0 0 36px;

	h2 {
		font-size: ${fonts.sizes.larger};
		font-weight: ${fonts.weights.regular};
		margin: 0 0 10px;
	}

	p {
		font-size: ${fonts.sizes.semiLarge};
		margin: 0;
	}
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	align-content: flex-end;
	align-items: flex-end;
`;

const StyledInput = styled(Input)`
	width: 100%;
	max-width: 305px;
	margin: 0 10px 0 0;

	label {
		margin: 0 0 16px;
	}
`;

const StyledSwitch = styled(Switch)`
	margin: 0;
	max-width: 110px;
`;

const StyledActions = styled.div`
	margin: 50px 0 0;
	display: flex;
	max-width: 175px;
`;

const StyledButton = styled(Button)`
	width: 100%;
`;

// Validation schema
const formValidationSchema = object<IOfflineNotificationSettings>().shape({
	notificationEmail: string()
		.required(validationMessages.required('email'))
		.email(validationMessages.email('email')),
});

// offline notification form

const OfflineNotificationForm: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get venue ID and offline notifaction settings from state
	const { selectedVenue, offlineNotificationSettings } = useSelector(
		(state: RootState) => state.venue
	);
	const { emailAddress } = useSelector((state: RootState) => state.auth.user);

	useEffect(() => {
		// Get venue data by venue id
		const getVenueData = async (id: string) => {
			id && (await dispatch(getVenueOfflineNotificationSettings(id)));
		};
		getVenueData(selectedVenue.id);
	}, [selectedVenue, dispatch]);

	// Initial form values
	const initialValues: IOfflineNotificationSettings = {
		notificationEmail:
			(selectedVenue.id && offlineNotificationSettings?.notificationEmail) ||
			emailAddress,
		notificationEnabled:
			(selectedVenue.id && offlineNotificationSettings?.notificationEnabled) ||
			false,
	};

	// Handle form submission
	const handleSubmit = async (values: IOfflineNotificationSettings) => {
		if (!values.notificationEnabled) {
			// Confirm user wishes to disable settings
			const confirmDisable = await fireDialog({
				title: intl.formatMessage({
					id: 'settings.offlineNotification.dialog.confirmDisable.title',
				}),
				text: intl.formatMessage({
					id: 'settings.offlineNotification.dialog.confirmDisable.copy',
				}),
				showCancelButton: true,
			});

			// If user clicked cancel
			if (!confirmDisable.value) {
				return;
			}
		}

		// Update venue
		const response = await dispatch(
			updateVenueOfflineNotificationSettings(selectedVenue.id, values)
		);

		// Return on fail
		if (!response) {
			return;
		}

		// fire success message
		await fireDialog({
			title: intl.formatMessage({
				id: 'settings.offlineNotification.dialog.saved',
			}),
			showConfirmButton: false,
			icon: 'success',
			iconHtml: `<svg width="103" height="103" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M77.5588 36.8739L43.3628 71.0699C42.6418 71.7909 41.7148 72.0999 40.6848 72.0999C39.7578 72.0999 38.7278 71.7909 38.0068 71.0699L25.4408 58.5039C24.1018 57.1649 24.1018 54.8989 25.4408 53.5599C26.1618 52.8389 26.9858 52.5299 27.9128 52.5299C28.8398 52.5299 29.7668 52.8389 30.3848 53.5599L40.6848 63.8599L72.6148 31.9299C73.3358 31.2089 74.1598 30.8999 75.0868 30.8999C76.0138 30.8999 76.9408 31.2089 77.5588 31.9299C78.8978 33.2689 78.8978 35.5349 77.5588 36.8739Z" fill="currentColor"/>
			<circle cx="51.5" cy="51.5" r="48" stroke="currentColor" stroke-width="7"/>
			</svg>`,
			customClass: {
				icon: 'swal-custom-icon-html',
			},
			timer: 3000,
		});

		setFormSubmission(true);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, touched, errors }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<StyledIntro>
						<h2>
							<FormattedMessage id="settings.offlineNotification.title" />
						</h2>
						<p>
							<FormattedMessage id="settings.offlineNotification.copy" />
						</p>
					</StyledIntro>
					<StyledFieldGroup>
						<Field
							component={StyledInput}
							name="notificationEmail"
							label={intl.formatMessage({
								id: 'form.fields.notificationEmail.label',
							})}
							placeholder={intl.formatMessage({
								id: 'form.fields.notificationEmail.label',
							})}
							showErrors={false}
						/>
						<Field component={StyledSwitch} name="notificationEnabled" />
					</StyledFieldGroup>
					{touched.notificationEmail && errors.notificationEmail && (
						<FieldError ariaLabel="notificationEmail-error">
							{errors.notificationEmail}
						</FieldError>
					)}
					<StyledActions>
						<StyledButton
							type="submit"
							ariaLabel="submit-button"
							size="lg"
							disabled={!dirty}
						>
							<FormattedMessage id="form.button.save" />
						</StyledButton>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default OfflineNotificationForm;
