import React from 'react';
import Masonry, { MasonryProps } from 'react-masonry-css';
import styled from 'styled-components';

import { mq } from 'assets/styles/variables/responsive';

interface IComponentProps {
	className?: string;
}

interface IStyledComponent extends MasonryProps {
	viewHeight?: number;
}

const StyledMasonry = styled(Masonry)<IStyledComponent>`
	display: flex;
	width: 100%;
	height: 100%;

	overflow-y: scroll;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}

	.sub-grid-column {
		padding-left: 14px;
		display: flex;
		flex-direction: column;

		${mq.mobile`
		padding: 0;
	`}
	}

	${mq.mobile`
		padding: 15px 0 0;
	`}

	> div {
		display: flex;
	}
`;

/** Renders masonry order card component */
const MasonryOrderCards: React.FC<IComponentProps> = ({
	className = 'styled-masonry-grid',
	children,
}) => {
	const masonryBreakpoints = {
		default: 3,
		768: 1,
	};

	return (
		<StyledMasonry
			data-testid="masonry-order-cards"
			breakpointCols={masonryBreakpoints}
			className={className}
			columnClassName="sub-grid-column"
		>
			{children}
		</StyledMasonry>
	);
};

export default MasonryOrderCards;
