import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { ITerminalStore } from './terminal.types';

// Create initial state
export const initialTerminalState: ITerminalStore = {
	eventsInProgress: 0,
	devices: [],
};

const terminalSlice = createSlice({
	name: 'terminal',
	initialState: initialTerminalState,
	reducers: {
		POST_ONBOARD_STRIPE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		POST_ONBOARD_STRIPE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_ONBOARD_STRIPE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_ONBOARD_COMPLETE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		POST_ONBOARD_COMPLETE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_ONBOARD_COMPLETE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_DEVICES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_DEVICES_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				devices: action.payload.data.data,
				devicesPagination: {
					page: action.payload.data.page,
					count: action.payload.data.count,
					totalPageCount: action.payload.data.totalPageCount,
				},
			};
		},
		GET_DEVICES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	POST_ONBOARD_STRIPE,
	POST_ONBOARD_STRIPE_SUCCESS,
	POST_ONBOARD_STRIPE_FAIL,
	POST_ONBOARD_COMPLETE,
	POST_ONBOARD_COMPLETE_SUCCESS,
	POST_ONBOARD_COMPLETE_FAIL,
	GET_DEVICES,
	GET_DEVICES_SUCCESS,
	GET_DEVICES_FAIL,
} = terminalSlice.actions;

export const onboardStripe = (
	stripeOnboardToken: string,
	venueId: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		POST_ONBOARD_STRIPE({
			request: {
				method: 'post',
				url: 'onboard/stripe',
				data: { stripeOnboardToken, venueId },
			},
		})
	);

	return payload?.data;
};

export const onboardComplete = (code: string, venueId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		POST_ONBOARD_COMPLETE({
			request: {
				method: 'post',
				url: 'onboard/complete',
				data: { code: code.toUpperCase(), venueId },
			},
		})
	);

	return payload?.data;
};

export const getDevices = (venueId: string) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		GET_DEVICES({
			request: {
				method: 'get',
				url: 'device',
				params: {
					venueId,
					// TODO - When people have lots of devices, implement pagination
					count: 200,
				},
			},
		})
	);

	return payload?.data;
};

export default terminalSlice.reducer;
