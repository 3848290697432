import { FieldProps } from 'formik';
import { rgba } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';

interface IComponentProps {
	label?: string;
	handleChangeEvent?: Function;
	max?: number;
}

const StyledFormElement = styled(FormElement)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	position: relative;
`;

const StyledInput = styled.input<IComponentProps>`
	width: 52px;
	text-align: center;
	padding: 0;
	color: ${brand.text};
	font-size: ${fonts.sizes.large};
	border: none;
	background: transparent;
	appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
	}
	&[type='number'] {
		appearance: textfield;
	}

	${mq.mobile`
		width: 46px;
	`}
`;

const commonButtonStyles = css`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	> div {
		flex-shrink: 0;
	}
`;

const StyledButtonDecrease = styled(Button)`
	${commonButtonStyles}
	background: ${rgba(brand.black, 0.2)};
`;

const StyledButtonIncrease = styled(Button)`
	${commonButtonStyles}
	background: ${brand.primary};
`;

/** Renders InputQuantity component */
const InputQuantity: React.FC<
	IComponentProps & FieldProps & React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
	const { className, field, form, max } = props;

	let fieldValueAsNum = parseFloat(field.value);

	const decreaseDisabled = fieldValueAsNum === 0;

	const increaseDisabled = fieldValueAsNum === max;

	return (
		<StyledFormElement className={className}>
			<StyledButtonDecrease
				data-testid="button-decrease"
				disabled={decreaseDisabled}
				onClick={() => {
					!decreaseDisabled &&
						form.setFieldValue(field.name, (fieldValueAsNum -= 1));
				}}
			>
				<Icon
					name="minus"
					colour="white"
					width={10}
					height={4}
					heightMobile={10}
					widthMobile={4}
				/>
			</StyledButtonDecrease>
			<StyledInput
				disabled
				{...field}
				type="number"
				id={field.name}
				data-testid="input-quantity"
			/>
			<StyledButtonIncrease
				data-testid="button-increase"
				disabled={increaseDisabled}
				onClick={() => {
					!increaseDisabled &&
						form.setFieldValue(field.name, (fieldValueAsNum += 1));
				}}
			>
				<Icon
					name="plus"
					colour="white"
					width={9}
					height={9}
					heightMobile={9}
					widthMobile={9}
				/>
			</StyledButtonIncrease>
		</StyledFormElement>
	);
};

export default InputQuantity;
