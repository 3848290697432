import { ITimeslot } from 'modules/venue/venue.types';

export const transformTimeslots = (timeSlots: ITimeslot[]): ITimeslot[] =>
	timeSlots.map((timeSlot) => ({
		day: timeSlot.day,
		name: timeSlot.name,
		closingTime: timeSlot.closingTime ?? '23:59',
		isOpen: timeSlot.isOpen,
		ordersPerSlot: timeSlot.ordersPerSlot ?? 5,
		slotDuration: timeSlot.slotDuration ?? 5,
		openingTime: timeSlot.openingTime ?? '00:00',
	}));
