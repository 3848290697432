import { rgba } from 'polished';
import { keyframes } from 'styled-components';

import brand from './brand';

export const keyframeBoxShadowPulse = (colour: string) => keyframes`
	0% {
		box-shadow: 0 0 0 0 ${rgba(colour, 0.4)};
	  }
	  70% {
		  box-shadow: 0 0 0 6px ${rgba(colour, 0)};
	  }
	  100% {
		  box-shadow: 0 0 0 0 ${rgba(colour, 0)};
	  }
`;

export const keyframeBorderPulse = (
	colour: string,
	borderWidth: number
) => keyframes`
	0% {
		border: ${borderWidth}px solid ${rgba(colour, 1)};
	  }
	  50% {
		border: ${borderWidth}px solid ${rgba(colour, 0.6)};
	  }
	  100% {
		border: ${borderWidth}px solid ${rgba(colour, 1)};
	  }
`;

export const keyframeOrderAlertEscalation = () => keyframes`
	0% {
		background: ${brand.generic_error};
		color: ${brand.white};
	  }
	  50% {
		background: ${brand.generic_error_light};
		color: ${brand.generic_error};
	  }
	  100% {
		background: ${brand.generic_error};
		color: ${brand.white};
	  }
`;
