import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import TipItem from './tip-item/tip-item.component';

import { ETransitionState, ITransition } from 'app.types';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { ITip } from 'modules/orders/order.types';

interface IComponentProps {
	refundActive?: boolean;
	tip: ITip;
}

const TipTransition = (state: keyof typeof ETransitionState) => {
	switch (state) {
	case 'entering':
		return css`
				margin: 0;
				transition: 0.2s;
				opacity: 0;
			`;
	case 'entered':
		return css`
				margin: 0 0 23px;
				transition: 0.2s;
				opacity: 1;
			`;
	case 'exiting':
		return css`
				margin: 0;
				transition: 0.2s;
				opacity: 0;
			`;
	}

	return css`
		margin: 0;
		transition: 0.2s;
		opacity: 0;
		max-height: 0;
		overflow: hidden;
	`;
};

const StyledOrderDetailsTipStatus = styled.div<ITransition>`
	${mq.mobile`
		margin: 0 0 15px;
	`}

	${({ state }) => TipTransition(state)}
`;

const StyledHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 16px 0 8px;

	h3 {
		font-size: ${fonts.sizes.semiLarge};
		font-weight: ${fonts.weights.medium};
		margin: 0;
	}

	.sub-refund {
		opacity: 0;
		transition: 0.2s;
		width: 150px;
		text-align: center;

		${mq.mobile`
			width: 106px;
		`}
	}
`;

const StyledOrderDetailsItemList = styled.ul`
	margin: 0;
	padding: 0;
`;

/** Renders tip component */
const OrderDetailsTip: React.FC<IComponentProps> = ({ refundActive, tip }) => {
	// Reference to DOM element that needs to transition
	const nodeRef = React.useRef(null);

	return (
		<div>
			<Transition in={true} timeout={200} nodeRef={nodeRef}>
				{(state) => (
					<StyledOrderDetailsTipStatus state={state}>
						<StyledHeader>
							<h3>
								<FormattedMessage id="orderDetails.item.status.tip" />
							</h3>
							{!tip.isRefunded && (
								<div className="sub-refund">
									<h3>
										<FormattedMessage id="order_details.item_status.refund" />
									</h3>
								</div>
							)}
						</StyledHeader>
						<StyledOrderDetailsItemList>
							<TipItem tip={tip} refundActive={refundActive} />
						</StyledOrderDetailsItemList>
					</StyledOrderDetailsTipStatus>
				)}
			</Transition>
		</div>
	);
};

export default OrderDetailsTip;
