import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import MasonryOrderCards from 'components/masonry-order-cards/masonry-order-cards.component';
import TicketOrderCard from 'components/ticket-order-card/ticket-order-card.component';
import dayjs from 'helpers/dayjs.helper';
import { RootState } from 'modules/core/state/root.reducer';
import { getOrdersList } from 'modules/orders/order.slice';
import { IOrderListFilters } from 'modules/orders/order.types';

const StyledOrderCard = styled(TicketOrderCard)`
	width: 100%;
`;

const StyledEmptyOrders = styled.div`
	background: ${brand.white};
	padding: 20px;
	border-radius: 5px;
	margin: 0 1%;

	h2 {
		margin: 0;

		${mq.mobile`
			font-size: ${fonts.sizes.semiLarge};
		`}
	}
`;

/** Renders ticket view in progress component */
const TicketViewInProgress: React.FC = () => {
	// Get hooks
	const dispatch = useDispatch();
	const { selectedVenue } = useSelector((state: RootState) => state.venue);
	// Get completed orders
	const { completedOrders, orderFilters } = useSelector(
		(state: RootState) => state.ticketView
	);

	// Get current date
	const dateToday = dayjs().tz('Europe/London').format('YYYY-MM-DD');

	// get orders and filter by status and completed orders, then sort
	const ordersList = useSelector((state: RootState) =>
		state.order.ordersList
			?.filter(
				(order) =>
					order.status === 'venueAccepted' ||
					order.status === 'orderBeingPrepared'
			)
			.filter(
				(order) =>
					!completedOrders.some(
						(completedOrder) => completedOrder.id === order.id
					)
			)
			.sort((a, b) => {
				const aDate = a.collectAt || a.orderedAt;
				const bDate = b.collectAt || b.orderedAt;
				if (aDate === bDate) {
					return 0;
				}
				return aDate! > bDate! ? 1 : -1;
			})
	);

	useEffect(() => {
		const getData = async () => {
			const filters: IOrderListFilters = { menuIds: orderFilters || [] };
			// dispatch get orders list
			!!selectedVenue.id &&
				(await dispatch(getOrdersList(selectedVenue.id, dateToday, filters)));
		};

		getData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, selectedVenue, orderFilters]);

	// Map orders to component
	const mappedOrders = ordersList.map((order) => (
		<StyledOrderCard key={order.id} order={order} menuFilters={orderFilters} />
	));

	return ordersList.length ? (
		<MasonryOrderCards>{mappedOrders}</MasonryOrderCards>
	) : (
		<StyledEmptyOrders>
			<h2>
				<FormattedMessage id="ticketView.inProgress.empty" />
			</h2>
		</StyledEmptyOrders>
	);
};

export default TicketViewInProgress;
