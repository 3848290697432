import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';

// Interface for component props
interface IComponentProps {
	variant?: string;
}

const loginStyling = css`
	width: 356px;
	padding: 30px 22px;
	color: ${brand.white};
	font-size: ${fonts.sizes.h1};
	font-weight: ${fonts.weights.semibold};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	svg {
		width: 40px;
		height: 40px;
		margin-right: 20px;
	}

	.sub-title {
		display: block;
	}
`;

const navStyling = css`
	width: 100%;
	height: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${brand.black};
	color: ${brand.white};
	border-radius: 0 0 0 10px;

	${mq.mobile`
		width: 60px;
		height: 100%;

		svg {
			width: 25px;
			height: 25px;
		}
	`}

	.sub-title {
		display: none;
	}

	svg {
		width: 29px;
		height: 29px;
	}
`;

const StyledLogo = styled(Logo)`
	color: ${brand.white};
`;

// Create styled logo block component
const StyledLogoBlock = styled.div<IComponentProps>`
	${({ variant }) => (variant === 'login' ? loginStyling : navStyling)}
`;

/** Renders logo block component */
const LogoBlock: React.FC<IComponentProps> = ({ variant }) => {
	return (
		<StyledLogoBlock variant={variant}>
			<StyledLogo />
			<div className="sub-title">
				<FormattedMessage id="app.title" />
			</div>
		</StyledLogoBlock>
	);
};

export default LogoBlock;
