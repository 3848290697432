import { FieldProps } from 'formik';
import React, {
	FunctionComponent,
	InputHTMLAttributes,
	useEffect,
} from 'react';
import DatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';

import {
	StyledFormElement,
	StyledLabel,
	StyledChevron,
} from './time-picker.styles';

// Interface for component props
interface IComponentProps {
	label?: string;
	minDate?: Date;
	maxDate?: Date;
	handleChangeEvent?: Function;
	isDisabled?: boolean;
	minTime?: string;
	timeType?: 'from' | 'to';
	showChevron?: boolean;
}

/** Renders TimePickerInput component */
const TimePickerInput: FunctionComponent<
	IComponentProps & FieldProps & InputHTMLAttributes<HTMLInputElement>
> = ({
	field,
	form,
	label,
	handleChangeEvent,
	minDate,
	maxDate,
	isDisabled,
	timeType,
	minTime,
	className,
	showChevron = true,
}) => {
	// Variable for field label
	let fieldLabel = label;
	const intl = useIntl();
	// Function to format time as HH:MM
	const formatTime = (dateToFormat: Date) => {
		if (dateToFormat) {
			return [dateToFormat.getHours(), dateToFormat.getMinutes()]
				.map((num: number) => `0${num}`.slice(-2))
				.join(':');
		}
		return '';
	};

	// Create new date;
	const currentTimeValue = new Date();
	const startTime = new Date();
	const endTime = new Date();
	endTime.setHours(23);
	endTime.setMinutes(59);

	// If field value, set time correctly
	if (field.value && field.value.includes(':')) {
		// Split current field value by colon
		const timeSplit = field.value.split(':');
		// Set date time by current field value
		currentTimeValue.setHours(Number(timeSplit[0]));
		currentTimeValue.setMinutes(Number(timeSplit[1]));
	} else {
		// default to 00:00
		currentTimeValue.setHours(0);
		currentTimeValue.setMinutes(0);
	}

	if (minTime) {
		// Split current field value by colon
		const timeSplit = minTime.split(':');
		// Set date time by current field value
		startTime.setHours(Number(timeSplit[0]));
		startTime.setMinutes(Number(timeSplit[1]));
	} else {
		// default to 00:00
		startTime.setHours(0);
		startTime.setMinutes(0);
	}

	useEffect(() => {
		// If start time is bigger than current time and we have a field value
		if (
			startTime.toISOString() > currentTimeValue.toISOString() &&
			field.value
		) {
			// set date to start time
			currentTimeValue.setHours(startTime.getHours());
			currentTimeValue.setMinutes(startTime.getMinutes());
			// set field value correctly
			form.setFieldValue(field.name, formatTime(currentTimeValue));
		}
	});

	// If no field label
	if (!fieldLabel && timeType) {
		// set to default label
		fieldLabel =
			timeType === 'from'
				? intl.formatMessage({ id: 'form.fields.openingTime.label' })
				: intl.formatMessage({ id: 'form.fields.closingTime.label' });
	}

	return (
		<StyledFormElement className={className}>
			{(timeType || fieldLabel) && (
				<StyledLabel htmlFor={`${field.name}-input`}>{fieldLabel}</StyledLabel>
			)}
			<div className="mod-input-wrapper">
				<DatePicker
					id={`${field.name}-input`}
					selected={field.value ? currentTimeValue : null}
					onChange={(day: Date) => {
						form.setFieldValue(field.name, formatTime(day));
						if (handleChangeEvent) {
							handleChangeEvent({
								fieldValue: formatTime(day),
								form,
							});
						}
					}}
					showTimeSelect
					showTimeSelectOnly
					timeFormat="HH:mm"
					timeIntervals={15}
					timeCaption={intl.formatMessage({
						id: 'form.fields.datePicker.time',
					})}
					dateFormat="HH:mm"
					minDate={minDate}
					maxDate={maxDate}
					disabled={isDisabled}
					showPopperArrow={false}
					placeholderText="00:00"
					minTime={startTime}
					maxTime={endTime}
				/>
				{showChevron && (
					<StyledChevron
						name="chevron"
						className="icon-chevron"
						opacity={isDisabled ? 0.3 : 1}
						colour="primary"
						width={8}
						height={8}
						heightMobile={8}
						widthMobile={8}
					/>
				)}
			</div>
		</StyledFormElement>
	);
};

export default TimePickerInput;
