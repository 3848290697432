import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { history } from '../../core/routing/app-router.component';
import { processLogout } from '../auth.slice';

import dayjs from 'helpers/dayjs.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

/** Renders auth expiry component */
const AuthExpiryComponent: FunctionComponent = () => {
	// Get useHooks
	const dispatch = useDispatch();

	// Get refresh token
	const { refreshToken } = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		let timeout: number = 0;
		// If we have a refresh expiry token
		if (refreshToken.expiry) {
			// Current date
			const currentDate = dayjs();
			// refresh date
			const refreshDate = dayjs(dayjs.unix(refreshToken.expiry!));

			const timeoutTime =
				refreshDate.diff(currentDate) > 2147483647
					? 2147483647
					: refreshDate.diff(currentDate);

			// Create set timeout
			timeout = setTimeout(() => {
				// Dispatch logout
				dispatch(processLogout());
				// notify user of auth expiry
				fireDialog({
					icon: 'error',
					title: intl.formatMessage({ id: 'authExpiry.dialog.title' }),
					text: intl.formatMessage({ id: 'authExpiry.dialog.text' }),
					confirmButtonText: intl.formatMessage({
						id: 'authExpiry.dialog.button.confirm',
					}),
				});
				// Redirect user to login
				history.push('/');
				// Timeout based on refresh - current date difference (defaults to milliseconds)
			}, timeoutTime);
		}
		// Clear timeout
		return () => clearTimeout(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToken.expiry]);

	return <></>;
};

export default AuthExpiryComponent;
