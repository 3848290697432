import { rgba } from 'polished';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import OrderIcon from '../order-icon/order-icon.component';
import StatusPill from '../status-pill/status-pill.component';

import { EOrderType } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Icon from 'components/icons/icon.component';
import dayjs from 'helpers/dayjs.helper';
import { serviceTypeColour } from 'helpers/service-type-colour.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { IOrder } from 'modules/orders/order.types';
import {
	addCompletedTicket,
	removeCompletedTicket,
} from 'modules/ticket-view/ticket-view.slice';

interface IComponentProps {
	className?: string;
	order: IOrder;
	isCompleted?: boolean;
	menuFilters?: string[];
}

interface IStyledComponentProps {
	className?: string;
	orderType?: keyof typeof EOrderType;
}

const StyledOrderCard = styled.div<IStyledComponentProps>`
	width: 100%;
	background: ${brand.white};
	border-radius: 5px;
	box-shadow: 4px 4px 8px ${rgba(brand.black, 0.1)};
	margin: 0 0 10px;
	display: block;
	flex-direction: column;
	transition: 0.3s;

	${mq.tabletPortrait`
		box-sizing: border-box;
	`}
`;

const StyledHeader = styled.div<IStyledComponentProps>`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 13px;
	align-items: center;
	justify-content: space-between;
	padding: 15px 20px;
	background: ${({ orderType }) => rgba(serviceTypeColour(orderType!), 0.1)};
	border-radius: 5px 5px 0 0;
`;

const StyledInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 13px;
`;

const StyledInfo = styled.div`
	display: flex;

	h3 {
		font-weight: ${fonts.weights.bold};
		font-size: ${fonts.sizes.large};
		margin: 0 0 2px;
	}

	h4 {
		font-weight: ${fonts.weights.light};
		font-size: ${fonts.sizes.med};
		margin: 0;
	}
`;

const StyledExtraDetailWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const StyledTime = styled.div`
	display: flex;
	align-items: center;

	.sub-table {
		text-transform: uppercase;

		h4 {
			font-weight: ${fonts.weights.bold};
		}

		.sub-extra-detail {
			text-transform: uppercase;

			h4 {
				font-weight: ${fonts.weights.bold};
			}
		}
	}

	.sub-divider {
		width: 1px;
		height: 14px;
		background: ${brand.black};
		margin: 0 10px;
		content: '';
		display: block;
	}
`;

const StyledTicketAction = styled.div`
	width: 38px;
	height: 38px;
	border-radius: 50%;
	background: ${brand.background_dark};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const StatusPillWrapper = styled.div`
	align-items: center;
	margin: 6px 0 0;
	display: inline-block;
	width: 100%;
`;

const StyledStatusPill = styled(StatusPill)`
	align-self: flex-start;
	margin-bottom: 3px;
`;

const StyledOrderItemWrapper = styled.ul`
	margin: 0;
	padding: 0 20px 10px;
	list-style: none;
`;

const StyledOrderItem = styled.li`
	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		border-bottom: 1px solid ${brand.borders};
	}
`;

const StyledOrderItemContent = styled.div`
	margin: 0;
	list-style: none;
	padding: 11px 0;
	display: flex;
	justify-items: center;
`;

const StyledOrderQuantity = styled.div`
	font-weight: ${fonts.weights.bold};
	font-size: ${fonts.sizes.semiLarge};
	width: 25px;
`;

const StyledOrderDescription = styled.div`
	display: flex;
	flex-direction: column;

	h4 {
		font-weight: ${fonts.weights.medium};
		font-size: ${fonts.sizes.semiLarge};
		margin: 0;
	}
`;

const StyledModifiers = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const StyledModifier = styled.li`
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.light};
	margin: 0 3px;
`;

const StyledOrderNote = styled.div`
	background: ${brand.note_background};
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.small};
	border-top: 1px solid ${brand.borders};
	border-bottom: 1px solid ${brand.borders};
	padding: 14px 20px;
	margin-top: -13px;
	h3 {
		font-weight: bold;
		font-size: ${fonts.sizes.base};
		line-height: ${fonts.line_height.small};
		color: ${brand.note_title};
		margin: 0 0 2px;
		text-transform: uppercase;
	}
	p {
		margin: 0;
	}
	p:not(:last-child) {
		margin: 0 0 5px;
	}
`;

const StyledItemNote = styled.div`
	width: 100%;
	border-radius: 5px;
	padding: 8px 25px;

	background-color: ${brand.note_background};
	font-size: 14px;
`;

/** Renders ticket order card component */
const TicketOrderCard: React.FC<IComponentProps> = ({
	order,
	isCompleted = false,
	className,
	menuFilters,
}) => {
	// Get use dispatch hook
	const dispatch = useDispatch();

	// destruct order and get variables
	const {
		type,
		orderedAt,
		collectAt,
		customer,
		items,
		customService,
		orderNum,
		tableNumber,
	} = order;
	const { name, allergens } = customer;

	// create allergens list
	let allergenList: string = '';
	!!allergens &&
		allergens?.length > 0 &&
		allergens.forEach((allergen, index) => {
			if (index === 0) {
				allergenList += allergen.name;
			} else {
				allergenList += ` | ${allergen.name}`;
			}
		});

	// format collect/order date
	const orderDate = collectAt
		? dayjs(collectAt).tz('Europe/London')
		: dayjs(orderedAt).tz('Europe/London');

	// Create order items
	// If we have menu filters, filter the items being shown by menu ID
	const orderItems = items
		.filter((item) =>
			menuFilters?.length ? menuFilters?.includes(item.menuId) : true
		)
		.map((item) => {
			const modifierItems = item.modifierCategories?.map((category) =>
				category?.modifiers?.map((modifier) => (
					<StyledModifier key={modifier.id}>{modifier.name}</StyledModifier>
				))
			);

			return (
				<StyledOrderItem key={item.id}>
					<StyledOrderItemContent>
						<StyledOrderQuantity>{item.quantity}</StyledOrderQuantity>
						<StyledOrderDescription>
							<h4>{item.name}</h4>
							<StyledModifiers>{modifierItems}</StyledModifiers>
						</StyledOrderDescription>
					</StyledOrderItemContent>
					{!!item.itemNotes && (
						<StyledItemNote>{item.itemNotes}</StyledItemNote>
					)}
				</StyledOrderItem>
			);
		});

	return (
		<StyledOrderCard className={className}>
			<StyledHeader orderType={type}>
				<StyledInfo>
					<OrderIcon
						variant={type}
						width={44}
						height={44}
						mobileWidth={44}
						mobileHeight={44}
						customIcon={customService?.imageUrl}
					/>
					<StyledInfoWrapper>
						<StyledTime>
							{order.type === 'tableService' && (
								<StyledExtraDetailWrapper>
									<div className="sub-extra-detail">
										<h4>
											<FormattedMessage id="orderCard.table" />
											&nbsp;
											{tableNumber}
										</h4>
									</div>
									<div className="sub-divider" />
								</StyledExtraDetailWrapper>
							)}
							<StyledExtraDetailWrapper>
								<div className="sub-extra-detail">
									<h4>
										<FormattedMessage id="orderCard.number" />
										&nbsp;
										{orderNum}
									</h4>
								</div>
								<div className="sub-divider" />
							</StyledExtraDetailWrapper>
							{order.type === 'customService' &&
								order.confirmationLineItems
									?.filter((item) => item.display)
									.map((item) => (
										<StyledExtraDetailWrapper key={item.value}>
											<div className="sub-extra-detail">
												<h4>{item.value}</h4>
											</div>
											<div className="sub-divider" />
										</StyledExtraDetailWrapper>
									))}
							<h3>{orderDate.format('HH:mm')}</h3>
						</StyledTime>
						<h4 data-private>
							{name || intl.formatMessage({ id: 'order.unkownName' })}
						</h4>
					</StyledInfoWrapper>
				</StyledInfo>
				{isCompleted ? (
					<StyledTicketAction
						onClick={() => dispatch(removeCompletedTicket(order.id))}
					>
						<Icon name="undo" width={12} height={12} colour="white" />
					</StyledTicketAction>
				) : (
					<StyledTicketAction
						onClick={() => dispatch(addCompletedTicket(order))}
					>
						<Icon name="tick" width={12} height={12} colour="white" />
					</StyledTicketAction>
				)}
				{!!allergens && allergens.length > 0 && (
					<StatusPillWrapper>
						<StyledStatusPill type="warning" backgroundColour="order_allergy">
							{allergenList}
						</StyledStatusPill>
					</StatusPillWrapper>
				)}
			</StyledHeader>
			{order.customerNotes && order.customerNotes.length > 0 && (
				<StyledOrderNote>
					<h3>Order Note</h3>
					{order.customerNotes.map(({ roundId, note }) => (
						<p key={roundId}>{note}</p>
					))}
				</StyledOrderNote>
			)}
			<StyledOrderItemWrapper>{orderItems}</StyledOrderItemWrapper>
		</StyledOrderCard>
	);
};

export default TicketOrderCard;
