import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IPusherStore, EConnectionStatus } from './pusher.types';

// Create initial state
export const initialPusherState: IPusherStore = {
	connectionStatus: 'initialized',
	eventsInProgress: 0,
};

const pusherSlice = createSlice({
	name: 'pusher',
	initialState: initialPusherState,
	reducers: {
		PUSHER_CONNECTION_STATUS(state, action) {
			return {
				...state,
				connectionStatus: action.payload.data,
			};
		},
		PUSHER_AUTH(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PUSHER_AUTH_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUSHER_AUTH_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	PUSHER_CONNECTION_STATUS,
	PUSHER_AUTH,
	PUSHER_AUTH_FAIL,
	PUSHER_AUTH_SUCCESS,
} = pusherSlice.actions;

/** Thunk to update pusher connection status */
export const updatePusherStatus = (
	status: keyof typeof EConnectionStatus
) => async (dispatch: Dispatch) => {
	// set connectionstatus
	await dispatch(
		PUSHER_CONNECTION_STATUS({
			data: status,
		})
	);
};

/** Thunk to process pusher auth request */
export const processPusherAuth = (
	socketId: string,
	channelName: string
) => async (dispatch: Dispatch) => {
	// Create pusher auth request
	const { payload } = await dispatch(
		PUSHER_AUTH({
			request: {
				method: 'post',
				url: 'pusher/auth',
				data: { socket_id: socketId, channel_name: channelName },
			},
		})
	);

	return payload;
};

export default pusherSlice.reducer;
