/* eslint-disable @typescript-eslint/indent */
import { EOrderType } from 'app.types';
import brand from 'assets/styles/variables/brand';

// Function to get the correct service type colour
export const serviceTypeColour = (
	serviceType: keyof typeof EOrderType
): string => {
	switch (serviceType) {
		case 'clickAndCollect': {
			return brand.order_collection;
		}
		case 'delivery': {
			return brand.order_delivery;
		}
		case 'tableService': {
			return brand.order_table_service;
		}
		case 'selfService': {
			return brand.order_selfService;
		}
		default: {
			return brand.order_generic;
		}
	}
};
