export enum EWebviewMessageType {
	registerPushNotification = 'register_push_request',
	deRegisterPushNotification = 'deregister_push_request',
}

export interface IWebViewMessage {
	type: EWebviewMessageType;
	payload: {
		venueId: string;
	};
}

export interface IWebviewStore {
	isVenueRegistered?: boolean;
}
