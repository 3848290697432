import { Field, Formik, FormikProps } from 'formik';
import { History } from 'history';
import { rgba } from 'polished';
import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { StyledSelect } from 'modules/settings/service-availability-list/service-availability/service-availability.styles';

export interface ITab {
	id: string;
	title: string;
	defaultTab?: string;
	queryString?: string;
}

interface ITabListProps {
	activeTab: string;
	setActiveTab: Function;
	tabs: ITab[];
	variant?: 'secondary';
	navigateOnClick?: boolean;
	history?: History;
	className?: string;
	parentUrl?: string;
}

const SecondaryTabStyles = css`
	min-width: auto;
	color: ${brand.primary};
	position: static;
	margin: -10px 10px 25px 0;
	padding: 5px 14px;
	border-radius: 50px;
	border: 1px solid ${brand.primary};

	&:hover,
	&.mod-active {
		background: ${brand.secondary};
		border-color: transparent;

		&:after {
			display: none;
		}
	}
`;

const StyledTabList = styled.ul<ITabListProps>`
	display: flex;
	list-style: none;
	margin: 0 0 -4px;
	padding: 0;
	user-select: none;

	${({ variant }) =>
		variant === 'secondary' ? 'margin-bottom: 0; border-bottom: none;' : null}

	${mq.mobile`
		display: none;
	`}
`;

const StyledTab = styled.li<ITabListProps>`
	max-width: 180px;
  flex: 1;
	text-align: center;
	padding: 13px;
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.medium};
	line-height: ${fonts.line_height.med};
	position: relative;
	color: ${brand.primary};
	cursor: pointer;
	background: ${rgba(brand.white, 0.5)};
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

	&:not(:last-child) {
		margin: 0 5px 0 0;
	}

	&:hover,
	&.mod-active {
		color: ${brand.text};
		background: ${brand.white};
	}

	${({ variant }) => (variant === 'secondary' ? SecondaryTabStyles : null)}

	${({ tabs }) => mq.mobile`min-width: calc(97% / ${tabs.length});`}

	${mq.mobile`
		padding: 13px;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	`}
`;

const StyledSelectContainer = styled.div`
	${mq.mobile`
		display: flex;
	`}

	${mq.tabletUp`
		display: none;
	`}
`;

const TabList: FunctionComponent<ITabListProps> = (props) => {
	const {
		activeTab,
		setActiveTab,
		tabs,
		navigateOnClick,
		history,
		parentUrl,
		className,
	} = props;

	const selectOptions = tabs.map((tab) => ({
		label: tab.title,
		value: tab.id,
	}));

	return (
		<div className={className}>
			<StyledTabList {...props}>
				{tabs.map((tab: ITab) => (
					<StyledTab
						{...props}
						key={tab.id}
						className={activeTab === tab.id ? 'mod-active' : ''}
						onClick={() => {
							if (navigateOnClick && history && parentUrl) {
								// Set default url
								let url = parentUrl + tab.id;
								// if we have a default tab, append to url
								!!tab.defaultTab && (url += `/${tab.defaultTab}`);
								// If we have a query string
								!!tab.queryString && (url += `?${tab.queryString}`);
								// push url via history
								history.push(url);
								return;
							}
							setActiveTab(tab.id);
						}}
						aria-label={`tab-${tab.id}`}
						role="button"
					>
						{tab.title}
					</StyledTab>
				))}
			</StyledTabList>

			<StyledSelectContainer>
				<Formik initialValues={{}} onSubmit={() => {}}>
					<Field
						component={StyledSelect}
						name="tab.id"
						initialValue={activeTab}
						handleChangeEvent={(value: {
							fieldValue: string;
							form: FormikProps<{}>;
						}) => {
							setActiveTab(value.fieldValue);
						}}
						selectOptions={selectOptions}
						value={selectOptions.find((option) => option.value === activeTab)}
					/>
				</Formik>
			</StyledSelectContainer>
		</div>
	);
};

export default TabList;
