import { rgba } from 'polished';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { removeToast } from './toast.slice';
import { IToastMessage } from './toast.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Icon from 'components/icons/icon.component';
import OrderIcon from 'components/order-icon/order-icon.component';
import { isMenuPaused } from 'helpers/availability.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { getVenue, getVenueMenus } from 'modules/venue/venue.slice';
import { IServiceType } from 'modules/venue/venue.types';

const StyledToasts = styled.div`
	display: flex;
	flex-flow: column-reverse;
	position: fixed;
	bottom: 50px;
	right: 20px;
	z-index: 1;

	${mq.mobile`
		bottom: 55px;
		right: 0;
		left: 0;
		marign: 0 auto;
	`}
`;

const StyledToast = styled.div<IToastMessage>`
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0px 3px 15px ${rgba(brand.black, 0.5)};
	margin-bottom: 10px;
	background-color: ${brand.white};
	display: flex;
	align-items: center;
	position: relative;

	${mq.mobile`
		width: 90%;
		margin: 0 auto;
	`}
`;

const StyledIconWrapper = styled.div<IToastMessage>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 17px;

	${({ type }) => {
		if (type === 'error') {
			return css`
				background-color: ${brand.toast_error};
			`;
		}
		if (type === 'success') {
			return css`
				background-color: ${brand.toast_success};
			`;
		}
		return css`
			background-color: ${brand.toast_info};
		`;
	}}
`;

const StyledContent = styled.div`
	padding: 0 14px;

	h4 {
		margin: 0;
		font-size: ${fonts.sizes.med};
		font-weight: ${fonts.weights.semibold};
		text-transform: capitalize;
	}
`;

const StyledClose = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
`;

const StyledPausedOrder = styled.div`
	margin: 5px 0 0;

	a {
		font-weight: ${fonts.weights.semibold};
		text-decoration: none;
		color: ${brand.black};
	}
`;

const StyledServiceTypeIcons = styled.div`
	display: flex;
	position: absolute;
	top: 7px;
	right: 9px;

	> * {
		margin-left: 5px;
	}
`;

// Styling for toast component message
const StyledNewVersion = styled.p`
	margin: 0;

	button {
		outline: none;
		border: none;
		background: none;
		padding: 0;
		font-weight: ${fonts.weights.semibold};
		text-decoration: none;
		color: ${brand.black};
	}
`;

const ToastMessage: FunctionComponent<IToastMessage> = ({
	title,
	message,
	type,
	id,
	persist = false,
	isClosable = true,
}) => {
	// Get redux dispatch
	const dispatch = useDispatch();

	return (
		<StyledToast type={type}>
			<StyledIconWrapper type={type}>
				<Icon
					name={type}
					colour="white"
					width={34}
					height={34}
					heightMobile={20}
					widthMobile={20}
				/>
			</StyledIconWrapper>
			<StyledContent>
				<h4>{title}</h4>
				{message}
			</StyledContent>
			{isClosable && (
				<StyledClose onClick={() => dispatch(removeToast(id!))}>
					<Icon
						name="close"
						colour="text"
						width={10}
						height={10}
						heightMobile={10}
						widthMobile={10}
					/>
				</StyledClose>
			)}
		</StyledToast>
	);
};

// Paused orders message component
const PausedOrdersMessage: FunctionComponent<{
	serviceTypes: IServiceType[];
}> = ({ serviceTypes }) => (
	<StyledPausedOrder>
		<StyledServiceTypeIcons>
			{serviceTypes?.map(
				(serviceType) =>
					serviceType?.isOrderingPaused &&
					serviceType?.type && (
						<OrderIcon
							key={serviceType.id}
							variant={serviceType.type}
							width={24}
							height={24}
						/>
					)
			)}
		</StyledServiceTypeIcons>
		<FormattedMessage id="toast.persist.ordersPaused.text" />{' '}
		<a href="/settings/availability">
			<FormattedMessage id="toast.persist.ordersPaused.link" />
		</a>
	</StyledPausedOrder>
);

// New version message component
const NewVersionMessage: FunctionComponent = () => (
	<StyledNewVersion>
		<FormattedMessage id="toast.persist.newVersion.text" />{' '}
		<button
			type="button"
			onClick={() => {
				window.location.reload();
			}}
		>
			<FormattedMessage id="toast.persist.newVersion.link" />
		</button>
	</StyledNewVersion>
);

/** Renders Toast component */
const Toasts: FunctionComponent = () => {
	const dispatch = useDispatch();

	const toasts: IToastMessage[] = useSelector(
		(state: RootState) => state.toast.toasts
	);

	// Check if orders are paused
	const {
		venueMenus,
		selectedVenue,
	} = useSelector((state: RootState) => state.venue);
	const serviceTypes = selectedVenue?.serviceTypes;

	// Check if there is a new version
	const { newVersion } = useSelector((state: RootState) => state.storeVersion);

	// variable to store paused service types
	const servicesPaused: string[] = [];

	// variable to store paused service types
	const menusPaused: string[] = [];

	// TODO: Remove polling when pusher sends notification correctly
	// TODO: Correctly configure for custom service types
	useEffect(() => {
		// variable for polling paused services
		let pollPausedServices = 0;

		// if services unpaused
		if (servicesPaused.length > 0 || menusPaused.length > 0) {
			// Poll get venue to check paused services every minute
			pollPausedServices = setInterval(() => {
				dispatch(getVenue(selectedVenue.id));
				dispatch(getVenueMenus(selectedVenue.id));
			}, 1000 * 60);
		} else {
			clearInterval(pollPausedServices);
		}

		return () => clearInterval(pollPausedServices);
	}, [dispatch, selectedVenue, servicesPaused, menusPaused]);

	// Variable to store paused orders title
	let pausedOrdersTitle: string = '';

	// Loop through services types
	serviceTypes?.forEach((serviceType) => {
		// if paused, add to services paused
		serviceType.isOrderingPaused && servicesPaused.push(serviceType.type);
	});

	venueMenus?.forEach((menu) => {
		// if paused, add to menus paused
		isMenuPaused(menu) && menusPaused.push(menu.name);
	});

	if (servicesPaused.length > 0) {
		if (menusPaused.length > 0) {
			pausedOrdersTitle = intl.formatMessage({
				id: 'toast.persist.ordersPaused.multipleItems.title',
			});
		} else if (servicesPaused.length === 1) {
			pausedOrdersTitle = `${servicesPaused?.[0]
				.split(/(?=[A-Z])/)
				.join(' ')} ${intl.formatMessage({
				id: 'toast.persist.ordersPaused.singleService.title',
			})}`;
		} else {
			pausedOrdersTitle = intl.formatMessage({
				id: 'toast.persist.ordersPaused.multipleServices.title',
			});
		}
	} else if (menusPaused.length > 0) {
		pausedOrdersTitle = intl.formatMessage(
			{
				id: 'toast.persist.ordersPaused.menusPaused.title',
			},
			{ value: menusPaused.length }
		);
	}

	// Create a paused orders toast
	const pausedOrdersToast: IToastMessage = {
		id: 'paused-orders-toast',
		title: pausedOrdersTitle,
		type: 'error',
		message: <PausedOrdersMessage serviceTypes={serviceTypes} />,
		persist: true,
		isClosable: false,
	};

	// Create a new version toast
	const newVersionToast: IToastMessage = {
		id: 'newVersion-toast',
		title: intl.formatMessage({
			id: 'toast.persist.newVersion.title',
		}),
		message: <NewVersionMessage />,
		type: 'info',
		persist: true,
		isClosable: false,
	};

	return (
		<StyledToasts>
			{toasts.map((toast: IToastMessage, index) => (
				<ToastMessage {...toast} key={toast.id} id={toast.id} />
			))}
			{(servicesPaused.length > 0 || menusPaused.length > 0) && (
				<ToastMessage
					{...pausedOrdersToast}
					key={pausedOrdersToast.id}
					id={pausedOrdersToast.id}
				/>
			)}
			{newVersion && (
				<ToastMessage
					{...newVersionToast}
					key={newVersionToast.id}
					id={newVersionToast.id}
				/>
			)}
		</StyledToasts>
	);
};

export default Toasts;
