import { Context, createContext } from 'react';

import { IFullScreenContext } from './full-screen.types';

// Create full screen context state
export const FullscreenContext: Context<IFullScreenContext> = createContext({
	isFullScreen: false,
	setIsFullScreen: () => {},
	toggleFullScreen: () => {},
} as IFullScreenContext);
