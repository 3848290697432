import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	Form,
	Field,
	FieldGroup,
	Actions,
	Container,
	Tooltip,
	HelpText,
} from './verify-terminal.styles';

import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { onboardComplete } from 'modules/terminal/terminal.slice';

const VerifyTerminal: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	interface IFormValues {
		char1: string;
		char2: string;
		char3: string;
		char4: string;
		char5: string;
		char6: string;
	}

	const initialFormValues: IFormValues = {
		char1: '',
		char2: '',
		char3: '',
		char4: '',
		char5: '',
		char6: '',
	};

	const handleFormSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		setSubmitting(true);
		const code = `${values.char1}${values.char2}${values.char3}${values.char4}${values.char5}${values.char6}`;

		const response = await dispatch(onboardComplete(code, selectedVenue.id));

		if (!response) {
			setSubmitting(false);
			return;
		}

		// @ts-ignore
		if (!response.success) {
			setSubmitting(false);
			fireDialog({
				icon: 'error',
				title: intl.formatMessage({ id: 'terminal.verify.failed' }),
				// @ts-ignore
				text: response.message,
			});
			return;
		}

		setSubmitting(false);
		history.push('/terminal/paired');
	};

	// On input value change > focus next input
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const element: HTMLInputElement = event.target;
		const { maxLength, value, name } = event.target;
		const [, fieldIndex] = name.split('char');

		// Check if they hit the max character length
		if (value.length >= maxLength) {
			// Check if it's not the last input field
			if (parseInt(fieldIndex, 10) < 6) {
				// Get the next input field
				const nextField = document.querySelector(
					`input[name=char${parseInt(fieldIndex, 10) + 1}]`
				);

				element.blur();
				// Focus the field
				(nextField as HTMLInputElement)?.focus();
			}
		}
	};

	return (
		<Container>
			<h2>
				<FormattedMessage id="terminal.verify.title" />
			</h2>
			<p>
				<FormattedMessage id="terminal.verify.text" />
			</p>
			<Formik
				enableReinitialize
				initialValues={initialFormValues}
				onSubmit={handleFormSubmit}
			>
				{({ isSubmitting, values }) => (
					<Form>
						<FieldGroup>
							<Field
								component={Input}
								name="char1"
								handleChangeFullEvent={handleChange}
								maxLength={1}
							/>
							<Field
								component={Input}
								name="char2"
								handleChangeFullEvent={handleChange}
								maxLength={1}
							/>
							<Field
								component={Input}
								name="char3"
								handleChangeFullEvent={handleChange}
								maxLength={1}
							/>
							<Field
								component={Input}
								name="char4"
								handleChangeFullEvent={handleChange}
								maxLength={1}
							/>
							<Field
								component={Input}
								name="char5"
								handleChangeFullEvent={handleChange}
								maxLength={1}
							/>
							<Field
								component={Input}
								name="char6"
								handleChangeFullEvent={handleChange}
								maxLength={1}
							/>
						</FieldGroup>
						<Actions>
							<Button
								type="submit"
								disabled={
									isSubmitting ||
									values.char1 === '' ||
									values.char2 === '' ||
									values.char3 === '' ||
									values.char4 === '' ||
									values.char5 === '' ||
									values.char6 === ''
								}
								ariaLabel="submit-button"
							>
								<FormattedMessage id="terminal.verify.submit" />
							</Button>
							<HelpText>
								<Tooltip>
									<FormattedMessage id="terminal.tooltip.nocode" />
									<a href="mailto:support@orderpay.com">support@orderpay.com</a>
								</Tooltip>
								<FormattedMessage id="terminal.verify.help" />
							</HelpText>
						</Actions>
					</Form>
				)}
			</Formik>
		</Container>
	);
};

export default VerifyTerminal;
