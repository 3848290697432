import {
	WaitingUntilOption,
	WaitingTimeOption,
} from '../types/waiting-time-options.type';

import {
	IVenueServiceWaitTimes,
	VenueServiceWaitTimesDto,
} from 'modules/venue/venue.types';

interface ConvertWaitTimesToDtoOptions {
	waitTimeOptions?: WaitingTimeOption[];
	displayUntilOptions?: WaitingUntilOption[];
}

export default function convertWaitTimesToDto(
	waitTimes?: IVenueServiceWaitTimes,
	{
		displayUntilOptions = [],
		waitTimeOptions = [],
	}: ConvertWaitTimesToDtoOptions = {}
): VenueServiceWaitTimesDto {
	// Omit the values from the GET response
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const { venueId, id, ...values } = waitTimes || {};
	const defaultWaitingTime = waitTimeOptions[0]?.value ?? 0;
	const defaultWaitingUntil = displayUntilOptions[0]?.value ?? null;

	return {
		drinksWaitingEnabled: false,
		drinksWaitingTime: defaultWaitingTime,
		drinksWaitingUntil: defaultWaitingUntil,
		foodWaitingEnabled: false,
		foodWaitingTime: defaultWaitingTime,
		foodWaitingUntil: defaultWaitingUntil,
		...values,
	};
}
