import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import MasonryOrderCards from 'components/masonry-order-cards/masonry-order-cards.component';
import TicketOrderCard from 'components/ticket-order-card/ticket-order-card.component';
import { RootState } from 'modules/core/state/root.reducer';

const StyledEmptyOrders = styled.div`
	background: ${brand.white};
	padding: 20px;
	border-radius: 5px;
	margin: 0 1%;

	h2 {
		margin: 0;

		${mq.mobile`
			font-size: ${fonts.sizes.semiLarge};
		`}
	}
`;

const StyledOrderCard = styled(TicketOrderCard)`
	width: 100%;
`;

/** Renders ticket view completed component */
const TicketViewCompleted: React.FC = () => {
	// Get completed orders and sort by date
	const completedOrders = useSelector(
		(state: RootState) => state.ticketView?.completedOrders
	)
		.slice()
		.sort((a, b) => {
			const aDate = a.collectAt || a.orderedAt;
			const bDate = b.collectAt || b.orderedAt;
			if (aDate === bDate) {
				return 0;
			}
			return aDate! < bDate! ? 1 : -1;
		});

	// Get order filters
	const { orderFilters } = useSelector((state: RootState) => state.ticketView);

	// Map orders to order card
	const mappedOrders = completedOrders.map((order) => (
		<StyledOrderCard
			key={order.id}
			order={order}
			isCompleted={true}
			menuFilters={orderFilters}
		/>
	));

	return completedOrders.length ? (
		<MasonryOrderCards>{mappedOrders}</MasonryOrderCards>
	) : (
		<StyledEmptyOrders>
			<h2>
				<FormattedMessage id="ticketView.completed.empty" />
			</h2>
		</StyledEmptyOrders>
	);
};

export default TicketViewCompleted;
