import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Heading = styled.h4<{ id: string }>`
	opacity: 0.4;
	font-size: 12px;
	margin: 0;
	grid-area: ${({ id }) => id};
`;

const ids = [
	{
		messageId: 'settings.serviceWaitTimes.tableServiceMenuHeading',
		id: 'menu-heading',
	},
	{
		messageId: 'settings.serviceWaitTimes.tableServiceStatusHeading',
		id: 'status-heading',
	},
	{
		messageId: 'settings.serviceWaitTimes.tableServiceWaitTimeHeading',
		id: 'wait-time-heading',
	},
	{
		messageId: 'settings.serviceWaitTimes.tableServiceDisplayUntilHeading',
		id: 'display-until-heading',
	},
];

/**
 * Renders the headings in a fragment so that individual items can be hidden and/or arranged in a grid
 */
const WaitTimeHeadings: React.FC = () => {
	return (
		<>
			{ids.map(({ messageId, id }) => (
				<Heading key={id} id={id}>
					<FormattedMessage id={messageId} />
				</Heading>
			))}
		</>
	);
};

export default WaitTimeHeadings;
