import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	IStockListFilters,
	IStockStore,
	IStockUpdateValues,
	IProductUpdateValues,
	IModifierItemUpdateValues,
} from './stock.types';

// Create initial state for stock slice
export const initialStockState: IStockStore = {
	eventsInProgress: 0,
};

const stockSlice = createSlice({
	name: 'stock',
	initialState: initialStockState,
	reducers: {
		GET_STOCK(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_STOCK_SUCCESS(state, action) {
			return {
				...state,
				stock: action.payload.data.data,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_STOCK_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PRODUCT_STOCK(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_PRODUCT_STOCK_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PRODUCT_STOCK_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_STOCK(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_MODIFIER_STOCK_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_STOCK_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_STOCK_STATE() {
			return initialStockState;
		},
		UPDATE_PRODUCT_ITEM(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_PRODUCT_ITEM_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PRODUCT_ITEM_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_ITEM(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MODIFIER_ITEM_SUCCESS(state, action) {
			return {
				...state,
				modifier: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_ITEM_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_ITEM(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_MODIFIER_ITEM_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_ITEM_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_STOCK,
	GET_STOCK_SUCCESS,
	GET_STOCK_FAIL,
	UPDATE_PRODUCT_STOCK,
	UPDATE_PRODUCT_STOCK_SUCCESS,
	UPDATE_PRODUCT_STOCK_FAIL,
	UPDATE_MODIFIER_STOCK,
	UPDATE_MODIFIER_STOCK_SUCCESS,
	UPDATE_MODIFIER_STOCK_FAIL,
	RESET_STOCK_STATE,
	UPDATE_PRODUCT_ITEM,
	UPDATE_PRODUCT_ITEM_SUCCESS,
	UPDATE_PRODUCT_ITEM_FAIL,
	GET_MODIFIER_ITEM,
	GET_MODIFIER_ITEM_SUCCESS,
	GET_MODIFIER_ITEM_FAIL,
	UPDATE_MODIFIER_ITEM,
	UPDATE_MODIFIER_ITEM_SUCCESS,
	UPDATE_MODIFIER_ITEM_FAIL,
} = stockSlice.actions;

/** Get stock list */
export const getStockList = (
	venueId: string,
	filters?: IStockListFilters
) => async (dispatch: Dispatch) => {
	// get stock list request
	const { payload } = await dispatch(
		GET_STOCK({
			request: {
				method: 'get',
				url: `venue/${venueId}/stock`,
				params: filters,
			},
		})
	);

	return payload?.data;
};

/** update product stock */
export const updateProductStock = (
	venueId: string,
	itemId: string,
	data: IStockUpdateValues
) => async (dispatch: Dispatch) => {
	// send product stock request
	const { payload } = await dispatch(
		UPDATE_PRODUCT_STOCK({
			request: {
				method: 'put',
				url: `venue/${venueId}/stock/product/${itemId}`,
				data,
			},
		})
	);

	return payload?.data;
};

/** update modifier stock */
export const updateModifierStock = (
	venueId: string,
	itemId: string,
	data: IStockUpdateValues
) => async (dispatch: Dispatch) => {
	// send modifier stock request
	const { payload } = await dispatch(
		UPDATE_MODIFIER_STOCK({
			request: {
				method: 'put',
				url: `venue/${venueId}/stock/modifier/${itemId}`,
				data,
			},
		})
	);

	return payload?.data;
};

/** Reset Stock State */
export const resetStockState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_STOCK_STATE());
};

export const updateProductDetails = (
	id: string,
	data: IProductUpdateValues,
	venueId: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		UPDATE_PRODUCT_ITEM({
			request: {
				method: 'patch',
				url: `menus/product/${id}`,
				headers: { 'venue-id': venueId },
				data,
			},
		})
	);

	return payload?.data;
};

export const updateModifierItem = (
	id: string,
	data: IModifierItemUpdateValues,
	venueId: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		UPDATE_MODIFIER_ITEM({
			request: {
				method: 'patch',
				url: `menus/modifier-items/${id}`,
				headers: { 'venue-id': venueId },
				data,
			},
		})
	);

	return payload?.data;
};

export const getModifierItem = (id: string, venueId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_MODIFIER_ITEM({
			request: {
				method: 'get',
				url: `menus/modifier-items/${venueId}/modifier/${id}`,
			},
		})
	);

	return payload?.data;
};

export default stockSlice.reducer;
