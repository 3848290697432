import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledWrapper = styled.section`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;

	h1 {
		font-size: 4em;
	}
`;

/** Renders 404 page  */
const NotFoundPage: React.FC = () => (
	<StyledWrapper>
		<h1>
			<FormattedMessage id="errors.pages.404" />
		</h1>
	</StyledWrapper>
);

export default NotFoundPage;
