import { WaitingTimeOption } from '../types/waiting-time-options.type';

export const WAIT_TIME_OPTIONS: WaitingTimeOption[] = [
	{
		label: '10 mins',
		value: 10 * 60,
	},
	{
		label: '20 mins',
		value: 20 * 60,
	},
	{
		label: '30 mins',
		value: 30 * 60,
	},
	{
		label: '40 mins',
		value: 40 * 60,
	},
	{
		label: '50 mins',
		value: 50 * 60,
	},
	{
		label: '1 hour',
		value: 1 * 60 * 60,
	},
];
