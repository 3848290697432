import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
	isOrderInAlert,
	isOrderInEscalation,
} from 'helpers/order-alert-times.helpers';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import { updateOrderAlertConfig } from 'modules/orders/order.slice';
import { IOrder } from 'modules/orders/order.types';

/** Renders order alert check component */
const OrderAlertCheck: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// get orders from state
	const orders = useSelector((state: RootState) => state.order?.ordersList);

	// Ref for venue data to be used within the useEffect
	const ordersRef = useRef(orders);
	// Update ref current to venue data
	ordersRef.current = orders;

	const checkOrdersWithAlerts = (ordersToCheck: IOrder[]): void => {
		// only run on orders where alert config is enabled and order is not in escalation
		ordersToCheck
			.filter(
				(order) =>
					order.alertConfig?.enabled && !order.alertConfig?.isOrderInEscalation
			)
			.forEach((order) => {
				// If order is in alert and not set on the order
				if (isOrderInAlert(order) && !order.alertConfig?.isOrderInAlert) {
					dispatch(updateOrderAlertConfig(order.id, { isOrderInAlert: true }));
				}

				// If order in escalation and not set on the order
				if (
					isOrderInEscalation(order) &&
					!order.alertConfig?.isOrderInEscalation
				) {
					dispatch(
						updateOrderAlertConfig(order.id, { isOrderInEscalation: true })
					);
				}
			});
	};

	// User effect
	useEffect(() => {
		let alertCheckInterval = 0;
		// initial check on component load
		checkOrdersWithAlerts(ordersRef.current);

		// Check orders with alerts every 20 seconds
		alertCheckInterval = setInterval(async () => {
			checkOrdersWithAlerts(ordersRef.current);
		}, 20000);

		return () => clearInterval(alertCheckInterval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <div data-testid="order-alert-check" />;
};

export default OrderAlertCheck;
