import { setContext, setUser } from '@sentry/react';
import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../state/root.reducer';

/** Renders log identifier component */
const LogIdentifier: React.FC = () => {
	// Get user and venue from state
	const { user } = useSelector((state: RootState) => state.auth);
	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	useEffect(() => {
		// Identify user in log rocket
		LogRocket.identify(user.id, {
			email: user.emailAddress,
			name: selectedVenue.name,
		});
		// Identify user in sentry
		setUser({
			id: user.id,
			email: user.emailAddress,
			username: selectedVenue.name,
		});
		setContext('user', user);
		setContext('venue', selectedVenue);
		// Identify user in freshdesk
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).FreshworksWidget('identify', 'ticketForm', {
			name: selectedVenue.name,
			email: user.emailAddress,
		});
	}, [user, selectedVenue]);

	return <div data-testid="log-identifier" />;
};

export default LogIdentifier;
