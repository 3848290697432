import { Store, Dispatch, AnyAction } from 'redux';

import { fireDialog } from '../dialog/dialog.service';
import { intl } from '../i18n/i18n.config';
import { history } from '../routing/app-router.component';

import { REFRESH, processLogout } from 'modules/auth/auth.slice';

// Refresh token middleware
export default (store: Store) => (next: Dispatch) => async (
	action: AnyAction
) => {
	// Get access + refresh token expiry times from state
	const accessTokenExpiry = store.getState().auth.accessToken.expiry;
	const refreshTokenExpiry = store.getState().auth.refreshToken.expiry;

	// eslint-disable-next-line no-console
	console.log('refreshMiddleware', accessTokenExpiry, refreshTokenExpiry);

	// If refresh token has expired
	if (
		action.type !== 'auth/LOGOUT' &&
		refreshTokenExpiry !== null &&
		new Date().getTime() / 1000 >= refreshTokenExpiry
	) {
		// eslint-disable-next-line no-console
		console.log(
			'refreshMiddleware - LOGOUT - refresh expired',
			action.type,
			new Date().getTime() / 1000,
			accessTokenExpiry,
			refreshTokenExpiry
		);

		// @ts-ignore // Call logout thunk
		await store.dispatch(processLogout());
		// notify user of auth expiry
		fireDialog({
			icon: 'error',
			title: intl.formatMessage({ id: 'authExpiry.dialog.title' }),
			text: intl.formatMessage({ id: 'authExpiry.dialog.text' }),
			confirmButtonText: intl.formatMessage({
				id: 'authExpiry.dialog.button.confirm',
			}),
		});
		// Redirect user to login
		history.push('/');
		return false;
	}

	// If action is a request (and not refresh request) and we have an access token
	if (
		action.type !== 'auth/REFRESH' &&
		(((action || {}).payload || {}).request || {}).url &&
		accessTokenExpiry !== null
	) {
		// eslint-disable-next-line no-console
		console.log('refreshMiddleware - ACCESS - checking access token');

		// Check if access token is expired with a 30 second margin of error
		if (new Date().getTime() / 1000 >= accessTokenExpiry - 30) {
			// eslint-disable-next-line no-console
			console.log('refreshMiddleware - ACCESS - access token has expired');
			// If access token isn't currently refreshing
			if (!store.getState().auth.refreshToken.refreshing) {
				// eslint-disable-next-line no-console
				console.log('refreshMiddleware - ACCESS - not refreshing');

				// Create auth/refresh request
				store.dispatch(
					REFRESH({
						request: {
							method: 'post',
							url: 'auth/refresh',
						},
					})
				);

				// eslint-disable-next-line no-console
				console.log(
					'refreshMiddleware - ACCESS - after refresh',
					store.getState().auth
				);
			}
		}
	}

	// eslint-disable-next-line no-console
	console.log('refreshMiddleware - END');

	return next(action);
};
