import { SweetAlertOptions } from 'sweetalert2';

import { intl } from '../i18n/i18n.config';

/** Default dialog configuration */
const dialogConfig: SweetAlertOptions = {
	reverseButtons: false,
	confirmButtonText: intl.formatMessage({
		id: 'sweetAlert.dialog.button.confirm',
	}),
};

export default dialogConfig;
