import { captureEvent, Severity } from '@sentry/react';
import LogRocket from 'logrocket';

/** Log errors to sentry and track in logrocket */
const errorLogger = (error: {
	message: string;
	level?: Severity;
	extra?: Object;
}) => {
	// eslint-disable-next-line no-console
	console.error(error);
	// Log error in sentry
	captureEvent(error);
	// Track error in logrocket
	LogRocket.track(error.message);
};

export default errorLogger;
