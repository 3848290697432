import { rgba } from 'polished';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';

import { resetLoadingStates } from './loading.slice';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { RootState } from 'modules/core/state/root.reducer';

const keyframeLoadingBallContainer = keyframes`
	100% { transform: rotate(360deg); }
`;

const keyframeLoadingBall = keyframes`
	80%, 100% { transform: rotate(360deg); }
`;
const keyframeLoadingBallBefore = keyframes`
	50% {
		transform: scale(0.4);
	} 100%, 0% {
		transform: scale(1.0);
	}
  `;

interface IComponentProps {
	isOpen: boolean;
}

const loadingIsOpenStyles = css`
	opacity: 1;
	pointer-events: all;
`;

const StyledLoadingComponent = styled.div<IComponentProps>`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 20;
	align-items: center;
	justify-content: center;
	transition: opacity 0.15s ease;
	background-color: ${rgba(brand.neutral, 0.3)};
	pointer-events: none;
	opacity: 0;

	${({ isOpen }) => isOpen && loadingIsOpenStyles}
`;

const StyledLoadingMessage = styled.div`
	font-size: ${fonts.sizes.h2};
	width: 100%;
	font-weight: ${fonts.weights.semibold};
	box-shadow: 4px 4px 8px ${rgba(brand.black, 0.1)};
	margin: 30px 0 0;
	background: ${brand.white};
	border-radius: 5px;
	padding: 20px;
	text-align: center;
`;

const StyledLoadingBallContainer = styled.div`
	opacity: 1;
	width: 40px;
	height: 40px;
	position: relative;
	animation: ${keyframeLoadingBallContainer} 2.5s infinite linear both;
`;

const StyledLoadingBall = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: ${keyframeLoadingBall} 2s infinite ease-in-out both;

	&:before {
		content: '';
		display: block;
		width: 25%;
		height: 25%;
		background-color: ${brand.primary};
		border-radius: 100%;
		animation: ${keyframeLoadingBallBefore} 2s infinite ease-in-out both;
	}

	&:nth-child(1) {
		animation-delay: -1.1s;
		&:before {
			animation-delay: -1.1s;
		}
	}
	&:nth-child(2) {
		animation-delay: -1s;
		&:before {
			animation-delay: -1s;
		}
	}
	&:nth-child(3) {
		animation-delay: -0.9s;
		&:before {
			animation-delay: -0.9s;
		}
	}
	&:nth-child(4) {
		animation-delay: -0.8s;
		&:before {
			animation-delay: -0.8s;
		}
	}
	&:nth-child(5) {
		animation-delay: -0.7s;
		&:before {
			animation-delay: -0.7s;
		}
	}
	&:nth-child(6) {
		animation-delay: -0.6s;
		&:before {
			animation-delay: -0.6s;
		}
	}
`;

/** Renders Loading component */
const Loading: React.FC = () => {
	const [isLoading, setIsLoading] = useState(false);
	// Get whole state object from store
	const fullStateObject: RootState = useSelector((state: RootState) => state);

	// Get data from loading config
	const loadingMessage = useSelector(
		(state: RootState) => state?.loading?.loadingConfig?.loadingMessage
	);

	const loadingTimeout = useSelector(
		(state: RootState) => state?.loading?.loadingConfig?.loadingTimeout
	);

	const dispatch = useDispatch();

	useEffect(() => {
		// Get loading timeout from config or environment variable
		const loadingTimeoutTime: number =
			loadingTimeout || parseFloat(process.env.REACT_APP_AXIOS_TIMEOUT!);

		// Get all eventsInProgress > 0 from store
		const events = Object.entries(fullStateObject).filter((obj) => {
			if (obj[1] && 'eventsInProgress' in obj[1]) {
				return (
					obj[1]?.eventsInProgress !== undefined && obj[1].eventsInProgress > 0
				);
			}
			return false;
		});

		setIsLoading(!!events.length);

		// timeout to clear loading state
		const loadingStateTimeout = setTimeout(async () => {
			// If events still in progress, reset loading state
			!!events.length && (await dispatch(resetLoadingStates()));
			// Run timeout length
		}, loadingTimeoutTime);

		// useEffect cleanup
		return () => {
			setIsLoading(false);
			clearTimeout(loadingStateTimeout);
		};
	}, [dispatch, fullStateObject, loadingTimeout]);

	// reset loading state
	useEffect(() => {
		const clearLoadingOnRefresh = () => dispatch(resetLoadingStates());
		window.addEventListener('beforeunload', clearLoadingOnRefresh);
		return () =>
			window.removeEventListener('beforeunload', clearLoadingOnRefresh);
	}, [dispatch]);

	return (
		<StyledLoadingComponent isOpen={isLoading}>
			<StyledLoadingBallContainer data-testid="loading-spinner">
				<StyledLoadingBall />
				<StyledLoadingBall />
				<StyledLoadingBall />
				<StyledLoadingBall />
				<StyledLoadingBall />
				<StyledLoadingBall />
			</StyledLoadingBallContainer>
			<div>
				{loadingMessage && (
					<StyledLoadingMessage>{loadingMessage}</StyledLoadingMessage>
				)}
			</div>
		</StyledLoadingComponent>
	);
};

export default Loading;
