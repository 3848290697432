import { rgba } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';
import icons from 'components/icons/icons.config';

// Interface for component props
interface IComponentProps {
	ariaLabel?: string;
	size?: 'md' | 'lg';
	variant?: 'primary' | 'secondary' | 'tertiary' | 'black' | 'icon';
	icon?: keyof typeof icons;
	iconWidth?: number;
	iconHeight?: number;
	iconColour?: keyof typeof brand;
}

const disabledStyling = css`
	background: ${brand.validation_disabled};
	color: ${brand.placeholder};
	box-shadow: 0px 0px 0px 1px ${brand.neutral};
	pointer-events: none;

	&:hover {
		background: ${brand.neutral};
	}
`;

const buttonWithIconStyling = css`
	display: flex;
	align-items: center;
`;

const primaryStyling = css`
	background: ${brand.link};
	color: ${brand.white};

	&:hover {
		background: ${brand.link_hover};
	}
`;

const secondaryStyling = css`
	background: ${rgba(brand.primary, 0.3)};
	color: ${brand.primary};

	&:hover {
		background: ${rgba(brand.primary, 0.2)};
	}
`;

const blackStyling = css`
	background: ${brand.black};
	color: ${brand.white};
	font-weight: ${fonts.weights.medium};

	&:hover {
		background: ${rgba(brand.black, 0.2)};
	}
`;

const iconStyling = css`
	border: none;
	padding: 0;
	background: none;

	&:hover,
	&:active,
	&:focus {
		background: none;
	}

	.icon {
		margin-right: 0;
	}
`;

const tertiaryStyling = css`
	background: ${brand.white};
	color: ${brand.primary};
	border: 1px solid ${brand.primary};

	&:active,
	&:focus,
	&:hover {
		background: ${brand.link};
		color: ${brand.white};
	}
`;

// Create styled button component
const StyledButton = styled.button<
	IComponentProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
	line-height: 1;
	padding: ${({ size }) => (size === 'md' ? '12px' : '17px 27px')};
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: ${({ size }) => (size === 'md' ? fonts.sizes.med : fonts.sizes.med)};
	font-weight: ${fonts.weights.semibold};

	&:active {
		background: ${brand.link_active};
		border-color: transparent;
		box-shadow: none;
	}

	&:focus {
		outline: none;
	}

	${({ icon }) => {
		if (icon) {
			return buttonWithIconStyling;
		}
		return null;
	}}

	${({ variant }) => {
		if (variant === 'tertiary') {
			return tertiaryStyling;
		}
		if (variant === 'secondary') {
			return secondaryStyling;
		}
		if (variant === 'black') {
			return blackStyling;
		}
		if (variant === 'icon') {
			return iconStyling;
		}

		return primaryStyling;
	}}
	${({ disabled }) => {
		if (disabled === true) {
			return disabledStyling;
		}
		return null;
	}}

`;

const StyledIcon = styled(Icon)`
	margin-right: 10px;
`;

/** Renders button component */
const Button: React.FC<
	IComponentProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
	const { ariaLabel, icon, iconHeight, iconWidth, children } = props;

	const iconColour = !props.variant ? 'white' : props.iconColour;
	const size = !props.size ? 'md' : props.size;
	const type = !props.type ? 'button' : props.type;
	const variant = !props.variant ? 'primary' : props.variant;

	return (
		<StyledButton
			{...props}
			aria-label={ariaLabel}
			variant={variant}
			type={type}
			size={size}
		>
			{icon && (
				<StyledIcon
					className="icon"
					name={icon}
					width={iconWidth}
					height={iconHeight}
					colour={iconColour}
				/>
			)}
			{children}
		</StyledButton>
	);
};

export default Button;
