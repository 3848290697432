export interface IVenueOpeningDay {
	open: boolean;
	openingTime?: string;
	closingTime?: string;
	interval?: string;
	maxOrdersPerSlot?: string;
}

export interface IVenueOpeningFormValues {
	[day: string]: IVenueOpeningDay;
}

export enum SettingsTabs {
	availability = 'availability',
	openingTimes = 'opening-times',
	offlineNotification = 'offline-notification',
	ticketView = 'ticket-view',
	serviceWaitTimes = 'service-wait-times',
}
