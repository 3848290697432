import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

// Interface for component props
interface IComponentProps {
	alert: boolean;
	escalation: boolean;
}

const StyledOrderModalAlert = styled.div`
	border-radius: 15px;
	border: 1px solid transparent;

	&.mod-alert {
		background: ${brand.validation_warning};
		padding: 12px;
	}

	&.mod-escalation {
		background: ${brand.validation_error};
		padding: 12px;
	}
`;

const StyledH5 = styled.h5`
	margin: 0 0 5px;
	font-size: ${fonts.sizes.larger};
	font-weight: ${fonts.weights.bold};
	color: ${brand.white};
	text-transform: uppercase;
`;

/** Renders order details modal alert component */
const OrderDetailsModalAlert: FunctionComponent<IComponentProps> = ({
	alert,
	escalation,
	children,
}) => {
	return (
		<StyledOrderModalAlert
			className={`${alert && !escalation && 'mod-alert'} ${
				escalation && 'mod-escalation'
			}`}
		>
			{alert && !escalation && (
				<StyledH5>
					<FormattedMessage id="orderAlertConfig.alert.prepRequired" />
				</StyledH5>
			)}
			{escalation && (
				<StyledH5>
					<FormattedMessage id="orderAlertConfig.alert.prepAsap" />
				</StyledH5>
			)}
			{children}
		</StyledOrderModalAlert>
	);
};

export default OrderDetailsModalAlert;
