import { Dayjs } from 'dayjs';

import { WaitingUntilOption } from '../types/waiting-time-options.type';

import dayjs from 'helpers/dayjs.helper';
import { IVenueOpeningDay } from 'modules/venue/venue.types';

const DEFAULT_INTERVAL = 30;

function getTimeValues(time: string) {
	const [hours, minutes] = time.split(':');
	return [Number(hours) || 0, Number(minutes) || 0] as const;
}

function setTime(date: Dayjs, hours = date.hour(), minutes = date.minute()) {
	return date.hour(hours).minute(minutes).second(0).millisecond(0);
}

function getNearestInterval(date: Dayjs, interval = DEFAULT_INTERVAL) {
	const mins = date.hour() * 60 + date.minute();
	const rounded = Math.ceil(mins / interval) * interval;
	return setTime(date, Math.floor(rounded / 60), rounded % 60);
}

function getStartTime(start: Dayjs) {
	const now = setTime(dayjs());

	if (start.isBefore(now)) {
		return getNearestInterval(now);
	}

	return getNearestInterval(start);
}

function getDateValue(date: Dayjs) {
	return date.toISOString();
}

function getOption(date: Dayjs): WaitingUntilOption {
	return {
		label: date.format('HH:mm'),
		value: getDateValue(date),
	};
}

function getOptionsBetweenDates(
	start: Dayjs,
	end: Dayjs,
	interval = DEFAULT_INTERVAL
): WaitingUntilOption[] {
	const numOptions = Math.floor(end.diff(start, 'minute') / interval) + 1;

	const options: WaitingUntilOption[] = Array.from(
		{ length: numOptions },
		(_, i) => {
			const date = start.add(i * interval, 'minute');
			return getOption(date);
		}
	);

	// If the last option isn't the closing time, add it
	// This occurs when the closing time is not a multiple of the provided interval
	// e.g. interval = 30 mins, and the close time is 23:45
	const lastOption = options.at(-1);
	if (lastOption && lastOption.value !== getDateValue(end)) {
		options.push(getOption(end));
	}

	return [
		...options,
		{
			label: 'Until further notice',
			value: null,
		},
	];
}

export default function getWaitingUntilOptions(
	openingTimes: IVenueOpeningDay[] = []
): WaitingUntilOption[] {
	const now = setTime(dayjs());
	const day = now.day();

	const timeValues = openingTimes.map(({ openingTime, closingTime }) => {
		return {
			openingTime: getTimeValues(openingTime),
			closingTime: getTimeValues(closingTime),
		};
	});

	// Sunday is 0, but this array starts from Monday
	const todaysOpeningTimes = timeValues.at(day - 1);
	const yesterdayClosingTimes = timeValues.at(day - 2);

	if (!todaysOpeningTimes || !yesterdayClosingTimes) {
		return [];
	}

	const {
		openingTime: [openingHour, openingMin],
		closingTime: [closingHour, closingMin],
	} = todaysOpeningTimes;
	const {
		openingTime: [yesterdayOpeningHour, yesterdayOpeningMin],
		closingTime: [yesterdayClosingHour, yesterdayClosingMin],
	} = yesterdayClosingTimes;

	let openingDate = setTime(now, openingHour, openingMin);
	let closingDate = setTime(now, closingHour, closingMin);

	// The closing time is the following day, usually the early hours of the morning
	if (closingDate.isBefore(openingDate)) {
		closingDate = closingDate.add(1, 'day');
	}

	/*
    Deal with an edge-case of someone viewing the page during the early hours of the
    morning and the previous day's hours still being applicable.
    For example, today is Sunday at 02:00 and we have the following opening times:
      - Saturday: 18:00 -> 04:00
      - Sunday:   12:00 -> 18:00
    In this case, we should still be using Saturday's opening times
  */
	const yesterdayClosingDate = setTime(
		now,
		yesterdayClosingHour,
		yesterdayClosingMin
	);

	if (
		now.isBefore(openingDate) &&
		yesterdayClosingHour < yesterdayOpeningHour &&
		now.isBefore(yesterdayClosingDate)
	) {
		closingDate = yesterdayClosingDate;
		openingDate = setTime(
			now.subtract(1, 'day'),
			yesterdayOpeningHour,
			yesterdayOpeningMin
		);
	}

	return getOptionsBetweenDates(getStartTime(openingDate), closingDate);
}
