import * as React from 'react';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import icons from './icons.config';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';

// Interface for component props
interface IComponentProps {
	name?: keyof typeof icons;
	colour?: keyof typeof brand;
	height?: number;
	width?: number;
	className?: string;
	widthMobile?: number;
	heightMobile?: number;
	onClick?: MouseEventHandler;
	opacity?: number;
}

const StyledDiv = styled.div<IComponentProps>`
	height: ${({ height }) => height}px;
	width: ${({ width }) => width}px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	opacity: ${({ opacity }) => opacity};

	${({ heightMobile, widthMobile, height, width }) => mq.mobile`
		height: ${heightMobile || height}px;
		width: ${widthMobile || width}px;
	`}

	svg {
		color: ${({ colour }) => (colour ? brand[colour] : brand.black)};
		width: 100%;
		height: 100%;
	}
`;

/** Renders Icon component */
const Icon: React.FC<IComponentProps> = ({
	name = 'logo',
	colour = 'black',
	height = 30,
	width = 30,
	widthMobile,
	heightMobile,
	className,
	onClick,
	opacity = 1,
}) => {
	const IconContent = icons[name];
	return (
		<StyledDiv
			className={className}
			colour={colour}
			height={height}
			width={width}
			widthMobile={widthMobile}
			heightMobile={heightMobile}
			data-testid="svg-icon-div"
			onClick={onClick}
			opacity={opacity}
		>
			<IconContent />
		</StyledDiv>
	);
};

export default Icon;
