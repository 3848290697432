import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import WaitTimeDisplayExample from './components/wait-time-display-example/wait-time-display-example.component';
import WaitTimeHeadings from './components/wait-time-headings/wait-time-headings.component';
import WaitTimeRow from './components/wait-time-row/wait-time-row.component';
import { WAIT_TIME_OPTIONS } from './constants/wait-time-options';
import { WAIT_TIMES_SCHEMA } from './constants/wait-times-schema';
import getWaitingUntilOptions from './helpers/get-waiting-until-options.helper';
import useLiveUpdate from './hooks/use-live-update.hook';
import {
	Container,
	Description,
	Example,
	Form,
	HeadingContainer,
	SaveButton,
	SettingsContainer,
	Subheading,
	Title,
} from './service-wait-times.styles';
import convertWaitTimesToDto from './transformers/convert-wait-times-to-wait-times-dto';

import useViewHeight from 'components/modal/hooks/use-view-height.hook';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenueOpeningTimes,
	getVenueServiceWaitTimes,
	updateVenueServiceWaitTimes,
} from 'modules/venue/venue.slice';
import {
	VenueServiceWaitTimesDto,
	WaitingTime,
	WaitingUntil,
} from 'modules/venue/venue.types';

const ServiceWaitTimes: React.FC = () => {
	const viewHeight = useViewHeight();
	const intl = useIntl();
	const dispatch = useDispatch();
	const { selectedVenue, openingTimesArray } = useSelector(
		(state: RootState) => state.venue
	);
	const serviceWaitTimes = useSelector(
		(state: RootState) => state.venue.serviceWaitTimes
	);

	const fetchData = useCallback(() => {
		if (selectedVenue.id) {
			dispatch(getVenueServiceWaitTimes(selectedVenue.id));
			dispatch(getVenueOpeningTimes(selectedVenue.id));
		}
	}, [dispatch, selectedVenue]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useLiveUpdate({ onTimeout: fetchData, serviceWaitTimes });

	const displayUntilOptions = useMemo(() => {
		return getWaitingUntilOptions(openingTimesArray);
	}, [openingTimesArray]);

	const initialValues = useMemo(() => {
		return convertWaitTimesToDto(serviceWaitTimes, {
			displayUntilOptions,
			waitTimeOptions: WAIT_TIME_OPTIONS,
		});
	}, [serviceWaitTimes, displayUntilOptions]);

	const findWaitingUntilOption = useCallback(
		(waitingUntil: WaitingUntil) => {
			return displayUntilOptions.find(({ value }) => value === waitingUntil);
		},
		[displayUntilOptions]
	);

	const findWaitingTimeOption = useCallback((waitingTime: WaitingTime) => {
		return WAIT_TIME_OPTIONS.find(({ value }) => value === waitingTime);
	}, []);

	const handleSubmit = useCallback(
		async (
			values: VenueServiceWaitTimesDto,
			{ resetForm }: FormikHelpers<VenueServiceWaitTimesDto>
		) => {
			resetForm({ values });

			const result = await dispatch(
				updateVenueServiceWaitTimes(selectedVenue.id, values)
			);

			if (result == null) {
				return;
			}

			fireDialog({
				title: intl.formatMessage({
					id: 'settings.serviceWaitTimes.dialog.saved',
				}),
				showConfirmButton: false,
				icon: 'success',
				timer: 2000,
			});
		},
		[dispatch, selectedVenue, intl]
	);

	return (
		<Container viewHeight={viewHeight}>
			<Title>
				<FormattedMessage id="settings.serviceWaitTimes.title" />
			</Title>

			<Subheading>
				<FormattedMessage id="settings.serviceWaitTimes.tableServiceSubheading" />
			</Subheading>

			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={WAIT_TIMES_SCHEMA}
				validateOnBlur={false}
			>
				{({
					values: {
						drinksWaitingEnabled,
						drinksWaitingTime,
						drinksWaitingUntil,
						foodWaitingEnabled,
						foodWaitingTime,
						foodWaitingUntil,
					},
					dirty,
				}) => {
					const isFoodDisabled = !foodWaitingEnabled;
					const isDrinkDisabled = !drinksWaitingEnabled;
					const foodWaitingTimeOption = findWaitingTimeOption(foodWaitingTime);
					const foodDisplayUntilOption = findWaitingUntilOption(
						foodWaitingUntil
					);
					const drinksWaitingTimeOption = findWaitingTimeOption(
						drinksWaitingTime
					);
					const drinksWaitingUntilOption = findWaitingUntilOption(
						drinksWaitingUntil
					);

					return (
						<Form>
							<SettingsContainer>
								<HeadingContainer>
									<WaitTimeHeadings />
								</HeadingContainer>

								<WaitTimeRow
									labelMessageId="settings.serviceWaitTimes.foodLabel"
									switchFieldProps={{
										name: 'foodWaitingEnabled',
									}}
									waitTimeFieldProps={{
										name: 'foodWaitingTime',
										isDisabled: isFoodDisabled,
										selectOptions: WAIT_TIME_OPTIONS,
										value: foodWaitingTimeOption,
									}}
									displayUntilProps={{
										name: 'foodWaitingUntil',
										isDisabled: isFoodDisabled,
										selectOptions: displayUntilOptions,
										value: foodDisplayUntilOption,
									}}
								/>

								<WaitTimeRow
									labelMessageId="settings.serviceWaitTimes.drinksLabel"
									switchFieldProps={{
										name: 'drinksWaitingEnabled',
									}}
									waitTimeFieldProps={{
										name: 'drinksWaitingTime',
										isDisabled: isDrinkDisabled,
										selectOptions: WAIT_TIME_OPTIONS,
										value: drinksWaitingTimeOption,
									}}
									displayUntilProps={{
										name: 'drinksWaitingUntil',
										isDisabled: isDrinkDisabled,
										selectOptions: displayUntilOptions,
										value: drinksWaitingUntilOption,
									}}
								/>
							</SettingsContainer>

							{(drinksWaitingEnabled || foodWaitingEnabled) && (
								<>
									<Description>
										<FormattedMessage id="settings.serviceWaitTimes.description" />
									</Description>

									<Example>
										<FormattedMessage id="settings.serviceWaitTimes.descriptionExample" />
										{foodWaitingEnabled && (
											<p>
												<WaitTimeDisplayExample
													time={
														findWaitingTimeOption(foodWaitingTime)?.label || ''
													}
													type="food"
												/>
											</p>
										)}
										{drinksWaitingEnabled && (
											<p>
												<WaitTimeDisplayExample
													time={
														// eslint-disable-next-line max-len
														findWaitingTimeOption(drinksWaitingTime)?.label ||
														''
													}
													type="drink"
												/>
											</p>
										)}
									</Example>
								</>
							)}

							<SaveButton type="submit" disabled={!dirty}>
								<FormattedMessage id="form.button.save" />
							</SaveButton>
						</Form>
					);
				}}
			</Formik>
		</Container>
	);
};

export default ServiceWaitTimes;
