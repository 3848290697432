import { Formik, Form, Field } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { ModalProps } from 'styled-react-modal';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import Switch from 'components/form-inputs/switch/switch.component';
import Modal, {
	IComponentProps as IModalProps,
} from 'components/modal/modal.component';
import OrderIcon from 'components/order-icon/order-icon.component';
import { isMenuPaused } from 'helpers/availability.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	pauseVenueOrdersByMenu,
	pauseVenueOrdersByType,
} from 'modules/venue/venue.slice';
import {
	IPauseOrdersByMenuData,
	IPauseOrdersFormValues,
} from 'modules/venue/venue.types';

interface IStyledModal extends ModalProps {
	viewHeight?: number;
}

interface IStyledComponent {
	viewHeight?: number;
}

interface IStyledTab {
	isActive: boolean;
}

const StyledModal = styled(Modal)<IStyledModal>`
	width: calc(100% - 50px);
	max-width: 420px;
	height: auto;
	max-height: 780px;
	padding: 0;
	border-radius: 10px;
	position: relative;
	display: block;
	z-index: 2;

	${mq.mobile`
		padding: 30px 0 0;
		height: 80%;
		width: calc(100% - 22px);
	`}
`;

const StyledContentWrapper = styled.div<IStyledComponent>`
	display: flex;
	padding: 38px 34px;
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-height: 650px;

	overflow: scroll;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}

	${mq.mobile`
		padding: 0 6px;
		display: block;
	`}
`;

const StyledCopy = styled.div`
	text-align: center;
	margin: 0 0 30px;

	h2 {
		font-size: ${fonts.sizes.larger};
		font-weight: ${fonts.weights.medium};
		margin: 0 0 15px;
	}

	p {
		font-size: ${fonts.sizes.standard};
		font-weight: ${fonts.weights.light};
		margin: 0;
	}
`;

const StyledOrderPause = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 5px;
	padding: 4px 8px;
	background: ${brand.body};
	border-radius: 5px;
`;

const StyledOrderType = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;

	h4 {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin: 0 0 0 14px;
		font-size: ${fonts.sizes.standard};
		font-weight: ${fonts.weights.light};
	}
`;

const StyledSwitch = styled(Switch)`
	margin: 0;
	max-width: 110px;
`;

const StyledFormActions = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 30px 0 0;
`;

const StyledButton = styled(Button)`
	flex-basis: calc(50% - 4px);
`;

const StyledTabs = styled.div`
	display: flex;
	justify-content: center;
	border-bottom: 1px solid ${brand.borders};
	margin: 0 -34px;
	margin-bottom: 34px;
`;

const StyledTab = styled.button<IStyledTab>`
	width: 105px;
	padding: 10px 0;
	background: none;
	text-align: center;
	border: none;
	cursor: pointer;
	border-bottom: 2px solid transparent;
	transition: 0.25s ease;
	${(props) =>
		props.isActive &&
		css`
			border-bottom: 2px solid ${brand.primary};
			font-weight: ${fonts.weights.semibold};
		`}
`;

const StyledMenusList = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
	max-height: 253px;
	padding-right: 10px;
`;

/** Renders Modal component */
const OrderDetailsModal: React.FC<IModalProps> = ({ isOpen, toggleModal }) => {
	const dispatch = useDispatch();

	// get data from redux
	const {
		venueMenus,
		selectedVenue: { id: selectedVenueId, serviceTypes },
	} = useSelector((state: RootState) => state.venue);

	const [showMenus, setShowMenus] = useState(false);

	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	// Varible for initial form values
	const initialFormValues: IPauseOrdersFormValues = {};

	// Set form values if they're available
	serviceTypes?.find(
		(serviceType) => serviceType.isActive && serviceType.type === 'tableService'
	) &&
		(initialFormValues.tableService = !serviceTypes.find(
			(serviceType) => serviceType.type === 'tableService'
		)?.isOrderingPaused);
	serviceTypes?.find(
		(serviceType) => serviceType.isActive && serviceType.type === 'delivery'
	) &&
		(initialFormValues.delivery = !serviceTypes.find(
			(serviceType) => serviceType.type === 'delivery'
		)?.isOrderingPaused);
	serviceTypes?.find(
		(serviceType) =>
			serviceType.isActive && serviceType.type === 'clickAndCollect'
	) &&
		(initialFormValues.clickAndCollect = !serviceTypes.find(
			(serviceType) => serviceType.type === 'clickAndCollect'
		)?.isOrderingPaused);
	serviceTypes?.find(
		(serviceType) => serviceType.isActive && serviceType.type === 'selfService'
	) &&
		(initialFormValues.selfService = !serviceTypes.find(
			(serviceType) => serviceType.type === 'selfService'
		)?.isOrderingPaused);

	venueMenus?.forEach((menu) => {
		initialFormValues[`menu_${menu.id}`] = !isMenuPaused(menu);
	});

	// Handle form submission
	const handleFormSubmit = async (values: IPauseOrdersFormValues) => {
		// get values
		const formValues = { ...values };
		// Reverse values for endpoint if available
		typeof formValues.tableService !== 'undefined' &&
			(formValues.tableService = !formValues.tableService);
		typeof formValues.delivery !== 'undefined' &&
			(formValues.delivery = !formValues.delivery);
		typeof formValues.clickAndCollect !== 'undefined' &&
			(formValues.clickAndCollect = !formValues.clickAndCollect);
		typeof formValues.selfService !== 'undefined' &&
			(formValues.selfService = !formValues.selfService);

		// Submit form values
		const response = await dispatch(
			pauseVenueOrdersByType(selectedVenueId, formValues)
		);

		// If any menu pause has changed
		if (
			venueMenus &&
			venueMenus.some(
				(menu) => values[`menu_${menu.id}`] === isMenuPaused(menu)
			)
		) {
			const pauseMenuData: IPauseOrdersByMenuData[] = venueMenus.map(
				(menu) => ({
					menuId: menu.id,
					isPaused: !values[`menu_${menu.id}`],
				})
			);

			const menuResponse = await dispatch(
				pauseVenueOrdersByMenu(selectedVenueId, pauseMenuData)
			);

			if (!menuResponse) {
				return;
			}
		}

		if (!response) {
			return;
		}

		// close modal
		toggleModal();
	};

	useEffect(() => {
		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => window.removeEventListener('resize', getSetViewHeight);
	}, [dispatch, selectedVenueId]);

	// TODO: Handle pause orders for custom service type
	return (
		<StyledModal
			isOpen={isOpen}
			toggleModal={toggleModal}
			allowScroll={true}
			backgroundProps={{ viewHeight }}
			viewHeight={viewHeight}
		>
			<StyledContentWrapper viewHeight={viewHeight}>
				<StyledCopy>
					<h2>
						<FormattedMessage id="settings.pauseOrders.modal.title" />
					</h2>
					<p>
						<FormattedMessage
							id="settings.pauseOrders.modal.copy"
							values={{
								strong: (msg: string) => <strong>{msg}</strong>,
							}}
						/>
					</p>
				</StyledCopy>
				<StyledTabs>
					<StyledTab onClick={() => setShowMenus(false)} isActive={!showMenus}>
						<FormattedMessage id="settings.pauseOrders.modal.services" />
					</StyledTab>
					<StyledTab onClick={() => setShowMenus(true)} isActive={showMenus}>
						<FormattedMessage id="settings.pauseOrders.modal.menus" />
					</StyledTab>
				</StyledTabs>
				<Formik initialValues={initialFormValues} onSubmit={handleFormSubmit}>
					{({ values }) => (
						<Form>
							{showMenus ? (
								<StyledMenusList>
									{venueMenus?.map((menu) => (
										<StyledOrderPause key={menu.id}>
											<StyledOrderType>
												<h4>{menu.name}</h4>
											</StyledOrderType>
											<Field
												component={StyledSwitch}
												name={`menu_${menu.id}`}
												size="small"
												offText={intl.formatMessage({
													id: 'form.pauseOrders.switch.off',
												})}
											/>
										</StyledOrderPause>
									))}
								</StyledMenusList>
							) : (
								<>
									{typeof values.tableService !== 'undefined' && (
										<StyledOrderPause>
											<StyledOrderType>
												<OrderIcon
													variant="tableService"
													width={30}
													height={30}
												/>
												<h4>
													<FormattedMessage id="generic.orderType.tableService" />
												</h4>
											</StyledOrderType>
											<Field
												component={StyledSwitch}
												name="tableService"
												size="small"
												offText={intl.formatMessage({
													id: 'form.pauseOrders.switch.off',
												})}
											/>
										</StyledOrderPause>
									)}
									{typeof values.clickAndCollect !== 'undefined' && (
										<StyledOrderPause>
											<StyledOrderType>
												<OrderIcon
													variant="clickAndCollect"
													width={30}
													height={30}
												/>
												<h4>
													<FormattedMessage id="generic.orderType.clickCollect" />
												</h4>
											</StyledOrderType>
											<Field
												component={StyledSwitch}
												name="clickAndCollect"
												size="small"
												offText={intl.formatMessage({
													id: 'form.pauseOrders.switch.off',
												})}
											/>
										</StyledOrderPause>
									)}
									{typeof values.selfService !== 'undefined' && (
										<StyledOrderPause>
											<StyledOrderType>
												<OrderIcon
													variant="selfService"
													width={30}
													height={30}
												/>
												<h4>
													<FormattedMessage id="generic.orderType.selfService" />
												</h4>
											</StyledOrderType>
											<Field
												component={StyledSwitch}
												name="selfService"
												size="small"
												offText={intl.formatMessage({
													id: 'form.pauseOrders.switch.off',
												})}
											/>
										</StyledOrderPause>
									)}
									{typeof values.delivery !== 'undefined' && (
										<StyledOrderPause>
											<StyledOrderType>
												<OrderIcon variant="delivery" width={30} height={30} />
												<h4>
													<FormattedMessage id="generic.orderType.delivery" />
												</h4>
											</StyledOrderType>
											<Field
												component={StyledSwitch}
												name="delivery"
												size="small"
												offText={intl.formatMessage({
													id: 'form.pauseOrders.switch.off',
												})}
											/>
										</StyledOrderPause>
									)}
								</>
							)}
							<StyledFormActions>
								<StyledButton variant="secondary" onClick={() => toggleModal()}>
									<FormattedMessage id="form.button.cancel" />
								</StyledButton>
								<StyledButton type="submit" variant="primary">
									<FormattedMessage id="form.button.confirm" />
								</StyledButton>
							</StyledFormActions>
						</Form>
					)}
				</Formik>
			</StyledContentWrapper>
		</StyledModal>
	);
};

export default OrderDetailsModal;
