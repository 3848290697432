import { Form, FormikHelpers, Formik, Field } from 'formik';
import { Howl } from 'howler';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import { processLogin } from '../auth.slice';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import LoginWrapper from 'components/login-wrapper/login-wrapper.component';
import LogoBlock from 'components/logo-block/logo-block.component';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';

// Styling for login form
const StyledLoginForm = styled(Form)`
	width: 350px;
	padding: 20px 28px 30px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${brand.white};

	p {
		padding: 0 20px;
		margin-bottom: 25px;
		text-align: center;
	}

	Button {
		width: 100%;
		margin-bottom: 20px;
	}

	.btn-link {
		color: ${brand.primary};
		font-size: ${fonts.sizes.small};
		font-weight: ${fonts.weights.semibold};
		text-decoration: none;
	}
`;

const StyledField = styled(Field)`
	width: 100%;
`;

/** Renders login page component */
const LoginPage: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useDispatch();

	// Get router history
	const history = useHistory();

	interface IFormValues {
		email: string;
		password: string;
	}

	// Initial form values
	const initialValues: IFormValues = {
		email: '',
		password: '',
	};

	// Interface for form errors
	interface IFormValidation {
		email?: string;
		password?: string;
	}

	// Login validation schema
	const formValidationSchema = object<IFormValidation>().shape({
		email: string()
			.required(validationMessages.required('email'))
			.email(validationMessages.email('email')),
		password: string().required(validationMessages.required('password')),
	});

	// Handle form submission
	const handleSubmit = async (
		values: IFormValues,
		{ resetForm }: FormikHelpers<IFormValues>
	) => {
		// Create login request
		const response = await dispatch(processLogin(values));
		if (!response) {
			return;
		}

		// Play login sound
		new Howl({
			src: ['/login.mp3'],
		}).play();

		resetForm();
		// Redirect user to dashboard
		history.push('/dashboard');
	};

	return (
		<LoginWrapper>
			<LogoBlock variant="login" />
			<Formik
				initialValues={initialValues}
				validationSchema={formValidationSchema}
				onSubmit={handleSubmit}
			>
				{() => (
					<StyledLoginForm>
						<h1>
							<FormattedMessage id="login.title" />
						</h1>
						<p>
							<FormattedMessage id="login.copy" />
						</p>
						<StyledField
							component={Input}
							type="email"
							name="email"
							placeholder={intl.formatMessage({
								id: 'form.email.placeholder',
							})}
							size="lg"
						/>
						<StyledField
							component={Input}
							type="password"
							name="password"
							placeholder={intl.formatMessage({
								id: 'form.password.placeholder',
							})}
							size="lg"
						/>
						<Button type="submit" size="lg" ariaLabel="login-button">
							<FormattedMessage id="login.form.submit" />
						</Button>
						<Link className="btn-link" to="/forgot" aria-label="forgot-link">
							<FormattedMessage id="login.forgotLink" />
						</Link>
					</StyledLoginForm>
				)}
			</Formik>
		</LoginWrapper>
	);
};

export default LoginPage;
