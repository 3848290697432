import {
	IVenueOpeningApi,
	IVenueOpeningDay,
	IVenueOpeningFormValues,
} from '../venue.types';

/** Format venue opening times from API as form values */
export const formatVenueOpeningTimesToForm = (
	openingTimes: IVenueOpeningApi[]
): IVenueOpeningFormValues => {
	// Create default day
	const defaultDay: IVenueOpeningDay = {
		open: false,
		openingTime: '00:00',
		closingTime: '23:59',
	};

	// Create default form values
	const formValues: IVenueOpeningFormValues = {
		monday: defaultDay,
		tuesday: defaultDay,
		wednesday: defaultDay,
		thursday: defaultDay,
		friday: defaultDay,
		saturday: defaultDay,
		sunday: defaultDay,
	};

	// Loop through days in data
	for (const day of openingTimes) {
		// Add each day to form values
		formValues[day.name] = {
			open: true,
			openingTime: day.openingTime,
			closingTime: day.closingTime,
		};
	}

	return formValues;
};

export const formatVenueOpeningTimesToApi = (
	formValues: IVenueOpeningFormValues
): IVenueOpeningApi[] => {
	// Array of days of the week
	const days = [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday',
	];

	return Object.entries(formValues)
		.filter((day) => day[1].open)
		.map((day) => {
			return {
				day: days.indexOf(day[0]),
				name: day[0],
				openingTime: day[1].openingTime || '',
				closingTime: day[1].closingTime || '',
			};
		});
};
