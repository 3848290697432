import { rgba } from 'polished';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Icon from 'components/icons/icon.component';
import dayjs from 'helpers/dayjs.helper';
import { RootState } from 'modules/core/state/root.reducer';

const StyledDates = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	width: 100%;
	max-width: 675px;
	border-radius: 100px;
	justify-content: flex-end;
	background: ${brand.white};

	${mq.tabletPortrait`
		max-width: 128px;
		jusfity-content: center;
		flex: 0 0 100%;
	`}

	li {
		&.mod-prev,
		&.mod-next {
			display: none;

			${mq.mobile`
				display: block;
			`}
		}
	}
`;

const StyledDate = styled.li`
	flex-basis: 69px;
	padding: 8px 0;
	margin: 0 15px;
	position: relative;
	justify-content: center;
	cursor: pointer;

	${mq.tabletPortrait`
		flex-basis: 128px;
		margin: 0;
		display: none;
	`}

	&:first-child {
		margin-left: 25px !important;

		${mq.tabletPortrait`
			margin-left: 0 !important;
		`}
	}

	&:last-child {
		margin-right: 0;
	}

	h3 {
		font-size: ${fonts.sizes.large};
		font-weight: ${fonts.weights.medium};
		line-height: 18px;
		color: ${brand.text};
		text-transform: uppercase;
		margin: 0;
		position: relative;
	}

	h4 {
		margin: 0;
		font-size: ${fonts.sizes.standard};
		font-weight: ${fonts.weights.medium};
		color: ${rgba(brand.text, 0.4)};
		position: relative;
	}

	&.mod-active {
		${mq.tabletPortrait`
			display: flex;
		`}

		&:before {
			content: '';
			position: absolute;
			width: calc(100% + 25px);
			border-radius: 100px;
			height: 100%;
			background: ${brand.text};
			left: -25px;
			top: 0;
			z-index: 0;

			${mq.tabletPortrait`
				width: 100%;
				left: 0;
				background: ${rgba(brand.text, 0.75)}
			`}
		}

		h3,
		h4 {
			color: ${brand.white};
		}
	}
`;

const StyledContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
`;

const StyledDay = styled.div`
	display: flex;
	align-items: flex-end;
	position: relative;
	z-index: 0;
`;

const StyledOrderIndicator = styled.div`
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: ${brand.text};
	margin-left: 5px;
	position: relative;
	top: -4px;

	&.mod-active {
		background: ${brand.white};
	}
`;

const StyledButton = styled.button`
	border: none;
	background: none;
	cursor: pointer;
	display: none;
	position: relative;

	${mq.tabletPortrait`
		display: block;
	`}

	&.mod-prev {
		transform: rotate(180deg);
	}

	svg {
		opacity: 50%;
	}

	&.mod-active {
		svg {
			color: ${brand.primary};
			opacity: 1;
		}
	}
`;

/** Renders VenueDates component */
const VenueDates: React.FC = () => {
	// Get url param
	const { date }: { date?: string } = useParams();
	// Get router history
	const history = useHistory();
	// Get dates from store
	const { venueDates } = useSelector((state: RootState) => state.venue);

	const dateItems: ReactElement[] = [];

	// For each venue
	venueDates?.length > 0 &&
		venueDates.forEach((venueDate, index) => {
			const parsedDate = dayjs(venueDate.date).utc();

			// If first index and no param
			// or param is set and equals link
			const isActive = !!(
				(index === 0 && !date) ||
				(!!date && date === parsedDate.format('YYYY-MM-DD'))
			);

			// Create element
			const element = (
				<StyledDate
					key={venueDate.date}
					className={`${isActive && 'mod-active'}`}
				>
					{isActive && (
						<StyledButton
							type="button"
							className={`mod-prev ${index !== 0 && 'mod-active'}`}
							onClick={() => {
								if (index !== 0) {
									history.push(
										`/future-orders/${dayjs(venueDates[index - 1].date).format(
											'YYYY-MM-DD'
										)}`
									);
								}

								if (index === 1) {
									history.push('/dashboard');
								}
							}}
						>
							<Icon name="chevron" width={15} height={15} colour="white" />
						</StyledButton>
					)}
					<StyledContentWrapper
						onClick={() => {
							// Redirect user to dashboard or future orders
							index === 0
								? history.push('/dashboard')
								: history.push(
									`/future-orders/${parsedDate.format('YYYY-MM-DD')}`
								  );
						}}
					>
						<StyledDay>
							<h3>{parsedDate.format('ddd')}</h3>
							{venueDate.hasOrders && (
								<StyledOrderIndicator
									className={`${isActive && 'mod-active'}`}
								/>
							)}
						</StyledDay>
						<h4>{parsedDate.format('DD MMM')}</h4>
					</StyledContentWrapper>
					{isActive && (
						<StyledButton
							type="button"
							className={`mod-next ${
								index !== venueDates.length - 1 && 'mod-active'
							}`}
							onClick={() => {
								if (index !== venueDates.length - 1) {
									history.push(
										`/future-orders/${dayjs(venueDates[index + 1].date).format(
											'YYYY-MM-DD'
										)}`
									);
								}
							}}
						>
							<Icon name="chevron" width={11} height={11} colour="white" />
						</StyledButton>
					)}
				</StyledDate>
			);

			// Push element to array
			dateItems.push(element);
		});

	// Return all date elements
	return <StyledDates data-testid="venue-dates">{dateItems}</StyledDates>;
};

export default VenueDates;
