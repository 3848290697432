import { rgba } from 'polished';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Header from 'components/header/header.component';
import OrderAlertCheck from 'components/order-alert-check/order-alert-check.component';
import OrderAlert from 'components/order-alert/order-alert.component';
import OrderCard from 'components/order-card/order-card.component';
import PageRefreshComponent from 'components/page-refresh/page-refresh.component';
import VenueDates from 'components/venue-dates/venue-dates.component';
import { isOrderCompleted } from 'helpers/completed-orders.helper';
import dayjs from 'helpers/dayjs.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import {
	getOrdersList,
	postOrderReceived,
	processOrderAlertConfig,
	resetOrderState,
} from 'modules/orders/order.slice';
import {
	getVenueDates,
	getVenuePrintingConfig,
	getVenueTimeslots,
} from 'modules/venue/venue.slice';

interface IStyledComponent {
	active?: boolean;
	viewHeight?: number;
}

const StyledHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 1%;

	${mq.mobile`
		background: ${brand.white};
		justify-content: space-between;
		margin: -10px 0 0;
		padding: 28px 1% 17px;
	`}
`;

const StyledOrderLanes = styled.div`
	margin: 28px 0 0;
	display: flex;
	flex-direction: column;

	> div {
		display: flex;
	}

	${mq.mobile`
		margin: 0;
	`}
`;

const laneCommonStyles = css`
	flex-basis: 33.33%;
	padding: 10px;

	${mq.tabletPortrait`
		padding: 0;
	`}
`;

const headerActiveStyling = css`
	${mq.tabletPortrait`
		color: ${brand.text};

		h3 {
			color: ${brand.text};
		}

		&:after {
			content: '';
			width: 87px;
			height: 4px;
			background: ${brand.primary};
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	`}
`;

const StyledLaneWrapper = styled.div`
	${mq.tabletPortrait`
		padding: 0 1%;
	`}
`;

const StyledLaneHeader = styled.div<IStyledComponent>`
	${laneCommonStyles}
	font-size: ${fonts.sizes.large};
	padding: 0 17px 7px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	${mq.tabletPortrait`
		padding: 10px 0 16px;
		justify-content: center;
		margin: 0 0 17px;
		font-size: ${fonts.sizes.standard};
		font-weight: ${fonts.weights.light};
		background: ${brand.white};
		border-bottom: 1px solid ${rgba(brand.text, 0.25)};
		color: ${rgba(brand.text, 0.5)};

		&:first-child {
			padding-left: 1%:
		}

		&:last-child {
			padding-right: 1%:
		}
	`}

	h3 {
		margin: 0 7px 0 0;
		font-size: ${fonts.sizes.large};
		font-weight: ${fonts.weights.semibold};
		color: ${rgba(brand.text, 0.5)};

		${mq.tabletPortrait`
			font-size: ${fonts.sizes.semiLarge};
			margin: 0;
		`}
	}

	${({ active }) => (active ? headerActiveStyling : '')}
`;

const StyledCount = styled.span`
	color: ${rgba(brand.text, 0.4)};

	${mq.tabletDown`
		margin-left: 5px;

		&:before {
			content: '(';
			display: inline-block;
		}

		&:after {
			content: ')';
			display: inline-block;
		}
	`}
`;

const laneActiveStyling = css`
	background-color: ${rgba(brand.black, 0.1)};

	${mq.tabletPortrait`
		background-color: transparent;
		display: block;
		flex-basis: 100%;
	`}
`;

const StyledLane = styled.div<IStyledComponent>`
	${laneCommonStyles}
	height: calc((${({ viewHeight }) => viewHeight}px * 100) - 150px);
	overflow-y: scroll;
	border-radius: 10px 10px 0 0;
	scrollbar-width: none;

	${({ viewHeight }) => mq.mobile`
		height: calc((${viewHeight!}px * 100) - 205px);
	`}

	::-webkit-scrollbar {
		display: none;
	}

	.sub-no-orders {
		display: none;
		text-align: center;
		font-size: ${fonts.sizes.med};
		font-weight: ${fonts.weights.regular};

		${mq.mobile`
			display: block;
		`}
	}

	${mq.tabletPortrait`
		display: none;
	`}

	${({ active }) => (active ? laneActiveStyling : '')}
`;

/** Renders dashboard page component */
const DashboardPage: React.FC = () => {
	const dispatch = useReduxDispatch();
	const { ordersList, printStatusList } = useSelector(
		(state: RootState) => state.order
	);
	const { selectedVenue, printingEnabled } = useSelector(
		(state: RootState) => state.venue
	);

	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	useEffect(() => {
		const getData = async () => {
			const dateToday = dayjs().tz('Europe/London').format('YYYY-MM-DD');
			if (selectedVenue.name) {
				// Get timeslots for order alert component
				await dispatch(getVenueTimeslots(selectedVenue.id));
				// Get venue dates for venue dates component
				await dispatch(getVenueDates(selectedVenue.id));
				// Get venue printing config
				await dispatch(getVenuePrintingConfig(selectedVenue.id));
				// dispatch get orders venue.id
				const ordersResponse = await dispatch(
					getOrdersList(selectedVenue.id, dateToday)
				);
				// dispatch process order alert config
				ordersResponse &&
					(await dispatch(
						processOrderAlertConfig(ordersResponse, selectedVenue.serviceTypes)
					));
			}

			// If we have orders, send order received
			ordersList.length > 0 && dispatch(postOrderReceived(ordersList[0].id));
		};

		getData();
		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => {
			window.removeEventListener('resize', getSetViewHeight);
			dispatch(resetOrderState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, selectedVenue]);

	// Filter out completed orders as backend don't update thier list
	const nonCompletedOrders = ordersList.filter(
		(order) => !isOrderCompleted(order.items)
	);

	const ordersWithPrintStatus = nonCompletedOrders.map((order) => ({
		...order,
		printStatus: printingEnabled ? printStatusList[order.id] ?? 'None' : null,
	}));

	const upcoming =
		ordersWithPrintStatus
			?.filter((order) => order.status === 'venueAccepted')
			.map((order) => <OrderCard key={order.id} {...order} />) || [];

	const inProgress =
		ordersWithPrintStatus
			?.filter((order) => order.status === 'orderBeingPrepared')
			.map((order) => <OrderCard key={order.id} {...order} />) || [];

	const collection =
		ordersWithPrintStatus
			?.filter((order) => order.status === 'pendingPickup')
			.map((order) => <OrderCard key={order.id} {...order} />) || [];

	const [active, setIsActive] = useState('progress');

	return (
		<main>
			<OrderAlert />
			<StyledHeaderWrapper>
				<Header title="dashboard.header.title" />
				<VenueDates />
			</StyledHeaderWrapper>
			<StyledOrderLanes>
				<div>
					<StyledLaneHeader
						active={active === 'upcoming'}
						onClick={() => setIsActive('upcoming')}
					>
						<h3>
							<FormattedMessage id="orderStatus.upcoming" />
						</h3>
						<StyledCount>{upcoming.length}</StyledCount>
					</StyledLaneHeader>
					<StyledLaneHeader
						active={active === 'progress'}
						onClick={() => setIsActive('progress')}
					>
						<h3>
							<FormattedMessage id="orderStatus.progress" />
						</h3>
						<StyledCount>{inProgress.length}</StyledCount>
					</StyledLaneHeader>
					<StyledLaneHeader
						active={active === 'pickup'}
						onClick={() => setIsActive('pickup')}
					>
						<h3>
							<FormattedMessage id="orderStatus.pickup" />
						</h3>
						<StyledCount>{collection.length}</StyledCount>
					</StyledLaneHeader>
				</div>
				<StyledLaneWrapper>
					<StyledLane active={active === 'upcoming'} viewHeight={viewHeight}>
						{upcoming.length > 0 ? (
							upcoming
						) : (
							<span className="sub-no-orders">
								<FormattedMessage id="dashboard.noOrders.upcoming" />
							</span>
						)}
					</StyledLane>
					<StyledLane active={active === 'progress'} viewHeight={viewHeight}>
						{inProgress.length > 0 ? (
							inProgress
						) : (
							<span className="sub-no-orders">
								<FormattedMessage id="dashboard.noOrders.inProgress" />
							</span>
						)}
					</StyledLane>
					<StyledLane active={active === 'pickup'} viewHeight={viewHeight}>
						{collection.length > 0 ? (
							collection
						) : (
							<span className="sub-no-orders">
								<FormattedMessage id="dashboard.noOrders.collection" />
							</span>
						)}
					</StyledLane>
				</StyledLaneWrapper>
			</StyledOrderLanes>
			<PageRefreshComponent />
			<OrderAlertCheck />
		</main>
	);
};

export default withNav(DashboardPage);
