import { Formik, Form, Field } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ModalProps } from 'styled-react-modal';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import Switch from 'components/form-inputs/switch/switch.component';
import Modal, {
	IComponentProps as IModalProps,
} from 'components/modal/modal.component';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { setOrderFilters } from 'modules/ticket-view/ticket-view.slice';

interface IStyledModal extends ModalProps {
	viewHeight?: number;
}

interface IStyledComponent {
	viewHeight?: number;
}

const StyledModal = styled(Modal)<IStyledModal>`
	width: calc(100% - 50px);
	max-width: 630px;
	height: auto;
	max-height: 780px;
	padding: 0;
	border-radius: 10px;
	position: relative;
	display: block;
	z-index: 2;

	${mq.mobile`
		padding: 0;
		height: 80%;
		width: calc(100% - 22px);
	`}
`;

const StyledContentWrapper = styled.div<IStyledComponent>`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-height: 650px;

	overflow: scroll;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}

	${mq.mobile`
		padding: 0;
		display: block;
	`}
`;

const StyledCopy = styled.div`
	padding: 22px 30px;

	${mq.mobile`
		text-align: center;
	`}

	h2 {
		font-size: ${fonts.sizes.larger};
		font-weight: ${fonts.weights.medium};
		margin: 0 0 15px;

		${mq.mobile`
			margin: 0 0 5px;
		`}
	}

	p {
		font-size: ${fonts.sizes.standard};
		font-weight: ${fonts.weights.light};
		margin: 0;
	}
`;

const StyledForm = styled(Form)`
	background: ${brand.body};
	padding: 22px 30px;

	${mq.mobile`
		padding: 18px 13px;
	`}
`;

const StyledFilter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 7px 22px;
	background: ${brand.white};
	border-radius: 5px;
	margin: 0 0 7px;

	h4 {
		margin: 0;
		font-size: ${fonts.sizes.med};
		font-weight: ${fonts.weights.medium};
	}
`;

const StyledSwitch = styled(Switch)`
	margin: 0;
	max-width: 110px;
`;

const StyledFormActions = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 30px 0 0;
`;

const StyledButton = styled(Button)`
	flex-basis: calc(50% - 4px);
`;

/** Renders Modal component */
const TicketViewFilterModal: React.FC<IModalProps> = ({
	isOpen,
	toggleModal,
}) => {
	const dispatch = useDispatch();

	// get data from redux
	const menus = useSelector((state: RootState) => state.venue.venueMenus);
	const { orderFilters } = useSelector((state: RootState) => state.ticketView);

	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	interface IFormValues {
		[key: string]: boolean;
	}

	// Varible for initial form values
	const initialFormValues: IFormValues = {};

	// Loop through menus
	menus?.forEach((menu) => {
		// set true/false based on current state
		initialFormValues[menu.id] =
			orderFilters?.some((curFilter) => curFilter === menu.id) || false;
	});

	// Handle form submission
	const handleFormSubmit = async (values: IFormValues) => {
		const filters: string[] = [];

		// for all form values
		for (const [key, value] of Object.entries(values)) {
			// if true, add to filters array
			value && filters.push(key);
		}

		dispatch(setOrderFilters(filters));

		// close modal
		toggleModal();
	};

	useEffect(() => {
		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => window.removeEventListener('resize', getSetViewHeight);
	}, [dispatch]);

	return (
		<StyledModal
			isOpen={isOpen}
			toggleModal={toggleModal}
			allowScroll={true}
			backgroundProps={{ viewHeight }}
			viewHeight={viewHeight}
		>
			<StyledContentWrapper viewHeight={viewHeight}>
				<StyledCopy>
					<h2>
						<FormattedMessage id="ticketView.filters.modal.title" />
					</h2>
					<p>
						<FormattedMessage id="ticketView.filters.modal.copy" />
					</p>
				</StyledCopy>
				<Formik initialValues={initialFormValues} onSubmit={handleFormSubmit}>
					{() => (
						<StyledForm>
							{menus?.map((menu) => (
								<StyledFilter key={menu.id}>
									<h4>{menu.name}</h4>
									<Field
										component={StyledSwitch}
										name={menu.id}
										size="small"
										onText={intl.formatMessage({
											id: 'form.ticketView.filter.switch.onText',
										})}
										offText={intl.formatMessage({
											id: 'form.ticketView.filter.switch.offText',
										})}
									/>
								</StyledFilter>
							))}
							<StyledFormActions>
								<StyledButton variant="secondary" onClick={() => toggleModal()}>
									<FormattedMessage id="form.button.cancel" />
								</StyledButton>
								<StyledButton type="submit" variant="primary">
									<FormattedMessage id="form.button.confirm" />
								</StyledButton>
							</StyledFormActions>
						</StyledForm>
					)}
				</Formik>
			</StyledContentWrapper>
		</StyledModal>
	);
};

export default TicketViewFilterModal;
