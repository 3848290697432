import { AxiosError } from 'axios';

import { fireDialog } from '../dialog/dialog.service';
import { intl } from '../i18n/i18n.config';
import {
	authErrors,
	forgotPasswordErrors,
} from './helpers/endpoint-error.helper';

/** Handle an error response from the API */
export const handleApiError = async (error?: AxiosError) => {
	// Get error code from error
	const errorCode: string | undefined = error?.code;
	// Get statuscode from error
	const statusCode: number | undefined = error?.response?.status;
	// Get requested endpoint from error
	const endpoint: string | undefined = error?.response?.config?.url;
	// Get redux action from error
	const reduxAction: string | undefined =
		// @ts-ignore
		error?.response?.config?.reduxSourceAction?.type;
	// Endpoint is excluded from generic response
	let isExcluded: boolean = false;

	// Endpoints with specific error handling
	const excludedEndpoints = [
		{
			route: 'auth/login',
			errors: [401],
		},
		{
			route: 'auth/forgot-password',
			errors: [404],
		},
		{
			route: 'auth/check-code',
			errors: [401],
		},
		{
			route: 'pusher/auth',
			errors: [401],
		},
	];

	const excludedActions = [
		// Wait times throws a 404 if they haven't been setup yet
		'venue/GET_VENUE_SERVICE_WAIT_TIMES',
		'order/ORDER',
	];

	// Check if requested endpoint is in excluded list
	const endpointExcluded = excludedEndpoints.find((x) => x.route === endpoint);
	// If endpoint is excluded + status code is set
	if (endpointExcluded && statusCode) {
		// If endpoint has this status code excluded
		isExcluded = endpointExcluded?.errors.includes(statusCode);
	}

	// Exclude get single order
	if (reduxAction && excludedActions.includes(reduxAction)) {
		isExcluded = true;
	}

	// Define responses we'll handle in a generic way
	const genericResponses = [400, 401, 403, 404, 409, 422, 424, 500];

	// If endpoint isn't excluded
	if (!isExcluded) {
		// If we have a status code and it's one of the generic responses we handle
		if (statusCode && genericResponses.includes(statusCode)) {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: `errors.responses.${statusCode}.title`,
				}),
				text: error?.response?.data?.errorMessage
					? error?.response?.data?.errorMessage
					: intl.formatMessage({
						id: `errors.responses.${statusCode}.text`,
					  }),
				icon: 'error',
			});
		} else if (
			// Timeout
			errorCode === 'ECONNABORTED'
		) {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.responses.timeout.title',
				}),
				text: intl.formatMessage({
					id: 'errors.responses.timeout.text',
				}),
				icon: 'error',
			});
		} else if (
			// Network error
			!!error?.isAxiosError &&
			!error?.response
		) {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.responses.network.title',
				}),
				text: intl.formatMessage({
					id: 'errors.responses.network.text',
				}),
				icon: 'error',
			});
		} else {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.responses.general.title',
				}),
				text: error?.response?.data?.errorMessage
					? error?.response?.data?.errorMessage
					: intl.formatMessage({
						id: 'errors.responses.general.text',
					  }),
				icon: 'error',
			});
		}
	} else {
		// run excluded endpoint
		authErrors(error!);
		forgotPasswordErrors(error!);
	}

	// Pass error back to caller
	return Promise.reject(error);
};

// Export error service as object
export const errorService = { handleApiError };
