import { Formik, Form, Field } from 'formik';
import { rgba } from 'polished';
import queryString from 'query-string';
import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useDebounce } from 'use-debounce';

import Header from '../../components/header/header.component';
import CompletedOrdersListItem from './completed-orders-list-item/completed-orders-list-item.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Input from 'components/form-inputs/input/input.component';
import Icon from 'components/icons/icon.component';
import OrderHistoryDates from 'components/order-history-dates/order-history-dates.component';
import Pagination from 'components/pagination/pagination.component';
import dayjs from 'helpers/dayjs.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import {
	getCompletedOrders,
	resetOrderState,
} from 'modules/orders/order.slice';

interface IStyledComponent {
	viewHeight?: number;
}

const StyledHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 1%;

	${mq.mobile`
		background: ${brand.white};
		justify-content: space-between;
		margin: -10px 0 0;
		padding: 28px 1% 17px;
	`}
`;

const StyledDateDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 16px 1% 0;

	h2 {
		font-size: ${fonts.sizes.h2};
		margin: 0;
		font-weight: ${fonts.weights.medium};

		span {
			font-weight: ${fonts.weights.light};
		}
	}

	${mq.mobile`
		padding: 0 1% 15px;
		background: ${brand.white};
		margin: 0 0 16px;

		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	`}
`;

const StyledForm = styled(Form)`
	width: 100%;
`;

const StyledInput = styled(Input)`
	min-width: 0;
	margin: 0;

	${mq.mobile`
		width: 90%;
		margin: 0;
	`}

	input {
		background-color: ${brand.neutralLight};
		font-size: ${fonts.sizes.large};
		font-weight: ${fonts.weights.light};
	}
`;

const StyledOrdersList = styled.ul`
	margin: 0 1%;
	padding: 0;
	list-style: none;

	li {
		position: relative;

		&.mod-headers {
			margin: 0;
			display: flex;
			align-items: flex-end;
			width: 100%;
			font-size: ${fonts.sizes.standard};
			font-weight: ${fonts.weights.medium};
			color: ${rgba(brand.text, 0.4)};
			padding: 0 0 16px 15px;

			${mq.mobile`
				display: none;
			`}
		}

		${mq.tabletDown`
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		`}

		> div {
			flex-basis: calc(100% / 6);
			flex-grow: 1;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(4) {
				max-width: 70px;
			}

			&:nth-child(3) {
				max-width: 450px;
			}

			&:nth-child(5) {
				max-width: 150px;
			}

			&:last-child {
				max-width: 230px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			${mq.mobile`
				&:nth-child(1) {
					position: absolute;
					top: 11px;
					right: 15px;
				}

				&:nth-child(3) {
					order: 1;
					flex-basis: 100%;
					font-size: ${fonts.sizes.large};
					font-weight: ${fonts.weights.regular};
					margin: 0 0 -2px;
					max-width: none;
				}

				&:nth-child(2) {
					order: 2;
					font-size: ${fonts.sizes.semiLarge};
					font-weight: ${fonts.weights.light};
					position: relative;
					max-width: 65px;

					&:after {
						content: '|';
						position: absolute;
						right: 12px;
						top: 45%;
						transform: translateY(-50%);
						display: block;
					}
				}

				&:nth-child(4) {
					order: 3;
					font-size: ${fonts.sizes.semiLarge};
					font-weight: ${fonts.weights.light};
					max-width: 65px;
					position: relative;

					&:after {
						content: '|';
						position: absolute;
						right: 12px;
						top: 45%;
						transform: translateY(-50%);
						display: block;
					}
				}

				&:nth-child(5) {
					order: 4;
					font-size: ${fonts.sizes.semiLarge};
					font-weight: ${fonts.weights.light};
					max-width: 100px;
				}

				&:nth-child(6) {
					order: 5;
					flex-basis: 100%;
					margin-top: 5px;
				}

				&:last-child  {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					max-width: none;
					padding: 0;

					> div {
						display: none;
					}
				}
			`}
		}
	}
`;

const listHeightCss = (viewHeight?: number) => css`
	height: calc((${viewHeight}px * 100) - 270px);
`;

const StyledListWrapper = styled.div<IStyledComponent>`
	${({ viewHeight }) => listHeightCss(viewHeight)}
	overflow: scroll;

	${({ viewHeight }) => mq.mobile`
		height: calc((${viewHeight}px * 100) - 280px);
	`}
`;

const StyledMobileSearchToggle = styled.div`
	display: none;

	margin-right: 11px;

	${mq.mobile`
		display: block;
	`}
`;

const StyledEmptyHeading = styled.h2`
	margin: 50px 15px 0 15px;
	text-align: center;
`;

/** Renders order history page component */
const CompletedOrdersPage: React.FC = () => {
	// Get hooks
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	// Get query string data
	const query: IQueryParams = queryString.parse(location.search);

	// set search query
	const [searchQuery, setSearchQuery] = useState<string>(query.search || '');
	const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);

	// Debounce searchTerm changes
	const [debouncedSearch] = useDebounce(searchQuery, 300);

	// Get date from params
	const { date }: { date?: string } = useParams();

	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// Get data from state
	const pagination = useSelector((state: RootState) => state.order.pagination);
	const orders = useSelector((state: RootState) => state.order.ordersList);
	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	useEffect(() => {
		const getData = async () => {
			const orderDate = date ? dayjs(date) : dayjs();
			// dispatch get orders list
			await dispatch(
				getCompletedOrders({
					venueId: selectedVenue.id,
					dateFrom: orderDate.format('YYYY-MM-DD'),
					pageNumber: parseFloat(query.pageNumber!) || 1,
					name: query.search || '',
				})
			);
		};

		getData();

		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => {
			window.removeEventListener('resize', getSetViewHeight);
			dispatch(resetOrderState());
		};
	}, [dispatch, selectedVenue, date, query.pageNumber, query.search]);

	const orderDate = date ? dayjs(date) : dayjs();

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(`${location.pathname}?${queryString.stringify(filters)}`);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearch, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearch]);

	return (
		<main>
			<StyledHeaderWrapper>
				<Header title="completedOrders.header.title" />
				<OrderHistoryDates />
			</StyledHeaderWrapper>
			<StyledDateDetails>
				{!showMobileSearch && (
					<div className="sub-details">
						<h2>
							{orderDate.tz('Europe/London').format('dddd Do MMMM')}{' '}
							{pagination?.total && <span>({pagination.total})</span>}
						</h2>
					</div>
				)}
				{showMobileSearch && (
					<Formik initialValues={{ searchQuery }} onSubmit={() => {}}>
						{() => {
							return (
								<StyledForm>
									<Field
										component={StyledInput}
										name="searchQuery"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={15}
										iconHeight={15}
										iconWidthMobile={15}
										iconheightMobile={15}
										iconColour="placeholderLight"
										iconPlacement="left"
										handleChangeEvent={(value: string) => {
											setSearchQuery(value);
										}}
									/>
								</StyledForm>
							);
						}}
					</Formik>
				)}
				<StyledMobileSearchToggle
					onClick={() => setShowMobileSearch(!showMobileSearch)}
				>
					{!showMobileSearch && (
						<Icon name="search" width={20} height={20} colour="primary" />
					)}
					{!!showMobileSearch && <Icon name="close" width={20} height={20} />}
				</StyledMobileSearchToggle>
			</StyledDateDetails>

			<StyledOrdersList>
				<li className="mod-headers">
					<div className="mod-header">
						<FormattedMessage id="completedOrders.list.headings.type" />
					</div>
					<div className="mod-header">
						<FormattedMessage id="completedOrders.list.headings.time" />
					</div>
					<div className="mod-header">
						<FormattedMessage id="completedOrders.list.headings.customer" />
					</div>
					<div className="mod-header">
						<FormattedMessage id="completedOrders.list.headings.total" />
					</div>
					<div className="mod-header">
						<FormattedMessage id="completedOrders.list.headings.status" />
					</div>
					<div className="mod-header">
						<FormattedMessage id="completedOrders.list.headings.flags" />
					</div>
					<div className="mod-header">
						<Formik initialValues={{ searchQuery }} onSubmit={() => {}}>
							{() => {
								return (
									<StyledForm>
										<Field
											component={StyledInput}
											name="searchQuery"
											placeholder={intl.formatMessage({
												id: 'form.search.placeholder',
											})}
											icon="search"
											iconWidth={15}
											iconHeight={15}
											iconWidthMobile={15}
											iconheightMobile={15}
											iconColour="placeholderLight"
											iconPlacement="left"
											handleChangeEvent={(value: string) => {
												setSearchQuery(value);
											}}
										/>
									</StyledForm>
								);
							}}
						</Formik>
					</div>
				</li>
				{orders.length > 0 ? (
					<StyledListWrapper viewHeight={viewHeight}>
						{orders.map((order) => (
							<CompletedOrdersListItem key={order.id} order={order} />
						))}
					</StyledListWrapper>
				) : (
					<StyledEmptyHeading>
						<FormattedMessage id="completedOrders.list.empty" />
					</StyledEmptyHeading>
				)}
				{pagination && pagination.pageCount > 1 && (
					<Pagination
						pageNumber={query.pageNumber ? parseFloat(query.pageNumber) : 1}
						pageCount={pagination.pageCount}
						setPageNumber={handlePagination}
					/>
				)}
			</StyledOrdersList>
		</main>
	);
};

export default withNav(CompletedOrdersPage);
