import { Field } from 'formik';
import { rgba } from 'polished';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { ETransitionState, ITransition } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import { intl } from 'modules/core/i18n/i18n.config';
import { IOrderItem, ITip } from 'modules/orders/order.types';

interface IComponentProps {
	tip: ITip;
	refundActive?: boolean;
}

interface IStyledComponent {
	orderColour?: keyof typeof brand;
	item?: IOrderItem;
	refundActive?: boolean;
}

const refundedStyling = css`
	&.mod-refunded {
		text-decoration: line-through;
	}
`;

const orderItemTransition = (state: keyof typeof ETransitionState) => {
	switch (state) {
	case 'entering':
		return css`
				transition: 0.3s;
				opacity: 0;
				max-height: 0;
			`;
	case 'entered':
		return css`
				transition: 0.3s;
				opacity: 1;
				max-height: 100px;
				${mq.mobile`
					max-height: 200px;
				`}
			`;
	case 'exiting':
		return css`
				transition: 0.2s;
				opacity: 0;
				max-height: 0;
			`;
	}

	return css`
		transition: 0.3s;
		opacity: 0;
		max-height: 0;
	`;
};

const StyledTipItem = styled.li<ITransition>`
	margin: 0 0 7px;
	background: ${rgba(brand.white, 0.6)};
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: ${fonts.sizes.large};

	&.mod-refunded {
		opacity: 0.4;
	}

	${({ state }) => orderItemTransition(state)}
`;

const StyledOrderWrapper = styled.div<IStyledComponent>`
	background-color: ${brand.white};
	border-radius: 5px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 15px 23px 15px 15px;
	transition: 0.2s;
	width: ${({ refundActive }) =>
		refundActive ? 'calc(100% - 150px)' : '100%'};

	${mq.mobile`
		flex-flow: row wrap;
	`}

	${({ refundActive }) =>
		refundActive
			? mq.mobile`width: calc(100% - 114px);`
			: mq.mobile`
		width: 100%;
	`};

	.sub-details-wrapper,
	.sub-actions-wrapper {
		display: flex;
		align-items: center;
		flex-grow: 1;

		${({ refundActive }) =>
		refundActive
			? mq.mobile`
			`
			: mq.mobile`
				width: 100%;
			flex-wrap: wrap; ;
			`}

		${mq.mobile`
			width: 80%;
		`}
	}

	.sub-details-wrapper {
		${mq.mobile`
			order: -1;
		`}
	}

	.sub-actions-wrapper {
		justify-content: flex-end;

		${({ refundActive }) =>
		refundActive
			? mq.mobile`
			margin: 5px 0 0 ;
		`
			: mq.mobile`
			margin: 12px 0 0 ;
		`}

		${mq.mobile`
			order: 1;
			width: 100%;
			justify-content: flex-end;
		`}
	}
`;

const StyledItem = styled.div<IStyledComponent>`
	flex-grow: 1;

	${mq.mobile`flex-basis: calc(100% - 55px)`}

	h5 {
		font-size: ${fonts.sizes.large};
		margin: 0;

		font-weight: ${fonts.weights.medium};

		color: ${brand.text};

		&.mod-allergen {
			color: ${brand.order_allergy};
		}

		${mq.mobile`
			font-size: ${fonts.sizes.med};
		`}
	}

	${refundedStyling}
`;

const StyledPrice = styled.div<IStyledComponent>`
	font-weight: ${fonts.weights.regular};
	${refundedStyling}

	${mq.mobile`
		order: -1;

		&.mod-refund-active {
			display: none;
		}
	`}
`;

const refundWrapperTransition = (state: keyof typeof ETransitionState) => {
	switch (state) {
	case 'entering':
		return css`
				opacity: 0;
				overflow: hidden;
				max-width: 0;
			`;
	case 'entered':
		return css`
				opacity: 1;
				overflow: visible;
				max-width: 150px;
			`;
	case 'exiting':
		return css`
				opacity: 0;
				overflow: hidden;
				max-width: 0;
			`;
	}

	return css`
		opacity: 0;
		overflow: hidden;
		max-width: 0;
	`;
};

const StyledRefundWrapper = styled.div<ITransition>`
	flex-basis: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s;
	position: relative;

	${mq.mobile`
		flex-basis: 106px;
	`}

	${({ state }) => refundWrapperTransition(state)}
`;

const StyledCheckbox = styled(Checkbox)`
	margin: 0;
	display: flex;
	justify-content: center;
`;

/** Renders tip item component */
const TipItem: React.FC<IComponentProps> = ({ tip, refundActive }) => {
	// variable for if tip has been refunded
	const { isRefunded } = tip;

	const [animate, setAnimate] = useState(false);

	useEffect(() => {
		setAnimate(true);
	}, []);

	// Reference to DOM element that needs to transition
	const nodeRef = React.useRef(null);

	return (
		<Transition
			in={animate}
			timeout={{
				enter: 300,
				exit: 200,
			}}
			nodeRef={nodeRef}
		>
			{(state) => {
				return (
					<StyledTipItem
						className={`${isRefunded ? 'mod-refunded' : ''}`}
						state={state}
					>
						<StyledOrderWrapper refundActive={refundActive && !tip.isRefunded}>
							<div className="sub-details-wrapper">
								<StyledItem
									className={`${isRefunded ? 'mod-refunded' : ''}`}
									refundActive={refundActive}
								>
									<h5>
										<FormattedMessage id="orderDetails.item.status.tip" />
									</h5>
								</StyledItem>
							</div>
							<StyledPrice
								className={`${isRefunded ? 'mod-refunded' : ''} ${
									refundActive ? 'mod-refund-active' : ''
								}`}
							>
								{intl.formatNumber(tip.amount / 100, {
									style: 'currency',
									currency: 'GBP',
								})}
							</StyledPrice>
						</StyledOrderWrapper>
						<Transition
							in={refundActive && !tip.isRefunded}
							timeout={200}
							nodeRef={nodeRef}
						>
							{(transitionState) => (
								<StyledRefundWrapper
									data-testid="tip-refund-field"
									state={transitionState}
								>
									<Field
										component={StyledCheckbox}
										name="refundTip"
										id="refundTip"
									/>
								</StyledRefundWrapper>
							)}
						</Transition>
					</StyledTipItem>
				);
			}}
		</Transition>
	);
};

export default TipItem;
