import { FieldProps } from 'formik';
import * as React from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';

// Interface for component props
interface IComponentProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
	className?: string;
	checkedBgColour?: keyof typeof brand;
}

// Create styled Checkbox component
const StyledCheckbox = styled.label<IComponentProps>`
	position: relative;
	cursor: pointer;
	user-select: none;
	width: 30px;
	height: 30px;
	border: 1px solid ${brand.primary};
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
`;

const StyledChecked = styled.div<IComponentProps>`
	display: block;
	background: ${({ checkedBgColour }) =>
		checkedBgColour ? brand[checkedBgColour] : brand.primary};
	width: 30px;
	height: 30px;
	border: 1px solid
		${({ checkedBgColour }) =>
		checkedBgColour ? brand[checkedBgColour] : brand.primary};
	border-radius: 2px;
`;

const StyledIcon = styled(Icon)`
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	transform: translateY(-50%);
`;

/** Renders Checkbox component */
const Checkbox: React.FC<FieldProps & IComponentProps> = ({
	field,
	form,
	className,
	checkedBgColour,
}) => {
	const { touched, errors } = form;
	const isTouched = touched[field.name] || false;
	const hasErrors = errors[field.name] || false;

	return (
		<FormElement className={className}>
			<StyledCheckbox className="container" htmlFor={field.name}>
				<input
					{...field}
					type="checkbox"
					id={field.name}
					aria-label={`${field.name}-checkbox`}
					data-testid="checkbox"
				/>
				{field.value === true && (
					<>
						<StyledChecked checkedBgColour={checkedBgColour} />
						<StyledIcon name="tick" width={13} height={10} colour="white" />
					</>
				)}
			</StyledCheckbox>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>{hasErrors}</FieldError>
			)}
		</FormElement>
	);
};

export default Checkbox;
