import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	ILoginCredentials,
	IResetPasswordParams,
	IAuthStore,
} from './auth.types';

// eslint-disable-next-line import/no-cycle
import { ReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getVenue, getVenueMenus, getVenues } from 'modules/venue/venue.slice';
import {
	processDeregisterVenueForWebview,
	processRegisterVenueForWebview,
} from 'modules/webview/webview.slice';

// Create initial state for auth slice
export const initialAuthState: IAuthStore = {
	accessToken: {
		expiry: null,
	},
	eventsInProgress: 0,
	hasAuth: false,
	refreshToken: {
		expiry: null,
		refreshing: false,
	},
	user: {
		id: '',
		venueId: '',
		emailAddress: '',
	},
};

const authSlice = createSlice({
	name: 'auth',
	initialState: initialAuthState,
	reducers: {
		LOGIN(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		LOGIN_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				hasAuth: true,
				accessToken: {
					...state.accessToken,
					expiry: action.payload.data.accessToken.expiry,
				},
				refreshToken: {
					...state.refreshToken,
					expiry: action.payload.data.refreshToken.expiry,
				},
				user: action.payload.data.user,
			};
		},
		LOGIN_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				hasAuth: false,
			};
		},
		LOGOUT(state) {
			return initialAuthState;
		},
		FORGOT_PASSWORD(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		FORGOT_PASSWORD_SUCCESS(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		FORGOT_PASSWORD_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_PASSWORD(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		RESET_PASSWORD_SUCCESS(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_PASSWORD_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		REFRESH(state, action) {
			// eslint-disable-next-line no-console
			console.log('REFRESH action');
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
				refreshToken: {
					...state.refreshToken,
					refreshing: true,
				},
			};
		},
		REFRESH_SUCCESS(state, action) {
			// eslint-disable-next-line no-console
			console.log('REFRESH_SUCCESS action');
			return {
				...state,
				hasAuth: true,
				eventsInProgress: state.eventsInProgress - 1,
				accessToken: {
					...state.accessToken,
					expiry: action.payload.data.accessToken.expiry,
				},
				refreshToken: {
					...state.refreshToken,
					expiry: action.payload.data.refreshToken.expiry,
					refreshing: false,
				},
			};
		},
		REFRESH_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				refreshToken: {
					...state.refreshToken,
					refreshing: false,
				},
			};
		},
		LOGIN_SAVE_EMAIL(state, action) {
			return {
				...state,
				user: { ...state.user, emailAddress: action.payload.data },
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAIL,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	REFRESH,
	REFRESH_SUCCESS,
	REFRESH_FAIL,
	LOGIN_SAVE_EMAIL,
} = authSlice.actions;

/** Thunk to process login request */
export const processLogin = (credentials: ILoginCredentials) => async (
	dispatch: ReduxDispatch
) => {
	// Create login request
	const response = await dispatch(
		LOGIN({
			request: {
				method: 'post',
				url: 'auth/login',
				data: credentials,
			},
		})
	);

	const { payload } = response;
	// If request was successful
	if (
		payload?.status === 200 &&
		payload?.data.accessToken &&
		payload?.data.refreshToken
	) {
		dispatch(LOGIN_SAVE_EMAIL({ data: credentials.email }));
		// register user for webview
		await dispatch(processRegisterVenueForWebview(payload.data.user.venueId));
		const usersVenues = await dispatch(getVenues());
		if (usersVenues.length > 0) {
			dispatch(getVenue(usersVenues[0].id));
			dispatch(getVenueMenus(usersVenues[0].id));
		}

		return true;
	}
	return false;
};

/** Thunk to process logout */
export const processLogout = () => async (
	dispatch: ReduxDispatch,
	getState: () => { auth: IAuthStore }
) => {
	// de register venue from web view push notifications
	await dispatch(
		processDeregisterVenueForWebview(getState().auth.user.venueId)
	);
	// Reset auth state
	await dispatch(LOGOUT());
};

/** Thunk to process forgot password request */
export const processForgotPassword = (email: string) => async (
	dispatch: Dispatch
) => {
	try {
		// Create forgot password request
		const { payload } = await dispatch(
			FORGOT_PASSWORD({
				request: {
					method: 'post',
					url: 'auth/forgot-password',
					data: {
						email,
					},
				},
			})
		);

		return payload?.status === 200;
	} catch (error) {
		return false;
	}
};

/** Thunk to process reset password request */
export const processResetPassword = (params: IResetPasswordParams) => async (
	dispatch: Dispatch
) => {
	try {
		// Create reset password request
		const { payload } = await dispatch(
			RESET_PASSWORD({
				request: {
					method: 'post',
					url: 'auth/check-code',
					data: params,
				},
			})
		);

		return payload?.status === 200;
	} catch (error) {
		return false;
	}
};

export default authSlice.reducer;
