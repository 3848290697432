enum ServiceType {
	clickAndCollect = 'click-and-collect',
	tableService = 'table-service',
	selfService = 'self-service',
	customService = 'custom-service',
	orderAhead = 'order-ahead',
	delivery = 'delivery',

}

export default ServiceType;