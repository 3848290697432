import { Field, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
	updateProductStock,
	updateModifierStock,
	getModifierItem,
} from '../stock.slice';
import { IStockItem, IStockUpdateValues } from '../stock.types';
import {
	StyledListItem,
	StyledForm,
	StyledSwitch,
	StyledSelect,
	StyledButton,
} from './stock-list-item.styles';

import { IOption } from 'components/form-inputs/select/select.component';
import Icon from 'components/icons/icon.component';
import ModifierEditModal from 'components/modifier-edit-modal/modifier-edit-modal.component';
import ProductEditModal from 'components/product-edit-modal/product-edit-modal.component';
import dayjs from 'helpers/dayjs.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

// Interface for component prop
interface IComponentProps {
	item: IStockItem;
}

interface IFormValues {
	inStock: boolean;
	backInStockDate: string | null;
}

interface ISelectChangeValue {
	fieldValue: string;
	form: FormikProps<IFormValues>;
}

type OptionString = IOption<string | null>;

/** Renders stock list item component */
const StockListItem: React.FC<IComponentProps> = ({ item }) => {
	const dispatch = useDispatch();
	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	const [isModalOpen, setIsModalOpen] = useState(false);

	// update stock item on change
	const handleSwitchChange = (value: boolean, values: IFormValues) => {
		// variable for if item is a product
		const { isProduct } = item;

		// data sent to update stock endpoints
		const stockData: IStockUpdateValues = {
			backInStockDate: values.backInStockDate,
			isOutOfStock: !value,
		};

		// Dispatch based on product or modifier
		isProduct
			? dispatch(updateProductStock(selectedVenue.id, item.id, stockData))
			: dispatch(updateModifierStock(selectedVenue.id, item.id, stockData));
	};

	// update back in stock on change
	const handleSelectChange = (
		value: ISelectChangeValue,
		values: IFormValues
	) => {
		// variable for if item is a product
		const { isProduct } = item;

		// data sent to update stock endpoints
		const stockData = {
			backInStockDate: value.fieldValue,
			isOutOfStock: !values.inStock,
		};

		// Dispatch based on product or modifier
		isProduct
			? dispatch(updateProductStock(selectedVenue.id, item.id, stockData))
			: dispatch(updateModifierStock(selectedVenue.id, item.id, stockData));
	};

	const backInStockDates: OptionString[] = [
		{
			value: dayjs().utc().add(1, 'day').startOf('day').format(),
			label: intl.formatMessage({ id: 'stock.backInStockOption.tomorrow' }),
		},
		{
			value: dayjs().utc().add(2, 'day').startOf('day').format(),
			label: intl.formatMessage({ id: 'stock.backInStockOption.twoDays' }),
		},
		{
			value: dayjs().utc().add(3, 'day').startOf('day').format(),
			label: intl.formatMessage({ id: 'stock.backInStockOption.threeDays' }),
		},
		{
			value: dayjs().utc().add(4, 'day').startOf('day').format(),
			label: intl.formatMessage({ id: 'stock.backInStockOption.fourDays' }),
		},
		{
			value: dayjs().utc().add(5, 'day').startOf('day').format(),
			label: intl.formatMessage({ id: 'stock.backInStockOption.fiveDays' }),
		},
		{
			value: null,
			label: intl.formatMessage({
				id: 'stock.backInStockOption.furtherNotice',
			}),
		},
	];

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const fetchModifierDetails = async () => {
		await dispatch(getModifierItem(item.id, selectedVenue.id));
	};

	// Initial form values
	const initialValues: IFormValues = {
		inStock: !item.isOutOfStock,
		backInStockDate:
			(!!item?.backInStockDate &&
				dayjs().isBefore(
					dayjs(item.backInStockDate).utc().startOf('day').format()
				) &&
				dayjs(item.backInStockDate).utc().startOf('day').format()) ||
			null,
	};

	return (
		<>
			<StyledListItem>
				<div
					data-cell-type="img"
					className={item.imageUrl ? 'mod-image' : 'mod-no-image'}
					style={{ backgroundImage: `url(${item.imageUrl})` }}
				/>
				<div className="mod-name" data-cell-type="text">
					{item.name}
				</div>
				<div className="mod-sku" data-cell-type="text">
					{item.sku}
				</div>
				<div className="mod-type" data-cell-type="text">
					<span className="mod-mobile-only">
						<FormattedMessage id="stock.list.headings.type" />:{' '}
					</span>
					<FormattedMessage
						id={
							item.isProduct
								? 'stock.list.item.product'
								: 'stock.list.item.modifier'
						}
					/>
				</div>
				<div data-cell-type="switch">
					<Formik initialValues={initialValues} onSubmit={() => {}}>
						{({ values }) => {
							return (
								<StyledForm>
									<Field
										component={StyledSwitch}
										name="inStock"
										onText={intl.formatMessage({
											id: 'form.fields.stockSwitch.on',
										})}
										offText={intl.formatMessage({
											id: 'form.fields.stockSwitch.off',
										})}
										handleChangeEvent={(value: boolean) => {
											handleSwitchChange(value, values);
										}}
									/>
									{!values.inStock && (
										<Field
											component={StyledSelect}
											selectOptions={backInStockDates}
											name="backInStockDate"
											handleChangeEvent={(selectValue: ISelectChangeValue) => {
												handleSelectChange(selectValue, values);
											}}
											value={backInStockDates.filter(
												(option) => option.value === values.backInStockDate
											)}
										/>
									)}
								</StyledForm>
							);
						}}
					</Formik>
				</div>
				<div data-cell-type="icon">
					<StyledButton className="mod-clickable" onClick={toggleModal}>
						<Icon name="editBox" width={20} height={20} colour="primary" />
					</StyledButton>
				</div>
			</StyledListItem>

			{item.isProduct ? (
				<ProductEditModal
					{...item}
					isOpen={isModalOpen}
					toggleModal={toggleModal}
				/>
			) : (
				<ModifierEditModal
					{...item}
					isOpen={isModalOpen}
					toggleModal={toggleModal}
					beforeOpen={fetchModifierDetails}
				/>
			)}
		</>
	);
};

export default StockListItem;
