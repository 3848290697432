import MockAdapter from 'axios-mock-adapter/types';

import {
	IOfflineNotificationSettings,
	IPauseOrdersFormValues,
	IPusherPausedService,
	IServiceType,
	IVenue,
	IVenueConfig,
	IVenueDate,
	IVenueMenu,
	IVenueMenuCategory,
	IVenueServiceAvailabilitySubmitValues,
	IVenueOpeningApi,
	IVenueOpeningFormValues,
	IVenueTimeslots,
	IVenueServiceWaitTimes,
} from './venue.types';

import dayjs from 'helpers/dayjs.helper';

const serviceTypes: IServiceType[] = [
	{
		id: '1231231243-0234dfsdf-23sdfsdfs',
		type: 'clickAndCollect',
		isActive: true,
		ignoreMaxOrders: true,
		deactivatedReason: null,
		prepTime: 15,
		orderInstructions: null,
		venueId: '6432245b-33c2-42fa-abaf-d694034b8aa7',
		visitExpiryTimeSeconds: 124400,
		ordersPausedUntil: 'dateTimeHere',
		isOrderingPaused: false,
		orderAlertConfig: {
			id: '1',
			enabled: true,
			initialAlertSeconds: 500,
			escalationAlertSeconds: 600,
		},
	},
];

/** MOCK DATA */
const venue: IVenue = {
	id: '6432245b-33c2-42fa-abaf-d694034b8aa7',
	logoUrl: '',
	name: 'Old street',
	hasPausedOrders: false,
	serviceTypes: [
		{
			id: '1231231243-0234dfsdf-23sdfsdfs',
			type: 'selfService',
			isActive: true,
			ignoreMaxOrders: true,
			deactivatedReason: null,
			prepTime: 15,
			orderInstructions: null,
			venueId: '6432245b-33c2-42fa-abaf-d694034b8aa7',
			visitExpiryTimeSeconds: 124400,
			ordersPausedUntil: 'dateTimeHere',
			isOrderingPaused: false,
		},
		{
			id: '1231231243-0234dfsdf-23sdfsdfs',
			type: 'delivery',
			isActive: true,
			ignoreMaxOrders: true,
			deactivatedReason: null,
			prepTime: 15,
			orderInstructions: null,
			venueId: '6432245b-33c2-42fa-abaf-d694034b8aa7',
			visitExpiryTimeSeconds: 124400,
			ordersPausedUntil: 'dateTimeHere',
			isOrderingPaused: false,
		},
	],
	orderAlertEnabled: true,
	orderAlertTime: 3,
	orderAlertEscalationTime: 2,
};

const venue2: IVenue = {
	id: '6432245b-33c2-42fa-abaf-222222222222',
	logoUrl: '',
	name: 'Old street2',
	hasPausedOrders: false,
	serviceTypes: [
		{
			id: '1231231243-0234dfsdf-23sdfsdfs',
			type: 'selfService',
			isActive: true,
			ignoreMaxOrders: true,
			deactivatedReason: null,
			prepTime: 15,
			orderInstructions: null,
			venueId: '6432245b-33c2-42fa-abaf-d694034b8aa7',
			visitExpiryTimeSeconds: 124400,
			ordersPausedUntil: 'dateTimeHere',
			isOrderingPaused: false,
		},
		{
			id: '1231231243-0234dfsdf-23sdfsdfs',
			type: 'delivery',
			isActive: true,
			ignoreMaxOrders: true,
			deactivatedReason: null,
			prepTime: 15,
			orderInstructions: null,
			venueId: '6432245b-33c2-42fa-abaf-d694034b8aa7',
			visitExpiryTimeSeconds: 124400,
			ordersPausedUntil: 'dateTimeHere',
			isOrderingPaused: true,
		},
	],
	orderAlertEnabled: true,
	orderAlertTime: 3,
	orderAlertEscalationTime: 2,
};

const menuId: string = '6432245b-du3d-42fa-abaf-d694034b8aa7';

const venueDates: IVenueDate[] = [
	{
		date: dayjs().format(),
		hasOrders: true,
	},
	{
		date: dayjs().add(1, 'day').format(),
		hasOrders: true,
	},
	{
		date: dayjs().add(2, 'day').format(),
		hasOrders: false,
	},
	{
		date: dayjs().add(3, 'day').format(),
		hasOrders: false,
	},
	{
		date: dayjs().add(4, 'day').format(),
		hasOrders: false,
	},
	{
		date: dayjs().add(5, 'day').format(),
		hasOrders: false,
	},
	{
		date: dayjs().add(6, 'day').format(),
		hasOrders: false,
	},
];

const venueDatesUpdated: IVenueDate[] = [
	{
		date: dayjs().format(),
		hasOrders: true,
	},
	{
		date: dayjs().add(1, 'day').format(),
		hasOrders: true,
	},
	{
		date: dayjs().add(2, 'day').format(),
		hasOrders: false,
	},
	{
		date: dayjs().add(3, 'day').format(),
		hasOrders: true,
	},
	{
		date: dayjs().add(4, 'day').format(),
		hasOrders: false,
	},
	{
		date: dayjs().add(5, 'day').format(),
		hasOrders: false,
	},
	{
		date: dayjs().add(6, 'day').format(),
		hasOrders: false,
	},
];

const venueDateToUpdate: string = dayjs().add(3, 'day').format();

const venueTimeSlots: IVenueTimeslots = {
	collection: {
		prepTime: 30,
		times: [
			{
				day: '0',
				name: 'monday',
				closingTime: '19:00',
				isOpen: true,
				ordersPerSlot: 10,
				slotDuration: 5,
				openingTime: '09:00',
			},
			{
				day: '1',
				name: 'tuesday',
				isOpen: false,
			},
			{
				day: '2',
				name: 'wednesday',
				isOpen: false,
			},
			{
				day: '3',
				name: 'thursday',
				isOpen: false,
			},
			{
				day: '4',
				name: 'friday',
				isOpen: false,
			},
			{
				day: '5',
				name: 'saturday',
				isOpen: false,
			},
			{
				day: '6',
				name: 'sunday',
				isOpen: false,
			},
		],
	},
	delivery: {
		prepTime: 15,
		times: [
			{
				day: '0',
				name: 'monday',
				closingTime: '19:00',
				isOpen: true,
				ordersPerSlot: 10,
				slotDuration: 5,
				openingTime: '09:00',
			},
			{
				day: '1',
				name: 'tuesday',
				closingTime: '19:00',
				isOpen: true,
				ordersPerSlot: 10,
				slotDuration: 5,
				openingTime: '09:00',
			},
			{
				day: '2',
				name: 'wednesday',
				closingTime: '19:00',
				isOpen: true,
				ordersPerSlot: 10,
				slotDuration: 5,
				openingTime: '09:00',
			},
			{
				day: '3',
				name: 'thursday',
				closingTime: '19:00',
				isOpen: true,
				ordersPerSlot: 10,
				slotDuration: 5,
				openingTime: '09:00',
			},
			{
				day: '4',
				name: 'friday',
				closingTime: '19:00',
				isOpen: true,
				ordersPerSlot: 10,
				slotDuration: 5,
				openingTime: '09:00',
			},
			{
				day: '5',
				name: 'saturday',
				closingTime: '19:00',
				isOpen: true,
				ordersPerSlot: 10,
				slotDuration: 5,
				openingTime: '09:00',
			},
			{
				day: '6',
				name: 'sunday',
				closingTime: '19:00',
				isOpen: true,
				ordersPerSlot: 10,
				slotDuration: 5,
				openingTime: '09:00',
			},
		],
	},
	customServices: [
		{
			name: 'Kerbside Ordering',
			iconUrl:
				'https://production-orderpay.s3-eu-west-1.amazonaws.com/venues/images/curbside.png',
			serviceId: '12315125-dvdfbdg23f-dffw32fs',
			slots: {
				prepTime: 30,
				times: [
					{
						day: '0',
						name: 'monday',
						closingTime: '19:00',
						isOpen: true,
						ordersPerSlot: 10,
						slotDuration: 5,
						openingTime: '09:00',
					},
					{
						day: '1',
						name: 'tuesday',
						isOpen: false,
					},
					{
						day: '2',
						name: 'wednesday',
						isOpen: false,
					},
					{
						day: '3',
						name: 'thursday',
						isOpen: false,
					},
					{
						day: '4',
						name: 'friday',
						isOpen: false,
					},
					{
						day: '5',
						name: 'saturday',
						isOpen: false,
					},
					{
						day: '6',
						name: 'sunday',
						isOpen: false,
					},
				],
			},
		},
	],
};

const venueWaitTimes: IVenueServiceWaitTimes = {
	drinksWaitingEnabled: false,
	drinksWaitingTime: 0,
	drinksWaitingUntil: null,
	foodWaitingEnabled: false,
	foodWaitingTime: 0,
	foodWaitingUntil: null,
	id: '',
	venueId: '',
};

const venueOfflineNotificationSettings: IOfflineNotificationSettings = {
	notificationEmail: 'joebob@orderpay.com',
	notificationEnabled: true,
};

const venueOfflineNotificationSettingsChanged: IOfflineNotificationSettings = {
	notificationEmail: 'joeyBobbey@orderpay.com',
	notificationEnabled: false,
};

const pauseByServiceType: IPauseOrdersFormValues = {
	tableService: true,
	delivery: false,
	clickAndCollect: true,
};

const pusherUpdatedPauseServiceTypes: IPusherPausedService[] = [
	{
		type: 'selfService',
		ordersPausedUntil: 'newDateTimeHere',
		isOrderingPaused: true,
	},
	{
		type: 'delivery',
		ordersPausedUntil: 'newDateTimeHere',
		isOrderingPaused: false,
	},
];

const updatedServiceTypes: IServiceType[] = [
	{
		id: '1231231243-0234dfsdf-23sdfsdfs',
		type: 'selfService',
		isActive: true,
		ignoreMaxOrders: true,
		deactivatedReason: null,
		prepTime: 15,
		orderInstructions: null,
		venueId: '6432245b-33c2-42fa-abaf-d694034b8aa7',
		visitExpiryTimeSeconds: 124400,
		ordersPausedUntil: 'newDateTimeHere',
		isOrderingPaused: true,
	},
	{
		id: '1231231243-0234dfsdf-23sdfsdfs',
		type: 'delivery',
		isActive: true,
		ignoreMaxOrders: true,
		deactivatedReason: null,
		prepTime: 15,
		orderInstructions: null,
		venueId: '6432245b-33c2-42fa-abaf-d694034b8aa7',
		visitExpiryTimeSeconds: 124400,
		ordersPausedUntil: 'newDateTimeHere',
		isOrderingPaused: false,
	},
];

const serviceAvailabilitySubmit: IVenueServiceAvailabilitySubmitValues = {
	openingTimes: [
		{
			day: '0',
			name: 'monday',
			closingTime: '23:59',
			isOpen: true,
			ordersPerSlot: 5,
			slotDuration: 10,
			openingTime: '00:00',
		},
		{
			day: '1',
			name: 'tuesday',
			closingTime: '23:59',
			isOpen: false,
			ordersPerSlot: 10,
			slotDuration: 20,
			openingTime: '00:00',
		},
		{
			day: '2',
			name: 'wednesday',
			closingTime: '23:59',
			isOpen: false,
			ordersPerSlot: 0,
			slotDuration: 0,
			openingTime: '00:00',
		},
		{
			day: '3',
			name: 'thursday',
			closingTime: '23:59',
			isOpen: true,
			ordersPerSlot: 0,
			slotDuration: 0,
			openingTime: '00:00',
		},
		{
			day: '4',
			name: 'friday',
			closingTime: '23:59',
			isOpen: true,
			ordersPerSlot: 0,
			slotDuration: 0,
			openingTime: '00:00',
		},
		{
			day: '5',
			name: 'saturday',
			closingTime: '23:59',
			isOpen: true,
			ordersPerSlot: 0,
			slotDuration: 0,
			openingTime: '00:00',
		},
		{
			day: '6',
			name: 'sunday',
			closingTime: '23:59',
			isOpen: true,
			ordersPerSlot: 0,
			slotDuration: 0,
			openingTime: '00:00',
		},
	],
	prepTime: 30,
	ignoreMaxOrders: false,
};

const venueMenus: IVenueMenu[] = [
	{
		id: '1231231243-0234dfsdf-23sdfsdfs',
		name: 'Menu 1',
		pausedUntil: '2123-06-13T14:27:29.378Z',
	},
	{
		id: '1231231243-0234dfsdf-23sdfsdfx',
		name: 'Menu 21',
		pausedUntil: undefined,
	},
	{
		id: '1231231243-0234dfsdf-23sdfsdfk',
		name: 'Menu 21',
		pausedUntil: undefined,
	},
	{
		id: '1231231243-0234dfsdf-23sdfsdfa',
		name: 'Menu 3',
		pausedUntil: undefined,
	},
	{
		id: '1231231243-0234dfsdf-23sdfsdfb',
		name: 'Menu 5 that has an exceeding long name wow',
		pausedUntil: '2123-06-13T14:27:29.378Z',
	},
	{
		id: '1231231243-0234dfsdf-23sdfsdfc',
		name: 'Menu 7',
		pausedUntil: undefined,
	},
	{
		id: '1231231243-0234dfsdf-23sdfsdfd',
		name: 'Menu 8',
		pausedUntil: '2123-06-13T14:27:29.378Z',
	},
];

const venueMenuCategories: IVenueMenuCategory[] = [
	{
		id: '1231231243-0234dfsdf-sadq32asdf',
		name: 'Category 1',
	},
	{
		id: '1231231243-0234dfsdf-axccsadfas',
		name: 'Category 2',
	},
];

const venueConfig: IVenueConfig = {
	orderPurgeTime: '4:00',
};

const venueOpeningTimesApi: IVenueOpeningApi[] = [
	{ day: 2, name: 'wednesday', openingTime: '00:15', closingTime: '23:59' },
	{ day: 5, name: 'saturday', openingTime: '00:10', closingTime: '21:00' },
	{ day: 6, name: 'sunday', openingTime: '11:25', closingTime: '23:20' },
];

const openingTimesFormValues: IVenueOpeningFormValues = {
	monday: { open: false, openingTime: '00:00', closingTime: '23:59' },
	tuesday: { open: false, openingTime: '00:00', closingTime: '23:59' },
	wednesday: {
		open: true,
		openingTime: '00:15',
		closingTime: '23:59',
	},
	thursday: { open: false, openingTime: '00:00', closingTime: '23:59' },
	friday: { open: false, openingTime: '00:00', closingTime: '23:59' },
	saturday: {
		open: true,
		openingTime: '00:10',
		closingTime: '21:00',
	},
	sunday: {
		open: true,
		openingTime: '11:25',
		closingTime: '23:20',
	},
};

const getVenue = {
	response: venue,
};

const getVenues = {
	response: [venue, venue2],
};

const getVenueDates = {
	response: venueDates,
};

const getVenueTimeslots = {
	response: venueTimeSlots,
};

const getVenueWaitingTimes = {
	response: { data: venueWaitTimes },
};

const getVenueOfflineNotificationSettings = {
	response: {
		venues: [
			{
				venueId: venue.id,
				...venueOfflineNotificationSettings,
			},
		],
	},
};

const putVenueOfflineNotificationSettings = {
	request: venueOfflineNotificationSettingsChanged,
	response: venueOfflineNotificationSettingsChanged,
};

const PatchPauseOrdersServiceType = {
	request: pauseByServiceType,
};

const getVenueMenus = {
	response: { data: venueMenus },
};

const patchPauseMenu = {
	response: { data: '' },
};

const getVenueMenuCategories = {
	response: { data: venueMenuCategories },
};

const getVenueConfig = {
	response: { data: venueConfig },
};

const patchVenueConfig = {
	request: { venueConfig },
	response: { data: venueConfig },
};

const patchServiceAvailability = {
	request: { serviceAvailabilitySubmit },
	response: { data: '' },
};

const getVenueOpeningTimes = {
	response: { data: venueOpeningTimesApi },
};

// Regex for get venue
export const venueEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}$/
);

// Regex for get venues
export const venuesEndpointRegex = new RegExp(/venue$/);

// Regex for venue config
export const venueConfigEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/config/
);

// Regex for venue wait times
export const venueWaitTimesEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/waiting-times/
);

// Regex for venue opening times
export const venueOpeningTimesEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/opening-times/
);

// Regex for get venue dates
export const venueDatesEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/dates/
);

// Regex for get venue timeslots
export const venueTimeslotsEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/timeslots/
);

// Regex for pause service type endpoint timeslots
export const venuePauseServiceTypeEpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/updateServiceOrderPauseState/
);

// Regex for venue menus endpoint timeslots
export const venueMenusEpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/menus/
);

// regex for /pauseMenu endpoint
export const pauseMenuEpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/pauseMenu/
);

// Regex for venue menu categories endpoint timeslots
export const venueMenuCategoriesEpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/menus\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/categories/
);

// Regex for get offline notification settings
export const venueOfflineNotificationSettingsRegex = new RegExp(
	/venue\/offline\/settings$/
);

export const mockedVenueEndpoints = (mockHttpClient: MockAdapter) => {
	mockHttpClient.onGet(venueConfigEndpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockVenue.endpoints.getVenueConfig.response.data]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(venueWaitTimesEndpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockVenue.endpoints.getVenueWaitingTimes.response.data]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(venueOpeningTimesEndpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockVenue.endpoints.getVenueOpeningTimes.response.data]);
			}, 1000);
		});
	});

	mockHttpClient.onPatch(venueConfigEndpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(venueDatesEndpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockVenue.endpoints.getVenueDates.response]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(venueTimeslotsEndpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockVenue.endpoints.getVenueTimeslots.response]);
			}, 1000);
		});
	});

	mockHttpClient.onPatch(venuePauseServiceTypeEpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(venueEndpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockVenue.endpoints.getVenue.response]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(venuesEndpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockVenue.endpoints.getVenues.response]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(venueOfflineNotificationSettingsRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([
					200,
					mockVenue.endpoints.getVenueOfflineNotificationSettings.response,
				]);
			}, 1000);
		});
	});

	mockHttpClient.onPut(venueOfflineNotificationSettingsRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([
					200,
					mockVenue.endpoints.getVenueOfflineNotificationSettings.response,
				]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(venueMenuCategoriesEpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockVenue.endpoints.getVenueMenuCategories.response]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(venueMenusEpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockVenue.endpoints.getVenueMenus.response]);
			}, 1000);
		});
	});

	mockHttpClient.onPatch(pauseMenuEpointRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 201 success
				resolve([201, mockVenue.endpoints.patchPauseMenu.response]);
			}, 1000);
		});
	});
};

const mockVenue = {
	data: {
		menuId,
		venue,
		venueDateToUpdate,
		venueDates,
		venueDatesUpdated,
		venueTimeSlots,
		pusherUpdatedPauseServiceTypes,
		updatedServiceTypes,
		venueConfig,
		serviceTypes,
		serviceAvailabilitySubmit,
		openingTimesFormValues,
		venueWaitTimes,
	},
	endpoints: {
		getVenue,
		getVenues,
		getVenueDates,
		getVenueTimeslots,
		getVenueOfflineNotificationSettings,
		putVenueOfflineNotificationSettings,
		PatchPauseOrdersServiceType,
		getVenueMenus,
		patchPauseMenu,
		getVenueMenuCategories,
		getVenueConfig,
		patchVenueConfig,
		patchServiceAvailability,
		getVenueOpeningTimes,
		getVenueWaitingTimes,
	},
};

export default mockVenue;
