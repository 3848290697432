import { useEffect, useState } from 'react';

export default function useViewHeight() {
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);
	useEffect(() => {
		const setWindowViewHeight = () => {
			const currentViewHeight = window.innerHeight * 0.01;
			setViewHeight(currentViewHeight);
		};
		window.addEventListener('resize', setWindowViewHeight);
		return () => window.removeEventListener('resize', setWindowViewHeight);
	}, []);

	return viewHeight;
}
