import styled from 'styled-components';

import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h2 {
		font-size: ${fonts.sizes.larger};
		margin-bottom: 15px;
	}

	p {
		max-width: 380px;
		margin-bottom: 55px;
		text-align: center;
	}

	button {
		width: 200px;
		height: 40px;
		margin-bottom: 30px;
	}

	${mq.mobile`
		padding: 50px 12px 0;
		text-align: center;
	`}
`;

export const LoadingWrapper = styled.div`
	width: 60px;
	height: 60px;
	margin-bottom: 30px;
`;
