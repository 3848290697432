import * as React from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';

// Create styled login wrapper component
const StyledLoginWrapper = styled.main`
	width: 100%;
	min-height: 100vh;
	padding-bottom: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	background: ${brand.black};
`;

/** Renders login wrapper component */
const LoginWrapper: React.FC = ({ children }) => {
	return <StyledLoginWrapper>{children}</StyledLoginWrapper>;
};

export default LoginWrapper;
