import { ReactComponent as additionalNote } from 'assets/svg/additionalNote.svg';
import { ReactComponent as chevron } from 'assets/svg/chevron.svg';
import { ReactComponent as close } from 'assets/svg/close.svg';
import {
	ReactComponent as contactless,
	ReactComponent as clickAndCollect,
} from 'assets/svg/collection.svg';
import { ReactComponent as completedOrders } from 'assets/svg/completedOrders.svg';
import { ReactComponent as customService } from 'assets/svg/customService.svg';
import { ReactComponent as delivery } from 'assets/svg/delivery.svg';
import { ReactComponent as edit } from 'assets/svg/edit.svg';
import { ReactComponent as editBox } from 'assets/svg/editBox.svg';
import { ReactComponent as error } from 'assets/svg/error.svg';
import { ReactComponent as filters } from 'assets/svg/filters.svg';
import { ReactComponent as fullscreen } from 'assets/svg/fullscreen.svg';
import { ReactComponent as info } from 'assets/svg/info.svg';
import { ReactComponent as logo } from 'assets/svg/logo.svg';
import { ReactComponent as logout } from 'assets/svg/logout.svg';
import { ReactComponent as miniTick } from 'assets/svg/miniTick.svg';
import { ReactComponent as minimise } from 'assets/svg/minimise.svg';
import { ReactComponent as minus } from 'assets/svg/minus.svg';
import { ReactComponent as orders } from 'assets/svg/orders.svg';
import { ReactComponent as pause } from 'assets/svg/pause.svg';
import { ReactComponent as phone } from 'assets/svg/phone.svg';
import { ReactComponent as plus } from 'assets/svg/plus.svg';
import { ReactComponent as print } from 'assets/svg/print.svg';
import { ReactComponent as query } from 'assets/svg/query.svg';
import { ReactComponent as refresh } from 'assets/svg/refresh.svg';
import { ReactComponent as search } from 'assets/svg/search.svg';
import { ReactComponent as selfService } from 'assets/svg/selfService.svg';
import { ReactComponent as settings } from 'assets/svg/settings.svg';
import { ReactComponent as stock } from 'assets/svg/stock.svg';
import { ReactComponent as success } from 'assets/svg/success.svg';
import { ReactComponent as successTick } from 'assets/svg/successTick.svg';
import { ReactComponent as tableService } from 'assets/svg/tableService.svg';
import { ReactComponent as terminal } from 'assets/svg/terminal.svg';
import { ReactComponent as tick } from 'assets/svg/tick.svg';
import { ReactComponent as ticketView } from 'assets/svg/ticketView.svg';
import { ReactComponent as undo } from 'assets/svg/undo.svg';
import { ReactComponent as warning } from 'assets/svg/warning.svg';

// Export all icons as default
export default {
	additionalNote,
	chevron,
	clickAndCollect,
	close,
	completedOrders,
	contactless,
	customService,
	delivery,
	edit,
	editBox,
	error,
	filters,
	fullscreen,
	info,
	logo,
	logout,
	minimise,
	miniTick,
	minus,
	orders,
	pause,
	phone,
	plus,
	print,
	query,
	refresh,
	search,
	selfService,
	settings,
	stock,
	success,
	successTick,
	tableService,
	terminal,
	tick,
	ticketView,
	undo,
	warning,
};
