import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import loadingReducer, {
	initialLoadingState,
} from 'components/loading/loading.slice';
import toastReducer from 'components/toast/toast.slice';
// eslint-disable-next-line import/no-cycle
import authReducer from 'modules/auth/auth.slice';
import featuresReducer, {
	initialFeatureTogglesState,
} from 'modules/core/feature-toggles/feature-toggles.slice';
import pusherReducer, {
	initialPusherState,
} from 'modules/core/pusher/pusher.slice';
import storeVersionReducer from 'modules/core/state/store-version/store-version.slice';
import orderReducer, { initialOrderState } from 'modules/orders/order.slice';
import stockReducer, { initialStockState } from 'modules/stock/stock.slice';
import terminalReducer, {
	initialTerminalState,
} from 'modules/terminal/terminal.slice';
import ticketViewReducer, {
	initialTicketViewState,
} from 'modules/ticket-view/ticket-view.slice';
import venueReducer, { initialVenueState } from 'modules/venue/venue.slice';
import webviewReducer from 'modules/webview/webview.slice';

// Define root reducer
const rootReducer = combineReducers({
	auth: authReducer,
	loading: loadingReducer,
	order: orderReducer,
	pusher: pusherReducer,
	storeVersion: storeVersionReducer,
	toast: toastReducer,
	venue: venueReducer,
	stock: stockReducer,
	terminal: terminalReducer,
	ticketView: ticketViewReducer,
	webview: webviewReducer,
	featureToggles: featuresReducer,
});

// Create and export a type for root reducer
export type RootState = ReturnType<typeof rootReducer>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: any, action: AnyAction) => {
	// Create current state varible
	let currentState = state;
	// if logout, set state to undefined (keep storeVersion in state)
	action.type === 'auth/LOGOUT' &&
		(currentState = { storeVersion: state.storeVersion });
	// If new version, keep user logged in but reset other stores
	action.type === 'storeVersion/NEW_VERSION' &&
		(currentState = {
			...state,
			venue: initialVenueState,
			order: initialOrderState,
			pusher: initialPusherState,
			stock: initialStockState,
			terminal: initialTerminalState,
			ticketView: initialTicketViewState,
			loading: initialLoadingState,
			featureToggles: initialFeatureTogglesState,
		});

	// If reset loading states, set events in progress to 0
	action.type === 'loading/RESET_LOADING_STATES' &&
		(currentState = {
			...state,
			auth: {
				...state.auth,
				eventsInProgress: 0,
				refreshToken: {
					...state.auth.refreshToken,
					refreshing: false,
				},
			},
			order: {
				...state.order,
				eventsInProgress: 0,
			},
			venue: {
				...state.venue,
				eventsInProgress: 0,
			},
			stock: {
				...state.stock,
				eventsInProgress: 0,
			},
			terminal: {
				...state.terminal,
				eventsInProgress: 0,
			},
			ticketView: {
				...state.ticketView,
				eventsInProgress: 0,
			},
			loading: {
				...state.loading,
				eventsInProgress: 0,
			},
			pusher: {
				...state.pusher,
				eventsInProgress: 0,
			},
		});
	// return root reducer
	return rootReducer(currentState, action);
};
