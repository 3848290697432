import { FieldProps } from 'formik';
import { rgba } from 'polished';
import React, { FunctionComponent, ReactHTMLElement } from 'react';
// eslint-disable-next-line import/no-named-default
import { default as ReactSelect, Theme } from 'react-select';
import styled from 'styled-components';
import { isArray } from 'util';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import { intl } from 'modules/core/i18n/i18n.config';

export interface IOption<T = string | boolean | number | null> {
	label?: string;
	value?: T;
}

// Interface for component props
interface ISelectProps extends ReactHTMLElement<HTMLSelectElement> {
	label?: string;
	handleChangeEvent?: Function;
	hideInitialValue?: boolean;
	initialValue?: string;
	isClearable?: boolean;
	isDisabled?: boolean;
	isMulti?: boolean;
	placeholder?: string;
	selectOptions: IOption[];
	value?: IOption | IOption[];
	formElementVariant?: 'header';
	size?: 'sm';
	testId?: string;
	className?: string;
	disableErrorMessage?: boolean;
}

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

// Create styled select component
const StyledSelect = styled(ReactSelect)<ISelectProps>`
	width: 100%;
	border: 1px solid ${brand.borders};
	border-radius: 10px;
	font-size: ${fonts.sizes.standard};

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}

	[class$='control'] {
		min-height: ${({ size }) => (size === 'sm' ? '35px' : '40px')};
		border: none;
		border-radius: 10px;
		box-shadow: none;
	}

	.react-select__option--is-selected {
		font-weight: ${fonts.weights.medium};
	}

	.react-select__multi-value,
	.react-select__multi-value__label {
		border-radius: 5px;
		font-size: 100%;
	}

	.react-select__indicator {
		color: ${brand.primary};
		padding: 6px 8px;
	}

	.react-select__indicator-separator {
		display: none;
	}
`;

/** Renders Select component */
const Select: FunctionComponent<FieldProps & ISelectProps> = ({
	field,
	form,
	label,
	isClearable,
	isDisabled,
	isMulti,
	placeholder,
	selectOptions,
	handleChangeEvent,
	formElementVariant,
	value,
	size,
	testId,
	className,
	disableErrorMessage = false,
}) => {
	const { touched, errors } = form;
	const { name } = field;

	const isTouched = touched?.[field.name] || false;
	const hasErrors = errors?.[field.name] || false;

	return (
		<FormElement testId={testId} className={className}>
			{label && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
			<StyledSelect
				name={name}
				size={size}
				classNamePrefix="react-select"
				className={isTouched && hasErrors && 'has-error'}
				isMulti={isMulti}
				isClearable={isClearable}
				isDisabled={isDisabled}
				aria-label={`select-${field.name}`}
				options={selectOptions}
				placeholder={
					placeholder || intl.formatMessage({ id: 'form.select.default' })
				}
				onChange={(option: IOption | IOption[]) => {
					// Update formik field
					form.setFieldValue(
						field.name,
						isArray(option) ? option : option?.value
					);
					form.setFieldTouched(field.name, true);
					// Run custom change event function
					handleChangeEvent &&
						handleChangeEvent({
							fieldValue: Array.isArray(option) ? option : option?.value,
							form,
						});
				}}
				theme={(theme: Theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: rgba(brand.primary, 0.25),
						primary75: rgba(brand.primary, 0.75),
						primary50: rgba(brand.primary, 0.5),
						primary: brand.primary,
					},
				})}
				value={value ?? ''}
			/>
			{isTouched && hasErrors && !disableErrorMessage && (
				<FieldError ariaLabel={`${field.name}-error`}>{hasErrors}</FieldError>
			)}
		</FormElement>
	);
};

export default Select;
