import { Formik, Field, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

import {
	StyledHeader,
	StyledActions,
	StyledColumn,
	StyledDayHeaders,
	StyledDay,
	StyledDayFields,
	StyledFieldGroup,
	StyledForm,
	StyledSwitchWrap,
	StyledTimePickerInput,
	StyledActionsMobile,
} from './opening-times-form.styles';

import Button from 'components/button/button.component';
import FieldError from 'components/field-error/field-error.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenueOpeningTimes,
	putVenueOpeningTimes,
} from 'modules/venue/venue.slice';
import {
	IVenueOpeningDay,
	IVenueOpeningFormValues,
} from 'modules/venue/venue.types';

// Opening times form
const OpeningTimesForm: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useDispatch();
	const intl = useIntl();

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);
	const [isEditingForm, setIsEditingForm] = useState(false);

	const { selectedVenue } = useSelector((state: RootState) => state.venue);
	const { openingTimes: activeVenueOpening } = useSelector(
		(state: RootState) => state.venue
	);

	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	useEffect(() => {
		// Get venue data by venue id
		const getVenueData = async (id: string) => {
			id && (await dispatch(getVenueOpeningTimes(id)));
		};
		getVenueData(selectedVenue.id);

		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => window.removeEventListener('resize', getSetViewHeight);
	}, [selectedVenue.id, dispatch]);

	// Default opening times field values
	const defaultOpening: IVenueOpeningDay = {
		open: false,
		openingTime: '00:00',
		closingTime: '23:59',
	};

	// Initial form values
	const initialValues: IVenueOpeningFormValues = {
		monday: activeVenueOpening?.monday || defaultOpening,
		tuesday: activeVenueOpening?.tuesday || defaultOpening,
		wednesday: activeVenueOpening?.wednesday || defaultOpening,
		thursday: activeVenueOpening?.thursday || defaultOpening,
		friday: activeVenueOpening?.friday || defaultOpening,
		saturday: activeVenueOpening?.saturday || defaultOpening,
		sunday: activeVenueOpening?.sunday || defaultOpening,
	};

	// Handle form submission
	const handleSubmit = async (
		values: IVenueOpeningFormValues,
		{ setSubmitting }: FormikHelpers<IVenueOpeningFormValues>
	) => {
		// Update venue
		const response = await dispatch(
			putVenueOpeningTimes(selectedVenue.id, values)
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Set formik submission state to false
		setSubmitting(false);

		// Set is editing form to false
		setIsEditingForm(false);
		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Show success alert
		await fireDialog({
			title: intl.formatMessage({
				id: 'alerts.success.title',
			}),
			showConfirmButton: false,
			timer: 3000,
			icon: 'success',
			iconHtml: `<svg width="103" height="103" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M77.5588 36.8739L43.3628 71.0699C42.6418 71.7909 41.7148 72.0999 40.6848 72.0999C39.7578 72.0999 38.7278 71.7909 38.0068 71.0699L25.4408 58.5039C24.1018 57.1649 24.1018 54.8989 25.4408 53.5599C26.1618 52.8389 26.9858 52.5299 27.9128 52.5299C28.8398 52.5299 29.7668 52.8389 30.3848 53.5599L40.6848 63.8599L72.6148 31.9299C73.3358 31.2089 74.1598 30.8999 75.0868 30.8999C76.0138 30.8999 76.9408 31.2089 77.5588 31.9299C78.8978 33.2689 78.8978 35.5349 77.5588 36.8739Z" fill="currentColor"/>
			<circle cx="51.5" cy="51.5" r="48" stroke="currentColor" stroke-width="7"/>
			</svg>`,
			customClass: {
				icon: 'swal-custom-icon-html',
			},
		});
	};

	const onCancelEdit = async (isDirty: boolean, resetForm: () => void) => {
		// If the form is dirty, prompt user
		if (isDirty && !formSubmission) {
			const { value } = await fireDialog({
				title: intl.formatMessage({
					id: 'settings.openingTimesForm.cancelEdit.title',
				}),
				text: intl.formatMessage({
					id: 'settings.openingTimesForm.cancelEdit.text',
				}),
				showCancelButton: true,
			});

			// if user cancels do nothing
			if (!value) {
				return;
			}

			// reset form
			resetForm();
		}

		setIsEditingForm(false);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ values, dirty, errors, isSubmitting, resetForm }) => (
				<StyledForm viewHeight={viewHeight}>
					<Prompt when={dirty && !formSubmission} message="" />
					<StyledFieldGroup>
						<StyledColumn>
							<StyledHeader>
								<h2>
									<FormattedMessage id="settings.openingTimes.title" />
								</h2>
								<StyledActions>
									{isEditingForm ? (
										<>
											<Button
												type="button"
												variant="secondary"
												onClick={() => onCancelEdit(dirty, resetForm)}
												ariaLabel="cancel-submit-button"
												className="mod-desktop-only"
											>
												<FormattedMessage id="form.button.cancel" />
											</Button>
											<Button
												type="submit"
												disabled={isSubmitting}
												ariaLabel="submit-button"
												className="mod-desktop-only"
											>
												<FormattedMessage id="form.button.save" />
											</Button>
										</>
									) : (
										<Button
											type="button"
											onClick={() => {
												setIsEditingForm(true);
											}}
											ariaLabel="edit-form-button"
											variant="tertiary"
										>
											<FormattedMessage id="form.button.edit" />
										</Button>
									)}
								</StyledActions>
							</StyledHeader>
							<StyledDayHeaders>
								<StyledDayFields>
									<div>
										<FormattedMessage id="settings.openingTimes.header.day" />
									</div>
									<div>
										<FormattedMessage id="settings.openingTimes.header.status" />
									</div>
									<div>
										<FormattedMessage id="settings.openingTimes.header.dayStart" />
									</div>
									<div>
										<FormattedMessage id="settings.openingTimes.header.dayEnd" />
									</div>
								</StyledDayFields>
							</StyledDayHeaders>
							{[
								'monday',
								'tuesday',
								'wednesday',
								'thursday',
								'friday',
								'saturday',
								'sunday',
							].map((day) => (
								<StyledDay key={day}>
									<StyledDayFields>
										<div
											className={`${isEditingForm ? '' : 'mod-display-value'}`}
										>
											<h3>{day}</h3>
										</div>
										{isEditingForm ? (
											<>
												<StyledSwitchWrap>
													<Field
														component={Switch}
														name={`${day}.open`}
														onText={intl.formatMessage({
															id: 'form.fields.venueOpen.on',
														})}
														offText={intl.formatMessage({
															id: 'form.fields.venueOpen.off',
														})}
													/>
												</StyledSwitchWrap>
												<Field
													component={StyledTimePickerInput}
													name={`${day}.openingTime`}
													isDisabled={!values[day].open}
													label={intl.formatMessage({
														id: 'form.fields.dayStart.label',
													})}
												/>
												<Field
													component={StyledTimePickerInput}
													name={`${day}.closingTime`}
													isDisabled={!values[day].open}
													label={intl.formatMessage({
														id: 'form.fields.dayEnd.label',
													})}
												/>
											</>
										) : (
											<>
												<div
													className={`mod-display-value ${
														values[day].open ? 'mod-open' : 'mod-closed'
													}`}
												>
													{values[day].open ? 'Open' : 'Closed'}
												</div>
												<div
													className={`mod-display-value ${
														values[day].open ? '' : 'mod-disabled'
													}`}
												>
													<div className="mod-mobile-label">
														<FormattedMessage id="form.fields.dayStart.label" />
													</div>
													{values[day].openingTime
														? values[day].openingTime
														: '-'}
												</div>
												<div
													className={`mod-display-value ${
														values[day].open ? '' : 'mod-disabled'
													}`}
												>
													<div className="mod-mobile-label">
														<FormattedMessage id="form.fields.dayEnd.label" />
													</div>
													{values[day].closingTime
														? values[day].closingTime
														: '-'}
												</div>
											</>
										)}
									</StyledDayFields>
									{!!errors[day] && !!values[`${day}`].open && (
										<FieldError ariaLabel={`${day}.open-error`}>
											<FormattedMessage id="form.fields.venueTimes.empty" />
										</FieldError>
									)}
								</StyledDay>
							))}
						</StyledColumn>
					</StyledFieldGroup>
					{isEditingForm && (
						<StyledActionsMobile>
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								<FormattedMessage id="form.button.save" />
							</Button>
							<Button
								type="button"
								variant="secondary"
								onClick={() => onCancelEdit(dirty, resetForm)}
								ariaLabel="cancel-submit-button"
							>
								<FormattedMessage id="form.button.cancel" />
							</Button>
						</StyledActionsMobile>
					)}
				</StyledForm>
			)}
		</Formik>
	);
};

export default OpeningTimesForm;
