import { rgba } from 'polished';
import { css } from 'styled-components';

import brand from '../variables/brand';
import fonts from '../variables/fonts';

/** Global structure styles */
const globalStructure = css`
	html {
		font-family: ${fonts.family};
		font-size: ${fonts.sizes.base};

		body {
			line-height: ${fonts.line_height.base};
			font-weight: ${fonts.weights.default};
			background: ${brand.body};
			color: ${brand.text};
			font-size: ${fonts.sizes.standard};
			margin: 0;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-family: ${fonts.family};

			input,
			select,
			textarea,
			button {
				font-family: ${fonts.family};
			}

			.fullscreen-enabled > * {
				background: ${brand.body};
			}
		}

		* {
			box-sizing: border-box;
		}
	}

	::selection {
		background: ${rgba(brand.primary, 0.75)};
	}

	::-moz-selection {
		background: ${rgba(brand.primary, 0.75)};
	}

	#freshworks-container iframe {
		right: 10px !important;
		bottom: 0px !important;
	}
`;

export default globalStructure;
