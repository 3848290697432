import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';

export const Container = styled.div<{ viewHeight: number }>`
	height: calc(${({ viewHeight }) => viewHeight}px * 100 - 200px);
	display: flex;
	border-radius: 8px;
	font-size: 15px;
	background: ${brand.white};
	flex-direction: column;
	overflow: auto;

	${({ viewHeight }) => mq.mobile`
		height: calc(${viewHeight}px * 100 - 250px);
	`}
`;

export const StepWrapper = styled.div`
	height: 100%;
	padding: 40px;

	${mq.mobile`
		padding: 12px;
		padding: 0;
	`}
`;
