import dayjs from './dayjs.helper';

import { IOrder } from 'modules/orders/order.types';

/** check if order is within prep time */
export const isOrderInPrepTime = (orderToCheck: IOrder): boolean => {
	// If not click and collect, use the ordered at date
	if (orderToCheck.type !== 'clickAndCollect') {
		return dayjs()
			.utc()
			.isAfter(
				dayjs(orderToCheck.orderedAt)
					.utc()
					.add(orderToCheck?.alertConfig?.prepTime!, 'minute')
			);
	}
	// else use collectAt date
	return dayjs()
		.utc()
		.isAfter(
			dayjs(orderToCheck.collectAt!)
				.utc()
				.subtract(orderToCheck?.alertConfig?.prepTime!, 'minute')
		);
};

/** Function to check if order is within alert time */
export const isOrderInAlert = (orderToCheck: IOrder): boolean => {
	// If not click and collect, use the ordered at date
	if (orderToCheck.type !== 'clickAndCollect') {
		return dayjs()
			.utc()
			.isAfter(
				dayjs(orderToCheck.orderedAt)
					.utc()
					.add(orderToCheck?.alertConfig?.prepTime!, 'minute')
					.add(orderToCheck?.alertConfig?.initialAlertSeconds!, 'second')
			);
	}

	// else use collectAt date
	return dayjs()
		.utc()
		.isAfter(
			dayjs(orderToCheck.collectAt!)
				.utc()
				.subtract(orderToCheck?.alertConfig?.prepTime!, 'minute')
				.add(orderToCheck?.alertConfig?.initialAlertSeconds!, 'second')
		);
};

/** Function to check if order is within escalation time */
export const isOrderInEscalation = (orderToCheck: IOrder): boolean => {
	// If not click and collect, use the ordered at date
	if (orderToCheck.type !== 'clickAndCollect') {
		return dayjs()
			.utc()
			.isAfter(
				dayjs(orderToCheck.orderedAt)
					.utc()
					.add(orderToCheck?.alertConfig?.prepTime!, 'minute')
					.add(orderToCheck?.alertConfig?.initialAlertSeconds!, 'second')
					.add(orderToCheck?.alertConfig?.escalationAlertSeconds!, 'second')
			);
	}

	// else use collectAt date
	return dayjs()
		.utc()
		.isAfter(
			dayjs(orderToCheck.collectAt!)
				.utc()
				.subtract(orderToCheck?.alertConfig?.prepTime!, 'minute')
				.add(orderToCheck?.alertConfig?.initialAlertSeconds!, 'second')
				.add(orderToCheck?.alertConfig?.escalationAlertSeconds!, 'second')
		);
};
