import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { intl } from 'modules/core/i18n/i18n.config';

interface IComponentProps {
	isRefund?: boolean;
	refundTotal?: number;
	total: {
		units: number;
		currencyCode: string;
	};
}

const StyledListOrderTotals = styled.ul`
	margin: 0 23px 0 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	${mq.mobile`
		flex-direction: row;
		justify-content: flex-end;
		width: 100%;
		margin: 0;
	`}
`;

const StyledTotalItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	.sub-title {
		font-size: ${fonts.sizes.med};
		font-weight: ${fonts.weights.light};
	}

	.sub-price {
		text-align: right;
		min-width: 80px;
		font-size: ${fonts.sizes.larger};
		font-weight: ${fonts.weights.medium};
	}

	${mq.mobile`
		font-size: ${fonts.sizes.large};
		&:last-child {
			margin-left: 20px;
		}
	`}
`;

/** Renders order totals list component */
const OrderTotals: React.FC<IComponentProps> = ({
	isRefund,
	refundTotal,
	total,
}) => {
	return (
		<StyledListOrderTotals>
			<StyledTotalItem>
				<div className="sub-title">
					<FormattedMessage
						id={`${
							isRefund ? 'orders.totals.refundAmount' : 'orders.totals.total'
						}`}
					/>
				</div>
				<div className="sub-price">
					{isRefund &&
						(refundTotal
							? intl.formatNumber(refundTotal / 100, {
								style: 'currency',
								currency: total.currencyCode,
							  })
							: '£0.00')}
					{!isRefund &&
						intl.formatNumber(total.units / 100, {
							style: 'currency',
							currency: total.currencyCode,
						})}
				</div>
			</StyledTotalItem>
		</StyledListOrderTotals>
	);
};

export default OrderTotals;
