import React, { ComponentType, useState, useEffect } from 'react';
import styled from 'styled-components';

import Nav from './nav.component';

import { mq } from 'assets/styles/variables/responsive';
import structure from 'assets/styles/variables/structure';

interface IStyledComponent {
	viewHeight: number;
}

const StyledWrapper = styled.div<IStyledComponent>`
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	overflow: hidden;

	height: calc(${({ viewHeight }) => viewHeight}px * 100);

	h1 {
		font-size: 4em;
	}
`;

const StyledComponentWrapper = styled.div`
	width: 97%;
	padding: 36px 0 0 96px;

	${mq.mobile`
		width: 100%;
		padding: ${structure.nav_mobile_height} 0 0;
	`}
`;

/** Wraps provided component with navigation component */
const withNav = (ComponentToWrap: ComponentType) => () => {
	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	useEffect(() => {
		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => window.removeEventListener('resize', getSetViewHeight);
	}, []);

	return (
		<StyledWrapper viewHeight={viewHeight}>
			<Nav />
			<StyledComponentWrapper>
				<ComponentToWrap />
			</StyledComponentWrapper>
		</StyledWrapper>
	);
};

export default withNav;
