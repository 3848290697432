import { rgba } from 'polished';
import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import Icon from '../icons/icon.component';
import OrderIcon from '../order-icon/order-icon.component';
import StatusPill from '../status-pill/status-pill.component';
import OrderCardAlert from './order-card-alert/order-card-alert.component';

import { ETransitionState, ITransition } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import OrderDetailsModal from 'components/order-details-modal/order-details-modal.component';
import dayjs from 'helpers/dayjs.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { printOrder, updateOrderStatus } from 'modules/orders/order.slice';
import { IOrder } from 'modules/orders/order.types';

interface IComponentProps extends IOrder {
	className?: string;
	printStatus?: string | null;
}

interface IStyledComponentProps extends ITransition {
	className?: string;
}

const orderCardTransition = (state: keyof typeof ETransitionState) => {
	switch (state) {
	case 'entering':
		return css`
				max-height: 0;
				opacity: 0;
				overflow: hidden;
			`;
	case 'entered':
		return css`
				opacity: 1;
				overflow: visible;

				${mq.tabletPortrait`
					max-height: 300px;
				`}
			`;
	case 'exiting':
		return css`
				max-height: 0;
				opacity: 0;
				overflow: hidden;
			`;
	}

	return css`
		max-height: 0;
		opacity: 0;
		overflow: hidden;
	`;
};

const StyledOrderCard = styled.div<IStyledComponentProps>`
	width: 100%;
	background: ${brand.white};
	padding: 12px;
	box-shadow: 4px 4px 8px ${rgba(brand.black, 0.1)};
	display: block;
	flex-direction: column;
	transition: 0.3s;

	.status-pill-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 11px 0;

		> div:first-child {
			margin-right: 7px;
		}

		${mq.tabletPortrait`
			flex-direction: row;
		`}
	}

	${({ state }) => orderCardTransition(state)}

	${mq.tabletPortrait`
		box-sizing: border-box;
	`}
`;

const StyledHeader = styled.div`
	display: flex;
	margin-bottom: 13px;
	align-items: center;
	justify-content: space-between;
`;

const StyledInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-left: 13px;

	h3 {
		font-weight: ${fonts.weights.light};
		font-size: ${fonts.sizes.large};
		margin: 0 0 2px;
	}

	h4 {
		font-weight: ${fonts.weights.medium};
		font-size: ${fonts.sizes.med};
		margin: 0;
	}

	.sub-time {
		display: flex;
		align-items: center;

		.sub-extra-detail {
			text-transform: uppercase;

			h4 {
				font-weight: ${fonts.weights.bold};
			}
		}

		.sub-divider {
			width: 1px;
			height: 14px;
			background: ${brand.black};
			margin: 0 10px;
			content: '';
			display: block;
		}
	}
`;

const StyledExtraDetailWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const StyledButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	button:last-child {
		margin-left: 10px;
	}
`;

const StyledButton = styled(Button)`
	flex-basis: calc(50% - 5px);
	padding: 11px;
`;

const StyledStatusPill = styled(StatusPill)`
	align-self: flex-start;
	margin-bottom: 3px;
`;

const StyledIconList = styled.div`
	align-self: flex-end;
	display: flex;
	flex-direction: row;
	gap: 13px;
`;

/** Renders order card component */
const OrderCard: React.FC<IComponentProps> = (order) => {
	const dispatch = useDispatch();

	const {
		id,
		type,
		orderedAt,
		collectAt,
		customer,
		status,
		className,
		notes,
		alertConfig,
		customerNotes,
		printStatus,
	} = order;

	const { name, allergens } = customer;

	const alertConfigRef = useRef(order.alertConfig);
	alertConfigRef.current = alertConfig;

	let allergenList: string = '';

	!!allergens &&
		allergens?.length > 0 &&
		allergens.forEach((allergen, index) => {
			if (index === 0) {
				allergenList += allergen.name;
			} else {
				allergenList += ` | ${allergen.name}`;
			}
		});

	// Reference to DOM element that needs to transition
	const nodeRef = useRef(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [animate, setAnimate] = useState(false);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const orderDate = collectAt
		? dayjs(collectAt).tz('Europe/London')
		: dayjs(orderedAt).tz('Europe/London');

	// Check if should have status pill
	const hasStatusPill: boolean =
		!!allergens ||
		!!notes ||
		Boolean(customerNotes && customerNotes.length > 0);

	useEffect(() => {
		setAnimate(true);
	}, [orderedAt]);

	return (
		<Transition
			in={animate}
			timeout={{
				enter: 300,
				exit: 200,
			}}
			nodeRef={nodeRef}
		>
			{(state) => {
				return (
					<OrderCardAlert
						alert={alertConfigRef.current?.isOrderInAlert || false}
						escalation={alertConfigRef.current?.isOrderInEscalation || false}
					>
						<StyledOrderCard state={state} className={className}>
							<StyledHeader>
								<StyledInfo>
									<h3 data-private>
										{name || intl.formatMessage({ id: 'order.unkownName' })}
									</h3>
									<div className="sub-time">
										{order.type === 'tableService' && (
											<StyledExtraDetailWrapper>
												<div className="sub-extra-detail">
													<h4>
														<FormattedMessage id="orderCard.table" />
														&nbsp;
														{order.tableNumber}
													</h4>
												</div>
												<div className="sub-divider" />
											</StyledExtraDetailWrapper>
										)}
										{order.type === 'customService' &&
											order.confirmationLineItems
												?.filter((item) => item.display)
												.map((item) => (
													<StyledExtraDetailWrapper key={item.value}>
														<div className="sub-extra-detail">
															<h4>{item.value}</h4>
														</div>
														<div className="sub-divider" />
													</StyledExtraDetailWrapper>
												))}
										<h4>{orderDate.format('HH:mm')}</h4>
									</div>
								</StyledInfo>
								<StyledIconList>
									{printStatus && (
										<div
											data-testid={
												printStatus === 'Complete' ? 'print' : 'print-bold'
											}
										>
											<Icon
												name="print"
												onClick={(event) => {
													dispatch(printOrder(id, printStatus === 'Complete'));
												}}
												width={35}
												height={35}
												opacity={printStatus === 'Complete' ? 0.3 : 1}
											/>
										</div>
									)}
									<OrderIcon
										variant={type}
										customIcon={order.customService?.imageUrl}
									/>
								</StyledIconList>
							</StyledHeader>
							{hasStatusPill && (
								<div className="status-pill-wrapper">
									{!!allergens && allergens.length > 0 && (
										<StyledStatusPill
											type="warning"
											backgroundColour="order_allergy"
										>
											{allergenList}
										</StyledStatusPill>
									)}
									{!!notes && (
										<StyledStatusPill
											type="query"
											backgroundColour="order_query"
										/>
									)}
									{customerNotes && customerNotes.length > 0 && (
										<StyledStatusPill
											backgroundColour="note_background"
											textColour="text"
											type="info"
										>
											<FormattedMessage id="statusPill.customerNote.text" />
										</StyledStatusPill>
									)}
								</div>
							)}
							<StyledButtons>
								<StyledButton onClick={() => toggleModal()} variant="secondary">
									<FormattedMessage id="orders.order_card.view" />
								</StyledButton>
								{status === 'venueAccepted' && (
									<StyledButton
										onClick={() => {
											setAnimate(false);
											setTimeout(() => {
												dispatch(updateOrderStatus(id, 'orderBeingPrepared'));
											}, 200);
										}}
									>
										<FormattedMessage id="orders.button.inProgress" />
									</StyledButton>
								)}
								{status === 'orderBeingPrepared' && (
									<StyledButton
										onClick={() => {
											setAnimate(false);
											setTimeout(() => {
												dispatch(updateOrderStatus(id, 'pendingPickup'));
											}, 200);
										}}
									>
										<FormattedMessage id="orders.order_card.ready" />
									</StyledButton>
								)}
								{status === 'pendingPickup' && (
									<StyledButton
										onClick={() => {
											setAnimate(false);
											setTimeout(() => {
												dispatch(updateOrderStatus(id, 'completed'));
											}, 200);
										}}
									>
										<FormattedMessage id="orders.order_card.completed" />
									</StyledButton>
								)}
							</StyledButtons>
							<OrderDetailsModal
								{...order}
								printStatus={printStatus}
								isOpen={isModalOpen}
								toggleModal={toggleModal}
							/>
						</StyledOrderCard>
					</OrderCardAlert>
				);
			}}
		</Transition>
	);
};

export default OrderCard;
