import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import ServiceAvailability from './service-availability/service-availability.component';

import { mq } from 'assets/styles/variables/responsive';
import { RootState } from 'modules/core/state/root.reducer';
import { getVenueTimeslots } from 'modules/venue/venue.slice';

const AvailablityWrapper = styled.div<{ viewHeight: number }>`
	margin: 0 0 30px;
	overflow: scroll;
	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}

	height: calc(${({ viewHeight }) => viewHeight}px * 100 - 113px);

	${({ viewHeight }) => mq.mobile`
		height: calc((${viewHeight}px * 100) - 175px);
		margin: 0 1% 30px;
	`}
`;

/** Renders service availability lsit component */
const ServiceAvailabilityList: React.FC = () => {
	// Get redux dispatch
	const dispatch = useDispatch();
	const { selectedVenue } = useSelector((state: RootState) => state.venue);
	const { timeslots } = useSelector((state: RootState) => state.venue);

	const [editingService, setEditingService] = useState<string | null>(null);

	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	useEffect(() => {
		const getData = async () => {
			// If we have a venue ID
			if (selectedVenue.id) {
				// Get timeslots for venue
				await dispatch(getVenueTimeslots(selectedVenue.id));
			}
		};

		getData();
		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => window.removeEventListener('resize', getSetViewHeight);
	}, [dispatch, selectedVenue]);

	return (
		<AvailablityWrapper viewHeight={viewHeight} data-testid="availability-list">
			{timeslots?.collection && timeslots.collection.times?.length > 0 && (
				<ServiceAvailability
					orderType="clickAndCollect"
					prepTime={timeslots.collection.prepTime}
					timeslots={timeslots.collection.times}
					editing={editingService === 'clickAndCollect'}
					setEditing={(val: boolean) => {
						setEditingService(val ? 'clickAndCollect' : null);
					}}
					canEdit={!editingService}
				/>
			)}
			{timeslots?.delivery && timeslots.delivery.times?.length > 0 && (
				<ServiceAvailability
					orderType="delivery"
					prepTime={timeslots.delivery.prepTime}
					timeslots={timeslots.delivery.times}
					editing={editingService === 'delivery'}
					setEditing={(val: boolean) => {
						setEditingService(val ? 'delivery' : null);
					}}
					canEdit={!editingService}
				/>
			)}
			{timeslots?.tableService && timeslots.tableService.times?.length > 0 && (
				<ServiceAvailability
					orderType="tableService"
					prepTime={timeslots.tableService.prepTime}
					timeslots={timeslots.tableService.times}
					editing={editingService === 'tableService'}
					setEditing={(val: boolean) => {
						setEditingService(val ? 'tableService' : null);
					}}
					canEdit={!editingService}
				/>
			)}
			{!!timeslots.customServices?.length &&
				timeslots.customServices?.map((service) => {
					const serviceDetails = {
						serviceId: service.serviceId,
						name: service.name,
						iconUrl: service.iconUrl,
					};
					return (
						<ServiceAvailability
							key={service.serviceId}
							customServiceDetails={serviceDetails}
							orderType="customService"
							prepTime={service.slots.prepTime}
							timeslots={service.slots.times}
							editing={service.serviceId === editingService}
							setEditing={(val: boolean) => {
								setEditingService(val ? service.serviceId : null);
							}}
							canEdit={!editingService}
						/>
					);
				})}
		</AvailablityWrapper>
	);
};

export default ServiceAvailabilityList;
