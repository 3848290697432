import { Severity } from '@sentry/react';
import { Dayjs } from 'dayjs';
import React, { FunctionComponent, useEffect, useState } from 'react';

import dayjs from 'helpers/dayjs.helper';
import errorLogger from 'modules/core/error/helpers/error-logger.helper';

/** Renders Page Refresh component */
const PageRefreshComponent: FunctionComponent = () => {
	// Store current date in local state
	const [initialDate] = useState<Dayjs>(dayjs().utc());

	useEffect(() => {
		// Check for date change
		const checkDateChange = setInterval(() => {
			// If current date is after the next day at 4am
			if (
				dayjs()
					.utc()
					.isAfter(initialDate.add(1, 'day').minute(0).hour(3).second(0))
			) {
				// Log within sentry
				errorLogger({
					message: 'Day change refresh at 4am',
					level: Severity.Info,
					extra: {},
				});
				// Refresh the page
				window.location.reload();
			}
		}, 100000);

		// Clear interval on component teardown
		return () => clearInterval(checkDateChange);
	});

	return <div data-testid="page-refresh-component" />;
};

export default PageRefreshComponent;
