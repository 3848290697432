import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import OrderDetailsItem from '../order-details-item/order-details-item.component';

import { ETransitionState, ITransition } from 'app.types';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { IOrderItem, EOrderStatus } from 'modules/orders/order.types';

interface IComponentProps {
	title: string;
	orderId: string;
	items: IOrderItem[];
	refundActive?: boolean;
	isVisible: boolean;
	orderStatus: keyof typeof EOrderStatus;
	isRefunded?: boolean;
}

const orderItemTransition = (state: keyof typeof ETransitionState) => {
	switch (state) {
	case 'entering':
		return css`
				margin: 0;
				transition: 0.2s;
				opacity: 0;
			`;
	case 'entered':
		return css`
				margin: 0 0 23px;
				transition: 0.2s;
				opacity: 1;
			`;
	case 'exiting':
		return css`
				margin: 0;
				transition: 0.2s;
				opacity: 0;
			`;
	}

	return css`
		margin: 0;
		transition: 0.2s;
		opacity: 0;
		max-height: 0;
		overflow: hidden;
	`;
};

const StyledOrderDetailsItemStatus = styled.div<ITransition>`
	${mq.mobile`
		margin: 0 0 15px;
	`}

	${({ state }) => orderItemTransition(state)}
`;

const StyledHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 16px 0 8px;

	h3 {
		font-size: ${fonts.sizes.semiLarge};
		font-weight: ${fonts.weights.medium};
		margin: 0;
	}

	.sub-refund {
		opacity: 0;
		transition: 0.2s;
		width: 150px;
		text-align: center;

		${mq.mobile`
			width: 106px;
		`}
	}
`;

const StyledOrderDetailsItemList = styled.ul`
	margin: 0;
	padding: 0;
`;

/** Renders order details item status component */
const OrderDetailsItemStatus: React.FC<IComponentProps> = ({
	title,
	items,
	orderId,
	refundActive,
	isVisible,
	orderStatus,
	isRefunded,
}) => {
	const orderItems = items.map((item) => (
		<OrderDetailsItem
			key={item.id}
			item={item}
			orderId={orderId}
			refundActive={refundActive}
			orderStatus={orderStatus}
			isRefunded={isRefunded}
		/>
	));

	// Reference to DOM element that needs to transition
	const nodeRef = React.useRef(null);

	return (
		<div>
			<Transition in={isVisible} timeout={200} nodeRef={nodeRef}>
				{(state) => (
					<StyledOrderDetailsItemStatus state={state}>
						<StyledHeader>
							<h3>{title}</h3>
							<div className="sub-refund">
								<h3>
									<FormattedMessage id="order_details.item_status.refund" />
								</h3>
							</div>
						</StyledHeader>
						<StyledOrderDetailsItemList>
							{orderItems}
						</StyledOrderDetailsItemList>
					</StyledOrderDetailsItemStatus>
				)}
			</Transition>
		</div>
	);
};

export default OrderDetailsItemStatus;
