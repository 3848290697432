import Select from 'react-dropdown-select';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

export const StyledSelect = styled(Select)`
	&.venue-dropdown {
		background: white;
		border-radius: 5px;
		font-weight: ${fonts.weights.regular};
		color: ${brand.text};
		min-height: 28px;

		.react-dropdown-select-dropdown {
			box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
			border-radius: 5px;
		}

		.react-dropdown-select-dropdown-handle {
			color: ${brand.primary};
		}
	}
`;
