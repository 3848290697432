import dayjs from 'dayjs';
import { useEffect } from 'react';

import {
	IVenueServiceWaitTimes,
	WaitingEnabled,
	WaitingUntil,
} from 'modules/venue/venue.types';

interface HandleWaitingTimesOptions {
	isEnabled: WaitingEnabled;
	waitingUntil: WaitingUntil;
	onTimeout: () => void;
}

/**
 * Creates a `setTimeout` to trigger on the `waitingUntil` date
 * @returns `-1` for when there is no timeout as `setTimeout` returns a positive integer
 */
function handleWaitTimes({
	isEnabled,
	waitingUntil,
	onTimeout,
}: HandleWaitingTimesOptions) {
	if (waitingUntil == null || !isEnabled) {
		return -1;
	}

	const now = dayjs();
	const waitingUntilDate = dayjs(waitingUntil);

	// Do not create a timeout if the date is in the past
	if (waitingUntilDate.isBefore(now)) {
		return -1;
	}

	// Fire the timeout on the `waitingUntilDate` i.e. the number of ms between now and then
	return setTimeout(onTimeout, [waitingUntilDate.diff(now, 'ms')]);
}

interface UseLiveUpdatesOptions {
	onTimeout: () => void;
	serviceWaitTimes?: IVenueServiceWaitTimes;
}

/**
 * Runs the timeout function when one of the "waiting until" values passes
 */
export default function useLiveUpdate({
	onTimeout,
	serviceWaitTimes,
}: UseLiveUpdatesOptions) {
	useEffect(() => {
		if (!serviceWaitTimes) {
			return () => {};
		}

		const {
			drinksWaitingEnabled,
			drinksWaitingUntil,
			foodWaitingEnabled,
			foodWaitingUntil,
		} = serviceWaitTimes;

		const drinksTimeout = handleWaitTimes({
			isEnabled: drinksWaitingEnabled,
			waitingUntil: drinksWaitingUntil,
			onTimeout,
		});
		const foodTimeout = handleWaitTimes({
			isEnabled: foodWaitingEnabled,
			waitingUntil: foodWaitingUntil,
			onTimeout,
		});

		return () => {
			clearTimeout(drinksTimeout);
			clearTimeout(foodTimeout);
		};
	}, [onTimeout, serviceWaitTimes]);
}
