import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';

// Interface for component props
interface IComponentProps {
	backgroundColour?: keyof typeof brand;
	textColour?: keyof typeof brand;
	className?: string;
	hasChildren?: boolean;
	type?: 'info' | 'query' | 'warning';
}

const StyledStatusPill = styled.div<IComponentProps>`
	display: inline-flex;
	align-items: center;
	padding: 5px 10px;
	border-radius: 100px;
	background-color: ${({ backgroundColour }) => brand[backgroundColour!]};
	color: ${({ textColour }) => brand[textColour!]};
	text-transform: uppercase;
	line-height: 1;
`;

const StyledIcon = styled(Icon)`
	margin: 0 7px 0 0;
`;

const StyledChildren = styled.div`
	font-weight: ${fonts.weights.semibold};
	line-height: 1;
	font-size: ${fonts.sizes.base};
`;
/** Renders status pill component */
const StatusPill: React.FC<IComponentProps> = ({
	backgroundColour = 'primary',
	textColour = 'black',
	type = 'query',
	className,
	children,
}) => {
	return (
		<StyledStatusPill
			backgroundColour={backgroundColour}
			textColour={textColour}
			className={className}
			hasChildren={!!children}
		>
			<StyledIcon
				name={type}
				height={15}
				width={15}
				widthMobile={13}
				heightMobile={13}
				colour={textColour}
			/>
			{children && <StyledChildren>{children}</StyledChildren>}
			{type === 'query' && (
				<StyledChildren>
					<FormattedMessage id="statusPill.query.text" />
				</StyledChildren>
			)}
		</StyledStatusPill>
	);
};

export default StatusPill;
