import { Form } from 'formik';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import TimePickerInput from 'components/form-inputs/time-picker/time-picker.component';

export const StyledHeader = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;

	h2 {
		font-size: ${fonts.sizes.larger};
		font-weight: ${fonts.weights.regular};
		margin: 0;
	}

	${mq.mobile`
		padding: 25px 0;
	`}
`;

export const StyledForm = styled(Form)<{ viewHeight: number }>`
	display: flex;
	flex-direction: column;
	background: ${brand.white};
	padding: 40px 40px 27px;
	border-radius: 8px;
	margin: 0 0 30px;

	overflow: scroll;
	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}

	height: calc(${({ viewHeight }) => viewHeight}px * 100 - 113px);

	${({ viewHeight }) => mq.mobile`
		height: calc((${viewHeight}px * 100) - 175px);
		margin: 0 1% 30px;
		padding: 0 16px 70px;
	`}
`;

export const StyledActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button {
		min-width: 140px;
	}

	Button:last-child {
		margin-left: 20px;
	}

	${mq.mobile`
		.mod-desktop-only {
			display: none;
		}
	`}
`;

export const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const StyledColumn = styled.div`
	width: 100%;
`;

const dayColumnStyling = css`
	display: flex;
	flex-direction: column;
	padding: 15px 10px;
	border-radius: 10px;
	font-weight: ${fonts.weights.medium};

	h3 {
		min-width: 35px;
		font-weight: ${fonts.weights.regular};
		text-transform: capitalize;
		margin: 0;
	}

	${mq.mobile`
		padding: 15px 20px;

		h3 {
			font-weight: ${fonts.weights.semibold};
		}
	`}
`;

export const StyledDayHeaders = styled.div`
	${dayColumnStyling}
	color: ${brand.placeholder};

	${mq.mobile`
		display: none;
	`}
`;

export const StyledDay = styled.div`
	${dayColumnStyling}
	background: ${brand.body};

	&:nth-child(even) {
		background: transparent;
	}
`;

export const StyledDayFields = styled.div`
	display: flex;
	align-items: center;

	> div {
		flex-basis: 25%;

		.mod-mobile-label {
			display: none;
			color: ${rgba(brand.black, 0.5)};
		}

		&.mod-disabled {
			opacity: 0.3;
		}

		&:first-child {
			flex-basis: 21%;
		}

		&:nth-child(2) {
			flex-basis: 25%;

			> * {
				max-width: 240px;
			}
		}

		&:nth-child(n + 3) {
			flex-basis: 18%;

			.react-datepicker-popper {
				max-width: 180px;
				width: 100%;

				.react-datepicker,
				.react-datepicker__time-box,
				.react-datepicker > div {
					width: 100%;
				}
			}

			> * {
				max-width: 180px;
			}
		}

		&:last-child {
			flex-grow: 1;
		}
	}

	${mq.mobile`
			flex-wrap: wrap;

			> div {
				margin: 0 0 5px;
				
				&:not(:last-child) {
					margin: 0 0 15px;
				}

				padding: 0;

				.mod-mobile-label {
					display: block;
				}

				&.mod-open {
					color: ${brand.switch_on};
				}

				&.mod-closed {
					color: ${brand.generic_error};
				}

				&:first-child {
					flex-basis: 60%;
				}

			 	&:nth-child(2) {
					flex-basis: 40%;
				}

				&:nth-child(n + 3) {
					flex-basis: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;

					label {
						flex-basis: 60%;
					}

					.react-datepicker-wrapper {
						flex-basis: 40%;
					}
				}

				&.mod-display-value {
					margin: 0 0 4px;

					&:first-child {
						flex-basis: content;
					}
	
					&:nth-child(2) {
						flex-grow: 1;
						flex-basis: 70%;
						font-weight: ${fonts.weights.semibold};
	
						&:before {
							width: 1px;
							height: 10px;
							background: ${brand.black};
							margin: 0 10px -1px 10px;
							content: '';
							display: inline-block;
						}
					}
	
					&:nth-child(n + 3) {
						flex-basis: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
					}
				}
			}
		`}
`;

export const StyledSwitchWrap = styled.div`
	width: 100px;
	flex-shrink: 0;
	padding-right: 10px;

	div {
		margin: 0;
	}
`;

export const StyledTimePickerInput = styled(TimePickerInput)`
	position: relative;

	label {
		display: none;
		opacity: 0.5;
	}

	input {
		max-width: 90px;
	}

	${mq.mobile`
		input {
			max-width: none;
		}

		label {
			display: inline-block;
		}
	`}
`;

export const StyledActionsMobile = styled.div`
	flex-direction: column;
	width: 100%;
	display: none;
	padding: 35px 0 10px;

	button {
		width: 100%;

		&:first-child {
			margin: 0 0 10px;
		}
	}

	${mq.mobile`
		display: flex;
	`}
`;
