import { configureScope, init } from '@sentry/react';
import LogRocket from 'logrocket';

import { IOrder } from 'modules/orders/order.types';

/** Initialises logging tools */
const initialiseLogs = () => {
	// If we're not in local dev environment
	if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
		try {
			// Initialise logrocket
			LogRocket.init('taaam2/opl', {
				release: process.env.REACT_APP_VERSION,
				// Sanitise some network requests
				network: {
					requestSanitizer: (request) => {
						// Set request body as empty on 'login', 'reset' and 'forgot'
						if (
							request.url.toLowerCase().indexOf('/login') !== -1 ||
							request.url.toLowerCase().indexOf('/reset') !== -1 ||
							request.url.toLowerCase().indexOf('/forgot') !== -1
						) {
							request.body = '';
						}

						// Return the modified request
						return request;
					},

					responseSanitizer: (response) => {
						if (response && response.body) {
							try {
								JSON.parse(response.body);
							} catch (e) {
								// eslint-disable-next-line no-console
								console.error(
									'LogRocket.init:responseSanitizer - HTTP response is not JSON: ',
									Response
								);
								return response;
							}

							// Parse body string
							const body = JSON.parse(response.body);

							if (
								// @ts-ignore
								response.url.toLowerCase().indexOf('/order') !== -1 &&
								response.method === 'GET'
							) {
								// If we have orders
								if (body.orders.length > 0) {
									// map orders, set name and phone
									const orders = body.orders.map((order: IOrder) => ({
										...order,
										customer: {
											...order.customer,
											name: 'customer name',
											phone: '01234 567 890',
										},
									}));

									// set response body
									response.body = {
										...body,
										orders,
									};
								}
							}

							// return response
							return {
								...response,
								body: JSON.stringify(body),
							};
						}
						return response;
					},
				},
			});

			// If we have sentry config
			if (process.env.REACT_APP_SENTRY_DSN) {
				// Initialise sentry
				init({
					dsn: process.env.REACT_APP_SENTRY_DSN,
					release: process.env.REACT_APP_VERSION,
				});

				// Get logrocket session URL
				LogRocket.getSessionURL((sessionURL) => {
					// Set sentry scope
					configureScope((scope) => {
						// Add logrocket session URL to sentry extra data
						scope.setExtra('sessionURL', sessionURL);
					});
				});
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('Logging: Failed to load logging plugins.');
		}
	}
};

export default initialiseLogs;
