import { object, number, date } from 'yup';

import { VenueServiceWaitTimesDto } from 'modules/venue/venue.types';

const getWaitingTimeSchema = (key: keyof VenueServiceWaitTimesDto) => {
	return number().when(key, {
		is: true,
		then: number().required().moreThan(0),
	});
};

const getWaitingUntilSchema = (key: keyof VenueServiceWaitTimesDto) => {
	return (
		date()
			// Deal with the string being nullable which nullable(true) didn't seem to pick up...
			.transform((v) => (v === null ? new Date() : v))
			.when(key, {
				is: true,
				then: date().nullable().required().min(new Date()),
				otherwise: date().nullable().optional(),
			})
	);
};

export const WAIT_TIMES_SCHEMA = object<
	Partial<VenueServiceWaitTimesDto>
>().shape({
	drinksWaitingTime: getWaitingTimeSchema('drinksWaitingEnabled'),
	foodWaitingTime: getWaitingTimeSchema('foodWaitingEnabled'),
	drinksWaitingUntil: getWaitingUntilSchema('drinksWaitingEnabled'),
	foodWaitingUntil: getWaitingUntilSchema('foodWaitingEnabled'),
});
