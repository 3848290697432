import styled from 'styled-components';

import { rowStyles } from '../../service-wait-times.styles';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';
import BaseSelect from 'components/form-inputs/select/select.component';
import BaseSwitch from 'components/form-inputs/switch/switch.component';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid ${brand.borders};
	padding: 10px 0px;

	& > h4 {
		display: none;
	}

	${rowStyles}

	${mq.mobile`
    border-bottom: none;
    padding: none;
    display: grid;
    grid-template-areas:
      "label switch"
      "wait-time-heading wait-time"
      "display-until-heading display-until";

    justify-content: space-between;
    align-items: center;
    grid-row-gap: 10px;

    & > h4:not(#menu-heading):not(#status-heading) {
      display: initial;
    }
  `}
`;

export const ItemLabel = styled.p`
	font-size: 14px;
	margin: 0;
	font-weight: 600;
	grid-area: label;
`;

export const Switch = styled(BaseSwitch)`
	width: 160px;
	margin: 0;
	grid-area: switch;
`;

export const Select = styled(BaseSelect)`
	width: 162px;
	margin: 0;
`;

export const SwitchContainer = styled.div`
	grid-area: switch;
`;

export const WaitTimeContainer = styled.div`
	grid-area: wait-time;
`;

export const DisplayUntilContainer = styled.div`
	grid-area: display-until;
`;
