import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

export const Title = styled.h2`
	font-weight: ${fonts.weights.regular};
`;

export const Container = styled.div`
	height: 100%;
	display: flex;
	padding: 24px 15px;
	flex-direction: column;
	overflow: auto;
`;

export const DevicesList = styled.div`
	& > div:nth-child(even) {
		background: ${brand.white};
	}
`;

export const StyledDevice = styled.div`
	padding: 14px 20px;
	border-radius: 5px;
	background: ${brand.background_light_grey};
	line-height: ${fonts.line_height.larger};
	margin-bottom: 14px;
`;

export const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	font-weight: ${fonts.weights.regular};
	margin-bottom: 6px;
`;

export const StyledLabel = styled.div`
	font-size: ${fonts.sizes.med};
	opacity: 0.5;
`;

export const StyledInfo = styled.div`
	font-size: ${fonts.sizes.standard};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 50%;
`;
