import { FieldProps } from 'formik';
import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FormElement from 'components/form-element/form-element.component';
import { intl } from 'modules/core/i18n/i18n.config';

// Interface for component props
interface IComponentProps {
	label?: string;
	offText?: string;
	onText?: string;
	handleChangeEvent?: Function;
	size?: 'small';
}

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

// Create styled Switch component
const StyledSwitch = styled.div`
	padding: 2px;
	border-radius: 5px;
	background: ${brand.switch_background};
	color: ${brand.text};
	position: relative;
	overflow: hidden;
	user-select: none;
	display: flex;
	z-index: 0;

	&:after {
		content: '';
		width: 50%;
		top: 50%;
		transform: translate(0, -50%);
		left: 2px;
		position: absolute;
		height: calc(100% - 4px);
		background: pink;
		border-radius: 3.25px;
		z-index: -1;
		transition: all 0.2s ease;
	}

	&.mod-on:after {
		background: ${brand.switch_on};
	}

	&.mod-off:after {
		background: ${brand.switch_off};
		transform: translate(100%, -50%);
		left: -2px;
	}
`;

const StyledInput = styled.input`
	width: 0;
	height: 0;
	opacity: 0;
	cursor: pointer;
	position: absolute;
`;

const StyledToggle = styled.div<IComponentProps>`
	height: 100%;
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.medium};
	min-width: 55px;
	text-align: center;
	color: ${brand.black};
	cursor: pointer;
	border-radius: 3.25px;
	transition: all 0.2s ease-out;
	padding: ${({ size }) => (size === 'small' ? '2px' : '8px 6px')};
	flex: 2 1 auto;

	&.mod-active {
		color: ${brand.white};
	}
`;
/** Renders Switch component */
const Switch: FunctionComponent<
	IComponentProps & FieldProps & InputHTMLAttributes<HTMLInputElement>
> = ({
	field,
	form,
	label,
	offText = intl.formatMessage({ id: 'form.switch.offText.default' }),
	onText = intl.formatMessage({ id: 'form.switch.onText.default' }),
	handleChangeEvent,
	className,
	size,
}) => {
	const handleSwitch = () => {
		const newValue = !field.value;
		form.setFieldValue(field.name, newValue);
		handleChangeEvent && handleChangeEvent(newValue);
	};

	return (
		<FormElement className={className}>
			{label && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
			<StyledSwitch
				className={`${field.value === true ? 'mod-on' : 'mod-off'}`}
				onClick={handleSwitch}
			>
				<StyledInput
					{...field}
					type="checkbox"
					id={field.name}
					aria-label={`${field.name}-switch`}
					data-testid="switch"
					value={field.value}
				/>
				<StyledToggle
					className={`${field.value === true && 'mod-active'}`}
					data-testid="switch-on-toggle"
					size={size}
				>
					{onText}
				</StyledToggle>
				<StyledToggle
					className={`${field.value === false && 'mod-active'}`}
					data-testid="switch-off-toggle"
					size={size}
				>
					{offText}
				</StyledToggle>
			</StyledSwitch>
		</FormElement>
	);
};

export default Switch;
