import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import Icon from 'components/icons/icon.component';

export const Container = styled.div`
	height: 100%;
	max-width: 360px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
`;

export const FinishButton = styled(Button)`
	width: 100%;
	max-width: 200px;
	margin-top: 30px;
	margin-bottom: 10px;

	${mq.mobile`max-width: initial;`}
`;

export const AnotherButton = styled(Button)`
	width: 100%;
	max-width: 200px;

	${mq.mobile`max-width: initial;`}
`;

export const SuccessIcon = styled(Icon)`
	width: 80px;
	height: 80px;
	padding: 21px 16px;
	margin-bottom: 30px;
	border: 5px solid ${brand.validation_success};
	border-radius: 50%;

	${mq.mobile`
		width: 60px;
		height: 60px;
		padding: 15px 10px;
		border: 3px solid ${brand.validation_success};
	`}
`;
