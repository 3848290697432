import { rgba } from 'polished';
import { css } from 'styled-components';

import brand from '../variables/brand';
import fonts from '../variables/fonts';

/** Global sweetalert styles */
const sweetAlert = css`
	.swal2-container {
		font-family: 'Montserrat', arial, helvetica, sans-serif !important;
		background: black;

		button,
		input {
			font-family: 'Montserrat', arial, helvetica, sans-serif;
		}

		.swal2-popup {
			max-width: 425px;
			min-width: 325px;
			padding: 35px 15px 15px;
			display: flex;
			border-radius: 10px;
			width: auto;

			.swal2-title {
				font-size: ${fonts.sizes.h1};
				font-weight: ${fonts.weights.medium};
				color: ${brand.text};
				margin: 0 0 13px;
			}

			.swal-custom-icon-html {
				width: auto;
				height: auto;
				margin: 0 0 27px;
				color: ${brand.validation_success};
				border-color: transparent;
			}

			.swal2-content {
				font-size: ${fonts.sizes.med};
				font-weight: ${fonts.weights.light};
				color: ${brand.text};
				padding: 0 10px;
				line-height: ${fonts.sizes.larger};
			}

			.swal2-actions {
				display: flex;
				padding: 20px 0 0;
				max-width: 300px;
				flex-direction: row-reverse;

				.swal2-cancel,
				.swal2-confirm {
					flex-grow: 1;
					outline: none;
					padding: 10px 22px;
					min-height: 40px;
					font-size: ${fonts.sizes.standard};
					box-shadow: 0px 0px 0px 1px ${brand.primary};
					border-radius: 5px;
					background: ${brand.primary};

					&:focus {
						box-shadow: none;
					}

					&:hover {
						background: ${brand.link_hover};
					}
				}
				s .swal2-confirm {
					color: ${brand.white};
					background: ${brand.primary};
				}

				.swal2-cancel {
					color: ${brand.primary};
					background: ${rgba(brand.primary, 0.3)};
					box-shadow: 0px 0px 0px 1px ${rgba(brand.primary, 0.3)};

					&:hover {
						background: ${rgba(brand.primary, 0.2)};
						box-shadow: 0px 0px 0px 1px ${rgba(brand.primary, 0.2)};
					}
				}
			}
		}
	}
`;

export default sweetAlert;
