import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Header from '../../components/header/header.component';
import { testPrintOrder } from '../orders/order.slice';
import OfflineNotificationForm from './offline-notification-form/offline-notification-form.component';
import OpeningTimesForm from './opening-times-form/opening-times-form.component';
import ServiceAvailabilityList from './service-availability-list/service-availability-list.component';
import ServiceWaitTimes from './service-wait-times/service-wait-times.component';
import { SettingsTabs } from './settings.types';
import TicketViewSettingsForm from './ticket-view-settings-form/ticket-view-settings-form.component';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import PauseOrdersModal from 'components/pause-orders-modal/pause-orders-modal.component';
import TabList, { ITab } from 'components/tab-list/tab-list.component';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import { getVenueMenus } from 'modules/venue/venue.slice';

const StyledMain = styled.main`
	height: 100vh;
	margin: 0 1%;
`;

const StyledHeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${mq.mobile`
		background: ${brand.white};
		align-items: flex-start;
		margin: -10px 0 15px;
		padding: 28px 1% 17px;
		border-bottom: 1px solid ${rgba(brand.text, 0.25)};

		> header {
			flex-basis: auto;
			margin-bottom: 12px;
		}
		`}
`;

const StyledHeaderItems = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0 0 20px;
`;

const StyledButtonWrapper = styled.div`
	display: flex;
	gap: 10px;

	${mq.mobile`
		justify-content: space-between;
		display: none;
	`}

	button {
		${mq.mobile`

			flex-basis: calc(50% - 5px);
			margin: 0 !important;
			padding: 13px;

			div:first-child{
				display: none;
			}
		`}

		&:first-child {
			margin-right: 10px;
		}
	}
`;

const StyledButtonWrapperMobile = styled.div`
	display: none;
	gap: 10px;

	${mq.mobile`
		justify-content: space-between;
		display: flex;
	`}

	button {
		${mq.mobile`
			flex-basis: calc(50% - 5px);
			margin: 0 !important;
			padding: 13px;

			div:first-child{
				display: none;
			}
		`}

		&:first-child {
			margin-right: 10px;
		}
	}
`;

const StyledTabList = styled(TabList)`
	width: 100%;
`;

/** Renders settings page component */
const SettingsPage: React.FC = () => {
	// Get history
	const history = useHistory();

	const dispatch = useDispatch();

	// Get active tabs from url params
	const { primaryTab } = useParams<{ primaryTab: string }>();

	const { serviceTypes } = useSelector(
		(state: RootState) => state.venue.selectedVenue
	);
	const { selectedVenue, printingEnabled } = useSelector(
		(state: RootState) => state.venue
	);

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'menus');

	// variable to get and set view height
	const [showPauseOrdersModal, setShowPauseOrdersModal] = useState<boolean>(
		false
	);

	useEffect(() => {
		if (!selectedVenue) return;
		setTimeout(() => {
			dispatch(getVenueMenus(selectedVenue.id));
		}, 2000);
	}, [dispatch, selectedVenue]);

	// Create variable for tabs
	const tabs: ITab[] = [
		{
			id: SettingsTabs.availability,
			title: intl.formatMessage({ id: 'settings.tab.availability' }),
		},

		{
			id: SettingsTabs.offlineNotification,
			title: intl.formatMessage({
				id: 'settings.tab.offlineNotifications',
			}),
		},
	];

	tabs.splice(1, 0, {
		id: SettingsTabs.openingTimes,
		title: intl.formatMessage({ id: 'settings.tab.openingTimes' }),
	});

	// Only display on non production or at specified venues
	const ticketViewVenues: string[] = [
		'd0c4ac60-a4e1-41ab-8808-910d04ccf590', // The Burrow at The Blues
		'17abd714-edc5-48b7-8367-4282ca8f267f', // OrderPay Social Club (stripe)
		'1ee730a7-853d-41b4-a0fb-56d683dd17a0', // Sixes Cricket Club Fitzrovia
		'f0df591a-9f60-4898-8f09-095e40c1a7ad', // Sixes Cricket Club Fulham
		'e501c93c-8c1d-4d24-9fbb-987ddba89a5f', // Gaucho Canary Wharf
	];
	// Only display on non production or venues with ticket view enabled
	if (
		process.env.REACT_APP_ENVIRONMENT !== 'production' ||
		ticketViewVenues.includes(selectedVenue.id)
	) {
		tabs.push({
			id: SettingsTabs.ticketView,
			title: intl.formatMessage({
				id: 'settings.tab.ticketView',
			}),
		});
	}

	tabs.push({
		id: SettingsTabs.serviceWaitTimes,
		title: intl.formatMessage({
			id: 'settings.tab.serviceWaitTimes',
		}),
	});

	const canPauseService: boolean = serviceTypes?.some(
		(service) => service.type !== 'customService'
	);

	return (
		<StyledMain>
			<StyledHeaderWrapper>
				<StyledHeaderItems>
					<Header title="settings.page.title" />
					<StyledButtonWrapper>
						{canPauseService && (
							<Button
								size="lg"
								variant="black"
								icon="pause"
								iconColour="white"
								iconHeight={16}
								iconWidth={16}
								onClick={() => {
									setShowPauseOrdersModal(true);
								}}
							>
								<FormattedMessage id="settings.pauseOrders" />
							</Button>
						)}
						{printingEnabled && (
							<Button
								size="lg"
								onClick={() => dispatch(testPrintOrder(selectedVenue.id))}
							>
								<FormattedMessage id="settings.testPrinters" />
							</Button>
						)}
					</StyledButtonWrapper>
					<StyledButtonWrapperMobile>
						{canPauseService && (
							<Button
								size="lg"
								variant="black"
								icon="pause"
								iconColour="white"
								iconHeight={16}
								iconWidth={16}
								onClick={() => {
									setShowPauseOrdersModal(true);
								}}
							>
								<FormattedMessage id="settings.pauseOrdersMobile" />
							</Button>
						)}
						{printingEnabled && (
							<Button
								size="lg"
								onClick={() => dispatch(testPrintOrder(selectedVenue.id))}
							>
								<FormattedMessage id="settings.testPrinters" />
							</Button>
						)}
					</StyledButtonWrapperMobile>
				</StyledHeaderItems>
				<StyledTabList
					{...{ activeTab, setActiveTab }}
					navigateOnClick={true}
					history={history}
					parentUrl="/settings/"
					tabs={tabs}
				/>
			</StyledHeaderWrapper>

			{activeTab === SettingsTabs.availability && <ServiceAvailabilityList />}
			{Boolean(activeTab === SettingsTabs.openingTimes) && <OpeningTimesForm />}
			{activeTab === SettingsTabs.offlineNotification && (
				<OfflineNotificationForm />
			)}
			{activeTab === SettingsTabs.ticketView && <TicketViewSettingsForm />}
			{activeTab === SettingsTabs.serviceWaitTimes && <ServiceWaitTimes />}

			<PauseOrdersModal
				isOpen={showPauseOrdersModal}
				toggleModal={setShowPauseOrdersModal}
			/>
		</StyledMain>
	);
};

export default withNav(SettingsPage);
