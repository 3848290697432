import {
	ITimeslot,
	IVenueServiceAvailability,
} from 'modules/venue/venue.types';

export const transformVenueServiceAvailabilityToTimeSlot = (
	values: IVenueServiceAvailability
): ITimeslot[] => {
	return Object.values(values);
};
