import { FieldProps } from 'formik';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';

// Interface for component props
interface IComponentProps extends HTMLTextAreaElement {
	label?: string;
	testId?: string;
}

const StyledLabel = styled.label`
	font-weight: ${fonts.weights.medium};
	font-size: ${fonts.sizes.semiLarge};
	margin: 0 0 8px;
	display: block;
`;

// Create styled Textarea component
const StyledTextArea = styled.textarea`
	background: ${brand.white};
	border: none;
	border-radius: 10px;
	font-size: ${fonts.sizes.med};
	padding: 16px 18px;
	width: 100%;
	min-height: 65px;

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: ${rgba(brand.black, 0.3)};
	}
	::-moz-placeholder {
		/* Firefox 19+ */
		color: ${rgba(brand.black, 0.3)};
	}
	:-ms-input-placeholder {
		/* IE 10+ */
		color: ${rgba(brand.black, 0.3)};
	}
	:-moz-placeholder {
		/* Firefox 18- */
		color: ${rgba(brand.black, 0.3)};
	}
`;

/** Renders Textarea component */
const TextArea: React.FC<FieldProps & IComponentProps> = ({
	field,
	form,
	label,
	className,
	placeholder,
	disabled,
	testId,
}) => {
	const { touched, errors } = form;
	const isTouched = touched[field.name] || false;
	const hasErrors = errors[field.name] || false;
	return (
		<FormElement className={className}>
			{label && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
			<StyledTextArea
				{...field}
				placeholder={placeholder}
				aria-label={`${field.name}-input`}
				className={isTouched && hasErrors ? 'has-error' : ''}
				data-testid={testId || 'textarea'}
				disabled={disabled}
			/>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>{hasErrors}</FieldError>
			)}
		</FormElement>
	);
};

export default TextArea;
