import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	StyledButton,
	StyledDefaultLogo,
	StyledHeader,
	StyledImage,
	StyledTitle,
	StyledTitleContainer,
	StyledVenuePicker,
	StyledVenues,
} from './header-mobile.styles';
import { VenuePicker } from './venue-picker.component';

import Button from 'components/button/button.component';
import { RootState } from 'modules/core/state/root.reducer';

interface IComponentProps {
	title: string;
	showVenueName?: boolean;
	showButton?: boolean;
	className?: string;
}

const HeaderMobile: React.FC<IComponentProps> = ({
	title,
	showVenueName = true,
	showButton,
	className,
}) => {
	const { selectedVenue, venues } = useSelector(
		(state: RootState) => state.venue
	);

	// Split string on space, map and join together
	const venueInitials = selectedVenue?.name
		.split(' ')
		.map((n) => n[0])
		.join('');

	const history = useHistory();

	return (
		<StyledHeader data-testid="header" className={className}>
			<StyledTitleContainer>
				{selectedVenue?.logoUrl ? (
					<StyledImage src={selectedVenue?.logoUrl} alt="" />
				) : (
					<StyledDefaultLogo>{venueInitials}</StyledDefaultLogo>
				)}
				<StyledTitle>
					<FormattedMessage id={title} />
				</StyledTitle>
				{showButton && (
					<StyledButton>
						<Button onClick={() => history.push('/terminal/device')}>
							<FormattedMessage id="terminal.button.addDevice" />
						</Button>
					</StyledButton>
				)}
			</StyledTitleContainer>

			<StyledVenues>
				{venues.length > 1 ? (
					<StyledVenuePicker className="venue-picker">
						<VenuePicker venues={venues} />
					</StyledVenuePicker>
				) : (
					showVenueName && <h2>{selectedVenue?.name}</h2>
				)}
			</StyledVenues>
		</StyledHeader>
	);
};

export default HeaderMobile;
