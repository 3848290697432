import { FieldProps } from 'formik';
import { rgba } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';
import icons from 'components/icons/icons.config';

interface IComponentProps {
	label?: string;
	icon?: keyof typeof icons;
	iconWidth?: number;
	iconHeight?: number;
	handleChangeEvent?: Function;
	handleChangeFullEvent?: Function;
	size?: 'md' | 'lg';
	iconColour?: keyof typeof brand;
	iconPlacement?: string;
	iconHeightMobile?: number;
	iconWidthMobile?: number;
	showErrors?: boolean;
	isDisabled?: boolean;
	maxLength?: number;
}

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.medium};
`;

const sizeLgStyling = css`
	height: 48px;
	line-height: 48px;
	font-size: ${fonts.sizes.med};
`;

const inputIconPadding = css`
	padding-left: 30px;

	${mq.mobile`
		padding-left: 40px;
	`}
`;

const StyledInput = styled.input<IComponentProps>`
	width: 100%;
	height: 35px;
	line-height: 35px;
	padding: 0 14px;
	border: 1px solid ${brand.borders};
	border-radius: 10px;
	font-size: ${fonts.sizes.standard};

	${({ size }) => size === 'lg' && sizeLgStyling}

	${({ iconPlacement }) =>
		iconPlacement === 'left' ? inputIconPadding : ''}

	&:focus {
		border-color: ${brand.link};
		outline: none;
	}

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}
`;

const iconLeftStyles = css`
	right: inherit;
	left: 10px;
`;

const StyledIconWrapper = styled.div<{ iconPlacement?: string }>`
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);

	${({ iconPlacement }) => (iconPlacement === 'left' ? iconLeftStyles : '')}
`;

/** Renders Input component */
const Input: React.FC<
	IComponentProps & FieldProps & React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
	const {
		className,
		field,
		form,
		icon,
		iconColour,
		iconHeight,
		iconWidth,
		iconHeightMobile,
		iconWidthMobile,
		isDisabled,
		label,
		step,
		min,
		max,
		placeholder,
		handleChangeEvent,
		handleChangeFullEvent,
		size,
		iconPlacement,
		showErrors = true,
		maxLength,
	} = props;

	const type = !props.type ? 'text' : props.type;

	const { touched, errors } = form;
	const isTouched = touched[field.name] || false;
	const hasErrors = errors[field.name] || false;

	// variable to set min/max props
	const minMaxProps = {
		min: (type === 'number' && ((!!min && min) || '0')) || undefined,
		pattern: (type === 'number' && '[0-9]*') || undefined,
		max: (type === 'number' && max) || undefined,
		step: (type === 'number' && step) || undefined,
	};

	return (
		<FormElement className={className} isDisabled={isDisabled}>
			{label && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
			<StyledInput
				{...field}
				{...minMaxProps}
				size={size}
				iconPlacement={iconPlacement}
				placeholder={placeholder}
				type={type}
				id={field.name}
				aria-label={`${field.name}-input`}
				className={isTouched && hasErrors ? 'has-error' : ''}
				data-testid="input"
				disabled={isDisabled}
				onChange={(changeEvent) => {
					form.setFieldValue(field.name, changeEvent.target.value);
					if (handleChangeEvent) {
						handleChangeEvent(changeEvent.target.value);
					}
					if (handleChangeFullEvent) {
						handleChangeFullEvent(changeEvent);
					}
				}}
				maxLength={maxLength}
			/>
			{icon && (
				<StyledIconWrapper iconPlacement={iconPlacement}>
					<Icon
						name={icon}
						width={iconWidth}
						height={iconHeight}
						colour={iconColour}
						widthMobile={iconWidthMobile}
						heightMobile={iconHeightMobile}
					/>
				</StyledIconWrapper>
			)}
			{showErrors && isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>{hasErrors}</FieldError>
			)}
		</FormElement>
	);
};

export default Input;
