import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface WaitTimeDisplayExampleProps {
	time: string;
	type: 'food' | 'drink';
}

const WaitTimeDisplayExample: React.FC<WaitTimeDisplayExampleProps> = ({
	time,
	type,
}) => {
	return (
		<FormattedMessage
			id={`settings.serviceWaitTimes.${type}Example`}
			values={{
				time,
				strong: (chunks: string) => <strong>{chunks}</strong>,
			}}
		/>
	);
};

export default WaitTimeDisplayExample;
