import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDevices } from '../terminal.slice';
import NoDevices from './no-devices/no-devices.component';
import { Container } from './terminal-dashboard.styles';
import TerminalsListMobile from './terminals-list/terminals-list-mobile.component';
import TerminalsList from './terminals-list/terminals-list.component';

import { isMobile } from 'assets/styles/variables/responsive';
import useViewHeight from 'components/modal/hooks/use-view-height.hook';
import { RootState } from 'modules/core/state/root.reducer';

const TerminalDashboard: React.FC = () => {
	const dispatch = useDispatch();
	const viewHeight = useViewHeight();
	const { selectedVenue } = useSelector((state: RootState) => state.venue);
	const { devices } = useSelector((state: RootState) => state.terminal);

	useEffect(() => {
		dispatch(getDevices(selectedVenue.id));
	}, [dispatch, selectedVenue.id]);

	return (
		<Container viewHeight={viewHeight}>
			{devices?.length === 0 ? (
				<NoDevices />
			) : (
				<>
					{isMobile() ? (
						<TerminalsListMobile devices={devices} />
					) : (
						<TerminalsList devices={devices} />
					)}
				</>
			)}
		</Container>
	);
};

export default TerminalDashboard;
