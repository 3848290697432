import MockAdapter from 'axios-mock-adapter/types';

import { EDeviceStatus, IDevice } from './terminal.types';

/** MOCK DATA */
const stripeOnboardToken: string = 'green-tiger-jelly';
const venueId: string = '0c0a030d-472a-41de-8d00-e7029231e38f';
const code: string = 'abc123';
const device: IDevice = {
	id: 'id',
	uniqueDeviceIdentifier: 'uniqueDeviceIdentifier',
	details: {
		manufacturer: 'manufacturer',
		model: 'model',
		os: 'os',
		osVersion: 'osVersion',
		version: 'version',
	},
	status: EDeviceStatus.ACTIVE,
	venueId: 'venueId',
	createdAt: new Date(),
};

/** ENDPOINT DATA */
const postOnboardStripe = {
	request: {
		stripeOnboardToken,
		venueId,
	},
	response: {
		locationId: 'locationId',
	},
};

const postOnboardComplete = {
	request: {
		code,
		venueId,
	},
	response: {
		success: true,
		message: 'message',
		deviceId: 'deviceId',
	},
};

const getDevices = {
	request: { venueId },
	response: {
		page: 0,
		count: 0,
		totalPageCount: 0,
		data: [device],
	},
};

export const postOnboardStripeRegex = new RegExp(/onboard\/stripe/);
export const postOnboardCompleteRegex = new RegExp(/onboard\/complete/);
export const getDevicesRegex = new RegExp(/device/);

export const mockedTerminalEndpoints = (mockHttpClient: MockAdapter) => {
	mockHttpClient.onPost(postOnboardStripeRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, postOnboardStripe.response]);
			}, 1000);
		});
	});

	mockHttpClient.onPost(postOnboardCompleteRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, postOnboardComplete.response]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(getDevicesRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, getDevices.response]);
			}, 1000);
		});
	});
};

const mockTerminal = {
	data: { stripeOnboardToken, venueId, code },
	endpoints: { postOnboardStripe, postOnboardComplete, getDevices },
};

export default mockTerminal;
