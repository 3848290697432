import { transformVenueServiceAvailabilityToTimeSlot } from './transform-venue-service-availability-to-timeslot.helper';

import {
	IVenueServiceAvailabilityFormValues,
	IVenueServiceAvailabilitySubmitValues,
} from 'modules/venue/venue.types';

export const transformFormValuesToSubmitValues = (
	formValues: IVenueServiceAvailabilityFormValues
): IVenueServiceAvailabilitySubmitValues => {
	return {
		openingTimes: transformVenueServiceAvailabilityToTimeSlot(
			formValues.days
		).filter((day) => day.isOpen !== false),
		prepTime: formValues.prepTime,
		ignoreMaxOrders: !formValues.ordersLimitedPerTimeslot,
	};
};
