import { Howl } from 'howler';
import React, {
	useEffect,
	useState,
	FunctionComponent,
	useRef,
	MutableRefObject,
} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ModalProps } from 'styled-react-modal';

import { keyframeOrderAlertEscalation } from 'assets/styles/variables/animations';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Modal from 'components/modal/modal.component';
import { isOrderCompleted } from 'helpers/completed-orders.helper';
import { filterByActiveAndTransformServiceTypeAlertConfig } from 'helpers/filter-service-type-alert-config.helper';
import { isOrderInPrepTime } from 'helpers/order-alert-times.helpers';
import { RootState } from 'modules/core/state/root.reducer';
import { IOrder } from 'modules/orders/order.types';
import { IServiceType } from 'modules/venue/venue.types';

interface IStyledModal extends ModalProps {
	viewHeight?: number;
}

const StyledModal = styled(Modal)<IStyledModal>`
	width: calc(100% - 50px);
	height: auto;
	max-height: 630px;
	padding: 0;
	position: relative;
	display: block;
	z-index: 2;
	border-radius: 10px;
`;

const StyledCopyContainer = styled.div`
	background: ${brand.order_query};
	padding: 70px 0 130px;
	text-align: center;
	color: ${brand.white};
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;

	&.mod-escalation {
		background: ${brand.generic_error};
		animation: ${keyframeOrderAlertEscalation()} 1.5s infinite step-end;
	}
`;

const StyledOrderCount = styled.div`
	font-size: ${fonts.sizes.heroGiant};
	font-weight: ${fonts.weights.bold};
`;

const StyledOrderCopy = styled.div`
	font-size: ${fonts.sizes.heroSmall};
	font-weight: ${fonts.weights.regular};
`;

const StyledAction = styled.div`
	background: ${brand.white};
	padding: 58px 0;
	display: flex;
	justify-content: center;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
`;

const StyledButton = styled(Button)`
	min-width: 265px;
`;

/** Renders order alert component */
const OrderAlert: FunctionComponent = () => {
	// Local use state configs
	const [escalation, setEscalation] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isUserActive, setIsUserActive] = useState<boolean>(true);
	const [filteredOrders, setFilteredOrders] = useState<IOrder[]>([]);
	const [alertTimeSeconds, setAlertTimeSeconds] = useState<number>(25);
	const [escalationTimeSeconds, setEscalationTimeSeconds] = useState<number>(
		25
	);
	const [alertsEnabled, setAlertsEnabled] = useState<boolean>(false);
	// Mutable ref object for timeout
	const escalationTimeout: MutableRefObject<number> = useRef(0);
	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// venue and service types from state
	const { id: selectedVenueId, serviceTypes } = useSelector(
		(state: RootState) => state.venue.selectedVenue
	);

	// Orders from state filtered by status, completed and if alerts are enabled
	const orders = useSelector((state: RootState) =>
		state.order.ordersList.filter(
			(order) =>
				!isOrderCompleted(order.items) &&
				order.status === 'venueAccepted' &&
				order?.alertConfig?.enabled
		)
	);

	// Create ref for orders so it can be used in use selector
	const ordersRef = useRef(orders);
	// Update ref current to orders
	ordersRef.current = orders;

	// Function to check if orders are within prep time
	const checkOrdersInPrepTime = (
		currentOrders: IOrder[],
		venueServiceTypes: IServiceType[]
	): Promise<IOrder[]> => {
		// Filter service types
		const filteredServiceTypes = filterByActiveAndTransformServiceTypeAlertConfig(
			venueServiceTypes
		);
		// Return filtered orders as a resolved promise
		return Promise.resolve(
			currentOrders.filter((order) => {
				// Get service type the same as order
				const orderServiceType = filteredServiceTypes.filter(
					(alertConfig) => alertConfig.serviceType === order.type
				)[0];

				// return true of order type matches service type and after service type prep time
				return (
					order.type === orderServiceType?.serviceType &&
					isOrderInPrepTime(order)
				);
			})
		);
	};

	// set local order alert config (alerts enabled, alertTimeSeconds & escalationTimeSeconds)
	const setOrderAlertConfig = (
		currOrders: IOrder[],
		venueServiceTypes: IServiceType[]
	): void => {
		// filtered services
		const filteredServices = filterByActiveAndTransformServiceTypeAlertConfig(
			venueServiceTypes
		);
		// get unique services from orders
		const serviceTypesFromOrders = Array.from(
			new Set(currOrders.map((order) => order.type))
		);
		// service types from filtered services
		const serviceTypesFromServices = filteredServices.map(
			(service) => service.serviceType
		);
		// get all available service config that has orders
		const availableServiceConfigWithOrders = serviceTypesFromOrders.filter(
			(type) => serviceTypesFromServices.includes(type)
		);

		// if we have no orders with available config
		if (availableServiceConfigWithOrders.length === 0) {
			setAlertsEnabled(false);
			return;
		}

		// if set to false, set it to true
		!alertsEnabled && setAlertsEnabled(true);

		// if we only have one service config available
		if (availableServiceConfigWithOrders.length === 1) {
			const availabileConfig = filteredServices.filter(
				(service) => service.serviceType === availableServiceConfigWithOrders[0]
			)[0];

			// Only set config if it's different
			availabileConfig?.config.initialAlertSeconds !== alertTimeSeconds &&
				setAlertTimeSeconds(availabileConfig?.config.initialAlertSeconds!);

			availabileConfig?.config.escalationAlertSeconds !==
				escalationTimeSeconds &&
				setEscalationTimeSeconds(
					availabileConfig?.config.escalationAlertSeconds!
				);
		} else {
			// Get lowest alert time with a reduce
			const lowestAlertTime = filteredServices
				?.filter((type) =>
					availableServiceConfigWithOrders.includes(type.serviceType)
				)
				?.reduce((prev, curr) => {
					return prev.config.initialAlertSeconds! <
						curr.config.initialAlertSeconds!
						? prev
						: curr;
				}).config.initialAlertSeconds;

			// Get lowest escalation time with reduce
			const lowestEscalationTime = filteredServices
				?.filter((type) =>
					availableServiceConfigWithOrders.includes(type.serviceType)
				)
				?.reduce((prev, curr) => {
					return prev.config.escalationAlertSeconds! <
						curr.config.escalationAlertSeconds!
						? prev
						: curr;
				}).config.escalationAlertSeconds;

			// Only set config if it's different
			lowestAlertTime !== alertTimeSeconds &&
				setAlertTimeSeconds(lowestAlertTime!);

			lowestEscalationTime !== escalationTimeSeconds &&
				setEscalationTimeSeconds(lowestEscalationTime!);
		}
	};

	// idle timer hook, returns a function to get the remaining time left
	useIdleTimer({
		onIdle: () => setIsUserActive(false),
		onActive: () => setIsUserActive(true),
		timeout: 1000 * alertTimeSeconds,
		debounce: 500,
	});

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	useEffect(() => {
		// Initial use function to get initial data
		const getInitialData = async () => {
			const prepOrders = await checkOrdersInPrepTime(
				ordersRef.current,
				serviceTypes
			);
			setFilteredOrders(prepOrders);
			setOrderAlertConfig(prepOrders, serviceTypes);
		};

		// Check on orders every 20 seconds
		const checkOrderInterval = setInterval(async () => {
			const prepOrders = await checkOrdersInPrepTime(
				ordersRef.current,
				serviceTypes
			);
			setFilteredOrders(prepOrders);
			setOrderAlertConfig(prepOrders, serviceTypes);
		}, 20000);

		getInitialData();

		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => {
			window.removeEventListener('resize', getSetViewHeight);
			clearInterval(checkOrderInterval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceTypes]);

	useEffect(() => {
		// Set timeout for escalation
		isOpen &&
			(escalationTimeout.current = setTimeout(() => {
				setEscalation(true);
				// Play order alert sound again
				new Howl({
					src: ['/order-alert.mp3'],
				}).play();
			}, 1000 * escalationTimeSeconds));

		// remove resize event listener
		return () => {
			window.removeEventListener('resize', getSetViewHeight);
			// Clear escalation timeout
			clearTimeout(escalationTimeout.current);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedVenueId, isOpen, escalationTimeSeconds]);

	useEffect(() => {
		if (
			!isOpen &&
			alertsEnabled &&
			filteredOrders.length > 0 &&
			!isUserActive
		) {
			setIsOpen(true);

			// Play order alert sound
			new Howl({
				src: ['/order-alert.mp3'],
			}).play();
		} else if (filteredOrders.length === 0 && isOpen) {
			setIsOpen(false);
		}
	}, [isOpen, alertsEnabled, filteredOrders, isUserActive]);

	return (
		<StyledModal
			isOpen={isOpen}
			toggleModal={setIsOpen}
			allowScroll={true}
			backgroundProps={{ viewHeight }}
			viewHeight={viewHeight}
		>
			<StyledCopyContainer className={`${escalation && 'mod-escalation'}`}>
				<StyledOrderCount>{filteredOrders.length}</StyledOrderCount>
				<StyledOrderCopy>
					<FormattedMessage id="orderAlert.copy" />
				</StyledOrderCopy>
			</StyledCopyContainer>
			<StyledAction>
				<StyledButton
					onClick={() => {
						setIsOpen(false);
						setEscalation(false);
						clearTimeout(escalationTimeout.current);
					}}
				>
					<FormattedMessage id="orderAlert.button.ok" />
				</StyledButton>
			</StyledAction>
		</StyledModal>
	);
};

export default OrderAlert;
