import { Field } from 'formik';
import { rgba } from 'polished';
import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { ETransitionState, ITransition } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import InputQuantity from 'components/form-inputs/input-quantity/input-quantity.component';
import { intl } from 'modules/core/i18n/i18n.config';
import { IOrderItem, EOrderStatus } from 'modules/orders/order.types';

interface IComponentProps {
	item: IOrderItem;
	orderId: string;
	refundActive?: boolean;
	orderStatus: keyof typeof EOrderStatus;
	isRefunded?: boolean;
}

interface IStyledComponent {
	orderColour?: keyof typeof brand;
	item?: IOrderItem;
	refundActive?: boolean;
}

const refundedStyling = css`
	&.mod-refunded {
		text-decoration: line-through;
	}
`;

const orderItemTransition = (state: keyof typeof ETransitionState) => {
	switch (state) {
	case 'entering':
		return css`
				transition: 0.3s;
				opacity: 0;
				max-height: 0;
			`;
	case 'entered':
		return css`
				transition: 0.3s;
				opacity: 1;
				max-height: max-content;
				${mq.mobile`
					max-height: 200px;
				`}
			`;
	case 'exiting':
		return css`
				transition: 0.2s;
				opacity: 0;
				max-height: 0;
			`;
	}

	return css`
		transition: 0.3s;
		opacity: 0;
		max-height: 0;
	`;
};

const StyledOrderItem = styled.li<ITransition>`
	margin: 0 0 7px;
	background: ${rgba(brand.white, 0.6)};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: ${fonts.sizes.large};

	&.mod-refunded {
		opacity: 0.4;
	}

	${({ state }) => orderItemTransition(state)}
`;

const StyledOrderWrapper = styled.div<IStyledComponent>`
	background-color: ${brand.white};
	border-radius: 5px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 15px 23px 15px 15px;
	transition: 0.2s;
	width: ${({ refundActive }) =>
		refundActive ? 'calc(100% - 150px)' : '100%'};

	${mq.mobile`
		flex-flow: row wrap;
	`}

	${({ refundActive }) =>
		refundActive
			? mq.mobile`width: calc(100% - 114px);`
			: mq.mobile`
		width: 100%;
	`};

	.sub-details-wrapper,
	.sub-actions-wrapper {
		display: flex;
		align-items: center;
		flex-grow: 1;

		${({ refundActive }) =>
		refundActive
			? mq.mobile`
			`
			: mq.mobile`
				width: 100%;
			flex-wrap: wrap; ;
			`}

		${mq.mobile`
			width: 80%;
		`}
	}

	.sub-details-wrapper {
		${mq.mobile`
			order: -1;
		`}
	}

	.sub-actions-wrapper {
		justify-content: flex-end;

		${({ refundActive }) =>
		refundActive
			? mq.mobile`
			margin: 5px 0 0 ;
		`
			: mq.mobile`
			margin: 12px 0 0 ;
		`}

		${mq.mobile`
			order: 1;
			width: 100%;
			justify-content: flex-end;
		`}
	}
`;

const StyledQuantity = styled.div<IStyledComponent>`
	width: 35px;
	height: 35px;
	margin-right: 32px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 35px;
	background-color: ${rgba(brand.black, 0.1)};

	color: ${brand.black};

	&.mod-allergen {
		background-color: ${brand.order_allergy};
		color: ${brand.white};
	}

	${refundedStyling}

	${mq.mobile`
		margin-right 20px;
	`}
`;

const StyledItem = styled.div<IStyledComponent>`
	flex-grow: 1;

	${mq.mobile`flex-basis: calc(100% - 55px)`}

	h5 {
		font-size: ${fonts.sizes.large};
		margin: 0;

		font-weight: ${fonts.weights.medium};

		color: ${brand.text};

		&.mod-allergen {
			color: ${brand.order_allergy};
		}

		${mq.mobile`
			font-size: ${fonts.sizes.med};
		`}
	}

	${refundedStyling}
`;

const StyledOrderContent = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const StyledItemNote = styled.div`
	padding: 16px 20px;
	background-color: ${brand.note_background};
	width: 100%;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	font-size: 14px;
`;

const StyledModifiers = styled.div`
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};

	${refundedStyling}
`;

const StyledPrice = styled.div<IStyledComponent>`
	font-weight: ${fonts.weights.regular};
	${refundedStyling}

	${mq.mobile`
		order: -1;

		&.mod-refund-active {
			display: none;
		}
	`}
`;

const refundWrapperTransition = (state: keyof typeof ETransitionState) => {
	switch (state) {
	case 'entering':
		return css`
				opacity: 0;
				overflow: hidden;
				max-width: 0;
			`;
	case 'entered':
		return css`
				opacity: 1;
				overflow: visible;
				max-width: 150px;
			`;
	case 'exiting':
		return css`
				opacity: 0;
				overflow: hidden;
				max-width: 0;
			`;
	}

	return css`
		opacity: 0;
		overflow: hidden;
		max-width: 0;
	`;
};

const StyledRefundWrapper = styled.div<ITransition>`
	flex-basis: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s;
	position: relative;

	${mq.mobile`
		flex-basis: 106px;
	`}

	${({ state }) => refundWrapperTransition(state)}
`;

/** Renders order details item component */
const OrderDetailsItem: React.FC<IComponentProps> = ({
	item,
	refundActive,
	isRefunded,
}) => {
	// Variable to hold modifiers
	const modifiers: string[] = [];

	// map category modifiers and add them to list
	item.modifierCategories?.map((category) => {
		category.modifiers?.forEach((modifier) => {
			modifiers.push(modifier.name);
		});
	});

	const itemModifiers = (
		<StyledModifiers className={`${isRefunded ? 'mod-refunded' : ''}`}>
			{modifiers.join(', ')}
		</StyledModifiers>
	);

	const [animate, setAnimate] = useState(false);

	useEffect(() => {
		setAnimate(true);
	}, []);

	// Reference to DOM element that needs to transition
	const nodeRef = React.useRef(null);

	const trueItemQuantity = isRefunded
		? item.refundedQuantity
		: item.quantity - item.refundedQuantity;

	return (
		<Transition
			in={animate}
			timeout={{
				enter: 300,
				exit: 200,
			}}
			nodeRef={nodeRef}
		>
			{(state) => {
				return (
					<StyledOrderItem
						key={item.id}
						className={`${isRefunded ? 'mod-refunded' : ''}`}
						state={state}
					>
						<StyledOrderContent>
							<StyledOrderWrapper refundActive={refundActive}>
								<div className="sub-details-wrapper">
									<StyledQuantity
										className={`${item.hasAllergen ? 'mod-allergen' : ''} ${
											isRefunded ? 'mod-refunded' : ''
										}`}
										item={item}
									>
										{trueItemQuantity}
									</StyledQuantity>
									<StyledItem
										item={item}
										className={`${isRefunded ? 'mod-refunded' : ''}`}
										refundActive={refundActive}
									>
										<h5 className={`${item.hasAllergen ? 'mod-allergen' : ''}`}>
											{item.name}
										</h5>
										{itemModifiers}
									</StyledItem>
								</div>
								<StyledPrice
									className={`${isRefunded ? 'mod-refunded' : ''} ${
										refundActive ? 'mod-refund-active' : ''
									}`}
								>
									{intl.formatNumber(item.price.units / 100, {
										style: 'currency',
										currency: item.price.currencyCode,
									})}
								</StyledPrice>
							</StyledOrderWrapper>
							<Transition in={refundActive} timeout={200} nodeRef={nodeRef}>
								{(transitionState) => (
									<StyledRefundWrapper
										data-testid="input-quantity-field"
										state={transitionState}
									>
										<Field
											component={InputQuantity}
											name={`items.${item.id}`}
											max={trueItemQuantity}
										/>
									</StyledRefundWrapper>
								)}
							</Transition>
						</StyledOrderContent>
						{!!item.itemNotes && (
							<StyledItemNote>{item.itemNotes}</StyledItemNote>
						)}
					</StyledOrderItem>
				);
			}}
		</Transition>
	);
};

export default OrderDetailsItem;
