import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StyledSelect } from './venue-picker.styles';

import brand from 'assets/styles/variables/brand';
import { RootState } from 'modules/core/state/root.reducer';
import { getVenue } from 'modules/venue/venue.slice';
import { IVenue } from 'modules/venue/venue.types';

interface IVenuePickerProps {
	venues: IVenue[];
}

export const VenuePicker: FunctionComponent<IVenuePickerProps> = ({
	venues,
}) => {
	const dispatch = useDispatch();
	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	const [locallySelectedVenue, setLocallySelectedVenue] = useState(
		selectedVenue
	);

	const handleVenueSelection = (selectedValues: IVenue[]) => {
		const newVenue = selectedValues[0];
		setLocallySelectedVenue(newVenue);
		dispatch(getVenue(newVenue.id));
	};

	return (
		<StyledSelect
			values={[locallySelectedVenue]}
			options={venues}
			onChange={(selectedValues) => {
				handleVenueSelection(selectedValues as IVenue[]);
			}}
			labelField="name"
			valueField="id"
			searchable={false}
			dropdownGap={0}
			color={brand.primary}
			dropdownHeight="300px"
			className="venue-dropdown"
		/>
	);
};
