import { Formik, Form, Field } from 'formik';
import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Header from '../../components/header/header.component';
import FutureOrdersListItem from './future-orders-list-item/future-orders-list-item.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Input from 'components/form-inputs/input/input.component';
import VenueDates from 'components/venue-dates/venue-dates.component';
import dayjs from 'helpers/dayjs.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import { getOrdersList, resetOrderState } from 'modules/orders/order.slice';
import { getVenueDates } from 'modules/venue/venue.slice';

interface IStyledComponent {
	viewHeight?: number;
}

const StyledHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 1%;

	${mq.mobile`
		background: ${brand.white};
		justify-content: space-between;
		margin: -10px 0 0;
		padding: 28px 1% 17px;
	`}
`;

const StyledDateDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 50px 1% 15px;

	h2 {
		font-size: ${fonts.sizes.h2};
		margin: 0;
		font-weight: ${fonts.weights.bold};

		span {
			font-weight: ${fonts.weights.light};
		}
	}

	${mq.mobile`
		padding: 0 1% 15px;
		background: ${brand.white};
		margin: 0 0 16px;
	`}
`;

const StyledForm = styled(Form)`
	width: 100%;
`;

const StyledInput = styled(Input)`
	min-width: 0;
	margin: 0;

	${mq.mobile`
		width: 100%;
		margin: 10px 0 0;
	`}

	input {
		background-color: ${brand.neutralLight};
		font-size: ${fonts.sizes.large};
		font-weight: ${fonts.weights.light};
	}
`;

const StyledOrdersList = styled.ul`
	margin: 0 1%;
	padding: 0;
	list-style: none;

	li {
		position: relative;

		&.mod-headers {
			margin: 0;
			display: flex;
			align-items: flex-end;
			width: 100%;
			font-size: ${fonts.sizes.standard};
			font-weight: ${fonts.weights.medium};
			color: ${rgba(brand.text, 0.4)};
			padding: 0 0 16px 15px;

			${mq.mobile`
				display: none;
			`}
		}

		${mq.mobile`
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		`}

		> div {
			flex-basis: calc(100% / 6);
			flex-grow: 1;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(4) {
				max-width: 70px;
			}

			&:last-child {
				max-width: 230px;
				display: flex;
				justify-content: flex-end;
			}

			${mq.mobile`
				&:nth-child(1) {
					position: absolute;
					top: 11px;
					right: 15px;

				}

				&:nth-child(3) {
					order: -1;
					font-size: ${fonts.sizes.large};
					margin: 0 0 -2px;
				}

				&:nth-child(2) {
					order: 1;
					font-size: ${fonts.sizes.med};
					font-weight: ${fonts.weights.medium};
				}

				&:nth-child(5) {
					order: 2;
					margin-top: 5px;
				}

				&:nth-child(4) {
					display: none;
				}

				&:last-child  {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					max-width: none;
					padding: 0;

					> div {
						display: none;
					}
				}
			`}
		}
	}
`;

const listHeightCss = (viewHeight?: number) => css`
	height: calc((${viewHeight}px * 100) - 225px);
`;

const StyledListWrapper = styled.div<IStyledComponent>`
	${({ viewHeight }) => listHeightCss(viewHeight)}
	overflow: scroll;

	${({ viewHeight }) => mq.mobile`
		height: calc((${viewHeight}px * 100) - 185px);
	`}
`;

const StyledEmptyHeading = styled.h2`
	margin: 50px 15px 0 15px;
	text-align: center;
`;

/** Renders future orders page component */
const FutureOrdersPage: React.FC = () => {
	// Get redux dispatch
	const [searchQuery, setSearchQuery] = React.useState('');
	const { date }: { date?: string } = useParams();
	const dispatch = useDispatch();
	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	const orders = useSelector(
		(state: RootState) => state.order.ordersList
	).filter((order) =>
		order.customer.name?.toLowerCase().includes(searchQuery.toLowerCase())
	);

	useEffect(() => {
		const getData = async () => {
			// Get venue dates for venue dates component
			await dispatch(getVenueDates(selectedVenue.id));
			// dispatch get orders list
			await dispatch(getOrdersList(selectedVenue.id, date));
		};

		getData();

		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => {
			window.removeEventListener('resize', getSetViewHeight);
			dispatch(resetOrderState());
		};
	}, [dispatch, selectedVenue, date]);

	return (
		<main>
			<StyledHeaderWrapper>
				<Header title="futureOrders.header.title" />
				<VenueDates />
			</StyledHeaderWrapper>
			<StyledDateDetails>
				<div className="sub-details">
					<h2>
						{dayjs(date).tz('Europe/London').format('dddd Do MMMM')}{' '}
						<span>({orders?.length})</span>
					</h2>
				</div>
			</StyledDateDetails>
			{orders.length > 0 ? (
				<StyledOrdersList>
					<li className="mod-headers">
						<div className="mod-header">
							<FormattedMessage id="futureOrders.list.headings.type" />
						</div>
						<div className="mod-header">
							<FormattedMessage id="futureOrders.list.headings.time" />
						</div>
						<div className="mod-header">
							<FormattedMessage id="futureOrders.list.headings.customer" />
						</div>
						<div className="mod-header">
							<FormattedMessage id="futureOrders.list.headings.total" />
						</div>
						<div className="mod-header">
							<FormattedMessage id="futureOrders.list.headings.flags" />
						</div>
						<div className="mod-header">
							<Formik initialValues={{ searchQuery }} onSubmit={() => {}}>
								{() => {
									return (
										<StyledForm>
											<Field
												component={StyledInput}
												name="searchQuery"
												placeholder={intl.formatMessage({
													id: 'form.search.placeholder',
												})}
												icon="search"
												iconWidth={15}
												iconHeight={15}
												iconWidthMobile={15}
												iconheightMobile={15}
												iconColour="placeholderLight"
												iconPlacement="left"
												handleChangeEvent={(value: string) => {
													setSearchQuery(value);
												}}
											/>
										</StyledForm>
									);
								}}
							</Formik>
						</div>
					</li>
					<StyledListWrapper viewHeight={viewHeight}>
						{orders.map((order) => (
							<FutureOrdersListItem key={order.id} order={order} />
						))}
					</StyledListWrapper>
				</StyledOrdersList>
			) : (
				<StyledEmptyHeading>
					<FormattedMessage id="futureOrders.list.empty" />
				</StyledEmptyHeading>
			)}
		</main>
	);
};

export default withNav(FutureOrdersPage);
