import currency from 'currency.js';
import { Field, Formik, FormikHelpers } from 'formik';
import queryString from 'query-string';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { object } from 'yup';

import {
	StyledModal,
	StyledForm,
	StyledHeader,
	StyledFieldGroup,
	StyledColumn,
	StyledMessage,
	StyledActions,
} from './product-edit-modal.styles';

import { IQueryParams } from 'app.types';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import useViewHeight from 'components/modal/hooks/use-view-height.hook';
import { IComponentProps as IModalProps } from 'components/modal/modal.component';
import { formatPrice } from 'helpers/price.helper';
import {
	genericValidationString,
	priceValidationString,
} from 'helpers/validation.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { RootState } from 'modules/core/state/root.reducer';
import { getStockList, updateProductDetails } from 'modules/stock/stock.slice';
import {
	IProductUpdateValues,
	IStockItem,
	IStockListFilters,
} from 'modules/stock/stock.types';

interface IFormValues {
	name: string;
	price: string;
}

interface IFormValidation {
	id: string;
	name: string;
	price: number;
}

const ProductEditModal: React.FC<IStockItem & IModalProps> = (props) => {
	const { id, name, price, toggleModal, isOpen } = props;
	const { selectedVenue } = useSelector((state: RootState) => state.venue);
	const location = useLocation();
	const query: IQueryParams = queryString.parse(location.search);
	const intl = useIntl();

	const formValidationSchema = object<IFormValidation>().shape({
		name: genericValidationString({
			fieldName: 'name',
		}),
		price: priceValidationString('price', intl),
	});

	const dispatch = useDispatch();
	const viewHeight = useViewHeight();

	const initialFormValues: IFormValues = {
		name,
		price: formatPrice(price),
	};

	const handleFormSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		setSubmitting(true);
		const formValues: IProductUpdateValues = {
			name: values.name,
			price: currency(values.price).intValue,
		};
		const response = await dispatch(
			updateProductDetails(id, formValues, selectedVenue.id)
		);

		if (!response) {
			setSubmitting(false);
			return;
		}

		fireDialog({
			title: intl.formatMessage({
				id: 'alerts.success.title',
			}),
			text: intl.formatMessage({
				id: 'productModalForm.footer.info',
			}),
			icon: 'success',
		});

		setSubmitting(false);
		const filters: IStockListFilters = {
			search: query.search,
			pageNumber: parseFloat(query.pageNumber!) || 1,
			menu: query.menu,
			category: query.category,
		};
		await dispatch(getStockList(selectedVenue.id, filters));
		toggleModal();
	};

	return (
		<StyledModal
			isOpen={isOpen}
			toggleModal={toggleModal}
			allowScroll={false}
			backgroundProps={{ viewHeight }}
			viewHeight={408}
		>
			<Formik
				enableReinitialize
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={handleFormSubmit}
			>
				{({ isSubmitting }) => (
					<StyledForm>
						<StyledHeader>
							<h2>
								<FormattedMessage id="productModalForm.headings.info" />
							</h2>
						</StyledHeader>
						<StyledFieldGroup>
							<StyledColumn>
								<Field
									component={Input}
									name="name"
									label={intl.formatMessage({
										id: 'form.fields.name.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.name.label',
									})}
								/>
								<Field
									component={Input}
									name="price"
									label={intl.formatMessage({
										id: 'form.fields.price.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.price.label',
									})}
								/>
							</StyledColumn>
						</StyledFieldGroup>
						<StyledMessage>
							<p>
								<FormattedMessage id="productModalForm.footer.info" />
							</p>
						</StyledMessage>
						<StyledActions>
							<Button
								type="button"
								ariaLabel="submit-button"
								onClick={() => toggleModal()}
							>
								<FormattedMessage id="form.button.cancel" />
							</Button>
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								<FormattedMessage id="form.button.save" />
							</Button>
						</StyledActions>
					</StyledForm>
				)}
			</Formik>
		</StyledModal>
	);
};

export default ProductEditModal;
