import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/header/header.component';
import TerminalDashboard from './terminal-dashboard/terminal-dashboard.component';
import TerminalRegistration from './terminal-registration/terminal-registration.component';
import {
	StyledMain,
	StyledHeaderWrapper,
	StyledHeaderItems,
} from './terminal.page.styles';

import { isMobile } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import HeaderMobile from 'components/header/header-mobile.component';
import withNav from 'modules/navigation/with-nav.component';

/** Renders terminal page component */
const TerminalPage: React.FC = () => {
	// Get registration step from url params
	const { step } = useParams<{
		step: string;
	}>();
	
	const history = useHistory();

	return (
		<StyledMain>
			<StyledHeaderWrapper>
				<StyledHeaderItems>
					{isMobile() ? (
						<HeaderMobile title="terminal.page.title" showButton={!step} />
					) : (
						<>
							<Header title="terminal.page.title" />
							{!step && (
								<Button onClick={() => history.push('/terminal/device')}>
									<FormattedMessage id="terminal.button.addDevice" />
								</Button>
							)}
						</>
					)}
				</StyledHeaderItems>
			</StyledHeaderWrapper>
			{!step && <TerminalDashboard />}
			{step && <TerminalRegistration step={step} />}
		</StyledMain>
	);
};

export default withNav(TerminalPage);
