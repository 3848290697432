import { Formik, Field, Form } from 'formik';
import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Header from '../../components/header/header.component';
import TicketViewCompleted from './ticket-view-completed/ticket-view-completed.component';
import TicketViewInProgress from './ticket-view-in-progress/ticket-view-in-progress.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Switch from 'components/form-inputs/switch/switch.component';
import Icon from 'components/icons/icon.component';
import TicketViewFilterModal from 'components/ticket-view-filter-modal/ticket-view-filter-modal.component';
import { RootState } from 'modules/core/state/root.reducer';
import { getVenueMenus } from 'modules/venue/venue.slice';

interface IStyledComponent {
	viewHeight?: number;
}

const StyledMain = styled.main`
	height: 100vh;
`;

const StyledHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${brand.white};
	padding: 18px 1%;

	${mq.tabletPortrait`
		background: ${brand.white};
		align-items: flex-start;
		margin: -10px 0 0;
		padding: 22px 1% 8px;
		flex-direction: column;
		border-bottom: 1px solid ${rgba(brand.text, 0.25)};
	`}
`;

const StyledHeader = styled(Header)`
	${mq.tabletPortrait`
		flex-basis: auto;
		margin-bottom: 12px;
		
		h2 {
			font-size: ${fonts.sizes.large} !important;
		}
	`}
`;

const StyledOrderTotal = styled.div`
	font-size: ${fonts.sizes.largish};
	margin: 0 0 0 20px;

	${mq.tabletPortrait`
		display: none;
	`}

	span {
		font-weight: ${fonts.weights.bold};
		display: inline-block;

		&:before {
			content: '|';
			display: inline-block;
			margin-right: 15px;
			font-weight: ${fonts.weights.light};
		}
	}
`;

const StyledActions = styled.div`
	display: flex;
	align-items: center;
`;

const StyledMobileOrderTotal = styled.div`
	font-size: ${fonts.sizes.largish};
	font-weight: ${fonts.weights.bold};
	display: none;

	${mq.tabletPortrait`
		display: flex;
	`}
`;

const StyledOrderTotalWrap = styled.div`
	display: flex;
	align-items: center;
`;

const StyledButtonWrapper = styled.div`
	display: flex;
	align-items: center;

	${mq.tabletPortrait`
		width: 100%;
		justify-content: space-between;
	`}

	button {
		${mq.tabletPortrait`
			flex-basis: calc(50% - 5px);
			margin: 0 !important;
			padding: 13px;
		`}

		&:first-child {
			margin-right: 10px;
		}
	}
`;

const StyledForm = styled(Form)`
	display: flex;
	margin: 0;
`;

const StyledSwitch = styled(Switch)`
	margin: 0;

	.mod-on:after,
	.mod-off:after {
		background: ${brand.primary};
	}
`;

const StyledFilter = styled.div`
	cursor: pointer;
	margin: 0 30px;
`;

const StyledClose = styled.div`
	cursor: pointer;
`;

const contentHeightCss = (viewHeight?: number) => css`
	height: calc((${viewHeight}px * 100) - 86px);

	${mq.tabletPortrait`
		height: calc((${viewHeight}px * 100) - 125px);
	`}
`;

const StyledContentWrapper = styled.main<IStyledComponent>`
	padding: 15px 15px 15px 0;
	background: ${brand.background_dark};

	${({ viewHeight }) => contentHeightCss(viewHeight)}
`;

/** Renders ticket view page component */
const TicketViewPage: React.FC = () => {
	// Get hooks
	const history = useHistory();
	const dispatch = useDispatch();

	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	// variable to show filter modal
	const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	// Get active tabs from url params
	const { primaryTab }: { primaryTab?: string } = useParams();

	// Get completed orders
	const { completedOrders } = useSelector(
		(state: RootState) => state.ticketView
	);

	// Get orders list and filter by status & completed
	const ordersList = useSelector((state: RootState) =>
		state.order.ordersList
			?.filter(
				(order) =>
					order.status === 'venueAccepted' ||
					order.status === 'orderBeingPrepared'
			)
			.filter(
				(order) =>
					!completedOrders.some(
						(completedOrder) => completedOrder.id === order.id
					)
			)
	);

	useEffect(() => {
		selectedVenue.id && dispatch(getVenueMenus(selectedVenue.id));
		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);
		return () => window.removeEventListener('resize', getSetViewHeight);
	}, [dispatch, selectedVenue]);

	return (
		<StyledMain>
			<StyledHeaderWrapper>
				<StyledOrderTotalWrap>
					<StyledHeader
						showVenueName={false}
						title={
							primaryTab === 'completed'
								? 'ticketView.page.completed.title'
								: 'ticketView.page.inProgress.title'
						}
					/>
					<StyledOrderTotal>
						<span>
							{primaryTab === 'completed'
								? completedOrders.length
								: ordersList.length}
						</span>
					</StyledOrderTotal>
				</StyledOrderTotalWrap>
				<StyledButtonWrapper>
					<Formik
						enableReinitialize
						initialValues={{ isInProgress: primaryTab === 'in-progress' }}
						onSubmit={() => {}}
					>
						{({ dirty, touched, errors }) => (
							<StyledForm>
								<Field
									component={StyledSwitch}
									name="isInProgress"
									onText="In progress"
									offText="Completed"
									handleChangeEvent={(isInProgress: boolean) => {
										!isInProgress && history.push('completed');
										isInProgress && history.push('in-progress');
									}}
								/>
							</StyledForm>
						)}
					</Formik>
					<StyledActions>
						<StyledMobileOrderTotal>
							{primaryTab === 'completed'
								? completedOrders.length
								: ordersList.length}
						</StyledMobileOrderTotal>
						<StyledFilter onClick={() => setShowFilterModal(true)}>
							<Icon name="filters" height={30} width={30} colour="primary" />
						</StyledFilter>
						<StyledClose
							onClick={() => {
								history.push('/dashboard');
							}}
						>
							<Icon name="close" height={30} width={30} />
						</StyledClose>
					</StyledActions>
				</StyledButtonWrapper>
			</StyledHeaderWrapper>
			<StyledContentWrapper viewHeight={viewHeight}>
				{primaryTab === 'in-progress' && <TicketViewInProgress />}
				{primaryTab === 'completed' && <TicketViewCompleted />}
			</StyledContentWrapper>
			<TicketViewFilterModal
				isOpen={showFilterModal}
				toggleModal={setShowFilterModal}
			/>
		</StyledMain>
	);
};

export default TicketViewPage;
