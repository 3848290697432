import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
	AnotherButton,
	Container,
	FinishButton,
	SuccessIcon,
} from './terminal-paired.styles';

const TerminalPaired: React.FC = () => {
	const history = useHistory();

	return (
		<Container>
			<SuccessIcon
				name="tick"
				width={28}
				height={18}
				colour="validation_success"
			/>
			<h2>
				<FormattedMessage id="terminal.paired.title" />
			</h2>
			<p>
				<FormattedMessage id="terminal.paired.text" />
			</p>
			<FinishButton onClick={() => history.push('/terminal')}>
				<FormattedMessage id="terminal.paired.button.finish" />
			</FinishButton>
			<AnotherButton
				variant="secondary"
				onClick={() => history.push('/terminal/device')}
			>
				<FormattedMessage id="terminal.paired.button.another" />
			</AnotherButton>
		</Container>
	);
};

export default TerminalPaired;
