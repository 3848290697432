import { Dayjs } from 'dayjs';
import { rgba } from 'polished';
import React, { useEffect, useState, ReactElement } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Icon from 'components/icons/icon.component';
import dayjs from 'helpers/dayjs.helper';

const StyledDates = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	width: 100%;
	max-width: 675px;
	border-radius: 100px;
	justify-content: flex-end;
	background: ${brand.white};

	${mq.tabletPortrait`
		max-width: 128px;
		jusfity-content: center;
		flex: 0 0 100%;
	`}

	li {
		&.mod-prev,
		&.mod-next {
			display: none;

			${mq.tabletPortrait`
				display: block;
			`}
		}
	}
`;

const StyledDate = styled.li`
	flex-basis: 69px;
	padding: 8px 0;
	margin: 0 15px;
	position: relative;
	justify-content: center;
	cursor: pointer;

	${mq.tabletPortrait`
		flex-basis: 128px;
		margin: 0;
		display: none;
	`}

	&:first-child {
		margin-left: 25px !important;

		${mq.tabletPortrait`
			margin-left: 0 !important;
		`}
	}

	&:last-child {
		margin-right: 0;
	}

	h3 {
		font-size: ${fonts.sizes.large};
		font-weight: ${fonts.weights.medium};
		line-height: 18px;
		color: ${brand.text};
		text-transform: uppercase;
		margin: 0;
		position: relative;
	}

	h4 {
		margin: 0;
		font-size: ${fonts.sizes.standard};
		font-weight: ${fonts.weights.medium};
		color: ${rgba(brand.text, 0.4)};
		position: relative;
	}

	&.mod-active {
		${mq.tabletPortrait`
			display: flex;
		`}

		&:before {
			content: '';
			position: absolute;
			width: calc(100% + 25px);
			border-radius: 100px;
			height: 100%;
			background: ${brand.text};
			left: -25px;
			top: 0;
			z-index: 0;

			${mq.tabletPortrait`
				width: 100%;
				left: 0;
				background: ${rgba(brand.text, 0.75)}
			`}
		}

		h3,
		h4 {
			color: ${brand.white};
		}
	}
`;

const StyledContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
`;

const StyledButton = styled.button`
	border: none;
	background: none;
	cursor: pointer;

	&.mod-prev {
		transform: rotate(180deg);
	}

	&.mod-mobile {
		display: none;
	}

	&.mod-active {
		svg {
			color: ${brand.primary};
		}
	}

	&.mob-mobile {
		display: none;
	}

	${mq.tabletPortrait`
		&.mod-desktop {
			display: none;
		}

		&.mod-mobile {
			display: block;
		}
	`}
`;

const StyledDay = styled.div`
	display: flex;
	align-items: flex-end;
	position: relative;
	z-index: 0;
`;

/** Renders VenueDates component */
const OrderHistoryDates: React.FC = () => {
	// Get url param
	const { date } = useParams<{ date: string }>();
	// Get router history
	const history = useHistory();
	// Get date offset
	const offset = Math.floor(dayjs().diff(dayjs(date), 'day') / 7);
	// get previous days with correct offeset
	const [previousDays, setPreviousDays] = useState<number>(offset * 7);
	// Get initial date
	const [initialDate, setInitialDate] = useState<Dayjs>(
		dayjs(date) || dayjs().subtract(previousDays, 'day')
	);

	useEffect(() => {
		// set initial date from previous minus previous days
		setInitialDate(dayjs().subtract(previousDays, 'day'));
	}, [previousDays, setInitialDate]);

	// create days array
	const previousDates = Array.from({ length: 7 }, (v, k) => ({
		date: dayjs(initialDate).subtract(k, 'day'),
	})).reverse();

	const dateItems: ReactElement[] = [];
	// For each date
	previousDates.forEach((previousDate, index) => {
		// get parsed date
		const parsedDate = dayjs(previousDate.date).utc();

		// If last index, dates not changed & no param
		// or param is set and equals link
		const isActive = !!(
			(index === 6 && previousDays === 0 && !date) ||
			(!!date && date === parsedDate.format('YYYY-MM-DD'))
		);

		// Create element
		const element = (
			<StyledDate
				key={previousDate.date.toString()}
				className={`${isActive && 'mod-active'}`}
			>
				{isActive && (
					<StyledButton
						type="button"
						className="mod-prev mod-active mod-mobile"
						onClick={() => {
							history.push(
								`/completed-orders/${dayjs(date)
									.subtract(1, 'day')
									.format('YYYY-MM-DD')}`
							);
						}}
					>
						<Icon name="chevron" width={15} height={15} colour="white" />
					</StyledButton>
				)}
				<StyledContentWrapper
					onClick={() => {
						// If previous days not changed and last in array, go to completed orders
						index === 6 && previousDays === 0
							? history.push('/completed-orders')
							: history.push(
								`/completed-orders/${parsedDate.format('YYYY-MM-DD')}`
							  );
					}}
				>
					<StyledDay>
						<h3>{parsedDate.format('ddd')}</h3>
					</StyledDay>
					<h4>{parsedDate.format('DD MMM')}</h4>
				</StyledContentWrapper>
				{isActive && (
					<StyledButton
						type="button"
						className={`mod-next mod-mobile ${
							Math.floor(dayjs().diff(date!, 'day')) >= 1 && 'mod-active'
						}`}
						onClick={() => {
							if (Math.floor(dayjs().diff(date!, 'day')) >= 1) {
								history.push(
									`/completed-orders/${dayjs(date)
										.add(1, 'day')
										.format('YYYY-MM-DD')}`
								);
							}
						}}
					>
						<Icon name="chevron" width={11} height={11} colour="white" />
					</StyledButton>
				)}
			</StyledDate>
		);

		// Push element to array
		dateItems.push(element);
	});

	// Return all date elements
	return (
		<>
			<StyledButton
				type="button"
				onClick={() => setPreviousDays(previousDays + 7)}
				className="mod-prev mod-active mod-desktop"
			>
				<Icon name="chevron" width={15} height={15} />
			</StyledButton>
			<StyledDates data-testid="previous-dates">{dateItems}</StyledDates>
			<StyledButton
				className={`mod-next mod-desktop ${previousDays > 0 && 'mod-active'}`}
				type="button"
				onClick={() => setPreviousDays(Math.max(0, previousDays - 7))}
			>
				<Icon name="chevron" width={15} height={15} colour="borders" />
			</StyledButton>
		</>
	);
};

export default OrderHistoryDates;
