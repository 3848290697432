import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

// Interface for component props
interface IComponentProps {
	alert: boolean;
	escalation: boolean;
}

const StyledOrderCardAlert = styled.div`
	border-radius: 15px;
	border: 1px solid transparent;
	margin: 0 0 10px;
	padding: 0 12px;

	&.mod-alert {
		background: ${brand.order_query};
		padding: 12px;
	}

	&.mod-escalation {
		background: ${brand.validation_error};
		padding: 12px;
	}
`;

const StyledH5 = styled.h5`
	margin: 0 0 5px;
	font-size: ${fonts.sizes.larger};
	font-weight: ${fonts.weights.bold};
	color: ${brand.white};
	text-transform: uppercase;
`;

/** Renders order card alert component */
const OrderCardAlert: FunctionComponent<IComponentProps> = ({
	alert,
	escalation,
	children,
}) => {
	return (
		<StyledOrderCardAlert
			className={`${alert && !escalation && 'mod-alert'} ${
				escalation && 'mod-escalation'
			}`}
		>
			{alert && !escalation && (
				<StyledH5>
					<FormattedMessage id="orderAlertConfig.alert.prepRequired" />
				</StyledH5>
			)}
			{escalation && (
				<StyledH5>
					<FormattedMessage id="orderAlertConfig.alert.prepAsap" />
				</StyledH5>
			)}
			{children}
		</StyledOrderCardAlert>
	);
};

export default OrderCardAlert;
