import { Form } from 'formik';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Input from 'components/form-inputs/input/input.component';
import Select from 'components/form-inputs/select/select.component';
import TimePickerInput from 'components/form-inputs/time-picker/time-picker.component';

export const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	background: ${brand.white};
	padding: 40px 40px 27px;
	border-radius: 8px;
	margin: 0 0 30px;

	${mq.mobile`
	padding: 20px 16px 20px;
`}
`;

export const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const StyledColumn = styled.div`
	width: 100%;
`;

const dayColumnStyling = css`
	display: flex;
	flex-direction: column;
	padding: 15px 10px;
	border-radius: 10px;
	font-weight: ${fonts.weights.medium};

	h3 {
		min-width: 35px;
		font-weight: ${fonts.weights.regular};
		text-transform: capitalize;
		margin: 0;
	}
`;

export const StyledDayHeaders = styled.div`
${dayColumnStyling}
color: ${brand.placeholder};

	${mq.mobile`
		display: none;
	`}
`;

export const StyledDay = styled.div`
${dayColumnStyling}
background: ${brand.body};

&:nth-child(even) {
	background: transparent;
}
`;

export const StyledDayFields = styled.div`
	display: flex;
	align-items: center;

	label {
		display: none;
	}

	.mobile-row-titles {
		display: none;
	}

	.open {
		color: ${brand.switch_on};
	}

	.closed {
		color: ${brand.generic_error};
	}

	> div {
		flex-basis: 25%;
		&.mod-disabled {
			opacity: 0.4;
		}

		&:first-child {
			flex-basis: 21%;
		}

		&:nth-child(2) {
			flex-basis: 25%;

			> * {
				max-width: 240px;
				margin: 0;
			}
		}

		&:nth-child(n + 3) {
			flex-basis: 18%;
			.react-datepicker-popper {
				max-width: 180px;
				width: 100%;

				.react-datepicker,
				.react-datepicker__time-box,
				.react-datepicker > div {
					width: 100%;
				}
			}

			> * {
				max-width: 180px;
			}
		}

		&:last-child {
			flex-grow: 1;
		}
	}

	${mq.mobile`
		flex-wrap: wrap;
		.mobile-row-titles{
			display: flex;
			opacity: 0.5;
		}
		label{
			display: inline-block;
		}

		> div {

			&:first-child {
				flex-basis: content;
				padding-bottom: 10px;
				order: 1;

				&.edit{
					display: none;
				}
			}

			&:nth-child(2) {
				flex-grow: 1;
				flex-basis: 70%;
				padding-bottom: 10px;
				order: 2;

				&:before {
					width: 1px;
					height: 12px;
					background: ${brand.black};
					margin: 0 10px -1px 10px;
					content: '';
					display: inline-block;
				}
				&.edit{

					&:before{
						display: none;
					}

					display: flex;

					div:first-child{
						display: flex;
						justify-content: space-between;
						align-content: center;
						padding-right: 5px;
						max-width: 100%;
						align-items: center;
						margin: 0;
					}
				}
			}

			&:nth-child(3){
				order: 3;

			}
			&:nth-child(4){
				order: 5;

			}
			&:nth-child(5){
				margin-left: 30%;
				order: 4;
			}
			&:nth-child(6){
				margin-left: 30%;
				order: 6;

			}

			&:nth-child(n + 3){
				display: flex;
				flex-basis: 35%;
				justify-content: space-between;
				padding-bottom: 5px;

				&.edit{
					flex-basis: 100%;
					align-items: center;
					padding-right: 5px;
					border-radius: 1px;

					label{
						opacity: ${brand.opacity_generic};
						font-size: 12px;
					}

					> div:nth-child(2) {
						width: 115px;
					}

					&:nth-child(3){
						order: 3;
						> div {
							&:first-child{
								max-width: 100%;
								> div {
									max-width: 115px;
								}
							}
						}
					}
					&:nth-child(4){
						order: 4;
						> div {
							&:first-child{
								max-width: 100%;
								> div {
									max-width: 115px;
								}
							}
						}

					}
					&:nth-child(5){
						margin: 0;
						order: 5;
						> div {
							&:first-child{
								max-width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								> div {
									max-width: 115px;
								}
							}
						}

					}
					&:nth-child(6){
						margin: 0;
						order: 6;
						> div {
							&:first-child{
								max-width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								> div {
									max-width: 115px;
								}
							}
						}
					}

				}
			}

			&.table-service-style{
				flex-basis: 100%;
				justify-content: flex-start;

				> div {
					&:first-child{
						padding-right: 25px;
					}

					&.mobile-row-titles {
						min-width: 60px;
					}
				}
			}
		}
	`}
`;

export const StyledHeader = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 0 20px;

	${mq.mobile`
		display: none;
		margin: 0 0 18px;
	`}

	.sub-title {
		width: 100%;
		align-items: center;
		display: flex;

		h3 {
			font-size: ${fonts.sizes.large};
			font-weight: ${fonts.weights.semibold};
			margin: 0;
			text-transform: capitalize;
		}

		h4 {
			font-size: ${fonts.sizes.large};
			font-weight: ${fonts.weights.light};
			margin: 0;

			${mq.mobile`
				font-size: ${fonts.sizes.semiLarge};
			`}
		}

		${mq.mobile`
			flex-wrap: wrap;
		`}
	}

	.sub-order-type {
		display: flex;
		align-items: center;
		padding-right: 15px;
	}
`;

export const StyledDivider = styled.div`
	width: 1px;
	height: 17px;
	background: ${brand.black};
	margin: 0 20px;

	${mq.mobile`
		margin: 0 10px;
	`}
`;

export const StyledMessage = styled.div`
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 14px;

	.sub-order-prep-time-message-mobile {
		display: none;
	}

	.sub-order-prep-time-message {
		margin-left: auto;
		order: 2;
		display: flex;
		align-items: center;

		&.edit {
			> div {
				&:first-child {
					width: 100px;
					padding-left: 5px;
				}
			}
		}
	}

	.sub-order-limit-message {
		display: flex;
		align-items: center;

		&.edit {
			align-items: center;
			> div {
				order: 1;
			}

			p {
				order: 2;
				margin: 0;
			}
		}
	}

	${mq.mobile`
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding-bottom: 10px;

		.sub-order-prep-time-message{
			display: none;
		}

		.sub-order-prep-time-message-mobile {
			margin-left: 0;
			display: flex;

			&.edit{
				order: 2;
				font-size: 12px;
				font-weight: 400;
				width: 100%;
				justify-content: space-between;
				align-content: center;
				align-items: center;
				display: flex;
				p{
					width: 161px;
					margin: 0;
				}

				> div:first-of-type{
					width: 130px;
					font-weight: 400;
				}

			}

		}

		.sub-order-limit-message {
			&.edit{
				order: 1;
				font-size: 12px;
				font-weight: 400;
				width: 100%;
				justify-content: space-between;
				padding-bottom: 10px;

				p{
					width: 128px;
					margin: 0;
					order: 1;
				}

				> div {
					display: flex;
					justify-content: flex-end;
					order: 2;
				}
			}

		}



	`}
`;

export const StyledHeaderMobile = styled.div`
	display: none;

	${mq.mobile`
			display: flex;
			padding-bottom: 10px;
			align-items: center;
			justify-content: space-between;
			h3 {
				font-size: ${fonts.sizes.large};
				font-weight: ${fonts.weights.semibold};
				margin: 0;
				text-transform: capitalize;

			}


		`}
`;

export const StyledActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	Button {
		width: 180px;
	}
	.sub-order-cancel-desktop-edit {
		margin-right: 5px;
	}

	${mq.mobile`
		Button {
			width: 80px;
		}

		.sub-order-cancel-desktop-edit {
			display: none;
		}
		.sub-order-save-desktop-edit {
			display: none;
		}
	`}

	${mq.tabletDown`
		Button {
			width: 100%;
		}
	`}
`;

export const StyledSwitchWrap = styled.div`
	width: 200px;
	flex-shrink: 0;
	padding-right: 20px;

	div {
		margin: 0;
	}

	${mq.mobile`
		padding: 0;
		justify-content: space-between;

		> div {
			width: 130px;
		}

	`}
`;

export const StyledSelect = styled(Select)`
	max-width: 200px;
	margin: 0;

	&.mod-disabled {
		div {
			&:first-child {
				opacity: 0.7;
			}
		}
	}

	${mq.tabletDown`
		max-width: none;
		margin: 0;

	`}
`;

export const StyledInput = styled(Input)`
	max-width: 200px;
	margin: 0;

	&.mod-disabled {
		opacity: 0.7;
	}

	input {
		${mq.tabletDown`
			max-width: 115px;
		`}
	}
`;

export const StyledTimePickerInput = styled(TimePickerInput)`
	&.mod-disabled {
		div {
			&:first-child {
				opacity: 0.7;
			}
		}
	}
`;

export const StyledMobileButtons = styled.div`
	display: none;

	${mq.mobile`
			display: flex;
			flex-direction: column;
			padding-top: 20px;

			Button{
				margin-bottom: 10px;
			 }
		`}
`;
