import * as React from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

// Interface for component props
interface IComponentProps {
	ariaLabel?: string;
}

// Create styled field error component
const StyledFieldError = styled.div`
	padding: 0 15px;
	line-height: ${fonts.line_height.large};
	font-weight: ${fonts.weights.regular};
	color: ${brand.validation_error};
`;

/** Renders field error component */
const FieldError: React.FC<IComponentProps> = ({ ariaLabel, children }) => {
	return <StyledFieldError aria-label={ariaLabel}>{children}</StyledFieldError>;
};

export default FieldError;
