import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

export const StyledHeader = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const StyledImage = styled.img`
	border-radius: 8px;
	margin-right: 20px;
	width: 40px;
	height: 40px;
`;

export const StyledDefaultLogo = styled.div`
	border-radius: 8px;
	width: 40px;
	height: 40px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	background: ${brand.black};
	color: ${brand.white};
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.semibold};
`;

export const StyledTitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledTitle = styled.span`
	font-size: ${fonts.sizes.larger};
	font-weight: ${fonts.weights.semibold};
`;

export const StyledButton = styled.div`
	margin-left: auto;

	button {
		align-self: auto;
		padding: 9px 16px 10px 14px;
	}
`;

export const StyledVenues = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	h2 {
		line-height: 20px;
		font-weight: ${fonts.weights.regular};
		font-size: ${fonts.sizes.larger};
		margin: 13px 0 0 2px;
	}
`;

export const StyledVenuePicker = styled.div`
	margin-top: 13px;
`;
