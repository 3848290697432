import currency from 'currency.js';
import { Field, Formik, FormikHelpers } from 'formik';
import queryString from 'query-string';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { object } from 'yup';

import {
	StyledActions,
	StyledColumn,
	StyledFieldGroup,
	StyledForm,
	StyledHeader,
	StyledMessage,
	StyledModal,
	StyledNotice,
} from './modifier-edit-modal.styles';

import { IQueryParams } from 'app.types';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Icon from 'components/icons/icon.component';
import useViewHeight from 'components/modal/hooks/use-view-height.hook';
import { IComponentProps as IModalProps } from 'components/modal/modal.component';
import { formatPrice } from 'helpers/price.helper';
import {
	genericValidationString,
	priceValidationString,
} from 'helpers/validation.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { RootState } from 'modules/core/state/root.reducer';
import { getStockList, updateModifierItem } from 'modules/stock/stock.slice';
import {
	IModifierItemUpdateValues,
	IStockItem,
	IStockListFilters,
} from 'modules/stock/stock.types';

interface IFormValues {
	title: string;
	price: string;
}

interface IFormValidation {
	id: string;
	title: string;
	price: number;
}

const ModifierEditModal: React.FC<IStockItem & IModalProps> = (props) => {
	const { id, name, price, toggleModal, isOpen, beforeOpen } = props;
	const intl = useIntl();
	const modifierPriceOveride = useSelector(
		(state: RootState) => state.stock.modifier?.overridesProductPrice
	);
	const location = useLocation();
	const query: IQueryParams = queryString.parse(location.search);
	const { selectedVenue } = useSelector((state: RootState) => state.venue);
	const formValidationSchema = object<IFormValidation>().shape({
		title: genericValidationString({
			fieldName: 'title',
		}),
		price: priceValidationString('price', intl),
	});

	const dispatch = useDispatch();
	const viewHeight = useViewHeight();

	const initialFormValues: IFormValues = {
		title: name,
		price: formatPrice(price),
	};

	const handleFormSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		setSubmitting(true);
		const formValues: IModifierItemUpdateValues = {
			title: values.title,
			price: currency(values.price).intValue,
		};
		const response = await dispatch(
			updateModifierItem(id, formValues, selectedVenue.id)
		);

		if (!response) {
			setSubmitting(false);
			return;
		}

		fireDialog({
			title: intl.formatMessage({
				id: 'alerts.success.title',
			}),
			text: intl.formatMessage({
				id: 'productModalForm.footer.info',
			}),
			icon: 'success',
		});

		setSubmitting(false);

		const filters: IStockListFilters = {
			search: query.search,
			pageNumber: parseFloat(query.pageNumber!) || 1,
			menu: query.menu,
			category: query.category,
		};

		await dispatch(getStockList(selectedVenue.id, filters));
		toggleModal();
	};

	return (
		<StyledModal
			isOpen={isOpen}
			toggleModal={toggleModal}
			allowScroll={false}
			backgroundProps={{ viewHeight }}
			viewHeight={408}
			beforeOpen={beforeOpen}
		>
			<Formik
				enableReinitialize
				initialValues={initialFormValues}
				validationSchema={formValidationSchema}
				onSubmit={handleFormSubmit}
			>
				{({ isSubmitting }) => (
					<StyledForm>
						<StyledHeader>
							<h2>
								<FormattedMessage id="modifierModalForm.headings.info" />
							</h2>
						</StyledHeader>
						<StyledFieldGroup>
							<StyledColumn>
								<Field
									component={Input}
									name="title"
									label={intl.formatMessage({
										id: 'form.fields.title.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.title.label',
									})}
								/>
								<Field
									component={Input}
									name="price"
									label={intl.formatMessage({
										id: 'form.fields.price.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.price.label',
									})}
								/>
							</StyledColumn>
						</StyledFieldGroup>
						<StyledNotice>
							<Icon
								name="warning"
								width={14}
								height={14}
								colour="text_cancel"
							/>
							<FormattedMessage
								id={
									modifierPriceOveride === true
										? 'modifierModalForm.price.override.info'
										: 'modifierModalForm.price.addition.info'
								}
							/>
						</StyledNotice>
						<StyledMessage>
							<FormattedMessage id="productModalForm.footer.info" />
						</StyledMessage>
						<StyledActions>
							<Button
								type="button"
								ariaLabel="submit-button"
								onClick={() => toggleModal()}
							>
								<FormattedMessage id="form.button.cancel" />
							</Button>
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								<FormattedMessage id="form.button.save" />
							</Button>
						</StyledActions>
					</StyledForm>
				)}
			</Formik>
		</StyledModal>
	);
};

export default ModifierEditModal;
