import { css, SimpleInterpolation } from 'styled-components';

// Media rules interface
interface IMediaRules {
	[index: string]: string;
}

/** Responsive breakpoints */
export const breakpoints = {
	desktop: 1200,
	tablet: 1024,
	mobile: 767,
};

/** Media rules */
export const mediaRules: IMediaRules = {
	/* only mobile */
	mobile: `only screen and (max-width: ${breakpoints.mobile}px)`,
	/* only mobile portrait */
	mobilePortrait: `only screen and (max-width: ${breakpoints.mobile}px) and (orientation: portrait)`,
	/* only tablet */
	tablet: `only screen and (min-width: ${breakpoints.mobile + 1}px) and ${
		breakpoints.desktop - 1
	}px)`,
	/* tablet and up */
	tabletUp: `only screen and (min-width: ${breakpoints.mobile + 1}px)`,
	/* only tablet portrait */
	tabletPortrait: `only screen and (max-width: ${breakpoints.tablet}px) and (orientation: portrait)`,
	/* tablet below */
	tabletDown: `only screen and (max-width: ${breakpoints.tablet}px)`,
	/* desktop and up */
	desktop: `(min-width: ${breakpoints.desktop}px)`,
	desktopDown: `(max-width: ${breakpoints.desktop - 1}px)`,
};

// Media query function
export const mq = (Object.keys(mediaRules) as Array<
	keyof typeof mediaRules
>).reduce((acc, label) => {
	acc[label] = (
		first: TemplateStringsArray,
		...interpolations: SimpleInterpolation[]
	) =>
		css`
			@media ${mediaRules[label]} {
				${css(first, ...interpolations)}
			}
		`;
	return acc;
}, {} as { [index: string]: Function });

export const isMobile = () => {
	return window.matchMedia(`screen and (max-width: ${breakpoints.mobile}px)`)
		.matches;
};
