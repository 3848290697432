import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IFeatureTogglesState } from './feature-toggles.types';

export const initialFeatureTogglesState: IFeatureTogglesState = {
	features: [],
};

const featureTogglesSlice = createSlice({
	name: 'feature-toggles',
	initialState: initialFeatureTogglesState,
	reducers: {
		CLEAR_FEATURE_TOGGLES(state) {
			return initialFeatureTogglesState;
		},
		SET_FEATURE_TOGGLES(state, action) {
			return {
				features: action.payload,
			};
		},
	},
});

export const {
	CLEAR_FEATURE_TOGGLES,
	SET_FEATURE_TOGGLES,
} = featureTogglesSlice.actions;

export const clearFeatureToggles = () => (dispatch: Dispatch) => {
	dispatch(CLEAR_FEATURE_TOGGLES());
};

export const setFeatureToggles = (features: string[]) => (
	dispatch: Dispatch
) => {
	dispatch(SET_FEATURE_TOGGLES(features));
};

export default featureTogglesSlice.reducer;
