import * as React from 'react';
import styled, { css } from 'styled-components';

import { EOrderType } from 'app.types';
import { mq } from 'assets/styles/variables/responsive';
import Icon from 'components/icons/icon.component';
import { serviceTypeColour } from 'helpers/service-type-colour.helper';

// Interface for component props
interface IComponentProps {
	variant: keyof typeof EOrderType;
	width?: number;
	height?: number;
	mobileWidth?: number;
	mobileHeight?: number;
	customIcon?: string;
}

const StyledIcon = styled.div<IComponentProps>`
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	${({ mobileHeight, mobileWidth }) => mq.mobile`
		height: ${mobileHeight}px;
		width: ${mobileWidth}px;
	`}

	${({ variant }) =>
		css`
			background-color: ${serviceTypeColour(variant)};
		`}
`;

const StyledCustomServiceImage = styled.img<IComponentProps>`
	display: inline-block;
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;

	${({ mobileHeight, mobileWidth }) => mq.mobile`
		height: ${mobileHeight}px;
		width: ${mobileWidth}px;
	`}
`;
/** Renders order icon component */
const OrderIcon: React.FC<IComponentProps> = ({
	variant,
	width = 35,
	height = 35,
	mobileWidth = 35,
	mobileHeight = 35,
	customIcon,
}) => {
	return (
		<StyledIcon
			width={width}
			height={height}
			mobileWidth={mobileWidth}
			mobileHeight={mobileHeight}
			variant={variant}
			data-testid="order-icon"
		>
			{variant === 'customService' && !!customIcon ? (
				<StyledCustomServiceImage
					variant={variant}
					width={width * 0.75}
					height={height * 0.75}
					mobileWidth={mobileWidth * 0.75}
					mobileHeight={mobileHeight * 0.75}
					src={customIcon}
				/>
			) : (
				<Icon
					name={variant}
					colour="white"
					width={width * 0.75}
					height={height * 0.75}
					heightMobile={mobileHeight * 0.75}
					widthMobile={mobileWidth * 0.75}
				/>
			)}
		</StyledIcon>
	);
};

export default OrderIcon;
