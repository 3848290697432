import { lighten, rgba } from 'polished';

/** Brand colour variables */
const brand = {
	black: '#000000',
	white: '#ffffff',
	body: '#EDEEF2',
	neutral: '#F8F8FB',
	borders: '#D9D9D9',
	text: '#101010',
	placeholder: '#C9C9CC',
	get headings() {
		return this.text;
	},
	opBlue: '#116DFF',
	primary: '#ED8F78',
	secondary: '#FEF6F4',
	generic_error: '#D63636',
	background: '#4C4C4C',
	background_light_grey: '#F4F6F9',
	background_dark: '#8c8e96',
	note_background: '#EDF2FF',
	note_title: rgba('#4F80FF', 0.5),
	background_cancel: '#F9DDD6',
	text_cancel: '#ED8F78',
	opacity_generic: '0.3',
	get link() {
		return this.primary;
	},
	get neutralLight() {
		return rgba('#807676', 0.12);
	},
	get placeholderLight() {
		return rgba('#3C3C43', 0.6);
	},
	get link_hover() {
		return lighten(0.02, this.primary);
	},
	get link_hover_secondary() {
		return lighten(0.02, this.secondary);
	},
	get link_active() {
		return lighten(0.08, this.primary);
	},
	get generic_error_light() {
		return rgba(this.generic_error, 0.2);
	},
	validation_error: '#dc3545',
	validation_success: '#28a745',
	validation_warning: '#ffc107',
	validation_disabled: '#F2F2F5',
	order_delivery: '#1AB1CE',
	order_selfService: '#22AFFF',
	order_collection: '#554ED3',
	order_table_service: '#8EC648',
	order_generic: '#EBBA0D',
	get order_allergy() {
		return this.generic_error;
	},
	order_query: '#FF8E09',
	toast_info: '#4689D9',
	get toast_error() {
		return this.generic_error;
	},
	toast_success: '#8EBD52',
	switch_on: '#11962E',
	get switch_off() {
		return this.generic_error;
	},
	switch_background: rgba('#767680', 0.12),
};

export default brand;
