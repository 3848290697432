import styled from 'styled-components';

import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { ReactComponent as terminalMobile } from 'assets/svg/terminalMobile.svg';
import { ReactComponent as terminalWisepos } from 'assets/svg/terminalWisepos.svg';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		font-size: ${fonts.sizes.larger};
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 35px;
	}

	${mq.mobile`
		padding: 50px 12px 0;
		text-align: center;
	`}
`;

export const Device = styled.div`
	margin-right: 75px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;

	&:last-child {
		margin-right: 0;
	}

	button {
		margin-top: 40px;
	}
`;

export const TerminalMobile = styled(terminalMobile)`
	width: 122px;
`;
export const TerminalWisepos = styled(terminalWisepos)`
	width: 62px;
`;
