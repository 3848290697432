import { rgba } from 'polished';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import LiveIndicator from './live-indicator/live-indicator.component';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';
import structure from 'assets/styles/variables/structure';
import Icon from 'components/icons/icon.component';
import iconsList from 'components/icons/icons.config';
import LogoBlock from 'components/logo-block/logo-block.component';
import { isIos } from 'helpers/device-helper';
import { processLogout } from 'modules/auth/auth.slice';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { FullscreenContext } from 'modules/core/full-screen/full-screen.context';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

const StyledNav = styled.nav`
	width: ${structure.nav_width};
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;

	${mq.mobile`
		flex-direction: row;
		height: ${structure.nav_mobile_height};
		width: 100%;
		padding: 0;
		border-radius: 0 0 0 10px;
		box-shadow: 0px 2px 8px ${rgba(brand.black, 0.15)};
	`}
`;

const StyledNavWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	background: ${brand.white};

	${mq.mobile`
		flex-direction: row;
		height: ${structure.nav_mobile_height};
		width: 100%;
		padding: 0;
	`}

	.sub-live-indicator {
		margin-top: 17px;
		height: 50px;

		${mq.mobile`
			margin: 0 0 0 14px;
			height: 100%;
			flex-grow: 1;

			> div {
				height: 100%;
				justify-content: flex-start;
			}
		`}
	}

	.sub-nav {
		flex-grow: 1;

		${mq.mobile`
			display: flex;
			height: 100%;
			flex-grow: 1;
			margin-right: 10px;
		`}
	}

	.sub-settings {
		margin-top: auto;

		${mq.mobile`
			display: flex;
			flex-grow: 1;
			height: 100%;
		`}
	}
`;

const sharedNavItemStyles = css`
	height: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;

	${mq.mobile`
		height: 100%;
		marign: 0;
		max-width: 60px;
		flex-grow: 1;
	`}

	&:hover,
	&.mod-active {
		background: ${rgba(brand.primary, 0.1)};

		svg {
			color: ${brand.primary};
		}
	}
`;

const StyledLink = styled(NavLink)`
	${sharedNavItemStyles}
`;

const StyledButton = styled.button`
	${sharedNavItemStyles}
	width: 100%;
	border: none;
	background: none;
	position: relative;

	${mq.mobile`
		width: 25px;
	`}

	&:before {
		content: '';
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		position: absolute;
		width: 85%;
		height: 1px;
		background: ${brand.borders};

		${mq.mobile`
			height: 65%;
			bottom: 0;
			margin: initial;
			left: 0;
			right: initial;
			width: 1px;
			top: 50%;
			transform: translateY(-50%);
		`}
	}
`;

const StyledLogout = styled.div`
	${sharedNavItemStyles}
	width: 100%;
	position: relative;

	${mq.mobile`
		width: 25px;
	`}

	&:before {
		content: '';
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		position: absolute;
		width: 85%;
		height: 1px;
		background: ${brand.borders};

		${mq.mobile`
			height: 65%;
			bottom: 0;
			margin: initial;
			left: 0;
			right: initial;
			width: 1px;
			top: 50%;
			transform: translateY(-50%);
		`}
	}
`;

/** Renders navigation component */
const Nav: React.FC = () => {
	// Get hooks
	const dispatch = useDispatch();
	const history = useHistory();
	// Get context
	const fullScreenContext = useContext(FullscreenContext);

	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	// Interface for link props
	interface ILinkProps {
		id: number;
		route: string;
		title: string;
		icon: keyof typeof iconsList;
		isHidden?: boolean;
	}

	// Links for top of nav bar
	const navLinks: ILinkProps[] = [
		{
			id: 1,
			route: 'dashboard',
			title: 'Orders',
			icon: 'orders',
		},
		{
			id: 3,
			route: 'completed-orders',
			title: 'Completed Orders',
			icon: 'completedOrders',
		},
		{
			id: 4,
			route: 'settings/availability',
			title: 'Settings',
			icon: 'settings',
		},
		{
			id: 5,
			route: 'stock',
			title: 'Stock',
			icon: 'stock',
		},
		{
			id: 6,
			route: 'terminal',
			title: 'Terminal',
			icon: 'terminal',
		},
	];

	const ticketViewVenues: string[] = [
		'd0c4ac60-a4e1-41ab-8808-910d04ccf590', // The Burrow at The Blues
		'17abd714-edc5-48b7-8367-4282ca8f267f', // OrderPay Social Club (stripe)
		'1ee730a7-853d-41b4-a0fb-56d683dd17a0', // Sixes Cricket Club Fitzrovia
		'f0df591a-9f60-4898-8f09-095e40c1a7ad', // Sixes Cricket Club Fulham
		'e501c93c-8c1d-4d24-9fbb-987ddba89a5f', // Gaucho Canary Wharf
	];
	// Only display on non production or venues with ticket view enabled
	if (
		process.env.REACT_APP_ENVIRONMENT !== 'production' ||
		ticketViewVenues.includes(selectedVenue.id)
	) {
		navLinks.splice(1, 0, {
			id: 2,
			route: 'ticket-view/in-progress',
			title: 'Ticket View',
			icon: 'ticketView',
		});
	}

	const handleLogout = async () => {
		// Confirm user wishes to delete account
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'nav.dialogs.confirmLogout.title',
			}),
			text: intl.formatMessage({
				id: 'nav.dialogs.confirmLogout.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Logout of application
		await dispatch(processLogout());
		// Redirect user to home page
		history.push('/');
	};

	const isLocal = process.env.REACT_APP_ENVIRONMENT === 'local';

	return (
		<StyledNav data-testid="nav">
			<StyledNavWrapper>
				<Link to="dashboard">
					<LogoBlock />
				</Link>
				<div className="sub-nav">
					{navLinks.map((item) =>
						!isLocal && item.isHidden ? (
							''
						) : (
							<StyledLink
								to={`/${item.route}`}
								activeClassName="mod-active"
								key={item.id}
								title={item.title}
								aria-label={`${item.title.toLowerCase}-nav-link`}
							>
								<Icon
									name={item.icon}
									width={32}
									height={32}
									widthMobile={25}
									heightMobile={25}
								/>
							</StyledLink>
						)
					)}
				</div>
				<div className="sub-settings">
					<div className="sub-live-indicator">
						<LiveIndicator />
					</div>
					{!isIos() &&
						(!fullScreenContext.isFullScreen ? (
							<StyledButton
								type="button"
								data-testid="button-enable-fullscreen"
								onClick={() => {
									fullScreenContext.toggleFullScreen();
								}}
							>
								<Icon
									name="fullscreen"
									width={25}
									height={25}
									widthMobile={20}
									heightMobile={20}
								/>
							</StyledButton>
						) : (
							<StyledButton
								type="button"
								data-testid="button-disable-fullscreen"
								onClick={() => {
									fullScreenContext.toggleFullScreen();
								}}
							>
								<Icon
									name="minimise"
									width={25}
									height={25}
									widthMobile={20}
									heightMobile={20}
								/>
							</StyledButton>
						))}
					<StyledLogout
						title="Logout"
						data-testid="nav-logout"
						onClick={() => handleLogout()}
					>
						<Icon
							name="logout"
							width={29}
							height={29}
							widthMobile={25}
							heightMobile={25}
						/>
					</StyledLogout>
				</div>
			</StyledNavWrapper>
		</StyledNav>
	);
};

export default Nav;
