import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';

export const rowStyles = css`
	& > *:not(:first-child) {
		flex: 1;
	}

	& > *:first-child {
		flex: 0.5;
	}
`;

export const Container = styled.div<{ viewHeight: number }>`
	padding: 40px;
	background: ${brand.white};
	border-radius: 8px;
	overflow: auto;
	height: calc(${({ viewHeight }) => viewHeight}px * 100 - 200px);
	display: flex;
	flex-direction: column;
	font-size: 15px;
`;

export const Title = styled.h2`
	font-size: 20px;
`;

export const Subheading = styled.h3`
	font-weight: 600;
	font-size: 16px;
	margin-top: 20px;
`;

export const Description = styled.p`
	margin-top: 2rem;
`;

export const Example = styled.div`
	margin: 0;
	margin-bottom: 1rem;

	& p {
		margin: 0;
	}

	& p:first-of-type {
		margin-top: 1rem;
	}
`;

export const Form = styled(FormikForm)`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SaveButton = styled(Button)`
	width: 100%;
	max-width: 180px;
	margin-top: auto;

	${mq.mobile`
    max-width: initial;
    position: sticky;
    bottom: 0;
  `}
`;

export const HeadingContainer = styled.div`
	display: flex;

	${rowStyles}

	${mq.mobile`
    display: none;
  `}
`;
