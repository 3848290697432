import { Form } from 'formik';
import styled from 'styled-components';
import { ModalProps } from 'styled-react-modal';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';
import Modal from 'components/modal/modal.component';

interface IStyledModal extends ModalProps {
	viewHeight?: number;
}

export const StyledHeader = styled.div`
	height: 30px;
	display: flex;
	justify-content: center;
	font-weight: 500;
	font-size: 21px;
	line-height: 30px;
`;

export const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	padding: 40px;
`;

export const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-top: 15px;

	input {
		height: 40px;
		border-radius: 8px;
	}
`;

export const StyledModal = styled(Modal)<IStyledModal>`
	width: calc(100% - 50px);
	max-width: 420px;
	height: 400px;
	max-height: 408px;
	padding: 0;
	border-radius: 15px;
	position: relative;
	display: block;
	z-index: 2;

	${mq.mobile`
    height: 80%;
    width: calc(100% - 22px);
`}
`;

export const StyledMessage = styled.div`
	display: flex;
	justify-content: center;
	font-size: 13px;
	padding-top: 10px;
	padding-bottom: 14px;
`;

export const StyledActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 15px;
	Button {
		width: 49%;
	}
	Button:first-child {
		background: ${brand.background_cancel};
		color: ${brand.text_cancel};
	}
	width: 100%;
`;

export const StyledColumn = styled.div`
	width: 100%;
`;

export const StyledNotice = styled.div`
	display: flex;
	font-size: 12px;
	color: ${brand.text_cancel};
	font-weight: 500;
	div:first-child {
		margin-right: 10px;
	}
`;
