import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ModalProvider } from 'styled-react-modal';

import ErrorBoundary from './modules/core/error/error-boundary.component';
import I18nProvider from './modules/core/i18n/i18n-provider.component';
import AppRouter from './modules/core/routing/app-router.component';
import store, { persistor } from './modules/core/state/store.config';

import Loading from 'components/loading/loading.component';
import { modalBackground } from 'components/modal/modal.component';
import NetworkConnectivity from 'components/network-connectivity/network-connectivity.component';
import Toasts from 'components/toast/toast.component';
import AuthExpiryComponent from 'modules/auth/auth-expiry/auth-expiry.component';
import FullScreenProvider from 'modules/core/full-screen/full-screen.provider';
import LogIdentifier from 'modules/core/logging/log-identifier.component';
import PusherComponent from 'modules/core/pusher/pusher.component';
import StoreVersion from 'modules/core/state/store-version/store-version.component';

/** Renders app component */
const App: FunctionComponent = () => {
	return (
		<ErrorBoundary>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<FullScreenProvider>
						<I18nProvider>
							<ModalProvider backgroundComponent={modalBackground}>
								<PusherComponent>
									<Loading />
									<LogIdentifier />
									<AppRouter />
									<StoreVersion />
									<Toasts />
									<NetworkConnectivity />
									<AuthExpiryComponent />
								</PusherComponent>
							</ModalProvider>
						</I18nProvider>
					</FullScreenProvider>
				</PersistGate>
			</Provider>
		</ErrorBoundary>
	);
};

export default App;
