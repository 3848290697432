import { intl } from './i18n.config';

export const validationMessages = {
	email: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.email',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	maxLength: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.maxLength',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	minLength: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.minLength',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),

	minNumber: (fieldName: string, min: number) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.minNumber',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
				number: min,
			}
		),
	maxNumber: (fieldName: string, max: number) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.maxNumber',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
				number: max,
			}
		),
	number: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.number',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	required: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.required',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
};
