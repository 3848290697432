import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { VenuePicker } from './venue-picker.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { RootState } from 'modules/core/state/root.reducer';

interface IComponentProps {
	title: string;
	showVenueName?: boolean;
	className?: string;
}

const StyledHeader = styled.header`
	flex-basis: 165px;
	flex-grow: 1;

	${mq.mobile`

	`}

	.sub-info {
		display: flex;

		img {
			border-radius: 8px;
			margin-right: 20px;
			width: 56px;
			height: 56px;

			${mq.mobile`
				width: 36px;
				height: 36px;
			`}
		}

		.sub-title {
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			h1 {
				font-weight: ${fonts.weights.semibold};
				font-size: ${fonts.sizes.h1};
				line-height: 24px;
				margin: 0;

				${mq.mobile`
					font-size: ${fonts.sizes.med};
				`}
			}

			h2 {
				font-weight: ${fonts.weights.regular};
				color: ${brand.black};
				line-height: 20px;
				font-size: ${fonts.sizes.semiLarge};
				margin: 0;

				${mq.mobile`
					font-weight: ${fonts.weights.regular};
					font-size: ${fonts.sizes.larger};
				`}
			}

			.venue-picker {
				margin-top: 8px;
			}
		}
	}
`;

const StyledDefaultLogo = styled.div`
	border-radius: 8px;
	margin-right: 20px;
	width: 56px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	background: ${brand.black};
	color: ${brand.white};
	font-size: ${fonts.sizes.large};
	font-weight: ${fonts.weights.semibold};

	${mq.mobile`
		width: 36px;
		height: 36px;
		margin-right: 12px;
		font-size: ${fonts.sizes.standard};
	`}
`;

/** Renders header component */
const Header: React.FC<IComponentProps> = ({
	title,
	showVenueName = true,
	className,
}) => {
	const { selectedVenue, venues } = useSelector(
		(state: RootState) => state.venue
	);

	// Split string on space, map and join together
	const venueInitials = selectedVenue?.name
		.split(' ')
		.map((n) => n[0])
		.join('');

	return (
		<StyledHeader data-testid="header" className={className}>
			<div className="sub-info">
				{selectedVenue?.logoUrl ? (
					<img src={selectedVenue?.logoUrl} alt="" />
				) : (
					<StyledDefaultLogo>{venueInitials}</StyledDefaultLogo>
				)}
				<div className="sub-title">
					<h1>
						<FormattedMessage id={title} />
					</h1>
					{venues.length > 1 ? (
						<div className="venue-picker">
							<VenuePicker venues={venues} />
						</div>
					) : (
						showVenueName && <h2>{selectedVenue?.name}</h2>
					)}
				</div>
			</div>
		</StyledHeader>
	);
};

export default Header;
