import React, { FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../root.reducer';
import { updateVersionNumber } from './store-version.slice';

/** Component to check the local store app version */
const StoreVersion: FunctionComponent = () => {
	// Get dispatch
	const dispatch = useDispatch();
	// Get app version from environment variables
	const latestVersionNumber = process.env.REACT_APP_VERSION;
	// Get version from current store
	const localVersionNumber: string =
		useSelector((state: RootState) => state.storeVersion?.versionNumber) || '';

	// Run once on load
	useEffect(() => {
		// if version mismatch, set new version number
		latestVersionNumber !== localVersionNumber &&
			dispatch(updateVersionNumber(latestVersionNumber!));
	});

	return <></>;
};

export default StoreVersion;
