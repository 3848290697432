import { EOrderType } from 'app.types';
import { IAlertConfig } from 'modules/orders/order.types';
import { IServiceType } from 'modules/venue/venue.types';

interface IMappedServiceTypeAlertConfig {
	serviceType: keyof typeof EOrderType;
	config: IAlertConfig;
}

// Filter services types if they have alert config and transform to a new config
export const filterByActiveAndTransformServiceTypeAlertConfig = (
	serviceTypes: IServiceType[]
): IMappedServiceTypeAlertConfig[] => {
	if (!Array.isArray(serviceTypes)) {
		return [];
	}

	return serviceTypes
		.filter((serviceType) => serviceType?.orderAlertConfig?.enabled)
		.map((serviceType) => ({
			serviceType: serviceType.type,
			config: {
				...serviceType.orderAlertConfig,
				prepTime: serviceType.prepTime,
			},
		}));
};
