import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
	Container,
	DevicesList,
	Headings,
	Title,
} from './terminals-list.styles';

import { IDevice } from 'modules/terminal/terminal.types';

interface IComponentProps {
	devices?: IDevice[];
}

const TerminalsList: React.FC<IComponentProps> = ({ devices }) => {
	return (
		<Container>
			<Title>
				<FormattedMessage id="terminal.list.title" />
			</Title>
			<Headings>
				<div>
					<FormattedMessage id="terminal.list.headings.deviceId" />
				</div>
				<div>
					<FormattedMessage id="terminal.list.headings.status" />
				</div>
				<div>
					<FormattedMessage id="terminal.list.headings.registered" />
				</div>
				<div />
			</Headings>
			<DevicesList>
				{devices?.map((device) => (
					<li key={device.uniqueDeviceIdentifier}>
						<div>{device.uniqueDeviceIdentifier}</div>
						<div>{device.status}</div>
						<div>
							{dayjs(device.createdAt)
								.tz('Europe/London')
								.format('Do MMMM YYYY')}
						</div>
						<div />
					</li>
				))}
			</DevicesList>
		</Container>
	);
};

export default TerminalsList;
