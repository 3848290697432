import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';

export const Container = styled.div<{ viewHeight: number }>`
	min-height: calc(${({ viewHeight }) => viewHeight}px * 100 - 200px);
	display: flex;
	border-radius: 8px;
	font-size: 15px;
	background: ${brand.white};
	flex-direction: column;
	overflow: auto;

	${({ viewHeight }) => mq.mobile`
		min-height: auto;
		min-height: calc(${viewHeight}px * 100 - 250px);
	`}
`;
