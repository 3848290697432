import React from 'react';

import DeviceSelect from './device-select/device-select.component';
import ProgressIndicator from './progress-indicator/progress-indicator.component';
import StripeInstall from './stripe-install/stripe-install.component';
import TerminalPaired from './terminal-paired/terminal-paired.component';
import { Container, StepWrapper } from './terminal-registration.styles';
import VerifyTerminal from './verify-terminal/verify-terminal.component';

import useViewHeight from 'components/modal/hooks/use-view-height.hook';

interface IComponentProps {
	step: string;
}

const TerminalRegistration: React.FC<IComponentProps> = ({ step }) => {
	const viewHeight = useViewHeight();

	return (
		<Container viewHeight={viewHeight}>
			<ProgressIndicator step={step} device="terminal" />
			<StepWrapper>
				{step === 'device' && <DeviceSelect />}
				{step === 'install' && <StripeInstall />}
				{step === 'verify' && <VerifyTerminal />}
				{step === 'paired' && <TerminalPaired />}
			</StepWrapper>
		</Container>
	);
};

export default TerminalRegistration;
