import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
	Container,
	DevicesList,
	StyledDevice,
	StyledInfo,
	StyledLabel,
	StyledRow,
	Title,
} from './terminals-list-mobile.styles';

import { IDevice } from 'modules/terminal/terminal.types';

interface IComponentProps {
	devices?: IDevice[];
}

const TerminalsListMobile: React.FC<IComponentProps> = ({ devices }) => {
	return (
		<Container>
			<Title>
				<FormattedMessage id="terminal.list.title" />
			</Title>
			<DevicesList>
				{devices?.map((device) => (
					<StyledDevice key={device.uniqueDeviceIdentifier}>
						<StyledRow>
							<StyledLabel>
								<FormattedMessage id="terminal.list.headings.deviceId" />
							</StyledLabel>
							<StyledInfo>{device.uniqueDeviceIdentifier}</StyledInfo>
						</StyledRow>
						<StyledRow>
							<StyledLabel>
								<FormattedMessage id="terminal.list.headings.registered" />
							</StyledLabel>
							<StyledInfo>
								{dayjs(device.createdAt)
									.tz('Europe/London')
									.format('Do MMMM YYYY')}
							</StyledInfo>
						</StyledRow>
						<StyledRow>
							<StyledLabel>
								<FormattedMessage id="terminal.list.headings.status" />
							</StyledLabel>
							<StyledInfo>{device.status}</StyledInfo>
						</StyledRow>
					</StyledDevice>
				))}
			</DevicesList>
		</Container>
	);
};

export default TerminalsListMobile;
