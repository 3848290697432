import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import Icon from 'components/icons/icon.component';

export const Container = styled.div`
	display: flex;
	align-items: center;
	padding: 20px 34px 18px;
	background: ${rgba(brand.body, 0.5)};
`;

export const ProgressWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-left: auto;
`;
export const ProgressStep = styled.div`
	width: 50px;
	margin-right: 80px;
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;

	${mq.mobile`margin-right: 40px;`}

	&:last-child {
		margin-right: 0;
	}

	span {
		margin-bottom: 12px;
		font-size: ${fonts.sizes.small};
		font-weight: ${fonts.weights.medium};
		color: ${brand.black};
		text-transform: capitalize;
	}

	&:last-child div:after {
		display: none;
	}
`;

export const ProgressIcon = styled.div<{
	variant?: string;
	completed: boolean;
}>`
	width: 18px;
	height: 18px;
	border: 2px solid ${brand.black};
	border-radius: 50%;
	opacity: 0.2;
	position: relative;

	&:after {
		width: 114px;
		height: 2px;
		content: '';
		display: block;
		background: ${brand.black};
		position: absolute;
		top: 50%;
		left: 100%;

		${mq.mobile`width: 74px;`}
	}

	${({ variant }) => {
		if (variant === 'active') {
			return css`
				opacity: 1;
				border-color: ${brand.validation_success};
				background: ${brand.white};

				&:after {
					opacity: 0.2;
				}
			`;
		}
		return null;
	}}

	${({ completed }) => {
		if (completed) {
			return css`
				opacity: 1;
				border-color: ${brand.validation_success};
				background: ${brand.validation_success};

				&:after {
					opacity: 1;
					background: ${brand.validation_success};
				}
			`;
		}
		return null;
	}}
`;

export const CloseIcon = styled(Button)`
	margin-left: auto;
`;

export const CompletedIcon = styled(Icon)<{ completed: boolean }>`
	position: relative;
	top: -2px;
	left: -1.5px;

	${({ completed }) => {
		if (!completed) {
			return css`
				display: none;
			`;
		}
		return null;
	}}
`;
