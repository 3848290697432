import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Container, LoadingWrapper } from './install-loading.styles';

import Button from 'components/button/button.component';
import LogoAnimation from 'components/logo-animation/logo-animation.component';

const InstallLoading: React.FC = () => {
	const history = useHistory();

	return (
		<Container>
			<LoadingWrapper>
				<LogoAnimation />
			</LoadingWrapper>
			<h2>
				<FormattedMessage id="terminal.install.installing.title" />
			</h2>
			<p>
				<FormattedMessage id="terminal.install.installing.text" />
			</p>
			<Button
				type="button"
				onClick={() => {
					history.push('/terminal/verify');
				}}
			>
				<FormattedMessage id="terminal.install.installing.continue" />
			</Button>
		</Container>
	);
};

export default InstallLoading;
