import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';

export const Title = styled.h2`
	margin-bottom: 35px;
`;

export const Container = styled.div`
	height: 100%;
	display: flex;
	padding: 40px 40px 100px 40px;
	flex-direction: column;
	overflow: auto;
`;

const ColumnStyling = css`
	font-size: ${fonts.sizes.standard};

	div {
		width: 211px;
		padding: 7px 20px;
		margin-right: 10px;

		&:nth-child(2) {
			width: 130px;
		}

		&:last-child {
			width: 100px;
			margin-left: auto;
			margin-right: 0;
			flex-shrink: 0;
		}
	}
`;

const RowStyling = css`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	${ColumnStyling}
`;

export const Headings = styled.div`
	${RowStyling}
	font-weight: ${fonts.weights.medium};
	opacity: 0.3;
`;

export const DevicesList = styled.ul`
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		list-style: none;
		padding: 0;
		margin: 0 0 7px 0;
		background: ${brand.neutral};
		line-height: ${fonts.line_height.larger};

		${RowStyling}

		div:first-child {
			font-weight: ${fonts.weights.medium};
			font-size: ${fonts.sizes.med};
		}

		div:last-child {
			width: 140px;
		}
	}
`;

export const DeactivateButton = styled(Button)`
	width: 100px;
	flex-shrink: 0;
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.medium};
`;
