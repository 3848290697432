/** structure variables */
const structure = {
	nav_width: '80px',
	nav_mobile_height: '53px',
	nav_width_closed: '80px',
	nav_width_open: '250px',
	form_max_width: '1250px',
};

export default structure;
