import { Severity } from '@sentry/react';
import axios, { AxiosError } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { Store } from 'redux';

import { handleApiError } from '../error/error.service';
import errorLogger from '../error/helpers/error-logger.helper';
import { history } from '../routing/app-router.component';

import { mockedAuthEndpoints } from 'modules/auth/auth.mock';
import { processLogout } from 'modules/auth/auth.slice';
import { mockedOrdersEndpoints } from 'modules/orders/order.mock';
import { mockedStockEndpoints } from 'modules/stock/stock.mock';
import { mockedTerminalEndpoints } from 'modules/terminal/terminal.mock';
import { mockedVenueEndpoints } from 'modules/venue/venue.mock';

/** Create and configure axios instance */
const httpClient = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	timeout: parseFloat(process.env.REACT_APP_AXIOS_TIMEOUT!),
	withCredentials: true,
});

/** Create a mocked axios client */
export const mockHttpClient = new MockAdapter(httpClient, {
	// Only mock matched/configured routes
	onNoMatch: 'passthrough',
});

/** Mocked endpoints */
const mockedEndpoints = () => {
	/** STOCK */
	mockedStockEndpoints(mockHttpClient);
	/** AUTH */
	mockedAuthEndpoints(mockHttpClient);
	/** VENUE */
	mockedVenueEndpoints(mockHttpClient);
	/** ORDERS */
	mockedOrdersEndpoints(mockHttpClient);
	/** TERMINAL */
	mockedTerminalEndpoints(mockHttpClient);
};

// If api mocking is enabled - mock api
process.env.REACT_APP_API_MOCKING === 'true' && mockedEndpoints();

// Set the retry wait time to axios timeout / 5
const retryWaitTime = parseFloat(process.env.REACT_APP_AXIOS_TIMEOUT!) / 5;

// Axios redux middleware config
export const httpMiddlewareConfig = {
	interceptors: {
		response: [
			{
				async error({ getState, dispatch }: Store, error: AxiosError) {
					// If, error was a 401
					if (error?.response?.status === 401) {
						// eslint-disable-next-line no-console
						console.log('httpMiddlewareConfig - got a 401');
						// If access token refreshing
						if (getState().auth.refreshToken.refreshing) {
							// eslint-disable-next-line no-console
							console.log('httpMiddlewareConfig - isRefreshing');
							// Retry the request 5 times
							for (let i = 0; i < 5; i++) {
								// eslint-disable-next-line no-console
								console.log(`httpMiddlewareConfig - in retry loop (${i})`);

								// eslint-disable-next-line no-await-in-loop
								await new Promise((resolve) =>
									setTimeout(resolve, retryWaitTime)
								);

								try {
									// eslint-disable-next-line no-console
									console.log(
										`httpMiddlewareConfig - retrying (${i})`,
										error.config.url
									);

									// eslint-disable-next-line no-await-in-loop
									const retry = await axios(error.config);

									// eslint-disable-next-line no-console
									console.log(`httpMiddlewareConfig - retried (${i})`, retry);

									if (retry.status === 200) {
										// eslint-disable-next-line no-console
										console.log(
											`httpMiddlewareConfig - retry success (${i})`,
											retry
										);
										return retry;
									}
								} catch (e) {
									// eslint-disable-next-line no-console
									console.error(`httpMiddlewareConfig - retry fail (${i})`, e);
								}
							}
						}
						// eslint-disable-next-line no-console
						console.log('httpMiddlewareConfig - should not get here on retry');

						try {
							// eslint-disable-next-line no-await-in-loop
							await new Promise((resolve) =>
								setTimeout(resolve, retryWaitTime)
							);

							// eslint-disable-next-line no-console
							console.log(
								'httpMiddlewareConfig - final retry',
								error.config.url
							);

							// Retry request one last time
							const finalTry = await axios(error.config);

							// eslint-disable-next-line no-console
							console.log('httpMiddlewareConfig - retried (final)', finalTry);

							if (finalTry.status === 200) {
								return finalTry;
							}
						} catch (e) {
							// eslint-disable-next-line no-console
							console.error('httpMiddlewareConfig - final retry fail', e);
							// If user logged in
							if (getState().auth?.user?.id) {
								// eslint-disable-next-line no-console
								console.error('httpMiddlewareConfig - WOULD logout', e);
								// Track 401 logout event
								errorLogger({
									message: 'Logout (HTTP)',
									level: Severity.Warning,
									extra: { error: e, authState: getState().auth },
								});

								// @ts-ignore // Call logout thunk
								await dispatch(processLogout());
								// Redirect user to login
								history.push('/');

								return handleApiError(error);
							}
							// Track 401 logout event
							errorLogger({
								message: 'Logout (HTTP) - no auth',
								level: Severity.Warning,
								extra: { error: e, authState: getState().auth },
							});
						}
					}

					return handleApiError(error);
				},
			},
		],
	},
};

// Export auth API as object
export default httpClient;
