import React, { useState } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import OrderDetailsModal from 'components/order-details-modal/order-details-modal.component';
import OrderIcon from 'components/order-icon/order-icon.component';
import StatusPill from 'components/status-pill/status-pill.component';
import dayjs from 'helpers/dayjs.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { IOrder } from 'modules/orders/order.types';

// Interface for component props
interface IComponentProps {
	order: IOrder;
}

const StyledListItem = styled.li`
	display: flex;
	align-items: center;
	padding: 8px 0px 9px 15px;
	margin: 0 0 7px;
	background: ${brand.white};
	border-radius: 5px;
	box-shadow: 0px 3px 7px rgba(${brand.black}, 0.1);

	> div {
		&.mod-clickable {
			outline: none;
			cursor: pointer;
		}

		&:last-child {
			padding-right: 25px;
		}
	}
`;

const StyledStatusPill = styled(StatusPill)`
	margin: 0 7px 0 0;
`;

/** Renders future orders list item component */
const FutureOrdersListItem: React.FC<IComponentProps> = ({ order }) => {
	// Get order collection/order time
	const orderDate = order.collectAt || order.orderedAt;
	// state for modal
	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	let allergenList: string = '';

	!!order.customer.allergens &&
		order.customer.allergens?.length > 0 &&
		order.customer.allergens.forEach((allergen, index) => {
			if (index === 0) {
				allergenList += allergen.name;
			} else {
				allergenList += ` | ${allergen.name}`;
			}
		});

	return (
		<StyledListItem>
			<div className="mod-item">
				<OrderIcon
					variant={order.type}
					customIcon={order?.customService?.imageUrl}
				/>
			</div>
			<div className="mod-item">
				{dayjs(orderDate).tz('Europe/London').format('HH:mm')}
			</div>
			<div className="mod-item" data-private>
				{order.customer.name || intl.formatMessage({ id: 'order.unkownName' })}
			</div>
			<div className="mod-item">
				{intl.formatNumber(order.total.units / 100, {
					style: 'currency',
					currency: order.total.currencyCode,
				})}
			</div>
			<div className="mod-item">
				{!!allergenList && (
					<StyledStatusPill type="warning" backgroundColour="order_allergy">
						{allergenList}
					</StyledStatusPill>
				)}
				{!!order.notes && (
					<StatusPill type="query" backgroundColour="order_query" />
				)}
			</div>
			<div
				className="mod-item mod-clickable"
				role="button"
				tabIndex={0}
				onClick={() => toggleModal()}
				onKeyPress={() => toggleModal()}
			>
				<Icon name="chevron" width={14} height={14} colour="primary" />
				<OrderDetailsModal
					{...order}
					isOpen={isModalOpen}
					toggleModal={toggleModal}
				/>
			</div>
		</StyledListItem>
	);
};

export default FutureOrdersListItem;
