import { Field } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import WaitTimeHeadings from '../wait-time-headings/wait-time-headings.component';
import {
	ItemLabel,
	Container,
	Switch,
	Select,
	SwitchContainer,
	DisplayUntilContainer,
	WaitTimeContainer,
} from './wait-time-row.styles';

type FieldProps = React.ComponentProps<typeof Field>;

export interface WaitTimeRowProps {
	labelMessageId: string;
	switchFieldProps: FieldProps;
	waitTimeFieldProps: FieldProps;
	displayUntilProps: FieldProps;
}

const WaitTimeRow: React.FC<WaitTimeRowProps> = ({
	labelMessageId,
	displayUntilProps,
	waitTimeFieldProps,
	switchFieldProps,
}) => {
	return (
		<Container>
			<ItemLabel>
				<FormattedMessage id={labelMessageId} />
			</ItemLabel>

			<SwitchContainer>
				<Field component={Switch} {...switchFieldProps} />
			</SwitchContainer>

			<WaitTimeContainer>
				<Field component={Select} disableErrorMessage {...waitTimeFieldProps} />
			</WaitTimeContainer>

			<DisplayUntilContainer>
				<Field component={Select} disableErrorMessage {...displayUntilProps} />
			</DisplayUntilContainer>

			<WaitTimeHeadings />
		</Container>
	);
};

export default WaitTimeRow;
