import MockAdapter from 'axios-mock-adapter/types';

import { IStockItem, IStockModifierDetails } from './stock.types';

import dayjs from 'helpers/dayjs.helper';
/** MOCK DATA */

const stockItem: IStockItem = {
	id: '1',
	imageUrl: 'https://via.placeholder.com/38.jpg',
	sku: 'sku',
	name: 'product',
	price: 100,
	isOutOfStock: false,
	updatedAt: '',
	isProduct: true,
};

const modifierItem: IStockModifierDetails = {
	id: '1',
	title: 'a modifier item',
	price: 300,
	overridesProductPrice: false,
};

const stockList: IStockItem[] = [
	{
		id: '1',
		imageUrl: 'https://via.placeholder.com/38.jpg',
		sku: 'sku',
		name: 'product',
		price: 100,
		isOutOfStock: false,
		updatedAt: '',
		isProduct: true,
	},
	{
		id: '2',
		imageUrl: 'https://via.placeholder.com/38.jpg',
		sku: 'sku2',
		name: 'product 2',
		price: 199,
		isOutOfStock: true,
		updatedAt: '',
		isProduct: false,
	},
];

/** MOCKED ENDPOINT CONFIG */

const getStockList = {
	response: {
		data: stockList,
		pagination: {
			pageSize: 20,
			pageNumber: 1,
			pageCount: 1,
		},
	},
};

const getModifierDetails = {
	response: {
		data: modifierItem,
	},
};

const updateModifierDetails = {
	request: {
		title: 'a modifier item',
		price: '300',
	},
};

const updateStockList = {
	request: {
		isOutOfStock: false,
		backInStockDate: dayjs().add(1, 'day').startOf('day').format(),
	},
};

const updateProductDetails = {
	request: {
		name: 'product',
		price: 500,
	},
};

export const mockedStockEndpoints = (mockHttpClient: MockAdapter) => {
	// Regex for order item update status url
	const getStockListRegex = new RegExp('venue/.*/stock');

	const putStockItemRegex = new RegExp('venue/.*/stock/.*');

	const patchProductDetails = new RegExp('menus/product/.*');

	const ModifierDetails = new RegExp('operator/modifiers/.*');

	/** get stock list */
	mockHttpClient.onGet(getStockListRegex).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200, mockStock.endpoints.getStockList.response]);
			}, 1000);
		});
	});

	/** put stock item */
	mockHttpClient.onPut(putStockItemRegex).reply((config) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// Return 200 success and mock response
				resolve([200]);
			}, 1000);
		});
	});

	mockHttpClient.onPatch(patchProductDetails).reply((config) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// return 200 success and response
				resolve([200]);
			}, 1000);
		});
	});

	mockHttpClient.onGet(ModifierDetails).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve([200, mockStock.endpoints.getModifierDetails.response]);
			}, 1000);
		});
	});

	mockHttpClient.onPatch(ModifierDetails).reply(() => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve([200]);
			}, 1000);
		});
	});
};

const mockStock = {
	data: {
		stockItem,
		stockList,
		modifierItem,
	},
	endpoints: {
		getStockList,
		updateStockList,
		updateProductDetails,
		getModifierDetails,
		updateModifierDetails,
	},
};

export default mockStock;
