import { rgba } from 'polished';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { keyframeBoxShadowPulse } from 'assets/styles/variables/animations';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { RootState } from 'modules/core/state/root.reducer';

interface IStyledComponentProps {
	colour: string;
}

const StyledDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ${fonts.sizes.standard};

	${mq.mobile`
		justify-content: flex-end !important;

		span {
			display: none;
		}
	`}
`;

const StyledIndicator = styled.div<IStyledComponentProps>`
	border-radius: 50%;
	width: 8px;
	height: 8px;
	box-shadow: 0 0 0 ${({ colour }) => rgba(colour, 0.4)};
	background-color: ${({ colour }) => colour};
	margin-right: 9px;
	animation: ${({ colour }) => keyframeBoxShadowPulse(colour)} 1.3s infinite;

	${mq.mobile`
		margin-right: 20px;
	`}
`;

/** Renders Live Indicator component */
const LiveIndicator: FunctionComponent = () => {
	const [indicatorColour, setIndicatorColour] = useState<string>(
		brand.validation_success
	);
	const [indicatorId, setIndicatorId] = useState<string>('live');

	const { connectionStatus } = useSelector((state: RootState) => state.pusher);

	useEffect(() => {
		if (
			connectionStatus === 'initialized' ||
			connectionStatus === 'connecting'
		) {
			setIndicatorColour(brand.validation_warning);
			setIndicatorId('retrying');
		} else if (connectionStatus === 'connected') {
			setIndicatorColour(brand.validation_success);
			setIndicatorId('live');
		} else {
			setIndicatorColour(brand.validation_error);
			setIndicatorId('offline');
		}
	}, [connectionStatus]);

	return (
		<StyledDiv>
			<StyledIndicator colour={indicatorColour} />
			<span>
				<FormattedMessage id={`navigation.live_indicator.${indicatorId}`} />
			</span>
		</StyledDiv>
	);
};

export default LiveIndicator;
