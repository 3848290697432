import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import {
	IOrderStore,
	EOrderStatus,
	IOrderRefund,
	IOrderItem,
	IRefundItem,
	IPayloadProcessOrderStatus,
	IPayloadProcessOrderItemStatus,
	IOrderItemStatus,
	ICustomerArrived,
	IOrder,
	IPayloadProcessOrder,
	IPayloadCustomerArrived,
	IPayloadProcessOrderRefund,
	IPayloadProcessOrderNote,
	IOrderListFilters,
	ICompletedOrdersFilters,
	IPayloadProcessOrderAlertConfig,
	IPayloadProcessOrderAlertConfigUpdate,
	IAlertConfig,
	IPayloadPrintStatus,
	IPrintJob,
} from './order.types';

import { SET_LOADING_CONFIG } from 'components/loading/loading.slice';
import { isOrderCompleted } from 'helpers/completed-orders.helper';
import { filterByActiveAndTransformServiceTypeAlertConfig } from 'helpers/filter-service-type-alert-config.helper';
import {
	isOrderInAlert,
	isOrderInEscalation,
} from 'helpers/order-alert-times.helpers';
import { intl } from 'modules/core/i18n/i18n.config';
import { IServiceType } from 'modules/venue/venue.types';

// Create initial state
export const initialOrderState: IOrderStore = {
	eventsInProgress: 0,
	ordersList: [],
	printStatusList: {},
};

const orderSlice = createSlice({
	name: 'order',
	initialState: initialOrderState,
	reducers: {
		ORDER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		ORDER_SUCCESS(state, action) {
			return {
				...state,
				order: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDERS_COMPLETED(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		ORDERS_COMPLETED_SUCCESS(state, action) {
			return {
				...state,
				ordersList: action.payload.data.orders,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDERS_COMPLETED_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDERS_LIST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		ORDERS_LIST_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDERS_LIST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDER_UPDATE_STATUS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		ORDER_UPDATE_STATUS_SUCCESS(state, action) {
			return processOrderStatusUpdate(state, action);
		},
		ORDER_UPDATE_STATUS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDER_ITEM_UPDATE_STATUS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		ORDER_ITEM_UPDATE_STATUS_SUCCESS(state, action) {
			return procressOrderItemStatusUpdate(state, action);
		},
		ORDER_ITEM_UPDATE_STATUS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDER_REFUND(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		ORDER_REFUND_SUCCESS(state, action) {
			return processOrderRefund(state, action);
		},
		ORDER_REFUND_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDER_QUERY(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		ORDER_QUERY_SUCCESS(state, action) {
			return processOrderNote(state, action);
		},
		ORDER_QUERY_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDER_RECEIVED(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		ORDER_RECEIVED_SUCCESS(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ORDER_RECEIVED_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_ORDERS_LIST(state, action) {
			return {
				...state,
				ordersList: [...action.payload.orders],
			};
		},
		ORDER_PUSHER_UPDATE_ARRIVED(state, action) {
			return processCustomerArrivedUpdate(state, action);
		},
		ORDER_PUSHER_UPDATE_ORDER(state, action) {
			return processOrderUpdate(state, action);
		},
		ORDER_PUSHER_NEW_ORDER(state, action) {
			return processNewOrder(state, action);
		},
		ORDER_ADD_ALERT_CONFIG(state, action) {
			return parseOrderAlertConfigToOrders(state, action);
		},
		ORDER_UPDATE_ALERT_CONFIG(state, action) {
			return processOrderAlertConfigUpdate(state, action);
		},
		GET_PRINT_JOB(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PRINT_JOB_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRINT_JOB_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRINT_STATUS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PRINT_STATUS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRINT_STATUS_SUCCESS(state, action) {
			const statusList = { ...state.printStatusList };

			action.payload.data.forEach((value: IPayloadPrintStatus) => {
				statusList[value.orderId] = value.status;
			});

			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				printStatusList: statusList,
			};
		},
		RESET_ORDER_STATE() {
			return initialOrderState;
		},
	},
});

// Destructure and export the plain action creators
export const {
	ORDER_FAIL,
	ORDER_ITEM_UPDATE_STATUS_FAIL,
	ORDER_ITEM_UPDATE_STATUS_SUCCESS,
	ORDER_ITEM_UPDATE_STATUS,
	ORDER_QUERY_FAIL,
	ORDER_QUERY_SUCCESS,
	ORDER_QUERY,
	ORDER_RECEIVED,
	ORDER_RECEIVED_SUCCESS,
	ORDER_RECEIVED_FAIL,
	ORDER_REFUND_FAIL,
	ORDER_REFUND_SUCCESS,
	ORDER_REFUND,
	ORDER_SUCCESS,
	ORDER_UPDATE_STATUS_FAIL,
	ORDER_UPDATE_STATUS_SUCCESS,
	ORDER_UPDATE_STATUS,
	ORDER,
	ORDERS_COMPLETED_FAIL,
	ORDERS_COMPLETED_SUCCESS,
	ORDERS_COMPLETED,
	ORDERS_LIST_FAIL,
	ORDERS_LIST_SUCCESS,
	ORDERS_LIST,
	SET_ORDERS_LIST,
	ORDER_PUSHER_UPDATE_ARRIVED,
	ORDER_PUSHER_UPDATE_ORDER,
	ORDER_PUSHER_NEW_ORDER,
	ORDER_ADD_ALERT_CONFIG,
	ORDER_UPDATE_ALERT_CONFIG,
	GET_PRINT_JOB,
	GET_PRINT_JOB_FAIL,
	GET_PRINT_JOB_SUCCESS,
	GET_PRINT_STATUS,
	GET_PRINT_STATUS_FAIL,
	GET_PRINT_STATUS_SUCCESS,
	RESET_ORDER_STATE,
} = orderSlice.actions;

/** Thunk to get an order */
export const getOrder = (orderId: string) => async (dispatch: Dispatch) => {
	try {
		// Create request
		const { payload } = await dispatch(
			ORDER({
				request: {
					method: 'get',
					url: `order/${orderId}`,
				},
			})
		);

		return payload.data;
	} catch (error) {
		throw new Error('Could not get order');
	}
};

/** Thunk to get current/future orders list */
export const getOrdersList = (
	venueId: string,
	dateFrom: string = '',
	filters?: IOrderListFilters
) => async (dispatch: Dispatch) => {
	// set loading config
	dispatch(
		SET_LOADING_CONFIG({
			data: {
				loadingMessage: intl.formatMessage({
					id: 'getOrders.loading.message',
				}),
			},
		})
	);

	const initialRequest = await dispatch(
		ORDERS_LIST({
			request: {
				method: 'get',
				url: 'order',
				params: {
					dateFrom,
					venueId,
					menuId: filters?.menuIds,
					pageSize: 100,
				},
			},
		})
	);

	if (initialRequest.payload.status !== 200) {
		// Reset loading config
		dispatch(
			SET_LOADING_CONFIG({ data: { loadingMessage: '', loadingTimeout: 0 } })
		);
		return false;
	}

	// Create store for orders
	const orders: IOrder[] = [...initialRequest.payload.data.orders];

	// get page count from initial request
	const pageCount = initialRequest.payload.data.pagination?.pageCount;

	// If we have more than 1 page
	if (pageCount > 1) {
		// loop through page count
		for (let i = 1; i < pageCount; i++) {
			// get order results for page
			// eslint-disable-next-line no-await-in-loop
			const { payload } = await dispatch(
				ORDERS_LIST({
					request: {
						method: 'get',
						url: 'order',
						params: {
							dateFrom,
							venueId,
							menuId: filters?.menuIds,
							pageSize: 100,
							pageNumber: i + 1,
						},
					},
				})
			);

			if (payload.status !== 200) {
				// Reset loading config
				dispatch(
					SET_LOADING_CONFIG({
						data: { loadingMessage: '', loadingTimeout: 0 },
					})
				);
				return false;
			}

			// Push orders to array of orders
			orders.push(...payload.data.orders);
		}
	}

	// Sort orders when we get them all
	const sortedOrders = orders.sort((a, b) => {
		const aDate = a.collectAt || a.orderedAt;
		const bDate = b.collectAt || b.orderedAt;
		if (aDate === bDate) {
			return 0;
		}
		return aDate! > bDate! ? 1 : -1;
	});

	// Get print job status for all orders
	await dispatch(
		GET_PRINT_STATUS({
			request: {
				method: 'post',
				url: 'receipts/printjob/status',
				data: {
					orderIds: orders.map((order) => order.id),
				},
			},
		})
	);

	//  update orders list in state with the array we created
	dispatch(SET_ORDERS_LIST({ orders: sortedOrders }));

	// Reset loading config
	dispatch(
		SET_LOADING_CONFIG({ data: { loadingMessage: '', loadingTimeout: 0 } })
	);

	return orders;
};

/** Thunk to get completed orders */
export const getCompletedOrders = (filters: ICompletedOrdersFilters) => async (
	dispatch: Dispatch
) => {
	try {
		// Create request
		const { payload } = await dispatch(
			ORDERS_COMPLETED({
				request: {
					method: 'get',
					url: 'order/completed',
					params: filters,
				},
			})
		);

		return payload.data;
	} catch (error) {
		return {};
	}
};

/** Thunk to update an order status */
export const updateOrderStatus = (
	orderId: string,
	status: keyof typeof EOrderStatus
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		ORDER_UPDATE_STATUS({
			request: {
				method: 'post',
				url: `order/${orderId}/status`,
				data: { status },
			},
		})
	);

	return {
		newOrderStatus: payload.data,
	};
};

/** Thunk update item status on an order */
export const updateItemStatus = (
	orderId: string,
	itemId: string,
	status: IOrderItemStatus
) => async (dispatch: Dispatch) => {
	try {
		// Create request
		const { payload } = await dispatch(
			ORDER_ITEM_UPDATE_STATUS({
				request: {
					method: 'put',
					url: `order/${orderId}/item/${itemId}/status`,
					data: status,
				},
			})
		);
		return {
			newItemStatus: payload.data,
		};
	} catch (error) {
		return {};
	}
};

/** Thunk to refund an entire order or specific items */
export const refundOrder = (refund: IOrderRefund) => async (
	dispatch: Dispatch
) => {
	try {
		// variable for request timeout
		const requestTimeout: number = 60000;
		// set loading config
		dispatch(
			SET_LOADING_CONFIG({
				data: {
					loadingMessage: intl.formatMessage({
						id: 'order.refund.loading.message',
					}),
					loadingTimeout: requestTimeout,
				},
			})
		);
		// Create request
		const { payload } = await dispatch(
			ORDER_REFUND({
				request: {
					method: 'post',
					url: `order/${refund.id}/refund`,
					data: refund,
					timeout: requestTimeout,
				},
			})
		);

		// Reset loading config
		dispatch(
			SET_LOADING_CONFIG({ data: { loadingMessage: '', loadingTimeout: 0 } })
		);

		return {
			refund: payload.response === 200,
		};
	} catch {
		// Reset loading config
		dispatch(
			SET_LOADING_CONFIG({ data: { loadingMessage: '', loadingTimeout: 0 } })
		);
		return false;
	}
};

/** Thunk to add a note to an order */
export const orderNote = (orderId: string, notes: string) => async (
	dispatch: Dispatch
) => {
	try {
		// Create request
		const { payload } = await dispatch(
			ORDER_QUERY({
				request: {
					method: 'post',
					url: `order/${orderId}/notes`,
					data: { notes },
				},
			})
		);

		return {
			orderQuery: payload.data,
		};
	} catch (error) {
		return {};
	}
};

/** Thunk to aknowledge an order has been received */
export const postOrderReceived = (orderId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		ORDER_RECEIVED({
			request: {
				method: 'post',
				url: `order/${orderId}/received`,
				data: { timestamp: new Date().toISOString() },
			},
		})
	);

	return payload?.status === 200;
};

/** Thunk to update when the customer has arrived from pusher */
export const pusherUpdateCustomerArrived = (
	arrivedDate: ICustomerArrived
) => async (dispatch: Dispatch) => {
	// send data
	await dispatch(
		ORDER_PUSHER_UPDATE_ARRIVED({
			data: arrivedDate,
		})
	);
};

/** Thunk to update an order from pusher */
export const pusherUpdateOrder = (order: IOrder) => async (
	dispatch: Dispatch
) => {
	// send data
	await dispatch(
		ORDER_PUSHER_UPDATE_ORDER({
			data: { order },
		})
	);
};

/** Thunk to add alert config to orders */
export const processOrderAlertConfig = (
	orders: IOrder[],
	serviceTypes: IServiceType[]
) => async (dispatch: Dispatch) => {
	// send data
	await dispatch(
		ORDER_ADD_ALERT_CONFIG({
			data: {
				orders,
				serviceTypes,
			},
		})
	);
};

/** Thunk to add update alert config on an order */
export const updateOrderAlertConfig = (
	orderId: string,
	alertConfig: Partial<IAlertConfig>
) => async (dispatch: Dispatch) => {
	// send data
	await dispatch(
		ORDER_UPDATE_ALERT_CONFIG({
			data: {
				orderId,
				alertConfig,
			},
		})
	);
};

/** Thunk to add new order from pusher */
export const pusherNewOrder = (
	order: IOrder,
	serviceTypes: IServiceType[]
) => async (dispatch: Dispatch) => {
	// send data
	await dispatch(
		ORDER_PUSHER_NEW_ORDER({
			data: {
				order,
				serviceTypes,
			},
		})
	);
};

/** Thunk to retrieve quickprinter printjob */
export const printOrder = (orderId: string, reprint = false) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_PRINT_JOB({
			request: {
				method: 'get',
				url: `receipts/printjob/print/${orderId}`,
			},
		})
	);
	if (payload?.status === 200) {
		processPrintOrder(payload.data, reprint);

		// Get print status for this order
		await dispatch(
			GET_PRINT_STATUS({
				request: {
					method: 'post',
					url: 'receipts/printjob/status',
					data: {
						orderIds: [orderId],
					},
				},
			})
		);
	}
};

/** Thunk to retrieve quickprinter test printjob */
export const testPrintOrder = (venueId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_PRINT_JOB({
			request: {
				method: 'post',
				url: `receipts/${venueId}/test`,
			},
		})
	);
	if (payload?.status === 201) {
		processPrintOrder(payload.data, false);
	}
};

/** Thunk to get print status for a specific order */
export const getPrintStatus = (orderId: string) => async (
	dispatch: Dispatch
) => {
	// Get print status for this order
	await dispatch(
		GET_PRINT_STATUS({
			request: {
				method: 'post',
				url: 'receipts/printjob/status',
				data: {
					orderIds: [orderId],
				},
			},
		})
	);
};

/** PROCESSES */

// adds order alret config to all orders
const parseOrderAlertConfigToOrders = (
	state: IOrderStore,
	action: PayloadAction<IPayloadProcessOrderAlertConfig>
) => {
	// Get variables from payload data
	const { serviceTypes, orders } = action.payload.data;

	// filter and map service type alert config
	const serviceTypeAlertConfig = filterByActiveAndTransformServiceTypeAlertConfig(
		serviceTypes
	);

	const updatedOrdersList: IOrder[] = orders.map((order) => {
		const orderServiceType = serviceTypeAlertConfig.filter(
			(alertConfig) => alertConfig.serviceType === order.type
		)[0];

		// default return current order
		if (orderServiceType?.serviceType !== order.type) {
			return order;
		}

		// Add alert config to order
		let orderWithConfig: IOrder = {
			...order,
			alertConfig: orderServiceType.config,
		};

		// update order if order is in prep time
		orderWithConfig = {
			...orderWithConfig,
			alertConfig: {
				...orderWithConfig.alertConfig,
				isOrderInAlert: isOrderInAlert(orderWithConfig),
				isOrderInEscalation: isOrderInEscalation(orderWithConfig),
			},
		};

		// return order
		return orderWithConfig;
	});

	return {
		...state,
		ordersList: updatedOrdersList,
	};
};

// Process order status update
const processOrderStatusUpdate = (
	state: IOrderStore,
	action: PayloadAction<IPayloadProcessOrderStatus>
) => {
	// Get variables from payload data
	const { id, status } = action.payload.data;

	// Get order from state
	const order = state.ordersList.filter((ord) => ord.id === id)[0];

	// Set order details
	const newOrder = {
		...order,
		status,
	};

	// remove old order with filter
	const filteredOrders = state.ordersList.filter(
		(filterOrder) => filterOrder.id !== id
	);
	// Push new order
	filteredOrders.push(newOrder);
	// return state
	return {
		...state,
		ordersList: filteredOrders,
		eventsInProgress: state.eventsInProgress - 1,
	};
};

// Process order alert config update
const processOrderAlertConfigUpdate = (
	state: IOrderStore,
	action: PayloadAction<IPayloadProcessOrderAlertConfigUpdate>
) => {
	const { alertConfig, orderId } = action.payload.data;
	// Get order from state
	const order = state.ordersList.filter((ord) => ord.id === orderId)[0];

	// Correctly set order alert config
	const updatedAlertCofig = order.alertConfig
		? { ...order.alertConfig, ...alertConfig }
		: { ...alertConfig };

	// Set order details
	const newOrder: IOrder = {
		...order,
		alertConfig: updatedAlertCofig,
	};

	// remove old order with filter
	const filteredOrders = state.ordersList.filter(
		(filterOrder) => filterOrder.id !== orderId
	);
	// Push new order
	filteredOrders.push(newOrder);
	// return state
	return {
		...state,
		ordersList: filteredOrders,
	};
};

// Process order item status update
const procressOrderItemStatusUpdate = (
	state: IOrderStore,
	action: PayloadAction<IPayloadProcessOrderItemStatus>
) => {
	// Get variables from payload data
	const { newItemStatus } = action.payload.data;
	const { orderId, itemId, ready, collected } = newItemStatus;

	// Get order from state
	const order = state.ordersList.filter((ord) => ord.id === orderId)[0];

	// Get item that needs updating, choose the fist in the array
	const itemToUpdate = order.items.filter(
		(item: IOrderItem) => item.id === itemId
	)[0];

	const updatedItem = {
		...itemToUpdate,
		ready,
		collected,
	};

	// remove old order item from items list
	const newOrderItems = order.items.filter(
		(item: IOrderItem) => item.id !== itemId
	);

	// Add item
	newOrderItems.push(updatedItem);

	// Check if all items are refunded or collected
	const orderCompleted = isOrderCompleted(newOrderItems);

	// create new order
	const newOrder = {
		...order,
		items: newOrderItems,
		status: orderCompleted ? 'completed' : order.status,
	};

	// remove old order with filter
	const filteredOrders = state.ordersList.filter(
		(filterOrder) => filterOrder.id !== orderId
	);
	// Push new order
	filteredOrders.push(newOrder);
	return {
		...state,
		ordersList: filteredOrders.sort((a, b) => {
			if (a.collectAt === b.collectAt) {
				return 0;
			}
			return a.collectAt! > b.collectAt! ? 1 : -1;
		}),
		eventsInProgress: state.eventsInProgress - 1,
	};
};

// Process customer arrived update from pusher
const processCustomerArrivedUpdate = (
	state: IOrderStore,
	action: PayloadAction<IPayloadCustomerArrived>
) => {
	const { id, arrivedAt } = action.payload.data;
	// Get order from state
	const order = state.ordersList.filter((ord) => ord.id === id)[0];

	// update order with new arrivedAt date
	// create new order
	const newOrder = {
		...order,
		arrivedAt,
	};

	// remove old order with filter
	const filteredOrders = state.ordersList.filter(
		(filterOrder) => filterOrder.id !== id
	);
	// Push updated order
	filteredOrders.push(newOrder);

	return {
		...state,
		ordersList: filteredOrders.sort((a, b) => {
			if (a.collectAt === b.collectAt) {
				return 0;
			}
			return a.collectAt! > b.collectAt! ? 1 : -1;
		}),
	};
};

const processOrderRefund = (
	state: IOrderStore,
	action: PayloadAction<IPayloadProcessOrderRefund>
) => {
	// Get variables from payload data
	const {
		id,
		items,
		reason,
		refundServiceCharge,
		refundTip,
	} = action.payload.data;

	const { ordersList } = state;

	// Get order from state
	const order = ordersList.filter((ord) => ord.id === id)[0];

	const newOrderItems = order.items.map((item: IOrderItem) => {
		const thisItemRefund = items.filter(
			(refundedItem: IRefundItem) => refundedItem.id === item.id
		)[0];

		return {
			...item,
			refundedQuantity:
				thisItemRefund?.quantity + item.refundedQuantity ||
				item.refundedQuantity,
		};
	});

	// Check if all items are refunded or collected
	const orderCompleted = isOrderCompleted(newOrderItems);

	// Set order details
	const newOrder: IOrder = {
		...order,
		items: newOrderItems,
		status: orderCompleted ? 'completed' : order.status,
		refundReason: reason,
	};

	// If we have service charge
	if (newOrder.serviceCharge) {
		// Replace with refunded service charge
		newOrder.serviceCharge = {
			...newOrder.serviceCharge,
			refunded: refundServiceCharge || newOrder.serviceCharge.refunded,
		};
	}

	// If we have tip
	if (newOrder.tip) {
		// Replace with refunded tip
		newOrder.tip = {
			...newOrder.tip,
			isRefunded: refundTip || newOrder.tip.isRefunded,
		};
	}

	// remove old order with filter
	const filteredOrders = state.ordersList.filter(
		(filterOrder) => filterOrder.id !== id
	);

	// Push new order
	filteredOrders.push(newOrder);
	// return state
	return {
		...state,
		ordersList: filteredOrders.sort((a, b) => {
			const aDate = a.collectAt || a.orderedAt;
			const bDate = b.collectAt || b.orderedAt;
			if (aDate === bDate) {
				return 0;
			}
			return aDate! > bDate! ? 1 : -1;
		}),
		eventsInProgress: state.eventsInProgress - 1,
	};
};

const processOrderNote = (
	state: IOrderStore,
	action: PayloadAction<IPayloadProcessOrderNote>
) => {
	// Get variables from payload data
	const { notes, id } = action.payload.data;
	// Get order from state
	const order = state.ordersList.filter((ord) => ord.id === id)[0];

	// Set order details
	const newOrder = {
		...order,
		notes,
	};

	// remove old order with filter
	const filteredOrders = state.ordersList.filter(
		(filterOrder) => filterOrder.id !== id
	);
	// Push new order
	filteredOrders.push(newOrder);
	// return state
	return {
		...state,
		ordersList: filteredOrders.sort((a, b) => {
			const aDate = a.collectAt || a.orderedAt;
			const bDate = b.collectAt || b.orderedAt;
			if (aDate === bDate) {
				return 0;
			}
			return aDate! > bDate! ? 1 : -1;
		}),
		eventsInProgress: state.eventsInProgress - 1,
	};
};

// Process entire order update from pusher
const processOrderUpdate = (
	state: IOrderStore,
	action: PayloadAction<IPayloadProcessOrder>
) => {
	// Get order from data
	const { order } = action.payload.data;

	const ordersList = state.ordersList.map((ord) => {
		if (ord.id !== order.id) {
			return ord;
		}
		return {
			...ord,
			...order,
		};
	});

	return { ...state, ordersList };
};

// Process entire new order from pusher
const processNewOrder = (
	state: IOrderStore,
	action: PayloadAction<IPayloadProcessOrder>
) => {
	// Get order from data
	const { order, serviceTypes } = action.payload.data;

	// Check if existing order
	const orderExists =
		state.ordersList.filter((filterOrder) => filterOrder.id === order.id)
			.length > 0;

	// If order already in the list, do nothing and return state
	if (orderExists) {
		return state;
	}

	// get current orders list
	const newOrdersList = [...state.ordersList];

	// filter and map service type alert config
	const serviceTypeAlertConfig = filterByActiveAndTransformServiceTypeAlertConfig(
		serviceTypes
	);

	const orderServiceType = serviceTypeAlertConfig.filter(
		(alertConfig) => alertConfig?.serviceType === order.type
	)?.[0];

	// Variable to hold new order
	let newOrder: IOrder = order;

	// if we have a service type config for this order type, add to order
	if (orderServiceType?.serviceType === order.type) {
		newOrder = {
			...order,
			alertConfig: orderServiceType?.config,
		};
	}

	// Push new order
	newOrdersList.push(newOrder);

	return {
		...state,
		ordersList: newOrdersList.sort((a, b) => {
			const aDate = a.collectAt || a.orderedAt;
			const bDate = b.collectAt || b.orderedAt;
			return aDate! > bDate! ? 1 : -1;
		}),
	};
};

// Encode and trigger print job through quickprinter
const processPrintOrder = (printJobs: IPrintJob[], reprint: boolean) => {
	printJobs.forEach((printJob, index) => {
		// Add reprint text if necesssary
		const printMarkup: string = printJob.data;
		// Encode receipt and open quickprinter
		const textEncoded = encodeURI(
			`${printMarkup.slice(0, printMarkup.indexOf('>'))}${
				reprint ? '<BR><CENTER> REPRINT ' : ''
			}${printMarkup.slice(printMarkup.indexOf('>') + 1)}`
		);
		setTimeout(() => {
			window.open(
				`intent://${textEncoded}#Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;`,
				'_blank'
			);
		}, 4000 * index);
	});
};

/** Reset Order State */
export const resetOrderState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_ORDER_STATE());
};

export default orderSlice.reducer;
