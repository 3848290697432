import { Field, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

import {
	StyledActions,
	StyledColumn,
	StyledHeader,
	StyledDay,
	StyledDayFields,
	StyledDayHeaders,
	StyledDivider,
	StyledFieldGroup,
	StyledForm,
	StyledInput,
	StyledMessage,
	StyledSelect,
	StyledSwitchWrap,
	StyledHeaderMobile,
	StyledMobileButtons,
	StyledTimePickerInput,
} from './service-availability.styles';

import { EOrderType } from 'app.types';
import Button from 'components/button/button.component';
import { IOption } from 'components/form-inputs/select/select.component';
import Switch from 'components/form-inputs/switch/switch.component';
import OrderIcon from 'components/order-icon/order-icon.component';
import { formatCapitalizeString } from 'helpers/capitalize-string.helper';
import { transformTimeslots } from 'helpers/transform-service-availability-dto-to-form.helper';
import { transformFormValuesToSubmitValues } from 'helpers/transform-service-availability-form-to-submit.helper';
import { transformOrderTypeToServiceType } from 'helpers/transformOrderTypeToServiceType.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { patchServiceAvailability } from 'modules/venue/venue.slice';
import {
	ITimeslot,
	IVenueServiceAvailability,
	IVenueServiceAvailabilityFormValues,
} from 'modules/venue/venue.types';

interface IComponentProps {
	orderType: keyof typeof EOrderType;
	timeslots: ITimeslot[];
	prepTime: number;
	customServiceDetails?: {
		serviceId: string;
		name: string;
		iconUrl: string;
	};
	editing: boolean;
	setEditing: (val: boolean) => void;
	canEdit: boolean;
}

// TODO: Availability functionality not implemented,

/** Renders Service Availability component */
const ServiceAvailability: React.FC<IComponentProps> = ({
	timeslots,
	orderType,
	prepTime,
	customServiceDetails,
	editing,
	setEditing,
	canEdit,
}) => {
	const dispatch = useDispatch();
	const [formSubmission, setFormSubmission] = useState(false);
	const isTableService = orderType === 'tableService';

	const { selectedVenue } = useSelector((state: RootState) => state.venue);

	const formattedDays = transformTimeslots(timeslots);
	const dayDetailsAvailability: IVenueServiceAvailability = formattedDays.reduce(
		(o, key) => ({ ...o, [key.name]: key }),
		{}
	);
	const dayDetails: IVenueServiceAvailability = {
		monday: dayDetailsAvailability.monday,
		tuesday: dayDetailsAvailability.tuesday,
		wednesday: dayDetailsAvailability.wednesday,
		thursday: dayDetailsAvailability.thursday,
		friday: dayDetailsAvailability.friday,
		saturday: dayDetailsAvailability.saturday,
		sunday: dayDetailsAvailability.sunday,
	};

	const selectedServiceType = selectedVenue?.serviceTypes?.find(
		(serviceType) => {
			if (serviceType.type === orderType) {
				return true;
			}
			return false;
		}
	);

	const initialValues: IVenueServiceAvailabilityFormValues = {
		days: dayDetails,
		prepTime,
		ordersLimitedPerTimeslot: !selectedServiceType?.ignoreMaxOrders ?? true,
	};

	const timeIntervals: IOption<number>[] = [
		{
			value: 15,
			label: '15',
		},
		{
			value: 20,
			label: '20',
		},
		{
			value: 25,
			label: '25',
		},
		{
			value: 30,
			label: '30',
		},
		{
			value: 40,
			label: '40',
		},
		{
			value: 45,
			label: '45',
		},
		{
			value: 50,
			label: '50',
		},
		{
			value: 55,
			label: '55',
		},
		{
			value: 60,
			label: '60',
		},
	];

	const handleSubmit = async (
		values: IVenueServiceAvailabilityFormValues,
		{ setSubmitting }: FormikHelpers<IVenueServiceAvailabilityFormValues>
	) => {
		const submitValues = transformFormValuesToSubmitValues(values);
		const serviceType = transformOrderTypeToServiceType(orderType);
		const response = await dispatch(
			patchServiceAvailability(selectedVenue.id, submitValues, serviceType)
		);

		if (!response) {
			setSubmitting(false);
			return;
		}

		setSubmitting(false);

		setEditing(false);
		setFormSubmission(true);

		await fireDialog({
			title: intl.formatMessage({
				id: 'alerts.success.title',
			}),
			icon: 'success',
			showConfirmButton: false,
			timer: 3000,
			iconHtml: `<svg width="103" height="103" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M77.5588 36.8739L43.3628 71.0699C42.6418 71.7909 41.7148 72.0999 40.6848 72.0999C39.7578 72.0999 38.7278 71.7909 38.0068 71.0699L25.4408 58.5039C24.1018 57.1649 24.1018 54.8989 25.4408 53.5599C26.1618 52.8389 26.9858 52.5299 27.9128 52.5299C28.8398 52.5299 29.7668 52.8389 30.3848 53.5599L40.6848 63.8599L72.6148 31.9299C73.3358 31.2089 74.1598 30.8999 75.0868 30.8999C76.0138 30.8999 76.9408 31.2089 77.5588 31.9299C78.8978 33.2689 78.8978 35.5349 77.5588 36.8739Z" fill="currentColor"/>
			<circle cx="51.5" cy="51.5" r="48" stroke="currentColor" stroke-width="7"/>
			</svg>`,
			customClass: {
				icon: 'swal-custom-icon-html',
			},
		});
	};

	const onCancelEdit = async (isDirty: boolean, resetForm: () => void) => {
		if (isDirty && !formSubmission) {
			const { value } = await fireDialog({
				title: intl.formatMessage({
					id: 'settings.openingTimesForm.cancelEdit.title',
				}),
				text: intl.formatMessage({
					id: 'settings.openingTimesForm.cancelEdit.text',
				}),
				showCancelButton: true,
			});

			// if user cancels do nothing
			if (!value) {
				return;
			}

			// reset form
			resetForm();
		}

		setEditing(false);
	};
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ values, dirty, errors, isSubmitting, resetForm }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<StyledFieldGroup>
						<StyledColumn>
							<StyledHeader>
								<div className="sub-title">
									<div className="sub-order-type">
										<OrderIcon
											variant={orderType}
											customIcon={customServiceDetails?.iconUrl}
											height={35}
											width={35}
											mobileHeight={25}
											mobileWidth={25}
										/>
									</div>
									<h3>
										{customServiceDetails?.name && customServiceDetails.name}
										{!customServiceDetails &&
											(orderType === 'clickAndCollect'
												? 'Click & Collect'
												: orderType.replace(/([A-Z])/g, ' $1').trim())}
									</h3>
									<StyledDivider />
									<h4>
										<FormattedMessage id="settings.service_availability.title" />
									</h4>
								</div>
								{editing ? (
									<StyledActions>
										<Button
											type="button"
											ariaLabel="cancel-button"
											variant="tertiary"
											onClick={() => onCancelEdit(dirty, resetForm)}
											className="sub-order-cancel-desktop-edit"
										>
											<FormattedMessage id="form.button.cancel" />
										</Button>
										<Button
											type="submit"
											disabled={isSubmitting}
											ariaLabel="submit-button"
											className="sub-order-save-desktop-edit"
										>
											<FormattedMessage id="form.button.save" />
										</Button>
									</StyledActions>
								) : (
									<StyledActions>
										<Button
											type="button"
											ariaLabel="edit-button"
											onClick={() => setEditing(true)}
											disabled={!canEdit}
										>
											<FormattedMessage id="form.button.edit" />
										</Button>
									</StyledActions>
								)}
							</StyledHeader>
							<StyledHeaderMobile>
								<div className="sub-title">
									<h3>
										{customServiceDetails?.name && customServiceDetails.name}
										{!customServiceDetails &&
											(orderType === 'clickAndCollect'
												? 'Collection'
												: orderType.replace(/([A-Z])/g, ' $1').trim())}
									</h3>
								</div>

								{!editing && (
									<>
										<StyledActions>
											<Button
												type="button"
												ariaLabel="edit-button"
												onClick={() => setEditing(true)}
												disabled={!canEdit}
											>
												<FormattedMessage id="form.button.edit" />
											</Button>
										</StyledActions>
									</>
								)}
							</StyledHeaderMobile>
							{editing ? (
								<>
									<StyledMessage>
										{!isTableService && (
											<>
												<div className="sub-order-prep-time-message edit">
													<FormattedMessage id="serviceAvailability.orderPrepTime" />
													<div>
														<Field
															component={StyledSelect}
															selectOptions={timeIntervals}
															name="prepTime"
															value={timeIntervals.filter(
																(option) => option.value === values.prepTime
															)}
														/>
													</div>
												</div>
												<div className="sub-order-prep-time-message-mobile edit">
													<p>
														<FormattedMessage id="serviceAvailability.orderPrepTime.edit" />
													</p>
													<div>
														<Field
															component={StyledSelect}
															selectOptions={timeIntervals}
															name="prepTime"
															value={timeIntervals.filter(
																(option) => option.value === values.prepTime
															)}
														/>
													</div>
												</div>
												<div className="sub-order-limit-message edit">
													<p>
														<FormattedMessage id="serviceAvailability.ordersLimitedPerSlot" />
													</p>

													<StyledSwitchWrap>
														<Field
															component={Switch}
															name="ordersLimitedPerTimeslot"
															onText={intl.formatMessage({
																id:
																	'serviceAvailability.ignoreMaxOrders.enable',
															})}
															offText={intl.formatMessage({
																id:
																	'serviceAvailability.ignoreMaxorders.disable',
															})}
														/>
													</StyledSwitchWrap>
												</div>
											</>
										)}
									</StyledMessage>
								</>
							) : (
								<>
									{!isTableService && (
										<StyledMessage>
											<div className="sub-order-prep-time-message">
												<FormattedMessage id="serviceAvailability.orderPrepTime" />{' '}
												{prepTime}
											</div>
											<div className="sub-order-prep-time-message-mobile">
												<FormattedMessage id="serviceAvailability.orderPrePTimeShort" />{' '}
												{prepTime}
											</div>

											<div className="sub-order-limit-message">
												<FormattedMessage id="serviceAvailability.ordersLimitedPerSlot" />
											</div>
										</StyledMessage>
									)}
								</>
							)}
							<StyledDayHeaders>
								<StyledDayFields>
									<div className="sub-day">
										<FormattedMessage id="settings.serviceAvailability.list.day" />
									</div>
									<div className="sub-availability">
										<FormattedMessage id="settings.serviceAvailability.list.availability" />
									</div>
									<div className="sub-start-time">
										<FormattedMessage id="settings.serviceAvailability.list.from" />
									</div>
									<div className="sub-end-time">
										<FormattedMessage id="settings.serviceAvailability.list.to" />
									</div>
									{!isTableService && (
										<>
											<div className="sub-duration">
												<FormattedMessage id="settings.serviceAvailability.list.slotDuration" />
											</div>
											<div className="sub-orders-per-slot">
												<FormattedMessage id="settings.serviceAvailability.list.ordersPerSlot" />
											</div>
										</>
									)}
								</StyledDayFields>
							</StyledDayHeaders>
							{[
								'monday',
								'tuesday',
								'wednesday',
								'thursday',
								'friday',
								'saturday',
								'sunday',
							].map((day) => (
								<StyledDay key={day}>
									<StyledDayFields>
										{editing ? (
											<>
												<div className="edit">
													<div>
														<h3>{day}</h3>
													</div>
												</div>
												<div className="edit">
													<Field
														component={Switch}
														name={`days[${day}].isOpen`}
														onText={intl.formatMessage({
															id: 'form.switch.onText.default',
														})}
														offText={intl.formatMessage({
															id: 'form.switch.offText.default',
														})}
														label={formatCapitalizeString(day)}
														placeholder={formatCapitalizeString(day)}
													/>
												</div>
												<div className="edit">
													<Field
														component={StyledTimePickerInput}
														name={`days[${day}].openingTime`}
														className={`${
															!values.days[day].isOpen ? 'mod-disabled' : ''
														}`}
														isDisabled={!values.days[day].isOpen}
														label={intl.formatMessage({
															id: 'settings.serviceAvailability.list.from',
														})}
														placeholder={intl.formatMessage({
															id: 'settings.serviceAvailability.list.from',
														})}
													/>
												</div>
												<div className="edit">
													<Field
														component={StyledTimePickerInput}
														className={`${
															!values.days[day].isOpen ? 'mod-disabled' : ''
														}`}
														name={`days[${day}].closingTime`}
														isDisabled={!values.days[day].isOpen}
														label={intl.formatMessage({
															id: 'settings.serviceAvailability.list.to',
														})}
														placeholder={intl.formatMessage({
															id: 'settings.serviceAvailability.list.to',
														})}
													/>
												</div>
												{!isTableService && (
													<>
														<div className="edit">
															<Field
																component={StyledInput}
																className={`${
																	!values.days[day].isOpen ? 'mod-disabled' : ''
																}`}
																name={`days[${day}].slotDuration`}
																value={values.days[day].slotDuration}
																label={intl.formatMessage({
																	id: 'settings.serviceAvailability.slots',
																})}
																placeholder={intl.formatMessage({
																	id: 'settings.serviceAvailability.slots',
																})}
																isDisabled={!values.days[day].isOpen}
																type="number"
																min="0"
																pattern="[0-9]*"
																inputMode="decimal"
															/>
														</div>
														<div className="edit">
															<Field
																component={StyledInput}
																className={`${
																	!values.days[day].isOpen ? 'mod-disabled' : ''
																}`}
																name={`days[${day}].ordersPerSlot`}
																value={values.days[day].ordersPerSlot}
																label={intl.formatMessage({
																	id: 'settings.serviceAvailability.orders',
																})}
																placeholder={intl.formatMessage({
																	id: 'settings.serviceAvailability.orders',
																})}
																isDisabled={
																	!values.days[day].isOpen ||
																	!values.ordersLimitedPerTimeslot
																}
																type="number"
																min="0"
																pattern="[0-9]*"
																inputMode="decimal"
															/>
														</div>
													</>
												)}
											</>
										) : (
											<>
												<div>
													<h3>{day}</h3>
												</div>
												<div
													className={`sub-availability ${
														values.days[day].isOpen ? 'open' : 'closed'
													}`}
												>
													{values.days[day].isOpen ? 'On' : 'Off'}
												</div>

												<div
													className={`${
														!values.days[day].isOpen ? 'mod-disabled' : ''
													} ${isTableService ? 'table-service-style' : ''}`}
												>
													<div
														className={`mobile-row-titles ${
															!values.days[day].isOpen ? 'mod-disabled' : ''
														}`}
													>
														<FormattedMessage id="settings.serviceAvailability.start" />
													</div>

													{values.days[day].openingTime ?? '-'}
												</div>

												<div
													className={`${
														!values.days[day].isOpen ? 'mod-disabled' : ''
													} ${isTableService ? 'table-service-style' : ''}`}
												>
													<div
														className={`mobile-row-titles ${
															!values.days[day].isOpen ? 'mod-disabled' : ''
														}`}
													>
														<FormattedMessage id="settings.serviceAvailability.end" />
													</div>
													{values.days[day].closingTime ?? '-'}
												</div>
												{!isTableService && (
													<>
														<div
															className={`sub-duration ${
																!values.days[day].isOpen ? 'mod-disabled' : ''
															}`}
														>
															<div
																className={`mobile-row-titles ${
																	!values.days[day].isOpen ? 'mod-disabled' : ''
																}`}
															>
																<FormattedMessage id="settings.serviceAvailability.slots" />
															</div>
															{values.days[day].slotDuration}
														</div>

														<div
															className={`sub-orders-per-slot ${
																!values.days[day].isOpen ? 'mod-disabled' : ''
															}`}
														>
															<div
																className={`mobile-row-titles ${
																	!values.days[day].isOpen ? 'mod-disabled' : ''
																}`}
															>
																<FormattedMessage id="settings.serviceAvailability.orders" />
															</div>
															{values.days[day].ordersPerSlot}
														</div>
													</>
												)}
											</>
										)}
									</StyledDayFields>
								</StyledDay>
							))}
							{editing && (
								<StyledMobileButtons>
									<Button
										type="submit"
										disabled={isSubmitting}
										ariaLabel="submit-button"
									>
										<FormattedMessage id="form.button.save" />
									</Button>
									<Button
										type="button"
										ariaLabel="cancel-button"
										onClick={() => onCancelEdit(dirty, resetForm)}
										className="light"
									>
										<FormattedMessage id="form.button.cancel" />
									</Button>
								</StyledMobileButtons>
							)}
						</StyledColumn>
					</StyledFieldGroup>
				</StyledForm>
			)}
		</Formik>
	);
};

export default ServiceAvailability;
