import { captureException } from '@sentry/react';
import LogRocket from 'logrocket';
import React, { Component } from 'react';

import { fireDialog } from '../dialog/dialog.service';
import { intl } from '../i18n/i18n.config';

// Create typing for component props
interface IErrorBoundaryProps {}

// Create typing for component state
interface IErrorBoundaryState {
	error: string | null;
}

/** ErrorBoundary component */
class ErrorBoundary extends Component<
	IErrorBoundaryProps,
	IErrorBoundaryState
> {
	/** Creates an instance of ErrorBoundary. */
	constructor(props: IErrorBoundaryProps) {
		super(props);

		// Set default state
		this.state = { error: null };
	}

	/** Set component state from error */
	static getDerivedStateFromError(error: Error) {
		// If we have an error
		if (error) {
			// Log error to logrocket
			LogRocket.captureException(error);
			// Log error to sentry
			captureException(error);
		}

		// Set error in component state
		return { error: error.message };
	}

	/** Render component */
	render() {
		// If there is an error
		if (this.state.error) {
			// Show error message in alert popup
			fireDialog({
				title: intl.formatMessage({ id: 'errors.exception.title' }),
				text: this.state.error,
				allowOutsideClick: false,
				allowEnterKey: false,
				allowEscapeKey: false,
				showConfirmButton: false,
			});

			return <></>;
		}

		return <>{this.props.children}</>;
	}
}

export default ErrorBoundary;
