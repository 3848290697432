import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { EWebviewMessageType, IWebviewStore } from './webview.types';

// Create initial state for webview slice
export const initialWebviewSlice: IWebviewStore = {};

const webviewSlice = createSlice({
	name: 'webview',
	initialState: initialWebviewSlice,
	reducers: {
		REGISTER_VENUE_REQUEST(state) {
			return {
				...state,
				isVenueRegistered: true,
			};
		},
		DEREGISTER_VENUE_REQUEST(state) {
			return {
				...state,
				isVenueRegistered: false,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	REGISTER_VENUE_REQUEST,
	DEREGISTER_VENUE_REQUEST,
} = webviewSlice.actions;

/** Thunk to process register venue for webview notifications */
export const processRegisterVenueForWebview = (venueId: string) => async (
	dispatch: Dispatch
) => {
	if (window.ReactNativeWebView) {
		window.ReactNativeWebView.postMessage(
			JSON.stringify({
				type: EWebviewMessageType.registerPushNotification,
				payload: { venueId },
			})
		);

		// register venue request
		dispatch(REGISTER_VENUE_REQUEST());
	}
};

/** Thunk to process deregister venue for webview notifications */
export const processDeregisterVenueForWebview = (venueId: string) => async (
	dispatch: Dispatch,
	getState: () => { webview: IWebviewStore }
) => {
	// if we are in a web view & venue is registered
	if (window.ReactNativeWebView && getState().webview.isVenueRegistered) {
		// De register venue push notifications
		window.ReactNativeWebView.postMessage(
			JSON.stringify({
				type: EWebviewMessageType.deRegisterPushNotification,
				payload: { venueId },
			})
		);
		// deregister venue request
		dispatch(DEREGISTER_VENUE_REQUEST());
	}
};

export default webviewSlice.reducer;
