import { Formik, Form, Field, FormikHelpers } from 'formik';
import { rgba } from 'polished';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ModalProps } from 'styled-react-modal';

import OrderDetailsItemsWrapper from './order-details-items-wrapper/order-details-items-wrapper.component';
import OrderDetailsModalAlert from './order-details-modal-alert/order-details-modal-alert.component';
import OrderTotals from './order-totals/order-totals.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Button from 'components/button/button.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import Icon from 'components/icons/icon.component';
import Modal, {
	IComponentProps as IModalProps,
} from 'components/modal/modal.component';
import OrderIcon from 'components/order-icon/order-icon.component';
import StatusPill from 'components/status-pill/status-pill.component';
import { isOrderCompleted } from 'helpers/completed-orders.helper';
import dayjs from 'helpers/dayjs.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import {
	updateOrderStatus,
	refundOrder,
	orderNote,
	printOrder,
} from 'modules/orders/order.slice';
import { IOrder, IOrderRefund } from 'modules/orders/order.types';

interface IFormValueProps {
	refundServiceCharge: boolean;
	refundTip: boolean;
	refundReason: string;
	notes: string;
	items: {
		[key: string]: string;
	};
}

interface IStyledModal extends ModalProps {
	viewHeight?: number;
}

interface IStyledComponent {
	viewHeight?: number;
}

const StyledModal = styled(Modal)<IStyledModal>`
	width: calc(100% - 50px);
	max-width: 789px;
	height: auto;
	max-height: 780px;
	padding: 0;
	border-radius: 15px;
	position: relative;
	display: block;
	z-index: 2;

	${mq.mobile`
		padding: 30px 0 0;
		height: 80%;
		width: calc(100% - 22px);
	`}
`;

const StyledClose = styled.div`
	cursor: pointer;
	position: absolute;
	top: -53px;
	right: 0;
	width: 38px;
	height: 38px;
	background: ${brand.white};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
`;

const StyledOrderView = styled.div<IStyledComponent>`
	display: flex;
	padding: 43px 30px 0;
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-height: 650px;
	background: ${brand.white};
	border-radius: 15px;

	overflow: scroll;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}

	${mq.mobile`
		padding: 0;
		display: block;
	`}
`;

const StyledOrderInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 12px;
	width: 100%;

	${mq.mobile`
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		padding: 0 2%;
	`}
`;

const StyledOrderDetails = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${mq.mobile`
		align-items: flex-start;
	`}
`;

const StyledInfoWrapper = styled.div`
	display: flex;
	margin: 0 0 0 17px;
	flex-direction: column;
	align-items: flex-start;

	.sub-name {
		h3 {
			font-size: ${fonts.sizes.h1};
			margin: 0 0 5px;

			${mq.mobile`
				font-size: ${fonts.sizes.larger};
				margin: 0;
				font-weight: ${fonts.weights.light};
			`}
		}
	}

	.sub-details {
		display: flex;
		align-items: center;

		${mq.mobile`
			flex-wrap: wrap;
		`}

		> div {
			display: flex;
			align-items: center;
			font-size: ${fonts.sizes.large};
			font-weight: ${fonts.weights.bold};

			${mq.mobile`
				font-size: ${fonts.sizes.semiLarge};
			`}

			&:after {
				content: '';
				width: 1px;
				height: 18px;
				background: ${brand.black};
				margin: 0 10px;
				display: inline-block;
			}

			&.sub-time span {
				margin-right: 5px;
			}

			&.sub-order-number:after {
				display: none;
			}

			span {
				font-weight: ${fonts.weights.light};
			}
		}
	}

	${mq.mobile`
		flex-basis: auto;
		margin: 0 0 0 15px;
	`}
`;

const StyledOrderActions = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	button {
		&:first-child {
			margin-bottom: 10px;

			div {
				transform: none;
				margin: 0 0 0 5px;
			}
		}

		div {
			opacity: 75%;
			margin: 0 5px 0 0;
			transform: rotate(180deg);
		}
	}

	${mq.mobile`
		flex-direction: row-reverse;
		align-items: stretch;
		width: 100%;

		button {
			font-weight: ${fonts.weights.medium};
			padding: 10px 0;
			flex-basis: calc(50% - 3px);
			margin: 0 !important;
		}
	`}
`;

const StyledIconButton = styled.button`
	margin-left: 20px;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;

	${mq.mobile`
		position: absolute;
		top: 15px;
		right: 15px;
	`}
`;

const StatusPillWrapper = styled.div`
	display: flex;

	&.mod-mobile {
		display: none;
	}

	${mq.mobile`
		display: none;

		&.mod-mobile {
			margin: 12px 0 15px;
			display: flex;
		}
	`}
`;

const StyledStatusPill = styled(StatusPill)`
	&:first-child {
		margin-right: 10px;
	}
`;

const StyledCustomServiceInfo = styled.div`
	background: ${brand.background};
	padding: 12px;
	margin: 0 0 12px;
	border-radius: 5px;
`;

const StyledInfoList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
`;

const StyledInfoItem = styled.li`
	display: flex;
	margin: 0;
	padding: 0;
	align-items: center;

	h3,
	h4 {
		margin: 0;
		color: ${brand.white};
		font-size: ${fonts.sizes.large};
		font-weight: ${fonts.weights.light};
	}

	h4 {
		font-weight: ${fonts.weights.bold};
		margin: 0 0 0 7px;
	}

	&:not(:last-child):after {
		content: '|';
		font-size: ${fonts.sizes.large};
		margin: 0 7px;
		color: ${brand.white};
	}
`;

const StyledCustomerAdditionalInfo = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.light};
	color: ${brand.white};
`;

const StyledAdditionalNoteItem = styled(Icon)`
	opacity: 0.5;
	margin: 0 10px 0 0;
`;

const StyledOrderWrapper = styled.div`
	padding: 7px 2%;
	background: ${rgba(brand.text, 0.1)};
	border-radius: 10px;

	${mq.mobile`
		border-radius: 0;
	`}
`;

const StyledTextArea = styled(TextArea)`
	margin: 0 0 16px;
`;

const StyledActionTotals = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 20px 0;

	${mq.mobile`
		margin: 18px 2% 4px;
		flex-direction: column-reverse;
	`}
`;

const StyledButtonWrapper = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	${mq.mobile`
	padding: 16px 0 0;
		width: 100%;
	`}
`;

const StyledButton = styled(Button)`
	flex-basis: calc(33% - 4px);
	display: flex;
	justify-content: center;
	align-items: center;

	&.mod-half {
		flex-basis: calc(50% - 5px);
	}

	${mq.mobile`
		flex-basis: 100%;
		margin: 0 0 5px;
		width: 100%;
	`}
`;

const StyledCustomerNotes = styled.div`
	background: ${brand.note_background};
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.small};
	padding: 9px 20px 14px;
	border-radius: 10px;
	margin: 0 0 12px;
	h3 {
		font-weight: bold;
		font-size: ${fonts.sizes.base};
		line-height: ${fonts.line_height.small};
		color: ${brand.note_title};
		margin: 0 0 2px;
		text-transform: uppercase;
	}
	p {
		margin: 0;
	}
	p:not(:last-child) {
		margin: 0 0 5px;
	}
`;

/** Renders Modal component */
const OrderDetailsModal: React.FC<IOrder & IModalProps> = (props) => {
	const {
		id,
		collectAt,
		type,
		notes,
		orderNum,
		tableNumber,
		customer,
		orderedAt,
		total,
		items,
		isOpen,
		status,
		toggleModal,
		refundReason,
		customService,
		confirmationLineItems,
		serviceCharge,
		tip,
		alertConfig,
		customerNotes,
		printStatus,
	} = props;

	const dispatch = useDispatch();

	// use states for order note/refunds
	const [isOrderNote, setIsOrderNote] = useState<boolean>(false);
	const [isRefund, setIsRefund] = useState<boolean>(false);

	const { name, allergens, phone } = customer;

	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	// variable for allergens
	let allergenList: string = '';

	// add allergens to string
	!!allergens &&
		allergens?.length > 0 &&
		allergens.forEach((allergen, index) => {
			if (index === 0) {
				allergenList += allergen.name;
			} else {
				allergenList += ` | ${allergen.name}`;
			}
		});

	// Varible for initial form values
	const initialFormValues: IFormValueProps = {
		notes: notes || '',
		refundReason: refundReason || '',
		refundServiceCharge: serviceCharge?.refunded || false,
		refundTip: tip.isRefunded || false,
		items: {},
	};

	// Check if order is from the past (e.g. user is on order history page)
	const orderFromPast = collectAt
		? // Collection order was collected before today
		  dayjs(collectAt).isBefore(dayjs(), 'day')
		: // Table service order was ordered before today
		  type === 'tableService' && dayjs(orderedAt).isBefore(dayjs(), 'day');

	// Check if order is completed or from the past
	const orderCompleted = isOrderCompleted(items) || orderFromPast;

	// Set initial form values
	items
		.filter((item) => item.refundedQuantity !== item.quantity)
		.forEach((item) => {
			initialFormValues.items[item.id] = '0';
		});

	// variable to hold waiting items
	const nonRefundedItems = items.filter(
		(item) => item.refundedQuantity !== item.quantity
	);

	// variable to hold refunded items
	const refundedItems = items.filter((item) => item.refundedQuantity > 0);

	// Handle print

	const handlePrint = () => {
		dispatch(printOrder(id, printStatus === 'Complete'));
	};

	// Handle form submission
	const handleFormSubmit = async (
		values: IFormValueProps,
		{ setFieldValue }: FormikHelpers<IFormValueProps>
	) => {
		const {
			refundServiceCharge,
			refundTip,
			notes: orderNotes,
			refundReason: orderRefund,
			items: orderItems,
		} = values;

		// if we have items
		if (isRefund) {
			// Create refund object
			const refund: IOrderRefund = {
				id,
				items: Object.entries(orderItems)
					.filter((orderItem) => parseFloat(orderItem[1]) > 0)
					.map((orderItem) => {
						return {
							id: orderItem[0],
							quantity: parseFloat(orderItem[1]),
						};
					}),
				reason: orderRefund,
			};

			// if service charge has changed, add it to refund
			if (serviceCharge?.refunded !== refundServiceCharge) {
				refund.refundServiceCharge = refundServiceCharge;
			}

			// if tip has changed, add it to refund
			if (tip.isRefunded !== refundTip) {
				refund.refundTip = refundTip;
			}

			// Send refund order
			await dispatch(refundOrder(refund));

			fireDialog({
				title: intl.formatMessage({
					id: 'orderDetailsModal.refund.dialog.success',
				}),
				icon: 'success',
				showConfirmButton: false,
				timer: 2500,
			});
		} else {
			// send query order
			await dispatch(orderNote(id, orderNotes));
		}

		// Reset item fields to 0
		Object.keys(orderItems).forEach((key) => {
			setFieldValue(`items.${key}`, 0);
		});

		// exit query refund screen
		setIsRefund(false);
		setIsOrderNote(false);
	};

	useEffect(() => {
		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => window.removeEventListener('resize', getSetViewHeight);
	}, []);

	const orderDate = collectAt
		? dayjs(collectAt).tz('Europe/London')
		: dayjs(orderedAt).tz('Europe/London');

	return (
		<StyledModal
			isOpen={isOpen}
			toggleModal={toggleModal}
			allowScroll={true}
			backgroundProps={{ viewHeight }}
			viewHeight={viewHeight}
		>
			<StyledClose onClick={() => toggleModal()}>
				<Icon name="close" width={13} height={13} />
			</StyledClose>
			<OrderDetailsModalAlert
				alert={alertConfig?.isOrderInAlert || false}
				escalation={alertConfig?.isOrderInEscalation || false}
			>
				<StyledOrderView viewHeight={viewHeight}>
					<div>
						<StyledOrderInfo>
							<StyledOrderDetails>
								<OrderIcon
									width={65}
									height={65}
									mobileWidth={45}
									mobileHeight={45}
									variant={type}
									customIcon={customService?.imageUrl}
								/>
								<StyledInfoWrapper>
									<div className="sub-name">
										<h3 data-private>
											{name || intl.formatMessage({ id: 'order.unkownName' })}
										</h3>
									</div>
									<div className="sub-details">
										<div className="sub-time">
											<span>{orderDate.format('Do MMM')}</span>
											{orderDate.format('HH:mm')}
										</div>
										{!!tableNumber && (
											<div className="sub-table">
												<span>
													<FormattedMessage id="orderCard.table" />
												</span>
												&nbsp;
												{tableNumber}
											</div>
										)}
										<div className="sub-order-number">
											<span>#</span>
											{orderNum}
										</div>
										{!!phone && (
											<StyledIconButton
												type="button"
												data-testid="telephone-button"
												onClick={() =>
													fireDialog({
														title: `${intl.formatMessage({
															id: 'order_details.telephone.dialog.title',
														})} <span style="display: inline-block; margin-left: 5px;" data-private> ${name}</span>`,
														html: `<div class="telephone-dialog"><h5>${intl.formatMessage(
															{ id: 'order_details.telephone.dialog.copy' }
														)}</h5><h4 data-private>${phone}</h4></div>`,
													})}
											>
												<Icon
													name="phone"
													width={30}
													height={30}
													colour="primary"
												/>
											</StyledIconButton>
										)}
									</div>
								</StyledInfoWrapper>
							</StyledOrderDetails>
							<StatusPillWrapper className="sub-status-pills mod-mobile">
								{!!allergens && allergens.length > 0 && (
									<StyledStatusPill
										type="warning"
										backgroundColour="order_allergy"
									>
										{allergenList}
									</StyledStatusPill>
								)}
								{(!!notes || !!refundReason) && (
									<StyledStatusPill backgroundColour="order_query" />
								)}
								{customerNotes && customerNotes.length > 0 && (
									<StyledStatusPill backgroundColour="note_background" />
								)}
							</StatusPillWrapper>
							{!orderCompleted && (
								<StyledOrderActions>
									{status === 'venueAccepted' && (
										<StyledButton
											onClick={() =>
												dispatch(updateOrderStatus(id, 'orderBeingPrepared'))}
										>
											<FormattedMessage id="orders.button.inProgress" />
											<Icon
												name="chevron"
												colour="white"
												width={12}
												height={12}
												heightMobile={10}
												widthMobile={10}
											/>
										</StyledButton>
									)}
									{status === 'orderBeingPrepared' && (
										<>
											<StyledButton
												onClick={() =>
													dispatch(updateOrderStatus(id, 'pendingPickup'))}
											>
												<FormattedMessage id="orders.button.pickup" />
												<Icon
													name="chevron"
													colour="white"
													width={12}
													height={12}
													heightMobile={10}
													widthMobile={10}
												/>
											</StyledButton>
											<StyledButton
												variant="secondary"
												onClick={() =>
													dispatch(updateOrderStatus(id, 'venueAccepted'))}
											>
												<Icon
													name="chevron"
													colour="primary"
													width={12}
													height={12}
													heightMobile={10}
													widthMobile={10}
												/>
												<FormattedMessage id="orders.button.move_to_upcoming" />
											</StyledButton>
										</>
									)}
									{status === 'pendingPickup' && (
										<>
											<StyledButton
												onClick={() =>
													dispatch(updateOrderStatus(id, 'completed'))}
											>
												<FormattedMessage id="orders.button.completed" />
												<Icon
													name="chevron"
													colour="white"
													width={12}
													height={12}
												/>
											</StyledButton>
											<StyledButton
												variant="secondary"
												onClick={() =>
													dispatch(updateOrderStatus(id, 'orderBeingPrepared'))}
											>
												<Icon
													name="chevron"
													colour="primary"
													width={12}
													height={12}
												/>
												<FormattedMessage id="orders.button.move_to_in_progress" />
											</StyledButton>
										</>
									)}
								</StyledOrderActions>
							)}
						</StyledOrderInfo>
						<StyledOrderInfo>
							<StatusPillWrapper className="sub-status-pills">
								{!!allergens && allergens.length > 0 && (
									<StyledStatusPill
										type="warning"
										backgroundColour="order_allergy"
									>
										{allergenList}
									</StyledStatusPill>
								)}
								{!!notes && <StyledStatusPill backgroundColour="order_query" />}
								{customerNotes && customerNotes.length > 0 && (
									<StyledStatusPill
										backgroundColour="note_background"
										textColour="text"
										type="info"
									>
										<FormattedMessage id="statusPill.customerNote.text" />
									</StyledStatusPill>
								)}
							</StatusPillWrapper>
						</StyledOrderInfo>
						{type === 'customService' && (
							<StyledCustomServiceInfo>
								<StyledInfoList>
									{!!confirmationLineItems &&
										confirmationLineItems
											.filter((item) => !!item.shortTitle)
											.map((lineItem) => (
												<StyledInfoItem key={lineItem.title}>
													<h3>{lineItem.shortTitle}</h3>
													<h4>{lineItem.value}</h4>
												</StyledInfoItem>
											))}
								</StyledInfoList>
								{!!confirmationLineItems?.find((item) => !item.shortTitle) && (
									<StyledCustomerAdditionalInfo>
										<StyledAdditionalNoteItem
											name="additionalNote"
											width={14}
											height={14}
											colour="white"
										/>
										{
											confirmationLineItems?.find((item) => !item.shortTitle)
												?.value
										}
									</StyledCustomerAdditionalInfo>
								)}
							</StyledCustomServiceInfo>
						)}
					</div>
					{customerNotes && customerNotes.length > 0 && (
						<StyledCustomerNotes>
							<h3>Order note</h3>
							{customerNotes.map(({ roundId, note }) => (
								<p key={roundId}>{note}</p>
							))}
						</StyledCustomerNotes>
					)}
					<Formik
						initialValues={{
							...initialFormValues,
						}}
						onSubmit={handleFormSubmit}
					>
						{({ values, setFieldValue, submitForm, dirty }) => {
							// check for has refunded items
							const hasRefundItems = Object.values(values.items).some(
								(quantity) => {
									return quantity && parseFloat(quantity) > 0;
								}
							);

							// get current values from form
							let refundTotal = Object.entries(values.items)
								// filter items with 0 quantity
								.filter(
									([itemId, refundQuantity]) => parseFloat(refundQuantity) > 0
								)
								.map(([itemId, refundQuantity]) => {
									// get item price
									const refundingItemPrice = items?.find(
										(item) => item.id === itemId
									)?.price.units;

									// if we have a price, calculate the item price * quantity
									if (refundingItemPrice) {
										return refundingItemPrice * parseFloat(refundQuantity);
									}

									// else return 0
									return 0;
								})
								// Reduce price to final total
								.reduce(
									(itemPrice = 0, currentValue = 0) => itemPrice + currentValue,
									0
								);

							// If we have service charge and it's not already refunded, add to total
							if (
								serviceCharge &&
								serviceCharge?.refunded !== values.refundServiceCharge &&
								values.refundServiceCharge === true
							) {
								refundTotal += serviceCharge.amount;
							}

							// If we have tip and it's not already refunded, add to total
							if (
								tip &&
								tip?.isRefunded !== values.refundTip &&
								values.refundTip === true
							) {
								refundTotal += tip.amount;
							}

							const handleCancelRefund = () => {
								setIsOrderNote(false);
								setIsRefund(false);
								setFieldValue('notes', notes);
								setFieldValue('refundReason', refundReason);
								setFieldValue('refundServiceCharge', serviceCharge?.refunded);
								setFieldValue('refundTip', tip?.isRefunded);

								const orderItems = values.items;
								// Reset item fields to 0
								Object.keys(orderItems).forEach((key) => {
									setFieldValue(`items.${key}`, 0);
								});
							};

							// variable to hold buttons to show
							const buttonsToShow = isRefund ? (
								<>
									<StyledButton
										className="mod-half"
										variant="secondary"
										onClick={() => handleCancelRefund()}
									>
										<FormattedMessage id="orderDetails.button.cancel" />
									</StyledButton>
									<StyledButton
										className="mod-half"
										variant="primary"
										onClick={() => submitForm()}
										disabled={
											!values.refundReason ||
											(!!values.refundReason &&
												!(
													hasRefundItems ||
													values.refundServiceCharge ||
													values.refundTip
												))
										}
									>
										<FormattedMessage id="orderDetails.button.confirm" />
									</StyledButton>
								</>
							) : (
								<>
									<StyledButton
										className="mod-half"
										variant="secondary"
										onClick={() => {
											handleCancelRefund();
										}}
									>
										<FormattedMessage id="orderDetails.button.cancel" />
									</StyledButton>
									<StyledButton
										className="mod-half"
										variant="primary"
										type="submit"
										disabled={!values.notes || values.notes === notes}
									>
										<FormattedMessage id="orderDetails.button.confirm" />
									</StyledButton>
								</>
							);
							return (
								<Form>
									<StyledOrderWrapper>
										<OrderDetailsItemsWrapper
											nonRefundedItems={nonRefundedItems}
											refundedItems={refundedItems}
											order={props}
											refundActive={isRefund}
											serviceCharge={serviceCharge}
										/>
										{(values.notes || isOrderNote) && (
											<Field
												component={StyledTextArea}
												label={intl.formatMessage({
													id: 'orderDetails.textarea.query.label',
												})}
												disabled={!isOrderNote}
												name="notes"
												testId="notes-textarea"
												placeholder={intl.formatMessage({
													id: 'order_details.textarea.query.placeholder',
												})}
											/>
										)}
										{(values.refundReason || isRefund) && (
											<Field
												component={StyledTextArea}
												name="refundReason"
												testId="refund-reason-textarea"
												disabled={!isRefund}
												label={intl.formatMessage({
													id: 'orderDetails.textarea.refund.label',
												})}
												placeholder={intl.formatMessage({
													id: 'order_details.textarea.refund.placeholder',
												})}
											/>
										)}
									</StyledOrderWrapper>
									<StyledActionTotals>
										<StyledButtonWrapper>
											{isRefund || isOrderNote ? (
												buttonsToShow
											) : (
												<>
													<StyledButton
														className="mod-half"
														variant="secondary"
														onClick={() => setIsOrderNote(true)}
													>
														<FormattedMessage id="orderDetails.button.addNote" />
													</StyledButton>
													<StyledButton
														data-testid="refund-button"
														className="mod-half"
														variant="secondary"
														onClick={() => setIsRefund(true)}
													>
														<FormattedMessage id="orderDetails.button.refund" />
													</StyledButton>
												</>
											)}
											{printStatus &&
												(printStatus === 'Complete' ? (
													<StyledButton
														className="mod-half"
														variant="primary"
														onClick={() => handlePrint()}
													>
														<FormattedMessage id="orderDetails.button.reprint" />
													</StyledButton>
												) : (
													<StyledButton
														className="mod-half"
														variant="primary"
														onClick={() => handlePrint()}
													>
														<FormattedMessage id="orderDetails.button.print" />
													</StyledButton>
												))}
										</StyledButtonWrapper>
										<OrderTotals
											isRefund={isRefund}
											total={total}
											refundTotal={refundTotal}
										/>
									</StyledActionTotals>
								</Form>
							);
						}}
					</Formik>
				</StyledOrderView>
			</OrderDetailsModalAlert>
		</StyledModal>
	);
};

export default OrderDetailsModal;
