import brand from '../variables/brand';
import fonts from '../variables/fonts';

/** Global date picker styles */
const datePicker = `
	.react-datepicker {
		font-family: ${fonts.family};
		font-size: ${fonts.sizes.med};
		border: 1px solid ${brand.borders};
		user-select: none;
	}

	.react-datepicker__time-container {
		border-left: 1px solid ${brand.borders};
	}

	react-datepicker__triangle {
		display: none;
	}

	.react-datepicker__day--selected,
	.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
		background-color: ${brand.primary};
		outline: none;

		&:hover {
			background-color: ${brand.primary} !important;
		}

		&:focus {
			outline: none;
		}
	}

	.react-datepicker__day--keyboard-selected, 
	.react-datepicker__month-text--keyboard-selected, 
	.react-datepicker__quarter-text--keyboard-selected {
		background-color: ${brand.borders};
		color: ${brand.text};
		outline: none;

		&:hover {
			background-color: ${brand.borders};
		}

		&:focus {
			outline: none;
		}
	}

	.react-datepicker__header {
		border-bottom: 1px solid ${brand.borders};
		background: ${brand.white};
	}

	.react-datepicker__current-month,
	.react-datepicker-time__header,
	.react-datepicker-year-header {
		color: ${brand.text};
		font-weight: ${fonts.weights.medium};
		font-size: ${fonts.sizes.med};
	}

	.react-datepicker__day-names,
	.react-datepicker__week, 
	.react-datepicker__time-list-item {
		font-size: ${fonts.sizes.standard};
	}

	.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
		height: 207px !important;
	}

	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,
	.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item  {
		margin: 0.22rem;
		outline: none;

		&:hover {
			background-color: ${brand.secondary};
		}

		&:focus {
			outline: none;
		}
	}

	.react-datepicker__navigation {
		top: 12px;
		outline: none;
		border-right-color: ${brand.borders};

		&:focus {
			outline: none;
		}

		&:hover {
			border-right-color: ${brand.primary};
		}
	}

	.react-datepicker__navigation--next {
		border-left-color: ${brand.borders};
		border-right: none;

		&:hover {
			border-left-color: ${brand.primary};
		}
	}

	.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
		right: 95px;
	}

	.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today {
		font-weight: ${fonts.weights.medium};
	}
`;

export default datePicker;
