import { Form as fForm, Field as fField } from 'formik';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		font-size: ${fonts.sizes.larger};
		margin-bottom: 15px;
	}

	p {
		max-width: 380px;
		margin-bottom: 55px;
		text-align: center;
	}

	${mq.mobile`
		padding: 50px 12px 0;
		text-align: center;
	`}
`;

export const Form = styled(fForm)`
	display: flex;
	flex-direction: column;

	${mq.mobile`
		width: 100%;
	`}
`;

export const FieldGroup = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 65px;

	input {
		width: 120px;
		height: 40px;
		margin: 0;
		border-radius: 3px;
		border-width: 2px;

		${mq.mobile`
			width: 100px;
		`}
	}
`;

export const Field = styled(fField)`
	margin-right: 20px;

	&:last-child {
		margin-right: 0;
	}

	input {
		text-transform: uppercase;
		text-align: center;
		font-size: ${fonts.sizes.med};
		font-weight: ${fonts.weights.medium};
	}
`;

export const Actions = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	button {
		width: 200px;
		height: 40px;
		margin-bottom: 30px;
	}
`;

export const HelpText = styled.div`
	display: flex;
	justify-content: center;
	color: ${brand.primary};
	font-weight: ${fonts.weights.medium};
	text-decoration: none;
	position: relative;
	cursor: pointer;

	&:hover div {
		display: block;
	}
`;

export const Tooltip = styled.div`
	width: 250px;
	padding: 8px 13px;
	display: none;
	border-radius: 7px;
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.medium};
	background: rgba(0, 0, 0, 0.8);
	color: ${brand.white};
	text-align: center;
	position: absolute;
	top: -85px;

	&:after {
		content: '';
		border-style: solid;
		border-width: 12px 12px 0 12px;
		border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
		position: absolute;
		bottom: -12px;
		left: 113px;
	}

	a {
		color: ${brand.white};
		text-decoration: none;
	}
`;
