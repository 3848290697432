import { Severity } from '@sentry/react';
import { Howl } from 'howler';
import Pusher, { Authorizer, AuthorizerGenerator, Channel } from 'pusher-js';
import {
	AuthorizerCallback,
	AuthorizerOptions,
} from 'pusher-js/types/src/core/auth/options';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useSelector } from 'react-redux';

import { fireDialog } from '../dialog/dialog.service';
import errorLogger from '../error/helpers/error-logger.helper';
import { intl } from '../i18n/i18n.config';
import { history } from '../routing/app-router.component';
import { RootState } from '../state/root.reducer';
import { setNewRelease } from '../state/store-version/store-version.slice';
import PusherContextProvider from './pusher.context';
import { processPusherAuth, updatePusherStatus } from './pusher.slice';
import { IConnectionStateChange } from './pusher.types';

import {
	addLoadingEvent,
	removeLoadingEvent,
} from 'components/loading/loading.slice';
import dayjs from 'helpers/dayjs.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { processLogout } from 'modules/auth/auth.slice';
import {
	getOrder,
	getPrintStatus,
	postOrderReceived,
	pusherNewOrder,
	pusherUpdateOrder,
} from 'modules/orders/order.slice';
import { IPusherNewOrderData } from 'modules/orders/order.types';
import { removeCompletedTicket } from 'modules/ticket-view/ticket-view.slice';
import {
	pusherUpdatePausedServiceTypes,
	pusherUpdateVenueDateHasOrder,
} from 'modules/venue/venue.slice';
import { IPusherPausedService } from 'modules/venue/venue.types';

/** Renders pusher component */
const PusherComponent: FunctionComponent = ({ children }) => {
	// Get useDispatch
	const dispatch = useReduxDispatch();

	const [pusher, setPusher] = useState<Pusher>();
	const [userChannel, setUserChannel] = useState<Channel>();
	const [oplVersionChannel, setOplVersionChannel] = useState<Channel>();
	const [venueChannel, setVenueChannel] = useState<Channel>();
	// Get user
	const { user, accessToken } = useSelector((state: RootState) => state.auth);
	const { selectedVenue, printingEnabled } = useSelector(
		(state: RootState) => state.venue
	);

	// get filtered menus
	const { orderFilters, completedOrders } = useSelector(
		(state: RootState) => state.ticketView
	);

	/**
	 * When user's access token changes
	 * Disconnect pusher + connect if we have an access token
	 * Store pusher instance in component state
	 */
	useEffect(() => {
		// Disconnect existing pusher connections
		pusher?.disconnect();

		if (!accessToken.expiry) {
			return;
		}

		// Creates a custom pusher authentication using redux dispatch
		const customPusherAuth: AuthorizerGenerator = (
			channel: Channel,
			options: AuthorizerOptions
		): Authorizer => {
			return {
				authorize: async (socketId: string, callback: AuthorizerCallback) => {
					// If no access token, do nothing
					if (!accessToken.expiry) {
						return;
					}
					// dispatch pusher auth
					const pusherAuth = await dispatch(
						processPusherAuth(socketId, channel.name)
					);

					// If auth fails
					if (!pusherAuth?.data || pusherAuth.status !== 200) {
						callback(true, {
							auth: '',
						});

						// log pusher auth error
						errorLogger({
							message: `Pusher error calling auth endpoint: ${pusherAuth?.response}`,
							level: Severity.Warning,
						});
					}

					// Tell pusher we authed successfully and pass auth token
					pusherAuth?.data && callback(false, pusherAuth.data);
				},
			};
		};

		// Create a new pusher instance
		const pusherInstance = new Pusher(process.env.REACT_APP_PUSHER_KEY || '', {
			cluster: 'eu',
			authorizer: customPusherAuth,
		});

		// Store pusher instance in component state
		setPusher(pusherInstance);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken.expiry]);

	/*
	 * Subscribes to the state_change event on the pusher connection.
	 * This allows us to debug production issues with pusher easily
	 */
	useEffect(() => {
		if (!pusher) {
			return () => {};
		}
		const stateChangeCallback = (states: IConnectionStateChange) => {
			dispatch(updatePusherStatus(states.current));
		};
		pusher.connection.bind('state_change', stateChangeCallback);
		return () => {
			pusher.unbind('state_change', stateChangeCallback);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pusher]);

	/**
	 * When user ID or pusher connection status change
	 * Subscribe to pusher user channel
	 */
	useEffect(() => {
		if (pusher && pusher.connection.state === 'connected' && user.id) {
			// Subscribe to user channel
			pusher.unsubscribe(`private-user-${user.id}`);
			setUserChannel(pusher.subscribe(`private-user-${user.id}`));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.id, pusher?.connection.state]);

	/**
	 * When pusher connection status change
	 * Subscribe to pusher version channel
	 */
	useEffect(() => {
		if (pusher && pusher.connection.state === 'connected') {
			// Subscribe to OPL channel
			pusher.unsubscribe('private-version-opl');
			setOplVersionChannel(pusher.subscribe('private-version-opl'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pusher?.connection.state]);

	/**
	 * When selected venue or pusher connection status change
	 * Subscribe to pusher user channel
	 */
	useEffect(() => {
		if (pusher && pusher.connection.state === 'connected') {
			// Subscribe to OPL channel
			pusher.unsubscribe(`private-venue-${selectedVenue.id}`);
			setVenueChannel(pusher.subscribe(`private-venue-${selectedVenue.id}`));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedVenue, pusher?.connection.state]);

	/**
	 * If user we have sent a logout request, log the user out correctly
	 */
	const handleSessionLogout = useCallback(
		async (message?: string) => {
			// Track logout event
			errorLogger({
				message: 'Pusher logout',
				level: Severity.Info,
				extra: { user, venue: selectedVenue.name },
			});
			// await for logout processing
			await dispatch(processLogout());
			// Redirect user to login
			history.push('/');

			// If we have a message
			if (message) {
				// Notify the user they have been logged out remotely with custom message attached
				fireDialog({
					icon: 'info',
					title: intl.formatMessage({
						id: 'pusher.remoteLogout.dialog.title',
					}),
					text: message,
				});
			}
		},
		[dispatch, user, selectedVenue]
	);

	/**
	 * When oplVersionChannel or dependencies of handle session logout change
	 * If we are subscribed to oplVersionChannel
	 * Unbind + rebind events to handleSessionLogout
	 */
	useEffect(() => {
		if (!oplVersionChannel) return () => {};
		oplVersionChannel.bind('logout-event', handleSessionLogout);
		return () => {
			oplVersionChannel.unbind('logout-event', handleSessionLogout);
		};
	}, [oplVersionChannel, handleSessionLogout]);

	/**
	 * When venueChannel or dependencies of handle session logout change
	 * If we are subscribed to venueChannel
	 * Unbind + rebind events to handleSessionLogout
	 */
	useEffect(() => {
		if (!venueChannel) return () => {};
		venueChannel.bind('logout-event', handleSessionLogout);
		return () => {
			venueChannel.unbind('logout-event', handleSessionLogout);
		};
	}, [venueChannel, handleSessionLogout]);

	/**
	 * When userChannel or dependencies of handle session logout change
	 * If we are subscribed to userChannel
	 * Unbind + rebind events to handleSessionLogout
	 */
	useEffect(() => {
		if (!userChannel) return () => {};
		userChannel.bind('logout-event', handleSessionLogout);
		return () => {
			userChannel.unbind('logout-event', handleSessionLogout);
		};
	}, [userChannel, handleSessionLogout]);

	// Callback function to handle session refresh event
	const handleSessionRefresh = useCallback(() => {
		// Track refresh event
		errorLogger({
			message: 'Pusher refresh',
			level: Severity.Info,
			extra: { user, venue: selectedVenue.name },
		});
		window.location.reload();
	}, [user, selectedVenue]);

	/**
	 * When oplVersionChannel or dependencies of handle session logout change
	 * If we are subscribed to oplVersionChannel
	 * Unbind + rebind events to refreshSession
	 */
	useEffect(() => {
		if (!oplVersionChannel) return () => {};
		oplVersionChannel.bind('refresh-event', handleSessionRefresh);
		return () => {
			oplVersionChannel.unbind('refresh-event', handleSessionRefresh);
		};
	}, [oplVersionChannel, handleSessionRefresh]);

	/**
	 * When venueChannel or dependencies of handle session logout change
	 * If we are subscribed to venueChannel
	 * Unbind + rebind events to handleSessionRefresh
	 */
	useEffect(() => {
		if (!venueChannel) return () => {};
		venueChannel.bind('refresh-event', handleSessionRefresh);
		return () => {
			venueChannel.unbind('refresh-event', handleSessionRefresh);
		};
	}, [venueChannel, handleSessionRefresh]);

	/**
	 * When userChannel or dependencies of handle session logout change
	 * If we are subscribed to userChannel
	 * Unbind + rebind events to handleSessionLogout
	 */
	useEffect(() => {
		if (!userChannel) return () => {};
		userChannel.bind('refresh-event', handleSessionRefresh);
		return () => {
			userChannel.unbind('refresh-event', handleSessionRefresh);
		};
	}, [userChannel, handleSessionRefresh]);

	// Callback function to handle new version event
	const handleVersionEvent = useCallback(
		({ version }: { version: string }) => {
			// Check that new version is different to current version then set new release in state
			if (version !== process.env.REACT_APP_VERSION) {
				dispatch(setNewRelease());
			}
		},
		[dispatch]
	);

	/**
	 * When oplVersionChannel or dependencies of handle session logout change
	 * If we are subscribed to oplVersionChannel
	 * Unbind + rebind events to handleSessionLogout
	 */
	useEffect(() => {
		if (!oplVersionChannel) return () => {};
		oplVersionChannel.bind('version-event', handleVersionEvent);
		return () => {
			oplVersionChannel.unbind('refresh-event', handleVersionEvent);
		};
	}, [oplVersionChannel, handleVersionEvent]);

	// Callback function to handle paused service event
	const handlePauseServiceEvent = useCallback(
		(data: IPusherPausedService[]) => {
			dispatch(pusherUpdatePausedServiceTypes(data));
		},
		[dispatch]
	);

	/**
	 * When venueChannel or dependencies of handlePauseServiceEvent change
	 * If we are subscribed to venueChannel
	 * Unbind + rebind events to handlePauseServiceEvent
	 */
	useEffect(() => {
		if (!venueChannel) return () => {};
		venueChannel.bind('ServicePauseUpdated', handlePauseServiceEvent);
		return () => {
			venueChannel.unbind('ServicePauseUpdated', handlePauseServiceEvent);
		};
	}, [venueChannel, handlePauseServiceEvent]);

	// Callback function to handle order event
	const handleOrderEvent = useCallback(
		async (data: IPusherNewOrderData) => {
			const eventType = data.type;
			const { order } = data.data;
			const orderDate = order.collectAt || order.orderedAt;

			// eslint-disable-next-line no-console
			console.log('Pusher event: Order', {
				currentTime: new Date().toISOString(),
				eventType,
				orderId: order.id,
				orderDate,
			});

			const isToday = dayjs().isSame(orderDate, 'day');
			const isFutureDate = dayjs().isBefore(orderDate, 'day');

			// If order is completed and order is within completed orders, remove it
			order.status === 'completed' &&
				completedOrders.some(
					(completedOrder) => completedOrder.id === order.id
				) &&
				dispatch(removeCompletedTicket(order.id));

			// If we have filters and order menu ID is not within those filters, ignore
			if (
				!!orderFilters?.length &&
				!order.menuIds.some((menuId) => orderFilters?.includes(menuId))
			) {
				return;
			}

			// If future date
			isFutureDate && dispatch(pusherUpdateVenueDateHasOrder(orderDate));

			// changeable variable for holding the full order
			let fullOrder = order;

			// If order is truncated, get the full order
			if (fullOrder.truncated) {
				try {
					// Try to get full order
					fullOrder = await dispatch(getOrder(order.id));
				} catch {
					try {
						// Start general loading event
						dispatch(addLoadingEvent());
						// Set the retry wait time to axios timeout / 5
						const retryWaitTime =
							parseFloat(process.env.REACT_APP_AXIOS_TIMEOUT!) / 5;
						// await promise resolution
						await new Promise((resolve) => setTimeout(resolve, retryWaitTime));
						// retry get order
						fullOrder = await dispatch(getOrder(order.id));
						// remove general loading event
						dispatch(removeLoadingEvent());
					} catch {
						// remove general loading event
						dispatch(removeLoadingEvent());
						// Fire dialog on fail making the user refresh the app
						fireDialog({
							title: intl.formatMessage({
								id: 'getOrders.retry.error.dialog.title',
							}),
							text: intl.formatMessage({
								id: 'getOrders.retry.error.dialog.text',
							}),
							icon: 'error',
							showConfirmButton: true,
							confirmButtonText: intl.formatMessage({
								id: 'getOrders.retry.error.dialog.button.refresh',
							}),
							onClose: () => window.location.reload(),
						});
					}
				}
			}

			// If today && is new order
			if (isToday && eventType === 'order') {
				// Notification settings
				let loopCounter: number = 0;
				const alertIterations: number = 2;

				// add new order
				await dispatch(pusherNewOrder(fullOrder, selectedVenue.serviceTypes));

				// Acknowledge order received
				dispatch(postOrderReceived(order.id));

				// Get print status
				printingEnabled && dispatch(getPrintStatus(order.id));

				// Play order notification sound
				const alertNotification = new Howl({
					src: ['/new-order-alert-notification.mp3'],
					loop: true,
					onend: () => {
						// Add 1 to counter
						loopCounter += 1;
						// Remove loop on last play
						if (loopCounter === alertIterations - 1) {
							alertNotification.loop(false);
						}
					},
				});

				// Play alert notification
				alertNotification.play();
			}

			// If today && is updated order
			isToday &&
				eventType === 'orderUpdated' &&
				dispatch(pusherUpdateOrder(fullOrder));
		},
		[dispatch, completedOrders, orderFilters, printingEnabled, selectedVenue]
	);

	/**
	 * When venueChannel or dependencies of handleOrderEvent change
	 * If we are subscribed to venueChannel
	 * Unbind + rebind events to handleOrderEvent
	 */
	useEffect(() => {
		if (!venueChannel) return () => {};
		venueChannel.bind('order-event', handleOrderEvent);
		return () => {
			venueChannel.unbind('order-event', handleOrderEvent);
		};
	}, [venueChannel, handleOrderEvent]);

	return (
		<PusherContextProvider pusher={pusher} userChannel={userChannel}>
			{children}
			<div data-testid="pusher-component" />
		</PusherContextProvider>
	);
};

export default PusherComponent;
