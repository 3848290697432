import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
	Container,
	Device,
	TerminalMobile,
	TerminalWisepos,
} from './device-select.styles';

import Button from 'components/button/button.component';

const DeviceSelect: React.FC = () => {
	const history = useHistory();

	const handleDeviceSelect = (deviceType: 'mobile' | 'terminal') => {
		if (deviceType === 'mobile') {
			history.push('/terminal/verify');
			return;
		}
		history.push('/terminal/install');
	};

	return (
		<Container>
			<h2>
				<FormattedMessage id="terminal.device.title" />
			</h2>
			<p>
				<FormattedMessage id="terminal.device.text" />
			</p>
			<div>
				<Device>
					<TerminalMobile />
					<Button onClick={() => handleDeviceSelect('mobile')}>
						<FormattedMessage id="terminal.device.mobile" />
					</Button>
				</Device>
				<Device>
					<TerminalWisepos />
					<Button onClick={() => handleDeviceSelect('terminal')}>
						<FormattedMessage id="terminal.device.terminal" />
					</Button>
				</Device>
			</div>
		</Container>
	);
};

export default DeviceSelect;
